import * as React from "react";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { StyledUsersRow, UserProfileButton } from "../styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import editUser from "../../../../api/v1/users/put";
import { User } from "../../../../context/UserContext";
import Delete from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import { IDepartment } from "../../../../api/v1/types";

interface IUsersRowProps {
  user: User;
  setUsers?: React.Dispatch<React.SetStateAction<{ [key: string]: User }>>;
  handleDelete?: (userId: number) => void;
  onClick? : (id:number) => void;
  departments?: { [key: string]: IDepartment };
}
const UsersTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "#4B4D4E",
    maxWidth: 220,
    border: "1px solid #dadde9",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    cursor: "pointer",
  },
}));
const UserRow = (props: IUsersRowProps) => {
  const {user, setUsers, handleDelete, departments} = props;
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [save, setSave] = React.useState(false);
 

  const handleStatusChange = () => {
    try {
      const updatedUser = user.copyOfUser()
      updatedUser.isActive = !user.isActive;
      editUserStatus(updatedUser);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  
  const handleEditToggle = () => {
    setIsEditing(!isEditing);

  };
  const getDepartmentNameById = (deptId) => {
    const department = departments[deptId];
    return department ? department.name : 'Unknown';
  };

  const handleInputChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const cloneWithPrototype = user.copyOfUser();
    // check if the property exists on the object
    if (cloneWithPrototype.hasOwnProperty(name)) {
      cloneWithPrototype[name] = value;
    }
    setUsers((prevState) => {
      const clonedState = { ...prevState };
      clonedState[cloneWithPrototype.id] = cloneWithPrototype;
      return clonedState;
    });
  };

  const handleSave = (values) => {
    const updatedUser = user.copyOfUser();
    for(let key in values){
      updatedUser[key] = values[key]
    }
    editUserStatus(updatedUser);
    setIsEditing(false);
  };

  const editUserStatus = async (user:User) => {
    try {
      const userData = await editUser(user);
      setUsers((prevState) => {
        const clonedState = { ...prevState };
        clonedState[userData.id] = User.fromAPIResponse(userData);
        return clonedState;
      })
      setSave(true);
    } catch (error) {
      console.error("Error updating user:", error);
      setSave(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      isActive: true,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      password: user?.password,
      department: user?.department,
    },
    onSubmit: handleSave,
    validateOnChange: true,
  });

  return (
    <StyledUsersRow
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        if (isEditing) {
          event.stopPropagation();
        }
      }}
      data-testid="user-row"
    >
      <td>
        {isEditing ? (
        <label className="switch">
          <input
            type="checkbox"
            name="isActive"
            data-testid="um-editing-isActive"
            onChange={formik.handleChange}
            checked={formik.values.isActive}
          />
          <span className="slider round"></span>
          <span
            className={`span-text ${user.isActive ? "active" : ""}`}
          >
            {formik.values.isActive ? "Active" : "Off"}
          </span>
        </label> 
        ) : (
          <label className="switch">
          <input
            type="checkbox"
            name="isActive"
            data-testid="um-isActive"
            onChange={handleStatusChange}
            checked={user.isActive}
          />
          <span className="slider round"></span>
          <span
            className={`span-text ${user.isActive ? "active" : ""}`}
          >
            {user.isActive ? "Active" : "Off"}
          </span>
        </label>
        )}
  
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            data-testid="um-editing-firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            name="firstName"
          />
        ) : (
          user?.firstName
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            data-testid="um-editing-lastName"
            value={formik?.values?.lastName}
            onChange={formik.handleChange}
            name="lastName"
          />
        ) : (
          user?.lastName
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            data-testid="um-editing-email"
            value={formik?.values?.email}
            onChange={formik.handleChange}
            name="email"
          />
        ) : (
          user?.email
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            data-testid="um-editing-password"
            value={formik?.values?.password}
            onChange={formik.handleChange}
            name="password"
          />
        ) : (
          "******"
        )}
      </td>
      <td>
        {isEditing ? (
          <select
            value={formik.values.department}
            onChange={formik.handleChange}
            name="department"
            data-testid="um-editing-department"
          >
            {Object.keys(departments).map((deptId) => (
              <option key={deptId} value={deptId}>
                {departments[deptId].name}
              </option>
            ))}
          </select>
        ) : (
          getDepartmentNameById(user?.department)
        )}
      </td>
      <td>
        <div className="actions">
          <UsersTooltip title="Edit User" placement="top">
            <CreateOutlinedIcon
              className={`edit-icon ${isEditing ? "active" : ""}`}
              onClick={handleEditToggle}
              style={{
                borderRadius: isEditing ? "30%" : "none",
                padding: isEditing ? "0.2rem" : "none",
                backgroundColor: isEditing ? "#EEF0F2" : "transparent",
              }}
            />
          </UsersTooltip>
          {isEditing ? (
            <UsersTooltip title="Save" placement="top">
              <TaskAltIcon
              data-testid="save-user-info"
                style={{
                  color: save ? "#61A87E" : "#B1B3C5",
                  padding: "0.2rem",
                }}
                onClick={() => formik.handleSubmit()}
              />
            </UsersTooltip>
          ) : (
            <UsersTooltip title="Full Account" placement="top">
              <UserProfileButton
              onClick={() => (props.onClick(props?.user?.id))}
              >
              <FolderSharedIcon
              data-testid="full-account"
                className="folder-icon"
                />
                </UserProfileButton>
            </UsersTooltip>
          )}
          <UsersTooltip title="Delete User" placement="top">
            <Delete data-testid="delete-user" onClick={() => user?.id && handleDelete(user?.id)} />
          </UsersTooltip>
        </div>
      </td>
    </StyledUsersRow>
  );
};

export default UserRow;
