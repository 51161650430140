import * as React from "react";
import {
  TableContainer,
  TableHeading,
  TableFilteringOptions,
} from "../styles";
import SearchIcon from "@mui/icons-material/Search";
import { Task } from "../../../../api/v1/assignments/types/assignments";
import TableBodyRow from "./TableBodyRow";
import { IHistoryProps } from "..";
import { getMockedTasks } from "../../../../api/v1/__mocks__/tasks";
import asModal from "../../../../hoc/asModal";
import ErrorInputModal from "../../TodayPriorities/components/ErrorInputModal";
export interface IHistoryProps {
  tasks: Task[];
  clickOnMessage: () => void;
}
const ErrorFilteringInput = asModal(ErrorInputModal, "#alerts-page");
const RenderedHistory = (props: IHistoryProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const filterByContractNo = (task: Task) => {

    return task && task?.contract_no?.toLowerCase()?.includes(searchTerm.toLowerCase());
  };

  const filteredTasks = props?.tasks?.filter(filterByContractNo);

  React.useEffect(() => {
    if (searchTerm && filteredTasks.length === 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }
  , [filteredTasks, searchTerm]);

  return (
    <>
      <TableContainer data-testid="tasks-container">
        <TableHeading>
          <h3 data-testid="task-history-text">Task History</h3>
          <TableFilteringOptions>
            <SearchIcon />
            <input
              data-testid="search-filter"
              type="text"
              placeholder="Search by Contract No."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              />
          </TableFilteringOptions>
        </TableHeading>

        {filteredTasks?.map((task) => (
          <TableBodyRow
          key={task?.id}
          tasks={task}
          clickOnMessage={props?.clickOnMessage}
          />
        ))}
      </TableContainer>
      <ErrorFilteringInput
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title="No Tasks Found"
        message="No tasks were found with the provided search term."
      />
    </>
  );
};

export default RenderedHistory;
