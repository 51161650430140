import * as React from "react";
import SubjectDescription from "./components/SubjectDesciption";
import RiskCategory from "./components/RiskCategory";
import CoverageInformation from "./components/CoverageInformation";
import InsuranceTerms from "./components/InsuranceTerms";
import ReinsuranceTerms from "./components/ReinsuranceTerms";
import AddIcon from "@mui/icons-material/Add";
import { InsuredSubject } from "../../../../../../api/v1/assignments/classes/insuredSubjects";
import InfoTip from "../../../../../../components/Tooltip";
import { useNavigate, useParams } from "react-router-dom";
import type { FormikValues } from "formik";
import {
  StyledInsuredSubject,
  StyledInsuredSubjectsSection,
  StyledInsuredSubjectsWrapper,
  StyledHeader,

} from "./styles"
import type AssignmentStructure from "../../../../../../api/v1/assignments/classes/assignment";


const InsuredSubjects = (props) => {
  const { values, setFieldValue, addReinsuranceTerms, errors } = props;
  const [insuredSubjects, setInsuredSubjects] = React.useState<
    InsuredSubject[]
  >(values?.insuredSubjects || []);
  const navigate = useNavigate();

  const addInsuredSubject = () => {
    const newInsuredSubject = new InsuredSubject();
    setInsuredSubjects([...values?.insuredSubjects, newInsuredSubject]);
    setFieldValue("insuredSubjects", [...values?.insuredSubjects, newInsuredSubject]);
  };

  React.useEffect(() => {
    if (values?.insuredSubjects?.length === 0) addInsuredSubject();
  }, [setFieldValue]);

  return (
    <StyledInsuredSubjectsWrapper>
      <StyledHeader>
        <nav>
          {insuredSubjects?.map((item, index) => {
            return <div key={index} onClick={(e) => {
              e.preventDefault();
              navigate(`/assignments/${values?.id}/insured-subjects/${index}`);
            }}>
              {item?.subjectName || `Insured Subject ${index + 1}`}
            </div>;
          })}
        </nav>
        {/* <InfoTip title="Add Insured Subject" placement="top">
          <button type="button" className="add-insurer-button" data-testid="add-insurer-button" onClick={addInsuredSubject}>
            <AddIcon className="add" />
          </button>
        </InfoTip> */}
      </StyledHeader>
      <>
        <StyledInsuredSubjectsSection>
          <AnInsuredSubject key={0} index={0} {...props} addReinsuranceTerms={addReinsuranceTerms} />
        </StyledInsuredSubjectsSection>
      </>
    </StyledInsuredSubjectsWrapper>
  );
};

interface IInsuredSubjectCardProp {
  addReinsuranceTerms: (index: number) => void;
  assignment: AssignmentStructure;
  formik: FormikValues;
  index: number;
}
export const AnInsuredSubject = (props: InsuredSubject & IInsuredSubjectCardProp): JSX.Element => {
  const { assignment, index, errors, setFieldValue } = props;
  const insuredSubject = assignment && assignment.insuredSubjects && Array.isArray(assignment.insuredSubjects) && assignment.insuredSubjects[index];
  const insuredSubjectErrors = errors?.insuredSubjects && errors?.insuredSubjects[index];


  React.useEffect(()=>{
    const reinsuranceTermsList: Array<unknown> = insuredSubject?.reinsuranceTerms;
    for ( let i = 0; i < reinsuranceTermsList?.length; i++ ) {
      const maxLimit = insuredSubject?.reinsuranceTerms[i]?.accounting?.limit?.max;
      if(!maxLimit && setFieldValue){
        setFieldValue(`insuredSubjects[${index}].reinsuranceTerms[${index}].accounting.limit.max`,insuredSubject?.insuranceTerms?.sumInsured)
      }
    }
  },[insuredSubject?.insuranceTerms?.sumInsured])
  return (
    <StyledInsuredSubject
      id={`insured-subject-${props?.index}-anchor`}
      dir="ltr"
      data-testid="insured-subject-section"
    >
      <SubjectDescription
        {...props} insuredSubject={insuredSubject} errors={insuredSubjectErrors} />
      <RiskCategory {...props} insuredSubject={insuredSubject} errors={insuredSubjectErrors} />
      <CoverageInformation {...props} insuredSubject={insuredSubject} errors={insuredSubjectErrors} />
      <InsuranceTerms {...props} insuredSubject={insuredSubject} errors={insuredSubjectErrors} values={assignment} />
      <ReinsuranceTerms {...props} insuredSubject={insuredSubject} addReinsuranceTerms={props?.addReinsuranceTerms} errors={insuredSubjectErrors} />
    </StyledInsuredSubject>
  );
};

export default InsuredSubjects;