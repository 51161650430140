export enum ERoute {
  "home" = "home",
  "default" = "default",
  "dashboard" = "",
  "assignments" = "assignments",
  "awaitingAction" = "awaiting-action",
  "notes" = "notes",
  "priorities" = "priorities",
  "history" = "history",
  "underwritingPolicies" = "underwriting-policies",
  "broadcastMessage" = "broadcast-message",
  "generalInformation" = "general-information",
  "insuredSubjects" = "insured-subjects",
  "reinsurance" = "reinsurance",
  "risks" = "risks",
  "payments" = "payments",
  "premiumLimit" = "premium-limit",
  "retrocession" = "retrocession",
  "comments" = "comments",
  "documents" = "documents",
  "inbox" = "inbox",
  "underwritersActivity" = "underwriters-activity",
  "companyActivity" = "company-activity",
  "metrics" = "metrics",
  "globus"= "globus",
  "userManagement"= "user-management",
  "permissions"= "permissions",
  "userModel"= "user-model",
  "rdpSettings"= "rdp-settings",
  "userProfile"= "user-profile",
  "userDepartment"= "user-department",
  "management"= "management",
  "departmentParents"= "department-parents",
  "departmentChildren"= "department-children",
}

export const RouteTitle: Record<string, string> = {
  [ERoute.default]: " ",
  [ERoute.home]: "Home",
  [ERoute.dashboard]: "Dashboard",
  [ERoute.assignments]: "Assignments",
  [ERoute.awaitingAction]: "Awaiting Action",
  [ERoute.notes]: "Notes",
  [ERoute.priorities]: "Priorities",
  [ERoute.history]: "History",
  [ERoute.underwritingPolicies]: "Underwriting Policies",
  [ERoute.broadcastMessage]: "Broadcast Message",
  [ERoute.generalInformation]: "General Information",
  [ERoute.insuredSubjects]: "Insured Subjects",
  [ERoute.reinsurance]: "Reinsurance Terms",
  [ERoute.risks]: "Risks",
  [ERoute.payments]: "Payments",
  [ERoute.premiumLimit]: "Premium and Limit",
  [ERoute.retrocession]: "Retrocession",
  [ERoute.comments]: "Comments",
  [ERoute.documents]: "Documents",
  [ERoute.inbox]: "Inbox",
  [ERoute.underwritersActivity]: "Underwriters Activity",
  [ERoute.companyActivity]: "Company Activity",
  [ERoute.metrics]: "Metrics",
  [ERoute.globus]: "Globus",
  [ERoute.userManagement]: "User Management",
  [ERoute.permissions]: "Permissions",
  [ERoute.userModel]: "User Model",
  [ERoute.rdpSettings]: "RDP Settings",
  [ERoute.userProfile]: "User Profile",
  [ERoute.userDepartment]: "Users",
  [ERoute.management]: "Management Info",
  [ERoute.departmentParents]: "Managing",
  [ERoute.departmentChildren]: "Managed",
};
