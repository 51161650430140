import * as React from 'react';
import { Link, Route, useNavigate } from 'react-router-dom';
import { StyledBreadCrumbs } from '../../../styles/DashboardContainer';
import { StyledAssignmentNavigation } from './styles/styles';
import { DashboardContext, LegacyLabel } from '../../..';
import { ERoute, RouteTitle } from '../../../../../context/BrowserContext/routes/types';
import { BackButton, NavigationButtonWrapper, SubmitButton } from '../../../Documents/styles';
import HorizontalNavigation from '../../../../../components/HorizontalNavigation';
import version from "./assets/version.text";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';
import AssignmentDefinitionSkeleton from './components/AssignmentDefinitionSkeleton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Popover } from '@mui/material';
import { PopoverTrigger } from '../../../../../components/PopOver';
import { deleteAssignment } from '../../../../../api/v1/assignments/delete';
import asModal from '../../../../../hoc/asModal';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { editAssignment } from '../../../../../api/v1/assignments/put';
import { Assignment } from '../../../../../api/v1/__mocks__/assignments';
import { IAssignment } from '../../../../../api/v1/assignments/types/assignments';
import { getPriorities } from '../../../../../api/v1/priorities/get';
import { AssignmentOptions } from '../../../../../components/AssignmentOptions';

type TRoute = {
  path: string;
  label: string;
  className: string;
}

const routeGenerator = (assignmentId: string | number, tab: string | number): TRoute => ({
  path: `/${ERoute.assignments}/${assignmentId}/${tab}`,
  label: RouteTitle[tab],
  className: tab as string
})

const routesGenerator = (assignmentId: string | number): TRoute[] => {
  const routes = [
    ERoute.generalInformation,
    ERoute.insuredSubjects,
    // ERoute.payments,
    ERoute.premiumLimit,
    ERoute.documents
  ]
  return routes.map(tab => routeGenerator(assignmentId, tab))
}


interface IDrillDownNavigationProps {
  breadcrumbs: JSX.Element[];
  assignmentId?: string | number;
  contractNo?: string;
  contractType?: string;
  selectedTab?: string;
  handleSubmit?: () => void;
  isLoading?: boolean;
  className?: string;
  onClick?: () => void;
  underwriterOptions?: any;
  setPriorities: (assignments: (IAssignment & ILegacyAssignment)[]) => void;
  selectAssignment: IAssignment;
}
type Priorities = "low" | "high" | "medium";
const ConfirmDeletion = asModal(ConfirmationModal, '#modal-root');
const StyledConfirmDeletion = styled(ConfirmDeletion)`
z-index: 1000;
  > * {
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
    }
  }
`;
const DrillDownNavigation = (props: IDrillDownNavigationProps) => {
  const [beforeLeftPosition, setBeforeLeftPosition] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const navigate = useNavigate();
  const routesToUse = routesGenerator(props.assignmentId);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const anchorElement = React.useRef<HTMLDivElement>(null);
  const { handleTransfer } = React.useContext(DashboardContext);
  const [openDeleteModal, setDeleteModal] = React.useState<boolean>(false);
  const navigateToDashboard = useNavigate();
  const { underwriterOptions, setPriorities, selectAssignment } = props;
  console.log('assignmentNav', selectAssignment)
  const handleClickEvent = (event): void => {
    event.stopPropagation();
    handleTransfer(underwriterOptions.id, underwriterOptions.legacy_id, props?.assignmentId).catch((err) => { console.error(err) })
  }

  const handleDeletingAssignment = async () => {
    try {
      setDeleteModal(false);
      const response = await deleteAssignment(props?.assignmentId);
      if (response) {
        setTimeout(() => {
          navigateToDashboard('/assignments?contract_type=0');
        }, 3000);
      }
    } catch (err) {
      setDeleteModal(false);
      console.error(err);
    }
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }

  const markPriority = async (priority: Priorities) => {
    try {
      // togglePopover();
      const response = await editAssignment(props?.assignmentId, { priority: priority } as Assignment)
      if (response) {
        const priorities = await getPriorities();
        setPriorities(priorities);
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  const determineSizeofBar = () => {
    try {
      const elementToGetWitdthAndPosition = document.querySelector(
        `.${props.selectedTab}`,
      );
      if (!elementToGetWitdthAndPosition) return;
      elementToGetWitdthAndPosition?.offsetLeft &&
        setBeforeLeftPosition(elementToGetWitdthAndPosition?.offsetLeft);
      elementToGetWitdthAndPosition?.clientWidth &&
        setWidth(elementToGetWitdthAndPosition?.clientWidth);
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(determineSizeofBar, [props.assignmentId, props.selectedTab]);
  React.useEffect(() => {
    // use ResizeObserver to determine the size of the bar
    const resizeObserver = new ResizeObserver(determineSizeofBar);
    resizeObserver.observe(document.body);
  });

  const handleOnClick = (): void => {
    setIsOpen(!isOpen);
  }

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (anchorElement.current && !anchorElement.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);

  }, [])
  return (
    <nav>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
        ref={anchorElement}
      >
        <AssignmentDefinitionContainer
          contractNo={props.contractNo}
          contractType={props.contractType}
          isLoading={props?.isLoading}
          openDeleteModal={openDeleteModal}
          setDeleteModal={setDeleteModal}
          handleDeletingAssignment={handleDeletingAssignment}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
        <HorizontalNavigation
          dependencies={[props.assignmentId, props.selectedTab]}
          routes={routesToUse}
          currentRoute={props.selectedTab}
        />
        <NavigationButtonWrapper>
          <BackButton
            data-testid="back-assignmentdet"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate(`/${ERoute.assignments}`);
            }}
          >
            <ArrowBackIcon /> <span>Back</span>
          </BackButton>
          <SubmitButton
            data-testid="save-assignmentdet"
            type="submit"
            onClick={props?.handleSubmit}
          >
            Save
          </SubmitButton>
          <AssignmentOptions assignment={selectAssignment} 
            setPriorities={setPriorities}
          />
        </NavigationButtonWrapper>
      </div>
    </nav>
  );
};



const StyledAssignmentHeader = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-top: 3.75rem;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  .drilldown-contract-type {
    font-size: 0.8rem;
  }
  .drilldown-contract-no {
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .show {
    display: block;
  }

  .hide {
    display: none;
  }
  `;

const SyledAsssignmentHeaderReverse = styled(StyledAssignmentHeader)`
    margin-top: 0;
    .drilldown-contract-type {
      font-size: 1.5rem;
    }
    .drilldown-contract-no {
      font-size: 0.8rem;
    }

`;
interface IAssignmentDefinitionContainer {
  contractNo: string;
  contractType: string;
  isLoading?: boolean;
  openDeleteModal?: boolean;
  setDeleteModal?: (value: boolean) => void;
  handleDeletingAssignment?: () => void;
  handleOpenDeleteModal?: () => void;
}

export const AssignmentDefinitionContainer = (props: IAssignmentDefinitionContainer): JSX.Element => {
  const constractString = props.contractNo? `${props.contractNo}` : "";
  const contractTypeString = props?.contractType
    return (
      <>
      <StyledAssignmentHeader data-testid="drilldown-container">
      <div className={props.isLoading ? "hide" : "show"}>
          <div className="drilldown-contract-type">
            {contractTypeString}
          </div>
          <div className="drilldown-contract-no">
            {constractString}
          </div>
        </div>
        <AssignmentDefinitionSkeleton className={props.isLoading ? "show" : "hide"} />
      </StyledAssignmentHeader>
    </>
  )
}

export const AssignmentDefinitionContainerReverse = (props: IAssignmentDefinitionContainer): JSX.Element => {
  const constractString = props.contractNo ? `${props.contractNo}` : "New Contract";
  const contractTypeString = props?.contractType
    return (
      <SyledAsssignmentHeaderReverse data-testid="creation-container-header">
        <div className={'drilldown-contract-no '}>
          {constractString}
        </div>
        <div className={'drilldown-contract-type'}>
          {contractTypeString}
        </div>
      </SyledAsssignmentHeaderReverse>
    )
}

export default DrillDownNavigation;