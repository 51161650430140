import {api} from "../index";

export const SearchFacts = async (searchType:string, searchText:string) => {
    if (!searchText || !searchType){
        return 
    }
    const response = await api.post(`/facts/get`, {
        id:searchText,
        search_type:searchType.toLowerCase().replace(' ', '_')
    } );
    return response.data
}

export const FetchSearchForCompany = async (searchType:string, searchText:string) => {
    return await api.get(`/facts/?text=${searchText}&search_type=${searchType.toLowerCase()}`)
}