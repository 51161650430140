import * as React from 'react';
import styled from 'styled-components';


export const StyledAssignmentNavigation = styled.div<{
    width?: number;
    left?: number;
  }>`
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 1.5rem;
    position: relative;
    &:before {
      content: " ";
      width: ${(props) => props?.width || 0}px;
      height: 2px;
      left: ${(props) => props?.left || 0}px;
      bottom: 0;
      transition: all 0.3s ease-in-out;
      position: absolute;
      background: #5b5fc7;
    }
    a {
      border-radius: 1rem 1rem 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        border-bottom: 2px solid #5b5fc7;
      }
    }
  `;