import * as React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";

const InfoTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "#4B4D4E",
      maxWidth: 220,
      border: "1px solid #dadde9",
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      cursor: "pointer",
    },
  }));

  export default InfoTip;