import * as React from "react";
import MetricsNavigation from "./components/MetricsNav";
import { Main, StyledMain } from "./styles";
import { ERoute } from "../../../context/BrowserContext/routes/types";
import Metrics, {
  IEndorsements,
  INewBusiness,
  IRenewals,
  ITotal,
} from "../Metrics";
import LeadershipMetrics from "../LeadershipMetrics";
import { useParams } from "react-router-dom";
import { IEmployee } from "../Settings";

interface ILeadershipKPIsProps {
  selectedTab?: string;
  breadcrumbs: JSX.Element[];
  employees: IEmployee[];
  total: ITotal;
  renewals: IRenewals;
  endorsements: IEndorsements;
  newBusiness: INewBusiness;
}

const LeadershipKPIs = (props: ILeadershipKPIsProps) => {
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<ERoute>(
    ERoute.underwritersActivity,
  );
  const [expandAll, setExpandAll] = React.useState(false);
  const { tab } = useParams();
  const { breadcrumbs } = props;

  const handleExpandAll = () => {
    setExpandAll(!expandAll);
  };

  const MainContent: object = {
    [ERoute.companyActivity]: (
      <Metrics
      renewals={props.renewals}
      endorsements={props.endorsements}
      newBusiness={props.newBusiness}
      total={props.total}
      expandAll={expandAll}
      />
      ),
      [ERoute.underwritersActivity]: (
        <LeadershipMetrics
        employees={props.employees}
        renewals={props.renewals}
        endorsements={props.endorsements}
        newBusiness={props.newBusiness}
        expandAllUnderwriters={handleExpandAll}
        expandAll={expandAll}
        />
        ),
  };

  React.useEffect(() => {
    setSelectedMenuItem(tab);
  }, [tab]);

  const handleMenuItemSelection = (menuItem: ERoute) => {
    setSelectedMenuItem(menuItem);
  };

  const SelectedView: JSX.Element = MainContent[selectedMenuItem];

  return (
    <>
      <MetricsNavigation
        expandAll={expandAll}
        setExpandAll={setExpandAll}
        breadcrumbs={breadcrumbs}
        selectedTab={tab}
      />
      <StyledMain>
        <Main>{SelectedView}</Main>
      </StyledMain>
    </>
  );
};
export default LeadershipKPIs;
