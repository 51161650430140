import * as React from 'react';
import {
  Table,
  TableContainer,
  TableHeading,
} from "../styles";
import { Skeleton } from '@mui/material';


const AssignmentsSkeleton = () => {
    return (
      <>
            {Array.from({length:12}).map((_, currentRow) => (
              <tr key={currentRow}>  
                {/* span td to all columns */}
                <td colSpan={1000}>
                <Skeleton className="skeletondiv" variant= "rectangular" height={40} animation="wave" />
                </td>        
              </tr>
        ))}
      </>
      
    )
    }
    export default AssignmentsSkeleton;