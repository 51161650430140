import auth from "..";
import { User } from "../../../context/UserContext";

export const editUser = async (user: User): Promise<any> => {
  try {
   const response = await auth.put(`/users/`, { user: user.toAPIRequest()});
    return response.data.user;
  } catch (error) {
    console.error("Error editing user:", error);
    throw error; 
  }
};

export const editUsers = async (users:User[]): Promise<any> => {
  const response = await auth.put("/users/bulk", { users });
  return response.data;
};

export default editUser;
