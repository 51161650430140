import * as React from "react";
import type {IUserErrors } from "./@types";
import type { IUser, IUserHierarchy } from "../../api/v1/users/types/user";
import getUser, { getUserHierarchy } from "../../api/v1/users/get";
import { useLocation, useNavigate } from "react-router-dom";
import loginDefault from "../../api/v1/auth/default";
import usePermissionsLifeCycle from "../../hooks/useUsersPermissions";

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  authorityLevel: {
    limits: {
      max: number;
      min: number
    },
    services: [
      {
        service_name: string;
        permissions: [
          {
            allowed: [
              {
                endpoint: string;
                method: string[];
                defaultParams?: string;

              },
            ];
            denied: [
              {
                endpoint: string;
                method: string[];
                defaultParams?: string;

              },
            ];
          },
        ];
      },
    ];
  };
  password: string;
  legacyName: string;
  legacyId: number;
  timeZone: number;
  legacyCode: number;
  legacyDepartment: number;
  department: number;
  rdpSettings: {
    username: string;
    password: string;
    host: string;
    port: number;
  };
  isActive: boolean;
  birthday: string;
  createdAt: string;
  updatedAt: string;
  
  constructor(
    firstName: string,
    lastName: string,
    email: string,
    authorityLevel?: {
      services: [
        {
          service_name: string;
          permissions: [
            {
              allowed: [
                {
                  endpoint: string;
                  method: string[];
                  defaultParams?: string;
                },
              ];
              denied: [
                {
                  endpoint: string;
                  method: string[];
                  defaultParams?: string;
                },
              ];
            },
          ];
        },
      ];
    },
    id?: number,
    password?: string,
    legacyName?: string,
    legacyId?: number,
    timeZone?: number,
    legacyCode?: number,
    legacyDepartment?: number,
    department?: number,
    rdpSettings?: {
      username: string;
      password: string;
      host: string;
      port: number;
    },
    isActive?: boolean,
    birthday?: string,
    updatedAt?: string,
    createdAt?: string,
  ) {

    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.authorityLevel = authorityLevel;
    this.id = id;
    this.password = password;
    this.legacyName = legacyName;
    this.legacyId = legacyId;
    this.timeZone = timeZone;
    this.legacyCode = legacyCode;
    this.legacyDepartment = legacyDepartment;
    this.department = department;
    this.rdpSettings = rdpSettings;
    this.isActive = isActive;
    this.birthday = birthday;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
  static fromAPIResponse(response: {
    family_name: string;
    given_name: string;
    email: string;
    authority_level?: {
      services: [
        {
          service_name: string;
          permissions: [
            {
              allowed: [
                {
                  endpoint: string;
                  method: string[];
                  defaultParams?: string;
                },
              ];
              denied: [
                {
                  endpoint: string;
                  method: string[];
                  defaultParams?: string;
                },
              ];
            },
          ];
        },
      ];
    };
    id?: number;
    password?: string;
    legacy_name?: string;
    legacy_id?: number;
    timezone?: number;
    legacy_code?: number;
    legacy_department?: number;
    hierarchy_id?: number;
    rdpSettings?: {
      username: string;
      password: string;
      host: string;
      port: number;
    };
    is_active?: boolean;
    birthday?: string;
    updatedAt?: string;
    createdAt?: string;
  }) {
    return new User(
      response.given_name,
      response.family_name,
      response.email,
      response?.authority_level,
      response?.id,
      response?.password,
      response?.legacy_name,
      response?.legacy_id,
      response?.timezone,
      response?.legacy_code,
      response?.legacy_department,
      response?.hierarchy_id,
      response?.rdpSettings,
      response?.is_active,
      response?.birthday,
      response?.updatedAt,
      response?.createdAt,
    );
  }

  toAPIRequest() {
    return {
      id: this.id,
      email: this.email,
      given_name: this.firstName,
      family_name: this.lastName,
      authority_level: this.authorityLevel,
      password: this.password,
      legacy_name: this.legacyName,
      legacy_id: this.legacyId,
      timezone: this.timeZone,
      legacy_code: this.legacyCode,
      legacy_department: this.legacyDepartment,
      hierarchy_id: this.department,
      rdpSettings: this.rdpSettings,
      is_active: this.isActive,
      birthday: this.birthday,
      updatedAt: this.updatedAt,
      createdAt: this.createdAt,
    };
  }
  copyOfUser() {
    return new User(
      this.firstName,
      this.lastName,
      this.email,
      this.authorityLevel,
      this.id,
      this.password,
      this.legacyName,
      this.legacyId,
      this.timeZone,
      this.legacyCode,
      this.legacyDepartment,
      this.department,
      this.rdpSettings,
      this.isActive,
      this.birthday,
      this.createdAt,
      this.updatedAt,
    );
  }
}

interface IUserContext {
  user: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser>>;
  errors: string;
  setErrors: React.Dispatch<React.SetStateAction<string>>;
  userHierarchy: IUserHierarchy;
  logout: () => void;
  setUserHierarchy: React.Dispatch<React.SetStateAction<IUserHierarchy>>;
  deniedRoutes: {
    [
    key: string
    ]: IUser["authorityLevel"]["services"]["0"]["permissions"]["0"]["denied"];
  };
  isEndPointDenied: (value: string) => boolean;
  allowedAPIRoutes: {
    [
    key: string
    ]: IUser["authorityLevel"]["services"]["0"]["permissions"]["0"]["allowed"];
  };
  defaultLoginFlow: (username: string, password: string) => void;
  sendGoogleAuthenticationRequest: () => void;
  isLoggedIn: boolean;
  underwriters: [];
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  setUser: () => { },
  error: null,
  setError: () => { },
  userHierarchy: null,
  setUserHierarchy: () => { },
  deniedRoutes: null,
  defaultLoginFlow: (username: string, password: string) => { },
  allowedAPIRoutes: null,
  sendGoogleAuthenticationRequest: () => { },
  logout: () => { },
  isEndPointDenied: (value: string) => false,
  isLoggedIn: false,
  underwriters: [],
});

const UserContextWrapper = <T extends object>(
  WrappedComponent: React.FC<T>,
) => {
  const UserContextWrapper = (props: T) => {
    const navigation = useNavigate();
    const location = useLocation();
    const currentlyLoggedIn =
      location?.state?.isLoggedIn ||
      JSON.parse(localStorage.getItem("isLoggedIn"));
    const [user, setUser] = React.useState<User>(null);
    const [errors, setErrors] = React.useState<IUserErrors>({});
    const [isLoggedIn, setIsLoggedIn] = React.useState(
      currentlyLoggedIn || false,
    );
    const [loadingUser, setLoadingUser] = React.useState(true);
    const [deniedRoutes, setDeniedRoutes] = React.useState<{ [key: string]: IUser["authorityLevel"]["services"]["0"]["permissions"]["0"]["denied"] }>({});
    const [allowedAPIRoutes, setAllowedAPIRoutes] = React.useState<{ [key: string]: IUser["authorityLevel"]["services"]["0"]["permissions"]["0"]["allowed"] }>({});
    const [userHierarchy, setUserHierarchy] = React.useState();
    const [error, setError] = React.useState<string>("");
    const permissionsValues = usePermissionsLifeCycle(user);
    const [underwriters, setUnderwriters] = React.useState([]);

    const loadUser = () => {
      if (!isLoggedIn) {
        setUser(null);
        return;
      }
      const user = getUser()
        .then((user) => {
          const userToPlaceInState = User.fromAPIResponse(user);
          setUser(userToPlaceInState);
          return userToPlaceInState;
        })
        .catch((err) => {
          console.error(err);
          setIsLoggedIn(false);
        });
    };

    const logout = () => {
      // await invalidateAccess();
      setUser(null);
      setIsLoggedIn(false);
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("isGoogle");
      localStorage.removeItem("currencyLastUpdated");
      localStorage.removeItem("emailsCount");
      localStorage.removeItem("currencyLookUp");
      navigation("/login");
    };

    const loadUserHierarchyDetails = async () => {
      const userHierarchy = await getUserHierarchy();
      setUserHierarchy(userHierarchy?.data?.hierarchy);
      const userHierarchyData = userHierarchy?.data?.hierarchy;
      if (userHierarchyData && userHierarchyData?.managing && userHierarchyData?.managing?.Underwriter) {
        setUnderwriters(Object.values(userHierarchyData?.managing?.Underwriter?.users))
      }
      return userHierarchyData;
    };

    const isEndPointDenied = (value: string) => {
      const routesToCheck = Object.keys(deniedRoutes);
      const isDenied = routesToCheck.some((route) => {
        const regex = new RegExp(route);
        return regex.test(value);
      });
      return isDenied;
    };

    const defaultLoginFlow = async (username: string, password: string) => {
      try {
        const res = await loginDefault(username, password);
        if (res?.status === 200) {
          setIsLoggedIn(true);
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          location.state = { isLoggedIn: true };
          navigation("/assignments?contract_type=4");
        }
      } catch (error) {
        throw error
      }
    }


    const sendGoogleAuthenticationRequest = () => {
      const url = new URL(process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL);
      url.searchParams.append(
        "response_type",
        process.env.REACT_APP_GOOGLE_RESPONSE_TYPE,
      );
      url.searchParams.append("access_type", "offline");
      url.searchParams.append(
        "client_id",
        process.env.REACT_APP_GOOGLE_CLIENT_ID,
      );
      url.searchParams.append("scope", process.env.REACT_APP_GOOGLE_SCOPE);
      url.searchParams.append(
        "redirect_uri",
        process.env.REACT_APP_GOOGLE_REDIRECT_URI,
      );
      url.searchParams.append("prompt", "consent");
      const redirectUrl = url.toString();
      // this routes to the oauth2/callback route, this routes uses a loader
      // to get the access token and then redirects to the root route
      window.location.assign(redirectUrl);
    };

    React.useEffect(() => {
      if (!isLoggedIn) return;
      Promise.all([loadUserHierarchyDetails(), loadUser()]).finally(() => {
        setLoadingUser(false);
      })
    }, [isLoggedIn]);



    React.useEffect(() => {
      const storedError = localStorage.getItem('error');
      if (storedError) {
        setError(storedError);
        localStorage.removeItem('error');
      }
    }, [isLoggedIn]);

    return (
      <UserContext.Provider
        value={{
          user,
          setUser,
          error,
          setError,
          deniedRoutes,
          isEndPointDenied,
          allowedAPIRoutes,
          logout,
          defaultLoginFlow,
          sendGoogleAuthenticationRequest,
          userHierarchy,
          setUserHierarchy,
          loadingUser,
          isLoggedIn,
          underwriters,
        }}
      >
        {<WrappedComponent {...props} permissionsValues={permissionsValues} />}
      </UserContext.Provider>
    );
  };
  return UserContextWrapper;
};

export default UserContextWrapper;
