import * as React from "react";
import { Table, TableContainer, TableHeading } from "../styles";
import { Skeleton } from "@mui/material";

const UsersTableSkeleton = () => {
  return (
    <>
      <TableContainer data-testid="table-container">
        <TableHeading>
          <h3>All Users</h3>
        </TableHeading>
        <section >
          <Table>
            <tr>
              <Skeleton
                className="skeletondiv"
                height={30}
                variant="text"
                animation="wave"
              />
            </tr>
            <tbody>
              {Array.from({ length: 10 }).map((_, currentRow) => (
                <tr key={currentRow}>
                  <td colSpan={10}>
                    <Skeleton
                      className="skeletondiv"
                      variant="rectangular"
                      height={40}
                      animation="wave"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>
      </TableContainer>
    </>
  );
};
export default UsersTableSkeleton;
