import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAssignments } from '../../../src/api/v1/assignments/get';
import AssignmentStructure from '../../api/v1/assignments/classes/assignment';
import type { ILegacyAssignment, IUseAssignmentsParams, IAssignment, IKPIResponse, } from '../../api/v1/types/index';
import { ELegacyAssignmentProps, } from '../../api/v1/types/index';
import { getPriorities } from '../../api/v1/priorities/get';
import { ERoute } from '../../context/BrowserContext/routes/types';
import { UserContext } from '../../context/UserContext';
import { convertToApiStructure } from '../../utils/urlSearchParams';

export type TAssignmentDict = Record<string, IAssignment & ILegacyAssignment>

interface ISearchparams {
  allUsersAssignments: boolean;
  underwriters: number[];
  typeOf: number[];
  status: number[];
  dateRange: string;
}

interface IAssignmentHook {
  assignments: Map<number, AssignmentStructure>;
  setAssignments: React.Dispatch<React.SetStateAction<Map<number, AssignmentStructure>>>;
  searchParams: ISearchparams;
  pageNumber: number;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setSearchParams: React.Dispatch<React.SetStateAction<ISearchparams>>;
  fetchAssignments: (params: IFetchAssignmentParams) => Promise<void>;
  sortParams: { sortBy: string; sortOrder: string };
  setSortParams: React.Dispatch<React.SetStateAction<{ sortBy: string; sortOrder: string }>>;
  kpis: IKPIResponse;
  displayAssignment: (assignmentId: number) => Promise<void>;
  priorities: string[] | number[];
  setPriorities: React.Dispatch<React.SetStateAction<string[] | number[]>>;
  loadingPriorities: boolean;
  loadingAssignments: boolean;
  totalAssignments: number;
  pageSize: number;
  setLoadingAssignments: React.Dispatch<React.SetStateAction<boolean>>;
}



export const defaultSearchParams = {
  underwriters: [],
  allUsersAssignments: false,
  typeOf: [],
  status: [],
  dateRange: null,
}

const useAssignment = ({
  pageSize,
  getAssignmentsProp = getAssignments,

}: IUseAssignmentsParams): IAssignmentHook => {
  const { userHierarchy, user } = React.useContext(UserContext)
  const navigate = useNavigate();
  const intervalRef = React.useRef<NodeJS.Timeout>(null);
  const syncingRef = React.useRef<boolean>(false);
  const controllerRef = React.useRef<AbortController>(null);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [assignments, setAssignments] = React.useState<Map<number, AssignmentStructure>>(new Map());
  const [sortParams, setSortParams] = React.useState<{ sortBy: string; sortOrder: string }>({ sortBy: ELegacyAssignmentProps.DATA_Create, sortOrder: 'desc' })
  const [searchParams, setSearchParams] = React.useState<ISearchparams>(defaultSearchParams);
  const [totalAssignments, setTotalAssignments] = React.useState<number>(0);
  const [kpis, setKpis] = React.useState<IKPIResponse>({ kpis: {} })
  const [priorities, setPriorities] = React.useState([]);
  const [loadingPriorities, setLoadingPriorities] = React.useState<boolean>(true);
  const [loadingAssignments, setLoadingAssignments] = React.useState<boolean>(true);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [pageChanges, setPageChanges] = React.useState(0);
  const managers = userHierarchy?.managing?.Manager?.users
  const urlParams = Object.fromEntries(urlSearchParams)
  const underwritersToFetch = Object.values((managers || {})).map(manager => (manager.legacy_id))
  const underwriters = Number(urlParams?.contract_type || 0) == 4 ? [
    ...(searchParams?.underwriters || []), ...(underwritersToFetch || [])
  ] : (searchParams?.underwriters || [])

  const apiParameters = convertToApiStructure({
    offset: pageNumber,
    limit: pageSize,
    ...sortParams,
    ...(searchParams || defaultSearchParams || {}),
    underwriters,
    detailLevel: "basic",
    ...urlParams
  })

  const fetchAssignments = async (): Promise<void> => {
    if (!user?.id) return;
    if (controllerRef.current) controllerRef.current.abort();
    controllerRef.current = new AbortController();
    try {
      !syncingRef?.current && setLoadingAssignments(true);
      const response = await getAssignmentsProp(apiParameters, controllerRef.current?.signal);
      const newAssignments = response.assignments;
      const totalAssignmentsFromAPI = response.total_assignments;
      const map = new Map<number, AssignmentStructure>();
      newAssignments.reduce((map, assignment) => {
        const newAssignment = new AssignmentStructure().convertAPIResponeToAssignmentStructure(assignment);
        map.set(assignment.id, newAssignment);
        return map;
      }, map);
      setAssignments(map);
      setTotalAssignments(totalAssignmentsFromAPI);
      setLoadingAssignments(false);
      const result = await getPriorities();
      setPriorities(result || []);
      setLoadingPriorities(false);
    } catch (error) {
      console.error('Error fetching assignments:', error);
    }
  }

  const displayAssignment = React.useCallback(async (assignmentId: number): Promise<void> => {
    navigate(`assignments/${assignmentId}/${ERoute.generalInformation}`)
  }, []);

  const setAssignmentInterval = (): void => {
    if (!user?.id) return;
    syncingRef.current = true;
    intervalRef.current = setInterval(() => {
      fetchAssignments()
    }, 60000 * 2)
  }

  const resetSearchParams = (): void => {
    if (!user?.id) return;
    setPageChanges(pageChanges + 1)
    setPageNumber(0)
    setSearchParams(defaultSearchParams);
  }

  const deconstruct = () => {
    return () => {
      if (intervalRef?.current) clearInterval(intervalRef?.current);
    }
  }

  const fetchAndSetInterval = (): unknown => {
    if (intervalRef?.current) clearInterval(intervalRef?.current);
    syncingRef.current = false;
    fetchAssignments().then(() => {
      syncingRef.current = true;
      // setAssignmentInterval()
    }).catch(() => {
      console.log('Error fetching assignments')
    })
    return deconstruct
  }

  const goToPrevPage = (): void => {
    const potentialPageNumber = pageNumber - pageSize;
    if (potentialPageNumber <= 0) setPageNumber(0);
    else setPageNumber(potentialPageNumber);
  }

  const goToNextPage = (): void => {
    const potentialPageNumber = pageNumber + pageSize;
    const lastPage = Math.floor(totalAssignments / pageSize) * pageSize;
    if (potentialPageNumber >= totalAssignments) setPageNumber(lastPage);
    else setPageNumber(potentialPageNumber);
  }


  React.useEffect(() => {
    resetSearchParams()
  }, [urlParams?.contract_type,])

  React.useEffect(fetchAndSetInterval, [
    user?.id,
    pageChanges,
    pageNumber,
    sortParams,
    searchParams?.allUsersAssignments,
    searchParams?.searchTerm,
    searchParams?.underwriters,
    searchParams?.status,
    searchParams?.typeOf,
    searchParams?.dateRange,
  ]);




  return {
    assignments,
    setAssignments,
    searchParams,
    pageNumber,
    pageSize,
    goToNextPage,
    goToPrevPage,
    setPageNumber,
    setSearchParams,
    fetchAssignments,
    sortParams,
    setSortParams,
    kpis,
    displayAssignment,
    priorities,
    setPriorities,
    loadingPriorities,
    loadingAssignments,
    totalAssignments,
    setLoadingAssignments
  };
};

export default useAssignment;
