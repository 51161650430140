import * as React from "react";
import { User } from "../../../context/UserContext";
import NoUsersView from "./components/NoUsers";
import RenderedUsers from "./components/RenderedUsers";
import UsersTableSkeleton from "./components/UsersTableSkeleton";
import { useOutletContext, useParams } from "react-router-dom";
import { createUser } from "../../../api/v1/users/post";
import { IDepartment } from "../../../api/v1/types";

interface IUsersTableProps {
  users: { [key: string]: User };
  setUsers: React.Dispatch<React.SetStateAction<{ [key: string]: User }>>;
  loading?: boolean;
  addUser: (user: User) => void;
  deleteUser: (userId: string) => void;
  userAdded: boolean;
  setUserAdded: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: IDepartment;
  departments?: IDepartment[];
}

const UsersTable = (props: IUsersTableProps) => {
  const { users, loading,setUsers, addUser, userAdded, setUserAdded, deleteUser, departmentId, departments  } = props;
  const currentUserView = !Object.values(users).length ? (
    <NoUsersView />
  ) : (
    <RenderedUsers users={users} setUsers={setUsers} addUser={addUser} userAdded={userAdded} setUserAdded={setUserAdded} deleteUser={deleteUser} departmentId={departmentId} departments={departments}/>
  );

  const viewToUse = !loading ? currentUserView : <UsersTableSkeleton />;
  return <>{viewToUse}</>
};

const ConnectedUsersTable = () => {
  const { departmentId } = useParams();
  const contextValue = useOutletContext<{
    users: { [key: string]: User };
    loading: boolean;
    setUsers: React.Dispatch<React.SetStateAction<{ [key: string]: User }>>;
    addUser: (user: User) => void;
    deleteUser: (userId: string) => void;
    departments: IDepartment[];
  }>();
  const users = contextValue?.users || {};
  const loading = contextValue?.loading || false;
  const [userAdded, setUserAdded] = React.useState<boolean>(false)



  return <UsersTable users={users} loading={loading} setUsers={contextValue?.setUsers} addUser={contextValue?.addUser} userAdded={userAdded} setUserAdded={setUserAdded} departmentId={departmentId} deleteUser={contextValue?.deleteUser} departments={contextValue?.departments}/>;
};
export default ConnectedUsersTable;
