import * as React from 'react';
import {
    TableContainer,
    TableHeading,
    TableFilteringOptions,
    Table,
    NoHistoryDiv,
  } from "../styles";
import SearchIcon from "@mui/icons-material/Search";


const NoHistory = () => {
    return (
        <TableContainer>
      <TableHeading>
        <h3>Task History</h3>
      </TableHeading>
      <NoHistoryDiv>No Task History</NoHistoryDiv>
      </TableContainer>
    )
}

export default NoHistory;