import styled from "styled-components";
import { Table } from "../../../../../global.styles";
import Input, { InputWithLabel } from "../../../../../components/FormElements/Input/Input";

// export const StyledPremiumTableHead = styled(Table)`

// `;

export const StyledPremiumTableHead = styled.thead`
  th:nth-child(n) {
    background-color: #cce5ff;
  }
`;

export const PremiumTableDivider = styled.div`
  .relative {
    position: relative;
  }

  .divider {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }

  .divider:before,
  .divider:after {
    content: "";
    flex-grow: 1;
    border-top: 1px solid #a2d1ff;
    margin-top: 0.5rem;
  }

  .text {
    color: #3e3e3c;
    margin: 0 1rem;
    text-transform: uppercase;
    letter-spacing: 1.44px;
  }
`;


export const StyledAccordionComponent = styled.section`
    margin-top: 1rem;
`

export const StyledTableData = styled.td`
border-right: 1px solid #E3E5EC;
 border-left: 1px solid #E3E5EC;
text-align: center;
background: #FAFBFB;

`

export const StyledTableFC = styled.td`
border-right: 1px solid #E3E5EC;
 border-left: 1px solid #E3E5EC;
background: #FAFBFB;
`

export const PremiumLimitInput = styled(Input).attrs({
  type: "number",
})`
  border: none;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  margin: 0;
  width: 100%;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  text-align:right;
  font-size: 0.8rem !important;
  padding: .2rem !important;
  /* Hide the up and down arrows */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const PremiumLimitTable = styled(Table)`
  th {
    text-align: right;
  }
  td {
    text-align: right;
    padding: 0.4rem;
    :hover {
      background-color: #f5f5f5;
    }
  }
`;
