import * as React from 'react';
import {
    CreateNewButton,
    SearchButton,
    SearchWrapper,
    Table,
    TableContainer,
    TableFilteringOptions,
    TableHeading,
  } from "../styles";
  import SearchIcon from "@mui/icons-material/Search";
  import TableHeader from "../../AssignmentTable/components/TableRowHeader";
  import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { NoReDiv } from '../styles';




const NoREView = () => {
    return (
        <TableContainer data-testid="re-table-container">
      <TableHeading>
        <h3>Renewals and Endorsements</h3>
        <CreateNewButton data-testid="create-new-button">
          <AddIcon />
          <Link className="create-new-text" to="/add-new">
            Create New
          </Link>
        </CreateNewButton>
      </TableHeading>
      <section className="table-container">
        <Table>
          <tbody>
        <NoReDiv>
        <h1>No Renewals/Endorsements Available</h1>
        </NoReDiv>
          </tbody>
        </Table>
        </section>
        </TableContainer>


    );
};
export default NoREView;
