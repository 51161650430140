import * as React from 'react';
import { IAssignment, ILegacyAssignment, ELegacyAssignmentProps } from '../../../api/v1/types';
import AccordionComponent from "../AssignmentDrillDown/components/Tasks/Accordion";
import { StyledAccordionTableRow, StyledTableRow } from './styles';
import StatusColumn from '../AssignmentTable/components/TableBody/StatusColumn';
import { EastOutlined, NorthOutlined, SortOutlined, SouthOutlined } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { editAssignment } from '../../../api/v1/assignments/put';
import { Task } from '../../../api/v1/types';
import { getPriorities } from '../../../api/v1/priorities/get';

interface ITodayPrioritiesRowProps {
  priority: IAssignment & ILegacyAssignment;
  clickOnMessage: () => void;
  expandTaskMenu?: boolean;
  expandAccordion?: boolean;
  setExpandAccordion?: React.Dispatch<React.SetStateAction<number>>;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  handleExpandAddTaskMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  assignments: { [key: string]: IAssignment & ILegacyAssignment };
  key: number;
  displayAssignment: any;
  expandAll: boolean;
  priorityId: string;
  setTasks: (tasks: Task[]) => void;
  tasks: Task[];
}


const TodayPrioritiesRow = (props: ITodayPrioritiesRowProps) => {

  const completedTasks = props?.priority?.tasks?.filter((task) => task.is_completed === false && task.is_deleted === false).length;
  const [expandAccordion, setExpandAccordion] = React.useState(false);
  const [history, setHistory] = React.useState<Task[]>([]);
  const [displayHistory, setDisplayHistory] = React.useState(false);

  const handleExpandAccordion = (event: React.MouseEvent<Element>) => {
    setExpandAccordion(!expandAccordion);
  };

  React.useEffect(() => {
    setExpandAccordion(props.expandAll);
  }, [props.expandAll]);

  const handleTitleChange = () => {
    setDisplayHistory(!displayHistory);
  };

  const handleDeletePriority = async () => {
    try {
      const response = await editAssignment(props?.priority?.id, { priority: null });
      if (response) {
        const newPrior = await getPriorities();
        props.setPriorities(newPrior)
      }
    } catch (error) {
      console.error('Error updating assignment:', error);
    }
  };
  return (
    <>
      <StyledTableRow data-testid="priority-row" selected={expandAccordion}
        onClick={handleExpandAccordion}
        priority={props?.priority?.priority}>
        <td><StatusColumn status={props?.priority[ELegacyAssignmentProps.STATUS]} /></td>
        <td data-testid="contract-number" className="colored-contract">{props?.priority.contract_no}</td>
        <td className='incomplete-tasks'>{completedTasks}</td>
        <td>{props?.priority.assignment_type}</td>
        <td>{props?.priority?.broker_id}</td>
        <td className='hide-on-print'>
          <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'center', width: '220px' }}>
            <div>
              {expandAccordion ? <NorthOutlined fontSize="small" /> : <SouthOutlined fontSize="small" />} <SortOutlined fontSize="small" />
            </div>
            <CloseOutlinedIcon data-testid="remove-priority" color="error" onClick={(e) => { e.stopPropagation(); handleDeletePriority() }} />
            <div data-testid="go-to-assignment" style={{ color: '#186ADE', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => props.displayAssignment(props?.priority?.id)}>
              Go To Assignment <EastOutlined />
            </div>
          </div>
        </td>

      </StyledTableRow>
      <StyledAccordionTableRow selected={expandAccordion} priority={props?.priority?.priority} data-testid="priority-accordion-row">
        <td colSpan={6}>
          <AccordionComponent
            assignmentId={props?.priority?.id}
            contract={props?.priority?.contract_no}
            clickOnMessage={props?.clickOnMessage}
            handleExpandAccordion={handleExpandAccordion}
            hideSummary={true}
            expandAccordion={expandAccordion}
            setExpandAccordion={setExpandAccordion}
            setTasks={props?.setTasks}
            tasks={props?.priority?.tasks}
            taskHistory={setHistory}
            titleChange={handleTitleChange}
            displayHistory={displayHistory}
            setDisplayHistory={setDisplayHistory}
          />
        </td>
      </StyledAccordionTableRow>
    </>
  )
}

export default TodayPrioritiesRow;