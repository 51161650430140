import * as React from "react";
import {
  StyledFilteredResultsContainer,
  StyledFilteredResultTable,
  MenuPropsStyles,
  SelectStyles,
  StyledDocumentResultsContainer,
} from "../styles";
import styled from "styled-components";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { IDocumentType } from "../../../../api/v1/documents/types/DocumentTypes";
import NoDocumentsView from "./NoDocumentsView";
import RenderedDocumentsResults from "./RenderedDocumentsResults";
import LoadingDocuments from "./oadingDocuments";

interface props {
  documents: IDocumentType[];
  contract_no: string;
  loading: boolean;
  documentType: string;
  setDocumentType: React.Dispatch<React.SetStateAction<string>>;
}

const DocumentItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const DocText = styled.p`
  font-weight: 800;
  font-size: 18px;
`;

const FilteredResults = (props: props) => {
  const filterDocumentType = [
    "Slip",
    "Original Proposal",
    "Cancellation Notice",
    "Decline Notice",
    "Reject Notice",
    "Confirmation Notice",
    "Inspection Report",
    "Endorsement",
    "All Documents",
  ];

  const returnFileType = (url: string) => {
    const array = url.split(".");
    const lastItem = array[array.length - 1];
    return lastItem.toUpperCase();
  };

  const viewToUse = props?.documents.length === 0 ? (
    <NoDocumentsView />
  ) : (
    <RenderedDocumentsResults documents={props?.documents}/>
  );

  const currentUserView = props?.loading ? <LoadingDocuments/> : viewToUse;
  return (
    <div style={{ height: "55%" }}>
      <StyledFilteredResultsContainer>
        <StyledFilteredResultTable>
          <div style={{ width: "30%" }}>
            <p>Contract No</p>
            {props?.contract_no}
          </div>
          <div style={{ width: "30%" }}>
            <p>Document Type</p>
            <FormControl
              sx={{
                width: "90%",
              }}
            >
              <Select
                role="select"
                labelId="document"
                sx={SelectStyles}
                MenuProps={{
                  sx: MenuPropsStyles,
                }}
                value={props.documentType}
                disabled
              >
                {/* document type prop */}
                {filterDocumentType.map((document, index) => (
                  <MenuItem key={index} value={document}>
                    {document}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </StyledFilteredResultTable>
      </StyledFilteredResultsContainer>
      <StyledDocumentResultsContainer hidden={props?.documents.length === 0}>
        {currentUserView}
      </StyledDocumentResultsContainer>
    </div>
  );
};

export default FilteredResults;
