import * as React from "react";
import { StyledContent, UserProfileHeader } from "./styles";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import UserProfileNav from "./UserProfileNav";
import getUser from "../../../../../api/v1/users/get";
import { User } from "../../../../../context/UserContext";
import editUser from "../../../../../api/v1/users/put";
import { createUser } from "../../../../../api/v1/users/post";
import { IDepartment } from "../../../../../api/v1/types";

interface IUserProfileProps {
  user: User;
  userId?: string;
  permissionState: {};
  setPermissionState: React.Dispatch<React.SetStateAction<{}>>;
  deletePathRule: (service: string, pathName: string) => void;
  editUser: (user: User) => void;
  addUser: (user: User) => void;
  departments?: { [key: string]: IDepartment };
}

const UserProfile = (props: IUserProfileProps) => {
  const {
    user,
    permissionState,
    setPermissionState,
    deletePathRule,
    editUser,
    addUser,
    departments,
  } = props;
  const navBackToUserManagement = useNavigate();

  const handleStatusToggle = () => {
    const editedUser = user.copyOfUser();
    editedUser.isActive = !user?.isActive;
    editUser(editedUser);
  };

  const handleBackButton = () => navBackToUserManagement("/user-management");

  return (
    <>
      <div className="profile-nav">
        <UserProfileHeader>
          <div className="username-toggle" data-testid="profile-header">
            {props?.user?.firstName} {user?.lastName}
            <div className="toggle">
              <label className="switch">
                <input type="checkbox" 
                  name={"all"} 
                  onChange={handleStatusToggle} 
                  checked={user?.isActive}
                />
                <span className="slider round"></span>
                <span className={`span-text ${user?.isActive ? "active" : ""}`}>
                  {user?.isActive ? "Active" : "Off"}
                </span>
              </label>
            </div>
          </div>
          <div className="back-button" onClick={handleBackButton}>
            <ReplyIcon />
            Back
          </div>
        </UserProfileHeader>
        <UserProfileNav user={user} userId={user?.id} />
      </div>
      <StyledContent>
        <Outlet
          context={{
            user,
            permissionState,
            setPermissionState,
            deletePathRule,
            editUser,
            addUser,
            departments,

          }}
        />
      </StyledContent>
    </>
  );
};

const ConnectedUserProfile = () => {
  const { userId } = useParams();
  const contextValue = useOutletContext();
  const users = contextValue?.users || {};
  const user: User = (users && users[userId]) || null;
  const departments = contextValue?.departments || {};

  const setPermissionState = async (permissionValue) => {
    try {
      if (!user?.authorityLevel)
        user.authorityLevel = {
          services: {},
        };
      user.authorityLevel.services = permissionValue;
      const userData = await editUser(user);
      const newUsers = { ...users };
      newUsers[userData.id] = User.fromAPIResponse(userData);
      contextValue?.setUsers(newUsers);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const deletePathRule = (service: string, pathName: string) => {
    const clonedState: { [key: string]: any } = {
      ...user?.authorityLevel?.services,
    };
    delete clonedState[service][pathName];
    // if service is empty, delete it
    if (Object.keys(clonedState[service]).length === 0) {
      delete clonedState[service];
    }
    setPermissionState(clonedState);
  };

  const editUserModel = async (user: User) => {
    try {
      const userData = await editUser(user);
      const newUsers = { ...users };
      newUsers[userData.id] = User.fromAPIResponse(userData);
      contextValue?.setUsers(newUsers);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <UserProfile
      user={user}
      userId={userId}
      permissionState={user?.authorityLevel?.services || {}}
      deletePathRule={deletePathRule}
      editUser={editUserModel}
      setPermissionState={setPermissionState}
      departments={departments}
    />
  );
};

ConnectedUserProfile.defaultProps = {
  getUser: getUser,
};
export default ConnectedUserProfile;
