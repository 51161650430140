import * as React from 'react';
import { NoUsersDiv, Table, TableContainer, TableHeading } from '../styles';

const NoUsersView = () => {
  return (
    <TableContainer data-testid="table-container">
      <TableHeading>
        <h3>All Users</h3>
      </TableHeading>
      <section className="table-container">
        <Table>
          <NoUsersDiv>
            No Users
          </NoUsersDiv>
        </Table>
      </section> </TableContainer>
  )
}
export default NoUsersView;