import * as React from 'react'
import styled from 'styled-components'
import { Stepper, Step, StepLabel } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import colorPallet from '../../../utils/design/collorPallet'
import { AssignmentDefinitionContainer, AssignmentDefinitionContainerReverse } from '../AssignmentDrillDown/components/Navigation/Navigation'

interface IProps {
  contractNo: string;
  contractType: string;
  steps: string[];
  currentStep: number;
}

const HeaderContainer = styled.div`
padding: 1rem 0;
.css-zpcwqm-MuiStepConnector-root {
  position: absolute;
  left: calc(-47%);
  right: calc(53%);
}
.css-ascpo7-MuiStepLabel-root, .MuiStepLabel-iconContainer {
  z-index: 1;
  position: relative;
  opacity: 1;
}
.MuiSvgIcon-root{
  z-index: 1;
  position: relative;
  opacity: 1;
  width: 2rem;
  height: 2rem;
}
.MuiStepConnector-line{
  height: 10px !important;
};
}
`
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {

  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        '#1976d2',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        '#1976d2',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      '#eaeaf0',

    borderRadius: 1,
  },
}));


const CreateAssignmentHeader = (props: IProps): JSX.Element => {
  return (
  <HeaderContainer>
    <div>
      <AssignmentDefinitionContainerReverse contractNo={props?.contractNo} contractType={props?.contractType} />
    </div>
    <Stepper data-testid="stepper"  activeStep={props.currentStep} alternativeLabel connector={<ColorlibConnector />}>
      {props.steps.map((step, a) => {
        return <Step data-testid="step" key={a}>
          <StepLabel data-testid="step-label" >{step}</StepLabel>
        </Step>
      })}
    </Stepper>
  </HeaderContainer>
  )
}

export default CreateAssignmentHeader