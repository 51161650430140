import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TableContainer, TableFilteringOptions, TableHeading, Table, PaginationContainer, ArrowsContainer, PageNumbers } from "../../AssignmentTable/styles";
import InboxRow from "../components/InboxRow";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Popover from "../../../../components/PopOver";
import FilterInboxMenu from "../components/FilterInboxMenu";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { ESelectedColumn } from "../../AssignmentTable";
import { IEmail } from "../../../../api/v1/types";
import InboxNav from "./InboxNavigation";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { sendEmail } from "../../../../api/v1/emails/post";

interface IRenderedEmailsProps {
  emails: IEmail[];
  fetchEmails: (filterType: String, searchKeyword: String, page: Number) => void;
  filterType: String;
  totalEmails: Number;
  token: String;
  loadPreviousPage: () => void;
  loadNextPage: () => void;
  email: IEmail;
  onClose: () => void;
  emailContent: EditorState;
  handleEmailContent: (content: EditorState, setFieldValue: Function) => void;
  handleFileUpload: (file: File) => Promise<any>;
  emailContentValidation: (values: any) => any;
}

const RenderedEmails: React.FC<IRenderedEmailsProps> = (props:IRenderedEmailsProps) => {
  const [selectedColumn, setSelectedColumn] = React.useState<ESelectedColumn>();
  const [searchKeyword, setSearchKeyword] = React.useState<string>("");
  const [sortOrder, setSortOrder] = React.useState(null);

  const [emailContent, setEmailContent] = React.useState<EditorState>(
    EditorState.createEmpty(),
  );
  const { onClose } = props;

  const handleEmailContent = (
    content: EditorState,
    setFieldValue: Function,
  ) => {
    setEmailContent(content);
    setFieldValue("editorContent", content);
  };

  const emailContentValidation = (values: any) => {
    const errors: any = {};
    if (
      values.bccField &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.bccField)
    ) {
      errors.bccField = "Invalid email address for Bcc recipients";
    }
    if (
      values.ccField &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.ccField)
    ) {
      errors.ccField = "Invalid email address for Cc recipients";
    }
    if (values.subject.trim().length === 1) {
      errors.subject = "Subject should be longer than 1 character";
    }

    const editorContentIsEmpty = !values.editorContent
      .getCurrentContent()
      .getPlainText("")
      .trim();
    if (editorContentIsEmpty) {
      errors.editorContent = "Email content cannot be empty";
    }
    return errors;
  };

  const handleSendEmail = (values: any) => {
    const errors = emailContentValidation(values);
    if (Object.keys(errors).length === 0) {
      const htmlContent = stateToHTML(emailContent.getCurrentContent());
      const data = {
        to: props?.email?.from || values.recipients,
        subject: values.subject,
        body: htmlContent,
        cc: values.ccField,
        bcc: values.bccField,
      };
      sendEmail(data);
    } else {
      console.error("error");
    }
  };

  const handleFileUpload = (file: File) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
      const data = new FormData(); // eslint-disable-line no-undef
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  return (
      <Table>
        <thead>
          <tr className="table-header">
            <th>
              <Popover content={
                <FilterInboxMenu />
              }>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  All Emails <ArrowDropDownIcon />
                </div>
                </Popover>
              </th>
              <th>Time</th>
              <th>From</th>
              <th>Subject</th>
              <th><AttachFileOutlinedIcon/></th>
              <th>Date</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {props?.emails?.map((email, key) => {
              return(
                  <InboxRow 
                  key={key}
                  email={email} 
                  attachmentId={props.attachmentId}
                  emailContent={emailContent}
                  handleSendEmail={handleSendEmail}
                  onClose={onClose}
                  handleFileUpload={handleFileUpload}
                  handleEmailContent={handleEmailContent}
                  emailContentValidation={emailContentValidation}
                  />
                  
                  )    
              }
              )}
          </tbody>
        </Table>
    )
}

export default RenderedEmails;