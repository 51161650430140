import * as React from 'react';

const DepartmentsModal = (props: DepartmentsModalProps) => {
  const [selectedDepartments, setSelectedDepartments] = React.useState<number[]>([]);
  return(
    <>
      <div style={{padding: '0.5rem'}}>
        Select which departments will be managed by <strong>Admins</strong>
      </div>
      <div style={{overflowY: 'scroll', height: '10rem', border: '1px solid black'}}>
        {props?.departments?.map((department) => (
          <div key={department.id} style={{display: 'flex', justifyContent: 'space-between', padding: '0.5rem', backgroundColor: selectedDepartments.includes(department.id) ? 'lightgray' : 'white'}} onClick={() => setSelectedDepartments([...selectedDepartments, department.id])}>
            <div>{department.name}</div>
          </div>
        ))}
      </div>
      <div>
        <button onClick={() => props.onClose()}>Cancel</button>
        <button onClick={() => props.onClose()}>Save</button>
      </div>

    </>
  )
}

export default DepartmentsModal;