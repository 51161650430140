import {api} from "../index";
import { IAttachment, IEmail } from "../types";

export const saveAttachment = async(documentInformation: IAttachment): Promise<Boolean> => {
  const document_information = {
    'label': documentInformation.label,
    'file_name': documentInformation.fileName,
    'assignment_id': documentInformation.assignmentId,
    'message_id': documentInformation.messageId,
    'attachment_id': documentInformation.attachmentId,
    'type': documentInformation.type,
}

  try {
    const response = await api.post('/emails/attachments', document_information);
    return response.data.success;
  } catch (error) {
    console.error('An error occurred while uploading data: ', error);
    return false;
  }
};

export const sendEmail = async(email: IEmail): Promise<Boolean> => {
  try {
    const response = await api.post('/emails/', email);
    return response.data.success;
  } catch (error) {
    console.error('An error occurred while sending email: ', error);
    return false;
  }
}