import * as React from 'react'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useLocation } from 'react-router-dom';


interface IReactAccordionProps {
    title: string;
    children: any;
    openingKey: string | string[];
    className?: string

}

const ReactiveAccordion = (props: IReactAccordionProps): JSX.Element => {
    const { title, children, openingKey, className } = props;
    const location = useLocation()
    const currentHash = location.hash
    const [expanded, setExpanded] = React.useState(false)
    const ref = React.useRef<HTMLDivElement>(null)
    const toggleExpansion = (e): void => {
        e.preventDefault()
        e.stopPropagation()
        setExpanded(!expanded)
    };
    const checkKey = (key) => {
        if (key) { currentHash.includes(key) && setExpanded(true) }
    }

    React.useEffect(() => {
        const openingKeyIsArray = Array.isArray(openingKey)
        openingKeyIsArray ? openingKey.forEach(checkKey) : checkKey(openingKey)
    }, [currentHash])


    return (
        <section ref={ref}>
            <Accordion expanded={expanded} className={className} >
                <AccordionSummary
                    expandIcon={<ArrowRightIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: " 1rem"
                    }}
                    onClick={toggleExpansion}
                >
                    <p>{title}</p>
                </AccordionSummary>
                <AccordionDetails>
                 {expanded && props.children}
                </AccordionDetails>
            </Accordion>
        </section>
    )

}

export default ReactiveAccordion;