import * as React from "react";
import AutoCompleteInput from "../../AutocompleteInput";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Map from "../../../../../../../components/Map";
import { InsuredSubject } from "../../../../../../../api/v1/assignments/classes/insuredSubjects";

const StyledRiskCoverage = styled.section`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  .MuiAutocomplete-root {
    width: 100%;
  }
  p {
    font-size: 0.8rem !important;
  }
  p:not(:last-child)::after {
    content: ",";
  }
`;

const CoverageInformation = (props: InsuredSubject):JSX.Element => {
  const {
    insuredSubject,
    search,
    options,
    setFieldValue,
    index,
  } = props;
  const riskCategory = insuredSubject?.riskCategory;
  const prefix = `insuredSubjects[${index}].riskCategory`;

  return (
    <>
      <Accordion className="child-accordion">
        <AccordionSummary
          expandIcon={<ArrowRightIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <p>Location Information</p>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion className='child-accordion'>
            <AccordionSummary
              expandIcon={<ArrowRightIcon />}

              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p>Included Location</p>
            </AccordionSummary>
            <AccordionDetails>
              <Map
                included={true}
                multiSelect
                options={options}
                search={search}
                name={`${prefix}.includedLocations`}
                setFieldValue={setFieldValue}
                values={riskCategory?.includedLocations || []}
                label={"Included Locations"}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion className='child-accordion'>
            <AccordionSummary
              expandIcon={<ArrowRightIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <p>Excluded Location</p>
            </AccordionSummary>
            <AccordionDetails>
              <Map
                included={false}
                multiSelect
                options={options}
                search={search}
                name={`${prefix}.excludedLocations`}
                setFieldValue={setFieldValue}
                values={riskCategory?.excludedLocations || []}
                label={"Excluded Locations"}
              />
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </>

  );
};

export default CoverageInformation;
