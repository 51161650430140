import styled from "styled-components";

export const UnderwriterTableWrapper = styled.div`
  background: #f2f8ff;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  padding: 2rem;
`;

export const UnderwriterTableContainer = styled.div`
  height: 18rem;
  overflow-y: auto;
`;

export const UnderwriterTable = styled.section`
  width: 87rem;
  font-size: 12px;
  align-items: center;
  background: #cce5ff;
  justify-content: space-between;
  box-shadow: 0px -1px 3px 0px #e1e3e5 inset;
  th,
  td {
    text-align: center;
    padding: 13px 13px;
  }
  .type-metrics {
    color: #646570;
    width: 10rem;
  }
  thead tr th:first-child,
  tbody tr td:first-child {
    position: sticky;
    left: 0;
    background: #cce5ff;
    z-index: 1;
  }
  .span-color {
    color: #2853e5;
  }
  tbody tr td {
    border-top: 1px solid #3361ff;
    border-bottom: 1px solid #3361ff;
    text-transform: uppercase;
    background-color: #cce5ff;
  }
  tr:nth-child(even) td:not(:first-child) {
    background-color: #f2f8ff;
  }
  tr:nth-child(odd) td:not(:first-child) {
    background-color: white;
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding: 0;
  }
`;

export const StyledUnderwriterRow = styled.tr<{
  selected?: boolean;
}>`
  background-color: #cce5ff;
  height: 54px;
  .employee-name {
    text-decoration: underline;
    background-color: 3361FF;
  }
`;
export const ControlContainerWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  }`;

export const AccordionContainer = styled.section`
  width: 100%;
  height: 74px;
  font-size: 12px;
  align-items: center;
  background: #cce5ff;
  .category-metrics {
    width: 12rem;
  }
  td {
    padding: 18px 16px;
  }
  tr td {
    text-transform: uppercase;
  }
`;
