import * as React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import Button from "../../../components/FormElements/Button";
import { IAssignment, Task } from "../../../api/v1/types";
import { useEffect } from "react";
import { IAssignmentComment } from "../../../api/v1/types";
import { getComments } from "../../../api/v1/comments/get";
import { addComment } from "../../../api/v1/comments/post";
import { UserContext } from "../../../context/UserContext";
import asModal from "../../../hoc/asModal";
import { Skeleton } from "@mui/material";

const Container = styled.div<{ isOpen: boolean }>`
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: left 0.3s ease-in-out;
  position: absolute;
  right: 0;
  z-index: 999;
  display: flex;
  height: 100%;
  width: 40rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 2px solid #f7f8fa;
  h3 {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 0;
  }
`;

const MessageContainer = styled.div<{ align: 'left' | 'right' }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
  margin: 0.8rem;
`;

const StyledMessage = styled.div<{ isMe: boolean }>`
  width: 80%;
  background-color: ${(props) => (props.isMe ? 'rgba(24, 106, 222, 0.20)' : '#F7F8FA')};
  border-radius: ${(props) => (props.isMe ? '0.625rem 0rem 0.625rem 0.625rem' : '0rem 0.625rem 0.625rem 0.625rem')};
  padding: 0.5rem;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  color: ${(props) => (props.isMe ? 'rgba(62, 62, 60, 0.85)' : '#000')};
  margin: 0;
  p {
    margin: 0;
  }
`;

const StyledTextarea = styled.textarea`
  resize: none;
  width: 90%;
  border: none;
  padding: 0.5rem;
  font-size: 1rem;
  font-family: inherit;
  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScrollContainer = styled.div`
  height: 80%;
  width: 100%;
  background-color: #E8EAEE;
  overflow-y: auto;
`;

const TimeContainer = styled.div`
  width: 80%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  color: #0D082C66;
  font-size: 0.875rem;
`;

const FooterContainer = styled.div`
  width: 100%;
  height: 10%;
  background-color: white;
  display: flex;
  padding-bottom: 2px;
  justify-content: space-evenly;
  align-items: center;
`;

const StyledDiv = styled.div`
  background-color: #E8EAEE;
  border-radius: 0.625rem;
  border: 1px solid black;
  width: 9rem;
  align-items: center;
  margin-bottom: 1rem;
`

const SkeletonContainer = styled.div`
  padding: 1.5rem;
`;

const CloseIconContainer = styled.div`
  align-self: flex-end;
`;

const UserListContainer = styled.div`
  position: absolute;
  left: 50px;
  width: 80%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  padding: 4px;
`;

const UserListItem = styled.div`
  padding: 4px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const UserList = ({ users }) => {
  return (
    <UserListContainer>
      {users.map(user => (
        <UserListItem key={user.id}>{user.name}</UserListItem>
      ))}
    </UserListContainer>
  );
};

export interface ICommentsProps {
  isOpen: boolean;
  handleCommentsClose: () => void;
  selectedTask: Task;
}

export const Comments = (props: ICommentsProps) => {
  const [comments, setComments] = React.useState<IAssignmentComment[]>([]);
  const { isOpen, handleCommentsClose, selectedTask } = props;
  const [newComment, setNewComment] = React.useState<string>('');
  const [contractNo, setContractNo] = React.useState(null);
  const [taskDescription, setTaskDescription] = React.useState(null);
  const { user } = React.useContext(UserContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [showUserList, setShowUserList] = React.useState(false);
  const commentsRef = React.useRef(null);
  const users = [
    { id: 1, name: 'User1' },
    { id: 2, name: 'User2' },
    { id: 3, name: 'User3' },
    { id: 4, name: 'User4' },
    { id: 5, name: 'User5' },
    { id: 6, name: 'User6' },
    { id: 7, name: 'User7' },
    { id: 8, name: 'User8' },
    { id: 9, name: 'User9' },
    { id: 10, name: 'User10' },
  ];

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (commentsRef.current && !commentsRef.current.contains(event.target)) {
        handleCommentsClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commentsRef]);


  React.useEffect(() => {
    if (selectedTask !== undefined) {
      getComments(selectedTask)
        .then((data) => {
          if (data) {
            setComments(data.comments)
            setIsLoading(false)
            setContractNo(data.contract)
            setTaskDescription(data.description)
          } else {
            console.log('no data')
          }
        }
        ).catch((error) => {
          console.log(error)
        })
    }
  }, [selectedTask])


  const handleNewCommentChange = (event) => {
    const value = event.target.value;
    setNewComment(value);
    if (value.includes('@')) {
      setShowUserList(true);
    } else {
      setShowUserList(false);
    }
  };

  const handleSendMessage = () => {
    const trimmedComment = newComment.trim();
    if (!trimmedComment || !selectedTask) {
      return;
    }

    addComment(selectedTask, trimmedComment)
      .then(() => getComments(selectedTask))
      .then((updatedComments) => {
        if (updatedComments) {
          setComments(updatedComments.comments);
        }
        setNewComment("");
      })
      .catch((error) => {
        console.error("Error posting comment:", error);
      });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default behavior (e.g., newline)
      handleSendMessage();
    }
  };

  return (
    <Container isOpen={isOpen} data-testid="commments-container" ref={commentsRef}>
      <HeaderContainer data-testid="task-name-comments">
        <CloseIconContainer>
          <CloseIcon data-testid="close-settings" onClick={handleCommentsClose} />
        </CloseIconContainer>
        <h3>{taskDescription}</h3>
        {isLoading ? (
          <Skeleton height="30px" variant="text" width='9rem' />
        ) : (
          <StyledDiv data-testid="task-contractno-comments">{contractNo}</StyledDiv>
        )}
      </HeaderContainer>

      <ScrollContainer>
        {isLoading && (
          <SkeletonContainer>
            <Skeleton height="50px" variant="text" />
            <Skeleton height="50px" variant="text" />
            <Skeleton height="50px" variant="text" />
            <Skeleton height="50px" variant="text" />
            <Skeleton height="50px" variant="text" />
            <Skeleton height="50px" variant="text" />
          </SkeletonContainer>
        )}
        {comments && comments?.map((comment, index) => {
          console.log('comment', comment.created_by)
          return (
            <MessageContainer data-testid="container" key={comment.id} align={comment.created_by == user.id ? 'right' : 'left'}>
              <p data-testid="user-name">
                <strong>{comment?.username}</strong>
              </p>
              <StyledMessage data-testid="message" align={comment.created_by == user.id ? 'right' : 'left'} isMe={comment.created_by == user.id}>
                <p>{comment?.text}</p>
              </StyledMessage>
              <TimeContainer>
                <p data-testid="date">{new Date(comment?.created_at).toLocaleDateString()}</p>
                <p data-testid="time">{new Date(comment?.created_at).toLocaleTimeString()}</p>
              </TimeContainer>
            </MessageContainer>)
        })}
      </ScrollContainer>
      <FooterContainer>
        <StyledTextarea
          data-testid="task-comment-input"
          placeholder="Type a message..."
          value={newComment}
          onChange={handleNewCommentChange}
          onKeyDown={handleKeyDown}
        />
        {showUserList && <UserList users={users} />}
        <SendOutlinedIcon data-testid="send-comment" color="disabled" onClick={handleSendMessage} />
      </FooterContainer>
    </Container>
  );
};

export default Comments;
