export interface IFont {
  name: string;
  url: string;
  fontWeights?: string[] | number[];
}

export enum EKPI {
  totalPending = "total_pending",
  totalEscalations = "total_escalations",
  completedWithinSLA = "completed_items_sla",
  workloadAverage = "total_average_daily_workload",
  totalInActiveAssignments = "total_inactive_assignments",
  totalNewAssignments = "total_new_assignments",
  totalLate = "total_late",
  completedItems = "completed_items",

  // Total Assignments
  totalAssignments = "total_assignments",
  totalProposal = "total_proposal",
  totalNewBusiness = "total_new_business",
  totalRenewals = "total_renewals_offers",
  totalEndorsements = "total_endorsements",
  totalDeclinedYtd = "total_declined_ytd",
  totalAverageToClose = "average_to_close",

  // Accepted Attritubes,
  totalAcceptedMtd = "total_accepted_mtd",
  totalAcceptedAsNewBusiness = "total_accepted_new_business",
  totalAcceptedAsRenewal = "total_accepted_renewals",
  totalAcceptedAsEndorsement = "total_accepted_endorsements",

  // Declined Attritubes
  totalDeclinedMtd = "total_declined_mtd",
  totalDeclinedAsNewBusiness = "total_declined_new_business",
  totalDeclinedAsRenewal = "total_declined_renewals",
  totalDeclinedAsEndorsement = "total_declined_endorsements",

  // Total Active
  totalActive = "total_active",

  // Total in Request
  totalInRequest = "total_in_request",
}

export enum RiskProcedures {
  Input = "input",
  Preview = "preview",
  InProgress = "in_progress",
  Request = "request",
  ToBeApprovedByLeadership = "to_be_approved_by_leadership",
  ApprovedByLeadership = "approved_by_leadership",
  NotApprovedByLeadership = "not_approved_by_leadership",
  ReturnedByLeadershipToRework = "returned_by_leadership_to_rework",
  Confirmed = "confirmed",
  Accepted = "accepted",
  Declined = "declined",
  Rejected = "rejected",
  Fronting = "fronting",
  Failed = "failed",
}

export type TKPI = keyof typeof EKPI;

export type KPIS = Record<
  string,
  {
    count: number;
    metrics: TKPI[];
  }
>;

export interface IUnderwriterAssignment {
  id: number;
  kodDgr: number;
  dogovor: string;
  insurer: string;
  cedent: string;
  broker: string;
  cedentNumber: string;
  startDate: string;
  endDate: string;
  acceptedDate: string;
  comments: string[];
  createdDate: string;
  type: number;
  underwriter: string;
  advanced: number;
  MeReNo: string;
  contractNo: string;
}

export enum EContractType {
  fac = "faculatative",
  treaty = "treaty",
}

export enum AttachmentType {
  pdf = ".pdf",
  wordDocx = ".docx",
  wordDoc = ".doc",
  meet = ".ics",
}

export enum ImageTypes {
  jpg = ".jpg",
  jpeg = ".jpeg",
  png = ".png",
  gif = ".gif",
  bmp = ".bmp",
  svg = ".svg",
  webp = ".webp",
}
