import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  font-size: 0.8rem;
  position: relative;
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;
    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
  th {
    background: white;
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 15px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }

`;

export const TableContainer = styled.section`
  background-color: #ffffff;
  height: 55rem;
  overflow: scroll;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .table-container {
    overflow: auto;
  }
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
`;

export const TableHeading = styled.section`
  padding: 1rem;
  display: flex;
  color: #6d7175;
  justify-content: space-between;
  align-items: center;
`;

export const TableFilteringOptions = styled.section`
  border: 1px solid #babfc3;
  flex-basis: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  overflow: hidden;
  input {
    flex-basis: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
  }
`;


export const NextStepsItem = styled.li`
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  &:hover {
    background-color: #edf0f4;
    cursor: pointer;
  }
  .assignment-contract {
    margin-right: 20px;
    width: 50%;
  }
  .task-icon {
    margin-right: 20px;
  }
  .task-description {
    margin-right: 20px;
    width: 100%;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000;
  }
  .task-date {
    margin-right: 20px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(0, 0, 0, 0.7);
  }
  .task-history-date {
    margin-right: 20px;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .history-skeleton{
    border-radius: 20px;
  }
`;

// export const ContentWrapper = styled.div`
//  display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 10px;
//   /* width: 100%;
//   height: 70px;
//   display: flex; */
//   /* justify-content: space-between; */
//   /* align-items: center;
//   gap: 2rem */
// `;

export const DashboardAlertBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .alert-count {
    position: absolute;
    top: -5px;
    height: 15px;
    left: 50%;
    width: 15px;
    border-radius: 50%;
    background-color: #f30000c2;
    display: flex;
    font-size: 0.8rem;
    justify-content: space-around;
    align-items: center;
    color: white;
  }
`;

export const IconWrapper = styled.div`
  width: 30%;
  display: flex;
  justify-content: end;
`;

export const IconContainer = styled.div`
  padding: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoHistoryDiv = styled.div`

display: flex;
justify-content: center;
font-size: 3rem;
padding: 12rem;

`

export const ContractNoLabel = styled.div<{ status: string }>`
border-radius: 5px;
border: 1px solid #757575;
background: #F7F8FA;
padding: 0.25rem 1rem;
color: #44474b;
text-align: center;
font-weight: 500;
display: inline-block;
`;