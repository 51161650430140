import styled from "styled-components";

export const StyledMetricsNavigation = styled.div<{
  width?: number;
  left?: number;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1.5rem;
  position: relative;
  &:before {
    content: " ";
    width: ${(props) => props?.width || 0}px;
    height: 2px;
    left: ${(props) => props?.left || 0}px;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    position: absolute;
    background: #5b5fc7;
  }
  a {
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border-bottom: 2px solid #5b5fc7;
    }
  }
`;
export const StyledMain = styled.div`
  position: relative;
  display: grid;
  // padding: 2rem;
  grid-template-columns: 1fr;
  grid-template-areas: "main";
  // overflow: scroll;
  .close {
    grid-area: close;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
  }
`;

export const Main = styled.div`
  // padding: 1rem;
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow: auto;
`;

export const MetricNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
