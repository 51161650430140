import * as React from "react";
import {
    PriorityShortView,
    PriorityShortViewTitle,
  } from "../styles";
import { Skeleton } from "@mui/material";

const ShortViewSkeleton = () => {
    return (
       <>
      
      <PriorityShortView><PriorityShortViewTitle>
        <span data-testid="tasks">Tasks</span>
        </PriorityShortViewTitle>
        <Skeleton variant="text" height={15} width="30%" animation="wave" />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Skeleton variant="circular" width="10%" animation="wave" />

          <Skeleton variant="text" height={20} width="5%" animation="wave" />
        </div>
        </PriorityShortView>
</>
    )

    
}
export default ShortViewSkeleton;