import * as React from "react";
import Popover, { PopoverContent, PopoverTrigger } from "../PopOver";
import AssignmentOptionsMenu from "../../pages/Dashboard/AssignmentTable/components/TableBody/TableBodyRow/components/MenuPopover";
import asModal from "../../hoc/asModal";
import ConfirmationModal from "../ConfirmationModal";
import ExtendedModal from "../ExtendedModal/ExtendedModal";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteAssignment } from "../../api/v1/assignments/delete";
import { editAssignment } from "../../api/v1/assignments/put";
import { getPriorities } from "../../api/v1/priorities/get";
import { Assignment } from "../../api/v1/__mocks__/assignments";
import AssignmentStructure from "../../api/v1/assignments/classes/assignment";
import addTask from "../../api/v1/tasks/post";

const IconWrapper = styled.section`
  cursor: pointer;
`;

const ConfirmDeletion = asModal(ConfirmationModal, '#modal-root');
const StyledConfirmDeletion = styled(ConfirmDeletion)`
z-index: 1000;
  > * {
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
    }
  }
`;

const CreateExtendedModal = asModal(ExtendedModal, "#modal-root");
const StyledCreateExtendedModal = styled(CreateExtendedModal)`
position: absolute;
width: 100%;
height: 100%;
z-index: 10000;
display: flex;
justify-content: center;
align-items: center;
> * {
    padding: 1rem;
    font-size: 1.2rem;
    font-family: "Lato", sans-serif;
  }
`;

export enum ActionType {
  PRIORITY_HIGH = 'high',
  PRIORITY_MEDIUM = 'medium',
  PRIORITY_LOW = 'low',
  DUPLICATE = 'duplicate',
  ALERT = 'alert'
}

interface ActionConfig {
  title: string;
  action: (assignment: AssignmentStructure) => Promise<any>;
}

export const actionConfigs: Record<ActionType, ActionConfig> = {
  [ActionType.PRIORITY_HIGH]: {
    title: "Leave a comment below before to mark this Contract as a Priority",
    action: async (assignment: AssignmentStructure) => {
      const response = await editAssignment(assignment.id, { priority: 'high' } as Assignment);
      return response;
    }
  },
  [ActionType.PRIORITY_MEDIUM]: {
    title: "Leave a comment below before to mark this Contract as a Priority",
    action: async (assignment: AssignmentStructure) => {
      const response = await editAssignment(assignment.id, { priority: 'medium' } as Assignment);
      return response;
    }
  },
  [ActionType.PRIORITY_LOW]: {
    title: "Leave a comment below before to mark this Contract as a Priority",
    action: async (assignment: AssignmentStructure) => {
      const response = await editAssignment(assignment.id, { priority: 'low' } as Assignment);
      return response;
    }
  },
  [ActionType.DUPLICATE]: {
    title: "Please leave a comment below before copying this assignment to a new contract number",
    action: async (assignment: AssignmentStructure) => {
      const newAssignment = new AssignmentStructure(assignment);
      const response = await newAssignment.duplicateAssignment();
      return response;
    }
  }
};


export const AssignmentOptions = (props) => {
  const {
    assignment,
    navigateToAssignment,
    markAsComplete,
    escalateAssignment,
    setPriorities,
    setSearchParams,
    fetchAssignments,
    setOpenExtendedModal: propsSetOpenExtendedModal,
    openExtendedModal: propsOpenExtendedModal
  } = props;
  const assignmentId = assignment?.id;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [currentAction, setCurrentAction] = React.useState<ActionType | null>(null);
  const [openDeleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [localOpenExtendedModal, setLocalOpenExtendedModal] = React.useState<boolean>(false);
  const anchorElement = React.useRef<HTMLDivElement>(null);

  const openExtendedModal = propsOpenExtendedModal !== undefined ? propsOpenExtendedModal : localOpenExtendedModal;
  const setOpenExtendedModal = propsSetOpenExtendedModal || setLocalOpenExtendedModal;

  const togglePopover = (): void => { setIsOpen(!isOpen) };

  const handleCloseModal = () => {
    setOpenExtendedModal(false);
    setCurrentAction(null);
  }

  const handleDeletingAssignment = async () => {
    try {
      setDeleteModal(false);
      await deleteAssignment(assignment?.id);
      await fetchAssignments();
    } catch (err) {
      setDeleteModal(false);
      console.error(err);
    }
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }


  const modalData = {
    contractNo: assignment?.contractNo,
    broker: assignment?.broker || assignment?.brokerId,
    title: currentAction ? actionConfigs[currentAction].title : "",
    assignmentId: assignment?.id
  };

  return (
    <>
      <IconWrapper className="icon-wrapper"
        onClick={togglePopover}
      >
        <PopoverTrigger ref={anchorElement}><MoreVertIcon data-testid="more-over-icon" /></PopoverTrigger>
        <Popover
          isOpen={isOpen}
          anchorEl={anchorElement?.current}
        >
          <PopoverContent>
            <div className="caret"></div>
          </PopoverContent>
          <AssignmentOptionsMenu
            id={assignmentId}
            setCurrentAction={setCurrentAction}
            markAsComplete={markAsComplete}
            escalateAssignment={escalateAssignment}
            viewAssignment={navigateToAssignment}
            viewLegacyAssignments={navigateToAssignment}
            assignmentId={assignmentId}
            setPriority={setPriorities}
            setPriorities={setPriorities}
            togglePopover={togglePopover}
            handleOpenDeleteModal={handleOpenDeleteModal}
            setOpenExtendedModal={setOpenExtendedModal}
            setDeleteModal={setDeleteModal}
          />
        </Popover>
      </IconWrapper>

      <StyledCreateExtendedModal
        isOpen={openExtendedModal}
        onClose={handleCloseModal}
        modalData={modalData}
        currentAction={currentAction}
        onConfirm={async (comment: string, selectedDateTime: Date) => {
          if (currentAction) {
            const response = await actionConfigs[currentAction].action(assignment);
            console.log("Response from onConfirm:", response);
            if (response && response?.assignment?.id) {
              await addTask(response.assignment.id, comment, selectedDateTime);
              if ([ActionType.PRIORITY_HIGH, ActionType.PRIORITY_MEDIUM, ActionType.PRIORITY_LOW].includes(currentAction)) {
                const priorities = await getPriorities();
                setPriorities(priorities);
              }
            }
          }
        }}
      />

      <StyledConfirmDeletion
        isOpen={openDeleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={handleDeletingAssignment}
        title="Delete Assignment"
        message="Are you sure you want to delete this assignment?"
      />

    </>
  )
}