import * as React from "react";
import {
  TaskMenuContainer,
  CreateNewTaskButton,
} from "./styles";
import NewTaskItems from "./NewTaskItems";
import CloseIcon from "@mui/icons-material/Close";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Skeleton } from "@mui/material";
import { IDefaultTask } from "../../../../../api/v1/types";
import addTask from "../../../../../api/v1/tasks/post";
import { Task } from "../../../../../api/v1/types";
import { DashboardContext } from "../../..";
import { getPriorities } from "../../../../../api/v1/priorities/get";
import CustomNewTaskList from "./components/CustomNewTaskList";

interface ITaskMenuProps {
  expandTaskMenu: boolean;
  handleExpandAddTaskMenu: () => void;
  defaultTask: IDefaultTask[];
  assignmentId?: string;
  setTasks: (tasks: Task[]) => void;
  tasks: Task[];
  isLoading: boolean;
}
const TaskMenu = (props: ITaskMenuProps) => {
  const {
    expandTaskMenu,
    handleExpandAddTaskMenu,
    defaultTask,
    assignmentId,
    tasks,
    isLoading,
  } = props;
  const [expandCreateNewTaskMenu, setExpandCreateNewTaskMenu] =
    React.useState(false);
  const { setPriorities } = React.useContext(DashboardContext);
  const formikRef = React.useRef();

  const handleExpandCreateNewTaskMenu = (event: React.MouseEvent<Element>) => {
    event.preventDefault();
    setExpandCreateNewTaskMenu(!expandCreateNewTaskMenu);
  };

  const onAddTask = (tasks) => {
    addTask(assignmentId, tasks)
      .then((response) => {
        props.setTasks(response);
        getPriorities()
          .then((priorities) => {
            setPriorities(priorities || []);
          })
          .catch((priorityError) => {
            console.error("Failed to fetch priorities:", priorityError);
          });
      })
      .catch((error) => {
        console.error("Failed to add task:", error);
      });
  };

  const handleSend = async (values: any, { setSubmitting, resetForm }: any) => {
    try {
      const nonEmptyTasks = Object.keys(values)
        .filter((key) => values[key].trim() !== "")
        .map((key) => ({ task: values[key] }));

      if (nonEmptyTasks.length === 0) {
        return;
      }

      await onAddTask(nonEmptyTasks);

      handleExpandAddTaskMenu();
      resetForm();
      setExpandCreateNewTaskMenu(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <TaskMenuContainer
      isAddTaskOpen={expandTaskMenu}
      onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}
    >
      <div className="accordion-container-header">
        <CreateNewTaskButton onClick={handleExpandCreateNewTaskMenu}>
          Create a New Task
          <BorderColorIcon style={{ fontSize: "16px", color: "white" }} />
        </CreateNewTaskButton>

        <CloseIcon
          onClick={(event: React.MouseEvent<Element>) => {
            handleExpandAddTaskMenu(event);
            setExpandCreateNewTaskMenu(false);
            if (formikRef.current) {
              formikRef.current.resetForm();
            }
          }}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className="accordion-container-body">
        <SkeletonLoader isLoading={isLoading} />
        <div className={`default-task-list ${expandCreateNewTaskMenu ? 'hidden' : ''}`}>
        <DefaultTaskList
          defaultTask={defaultTask}
          tasks={tasks}
          assignmentId={assignmentId}
          setTasks={props.setTasks}
          handleExpandAddTaskMenu={handleExpandAddTaskMenu}
          />
          </div>
        <CustomNewTaskList expandCreateNewTaskMenu={expandCreateNewTaskMenu} handleSend={handleSend} formikRef={formikRef} />
      </div>
    </TaskMenuContainer>
  );
};

export default TaskMenu;

interface ISkeletonLoaderProps {
  isLoading: boolean;
}

const SkeletonLoader = (props: ISkeletonLoaderProps) => {
  return (
    <React.Fragment>
      {Array.from({ length: 8 }).map((_, currentRow) => (
        <React.Fragment key={currentRow}>
          <Skeleton
            className={`custom-skeleton ${
              props.isLoading ? "skeleton" : "skeleton-hidden"
            }`}
            variant="text"
            animation="wave"
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

interface IDefaultTaskProps {
  tasks: Task[];
  assignmentId: string;
  setTasks: (tasks: Task[]) => void;
  handleExpandAddTaskMenu: () => void;
  defaultTask: IDefaultTask[];
}

const DefaultTaskList = (props: IDefaultTaskProps) => {
  const {
    defaultTask,
    tasks,
    assignmentId,
    setTasks,
    handleExpandAddTaskMenu,
  } = props;
  return (
    <>
      {defaultTask
        ?.filter((task) => !tasks.find((t) => t.description === task.task))
        .map((task, index) => (
          <NewTaskItems
            key={index}
            task={task}
            assignmentId={assignmentId}
            setTasks={setTasks}
            handleExpandAddTaskMenu={handleExpandAddTaskMenu}
          />
        ))}
    </>
  );
};
