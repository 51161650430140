import React from "react";
import { useFormik } from "formik";
import { useOutletContext } from "react-router-dom";
import { updateDepartmentsV2 } from "../../../api/v1/organizationHierarchy/put";
import { IDepartment } from "../../../api/v1/types";
import styled from "styled-components"; // Import styled-components

const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
  display: block;
  margin: 10px 0;
  font-size: 16px;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const SubmitButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;
const ManagingDepartments = (props) : JSX.Element => {

    const { departments, department } = useOutletContext();

    const updateManagedDepartments = (values) => {
        updateDepartmentsV2(values);
    };

    const form = useFormik({
        initialValues: {
            department,
        },
        onSubmit: (values) => {
            updateManagedDepartments(values);
        },
    });

    const departmentOptions = Object.values(departments || {}).map((department: IDepartment, key) => (
        <Label key={key}>
            <Checkbox type="checkbox" key={department.id} value={department.id} name="managing" onChange={form.handleChange} />
            {department.name}
        </Label>
    ));

    return (
        <Container>
            {departmentOptions}
            <SubmitButton type="submit" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                form.handleSubmit();
            }}>
                Submit
            </SubmitButton>
        </Container>
    );
};

ManagingDepartments.defaultProps = {
    users: {},
    loading: true,
    setUsers: () => { },
};

export default ManagingDepartments;
