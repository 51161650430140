import * as React from "react";
import { Skeleton } from "@mui/material";
import { HistoryItem, HistoryItemsContainer } from "../styles";

const BroadcastMessageSkeleton = () => {
    return (
        <HistoryItemsContainer className="grid">
        {Array.from({length:6}).map((key, index) => (  
            <HistoryItem>
            <div className="description-date-row">
              <p><Skeleton variant="text" width={200}/></p>
              <p><Skeleton variant="text" width={100}/></p>
            </div>
            <div className='activedays-username-row'>
                <span><Skeleton variant="text" width={100}/></span>
                <span><Skeleton variant="text" width={100}/></span>
            </div>
          </HistoryItem>     
       ))}
       </HistoryItemsContainer>
    )};

export default BroadcastMessageSkeleton