

export class RiskCategory {
  category: string
  subCategory: string
  risk: string
}

export class CoverageInformation {
  locations: {
    address: string,
    characteristics: string,
    excluded: google.maps.places.PlaceResult[]
    included: google.maps.places.PlaceResult[]
  }

  coverage: {
    included: string[]
    excluded: string[]
  }

  deductible: {
    type: string
    basis: string
    assetValues: {
      type: "absolute" | "approximate",
      value: number
    }
  }
}

export class InsuranceTerms {
  sumInsured: number
  orignalRate: number
  PML: number
  tarif: number
  period: {
    inception: Date
    expiry: Date
  }
}

export class InsurancePeriod {
  inception: Date
  expiry: Date
}

export class InsuranceClass {
  class: string
  subClass: string
}

export class CurrencyInfo {
  contract: string
  payment: string
  exchangeRate: {
    rate: number
    date: Date
  }
}

export class ReinsuranceTerms {
  formOfRI: string
  insuranceClass: InsuranceClass
  period: InsurancePeriod
  currency: CurrencyInfo

}

class PaymentTerms {
  earnedPremium: number
  unearnedPremium: number
  acceptedformattedDate: Date
  paymentStatus: string
  schedule: {
    expectedformattedDate: Date
    expectedAmount: number
    actualformattedDate: Date
    actualAmount: number
  }
  
}

class InsuredSubjectAccounting {
  
  limit: {
    from: number
    to: number
  }

  companyShare: {
    from: number
    to: number
  }

  premium: {
    from: number
    to: number
  }

  deductions: {
    from: number
    to: number
  }

  brokerage: {
    from: number
    to: number
  }

  otherDDCs: {
    from: number
    to: number
  }

  upformattedDatedBy: {
    id: number
    name: string
  }

  lastUpformattedDatedformattedDate: Date
  status: string
}


export class InsuredSubject {
  id?: number
  index?: number
  subjectName: string
  policyNumber: string
  riskCategory: RiskCategory
  insuredObject: string
  riskDescription: CoverageInformation
  insuranceTerms: InsuranceTerms
  reinsuranceTerms: ReinsuranceTerms[]
  paymentTerms: PaymentTerms
  accounting: InsuredSubjectAccounting
  additionalInfo: string
  characteristics: string
}