import * as React from "react";
import { TableContainer, UserPermissionsContainer, UserPermissionTable } from "../../../styles";
import { ServiceOptions, EServices } from "./Permissions";
import { Formik } from "formik";
import PermissionsTableHead from "./PermissionsTableHead";
import NewPermissionsRow from "./NewPermissionsRow";
import * as Yup from 'yup';

interface IAddPermissionsView {
  handleAddRestriction: (values) => void;
  onClose: () => void;
}
const AddRestrictionView = (props: IAddPermissionsView) => {
  const { handleAddRestriction } = props;

  const handleTableClick = (e: React.MouseEvent<HTMLTableElement>) => {
    e.stopPropagation();
  };


  const validationSchema = Yup.object().shape({
    path: Yup.string().required("Required"),
  });


  return (
    <Formik
      initialValues={{
        path: "*",
        component: [],
        operation: [],
        service: EServices.underwriting_dashboard,
        permissionType: "deny",
      }}
      onSubmit= {(values) => {
        props.handleAddRestriction(values);
      }}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit}>
            <UserPermissionsContainer>
              <UserPermissionTable onClick={handleTableClick}>
                <thead>
                  <tr>
                    <th>Service</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <select name="service" data-testid="service" onChange={handleChange}>
                        {ServiceOptions.map((service) => {
                          return (
                            <option value={service.value}>
                              {service.label}
                            </option>
                          );
                        })}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <UserPermissionTable onClick={handleTableClick}>
                      <PermissionsTableHead/>
                        <tbody>
                          <NewPermissionsRow
                          data-testid="new-permissions-row"
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            handleSubmit={handleSubmit}
                            onClose={props.onClose}
                          />
                        </tbody>
                      </UserPermissionTable>
                    </td>
                  </tr>
                </tbody>
              </UserPermissionTable>
            </UserPermissionsContainer>
          </form>
        );
      }}
    </Formik>
  );
};
export default AddRestrictionView;
