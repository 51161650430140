import styled from 'styled-components';
import { DropDown } from '../components/DropDown/styles';
import { Layers } from '../../../../../../pages/styles/GlobalStyles';

export const StyledWrapper = styled.div<{ open: boolean }>`
    width: 100%;
    margin: 0.3rem 0;
    position: relative;
    ${DropDown} {
        pointer-events: none;
        ${(props) => {
    if (props.open) {
      return `
                    pointer-events: all;
                    visibility: visible;
                `
    }
    return `visibility: hidden;`
  }
  }
    }
    &:focus-within {
        ${DropDown} {
            pointer-events: all;
            visibility: visible;
        }
        .error {
            display: block;
            color: red;
            font-size: 0.8rem;
        }
    }

`;
export const StyledInput = styled.input`
    outline: none;
    padding-block: 1rem;
    box-sizing: border-box;
    border: none;
    width: 100%;
    border-radius: 0.8rem;
    font-size: 0.8rem;
    padding: 1rem;
    border-bottom: 1px solid #2d2b2b45;

`;


export const ErrorDiv = styled.div`
  display: none;
`;



const onFocus = `
  z-index: ${Layers.Modal};
  .title{
      font-size: 0.8rem;
      position: absolute;
      top: 0rem;
      transform: revert;
      padding: 0;
  }
  input {
      caret-color: black;
      background-color: white;
    }
    input[type=date],
    input[type=datetime-local]{
      color: revert;
    }
`



export const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .error {
    color: red;
    font-size: 0.7rem;
  }
  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    align-items: center;
  }
`

export const BrandedInputWithLabel = styled(StyledLabel) <{ value: string }>`
  position: relative;
  width: 100%;
  input {
    background-color: rgba(247, 248, 250, 0.8);
    background-color: white;
    color: black;
    transition: all 0.6s;
    margin-top: 0.8rem;
  }
  ${(props) => !props?.value && `
    input{
      color: transparent;
      background-color: #d7dfe659;
      }`
  }
  .title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 1rem;
    transition: all 0.6s;
  }
  &:hover {
    z-index: 1;
  }
  ${(props) => props?.value != "" && onFocus}
    &:focus-within {
        ${onFocus}
      }
`;