import * as React from 'react'
import Payments from "../Payments";
import ReinsuranceTerms from "../ReinsuranceTerms";
import PremiumAndLimit from "../PremiumAndLimit";
import { ERoute } from "../../../../context/BrowserContext/routes/types";
import AssignmentDrillDownSkeleton from "../components/AssignmentDrilDownSkeleton";
import type { FormikValues } from "formik";
import type AssignmentStructure from "../../../../api/v1/assignments/classes/assignment";
import Risk from "../Steps/components/Risk"
import GeneralInformation from "../Steps/components/GeneralInformation";
import DocumentsMeta from "../Documents";
import { useLocation, useNavigate } from 'react-router-dom';

interface ISelectAbleTabs {
    currentTab: string;
    assignment: AssignmentStructure;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
    addReinsuranceTerm?: () => void;
    loadingDocuments: boolean;
    formik: FormikValues;
    searchFunctions: Record<string, (searchTerm: string) => void>;
    search: (event: React.ChangeEvent<HTMLInputElement>) => void;
    options: any;
    isLoading: boolean;
  }


const MainContent: Record<ERoute, React.FC<Omit<ISelectAbleTabs, "currentTab" | "isLoading">>> = {
    [ERoute.generalInformation]: GeneralInformation,
    [ERoute.insuredSubjects]: Risk,
    [ERoute.reinsurance]: ReinsuranceTerms,
    // [ERoute.payments]: Payments,
    [ERoute.premiumLimit]: PremiumAndLimit,
    [ERoute.documents]: DocumentsMeta,
  };

const HashTriggerRoute = {
    "#general-information": ERoute.generalInformation,
    "insuredSubjects": ERoute.insuredSubjects,
    "#reinsurance": ERoute.reinsurance,
    // "#payments": ERoute.payments,
    "#premium-limit": ERoute.premiumLimit,
    "#documents": ERoute.documents,}
  
  const SelectableTabs = (props: ISelectAbleTabs): JSX.Element => {
    const {
      currentTab,
      assignment,
      handleChange,
      loadingDocuments,
      handleSelection,
      formik,
      search,
      options,
      isLoading,
      searchFunctions,
      currentAssignemtFilesLength
    } = props;
    const {hash} = useLocation();
    const [internalTab, setInternalTab] = React.useState<string>(ERoute.generalInformation);
    const navigate = useNavigate();
    const location = useLocation();


    const navigateAssignments = (): void => {
      let section = hash.split(".")
      const matchingTab = Object.keys(HashTriggerRoute).find((key) => section[0].includes(key))
      if(section.length > 0 && matchingTab && section[0] !== ""){
        setInternalTab(HashTriggerRoute[matchingTab])
        const replaceLastPath = location.pathname.replace(/[^/]*$/, HashTriggerRoute[matchingTab]);
        navigate(`${replaceLastPath}${hash}`)
      }else if(
        section.length > 0 && !matchingTab && section[0] !== ""
      ) {
        setInternalTab(ERoute.generalInformation)
        const url = location.pathname;
        const replaceLastPath = url.replace(/[^/]*$/, ERoute.generalInformation);
        navigate(`${replaceLastPath}${hash}`)
      }else{
        setInternalTab(currentTab)
      }
    };

    React.useEffect(() => {
      navigateAssignments()
    }, [hash, currentTab])



    const Tab = MainContent[internalTab];
    return (isLoading ? <AssignmentDrillDownSkeleton /> :
      <Tab
        assignment={assignment}
        search={search}
        handleChange={handleChange}
        handleSelection={handleSelection}
        searchFunctions={searchFunctions}
        loadingDocuments={loadingDocuments}
        options={options}
        setFieldValue={formik?.setFieldValue}
        errors={formik?.errors}
        createAssignment
        isLoading={isLoading}
        // goToNextInput={goToNextInput}
      />
    )
  
  }

export default SelectableTabs