import * as React from "react";

import {
  Container,
  Section,
  Title,
  Description,
  Form,
  Label,
  Input,
  Select,
  ConditionContainer,
  ConditionRow,
  ToggleButton,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableHeadColumn,
} from "./styling/StyledAlertsPageWrapper";

import { useFormik } from "formik";
import AutoCompleteInput from "../../../components/FormElements/FieldGenerator/compositeFields/AutoComplete";
import { CreateAssignmentInput } from "../AssignmentDrillDown/Steps/styles";
import Toggle from "../../../components/FormElements/Toggle/Toggle";
import { ArrowDownward, Delete, Edit } from "@mui/icons-material";
import PillButton from "../../../components/FormElements/Button/PillButton";
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ClassIcon from '@mui/icons-material/Class';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material//Add';
interface IAlertCondition {
  property: string;
  operation: string;
  dataType: string;
  value: string;
}

interface IAManagedAlert {
  object: string;
  title: string;
  description: string;
  conditions: IAlertCondition[];
  isActive: boolean;
  index: number;
  prefix: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hasChanged?: boolean;
  isNew?: boolean;
}



const defaultTrigger: IAManagedAlert = {
  object: 'DASHBOARD',
  title: '5 DAYS LATE ASSIGNMENTS',
  isActive: true,
  description: 'This trigger sends an alert any time an assignment is been on the dashboard for more than 5 days.',
  conditions: [
    { property: 'Status ID', operation: 'Equal', dataType: 'Number', value: '3' },
    { property: 'Status change Date', operation: 'Equal', dataType: 'Date', value: '' },
  ],
  hasChanged: false,
  isNew: true,
}

const defaultConditon: IAlertCondition = {
  property: '',
  operation: '',
  dataType: '',
  value: ''
}


const TriggerForm = (): JSX.Element => {

  const prefix = "alerts"

  const alertsManagementForm = useFormik({
    initialValues: {
      alerts: [
        {
          object: 'DASHBOARD',
          title: '5 DAYS LATE ASSIGNMENTS',
          isActive: true,
          description: 'This trigger sends an alert any time an assignment is been on the dashboard for more than 5 days.',
          conditions: [
            { property: 'Status ID', operation: 'Equal', dataType: 'Number', value: '3' },
            { property: 'Status change Date', operation: 'Equal', dataType: 'Date', value: '' },
          ],
          hasChanged: false,
          isNew: false,
        },
        {
          object: 'DASHBOARD',
          title: '5 DAYS LATE ASSIGNMENTS',
          isActive: true,
          description: 'This trigger sends an alert any time an assignment is been on the dashboard for more than 5 days.',
          conditions: [
            { property: 'Status ID', operation: 'Equal', dataType: 'Number', value: '3' },
            { property: 'Status change Date', operation: 'Equal', dataType: 'Date', value: '' },
          ],
          hasChanged: false,
          isNew: false,
        },
        {
          object: 'DASHBOARD',
          title: '5 DAYS LATE ASSIGNMENTS',
          isActive: true,
          description: 'This trigger sends an alert any time an assignment is been on the dashboard for more than 5 days.',
          conditions: [
            { property: 'Status ID', operation: 'Equal', dataType: 'Number', value: '3' },
            { property: 'Status change Date', operation: 'Equal', dataType: 'Date', value: '' },
          ],
          hasChanged: false,
          isNew: false,
        },
      ]
    },
    onSubmit: (values) => {
      console.log(values);
    },
  })

  const { values, handleChange } = alertsManagementForm;

  const processTriggerGroup = (trigger, index): JSX.Element => (<AManagedAlertGroup key={index} index={index} {...trigger} onChange={handleChange} prefix={prefix} />)
  return (
    <>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan="12">
              <section style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '1rem 0',
              }}>
                <PillButton onClick={alertsManagementForm.handleSubmit}>+ Create New Trigger</PillButton>
              </section>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableHeadColumn colSpan="2">Status</TableHeadColumn>
            <TableHeadColumn colSpan="8">Description</TableHeadColumn>
            <TableHeadColumn colSpan="2" className="action-section">Actions</TableHeadColumn>
          </TableRow>
        </TableHead>
        {alertsManagementForm?.values?.alerts.map(processTriggerGroup)}
      </Table>
    </>
  );
};




const AManagedAlertGroup = (props: IAManagedAlert): JSX.Element => {
  const { isActive, description, index, onChange, prefix } = props;
  return (
    <TableBody key={index}>
      <TableRow>
        <TableCell colSpan="2">
          <Toggle onChange={onChange} isActive={isActive} name={`${prefix}[${index}].isActive`} />
        </TableCell>
        <TableCell colSpan="8">
          {description}
        </TableCell>
        <TableCell className="action-section" colSpan="2">
          <section>
            <button><Edit /></button>
            <button><Delete /></button>
            <button><ArrowDownward /></button>
          </section>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan="12">
          <AManagedAlert key={index} {...props} />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}



interface IToggleActive {
  active: boolean;
  handleToggle: () => void;
}

export const ToggleActive = (props: IToggleActive): JSX.Element => {
  const { active, handleToggle } = props;
  const _handleToggle = (e): void => {
    e?.preventDefault();
    handleToggle();
  }
  return (
    <ToggleButton active={active} onClick={_handleToggle}>
      {active ? 'ACTIVE' : 'INACTIVE'}
    </ToggleButton>)
};






export const AManagedAlert = (props: IAManagedAlert): JSX.Element => {
  const { object, title, description, conditions, index, prefix, setFieldValue } = props;

  const addAndCondition = (e): void => {

  }

  const addORCondition = (e): void => {

  }

  const addAndEntity = (e): void => {

  }
  const addOREntity = (e): void => {

  }


  return (
    <>
      <Form>
        <section className="subject-section">
          <AutoCompleteInput label={"Select Object"} value={title} name={`${prefix}[${index}].object`} />
          <div> - </div>
          <AutoCompleteInput label={"Select Title"} value={description} name={`${prefix}[${index}].title`} />
        </section>
        <AutoCompleteInput label={"Object Description"} name={`${prefix}[${index}].descriptions`} value={description} />
        <section>
          {conditions.map((condition, conditionIndex) => (
            <ConditionContainer key={conditionIndex}>
              <ConditionRow>
                <span className="indicator-container"> <span className="indicator active"> </span><span>{conditionIndex + 1}</span> <span>Condition</span></span>
                <AutoCompleteInput label={'Property'} value={condition?.property} name={`${prefix}[${index}].conditions[${conditionIndex}].property`} />
                <AutoCompleteInput label={'Operation'} value={condition?.operation} name={`${prefix}[${index}].conditions[${conditionIndex}].operation`} />
                <AutoCompleteInput label={'Data Type'} value={condition?.dataType} name={`${prefix}[${index}].conditions[${conditionIndex}].dataType`} />
                <AutoCompleteInput label={'Value(s)'} value={condition?.value} name={`${prefix}[${index}].conditions[${conditionIndex}].value`} />
              </ConditionRow>
            </ConditionContainer>
          ))}
          <section className="condition-action">
            <div className="cta">
            <AccountTreeIcon className="icon" /> 
              <PillButton onClick={addAndCondition} title="And Conditon"><b>AND</b><AddIcon className="icon"/> Condition</PillButton>
            </div>
            <div className="cta">
              <AccountTreeIcon className="icon" />
              <PillButton onClick={addORCondition}><AddIcon /><b>OR</b>Conditon</PillButton>
            </div>
            <div className="cta">
              <ClassIcon className="icon" />
              <PillButton onClick={addAndEntity}><AddIcon /> <b>OR</b> <span>Entity</span></PillButton>
            </div>
            <div className="cta">
              <ClassIcon className="icon" />
              <PillButton onClick={addOREntity}><AddIcon /><b>AND</b> <span>Entity</span></PillButton>
            </div>
          </section>
        </section>
      </Form>
    </>
  )

};

export default TriggerForm