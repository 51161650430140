import * as React from "react";
import * as accounting from 'accounting-js';
import { CreateAssignmentInput } from "../styles";

const ToLocaleStringInput = ({ value, onChange, setFieldValue, name, ...props }) => {

  const textValue = React.useRef()
 

  React.useEffect(() => {
    textValue.current = accounting.format(value)
  }, [])
  return (
    <>
      <CreateAssignmentInput
        {...props}
        value={value}
        name={name}
        onChange={onChange}
      />
    </>
  );
};



export default ToLocaleStringInput;
