import styled from "styled-components";

export const TableContainer = styled.section`
  background-color: #ffffff;
  overflow: hidden;
  height: 650px;
  /* overflow-y: scroll; */
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  .table-container {
    overflow: auto;
  }
  .skeletondiv {
    border-radius: 20px;
  }
`;

export const Table = styled.table`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const TableHeading = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #cce5ff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const StyledFilteredResultsContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: #fafbfb;  
  border-bottom: 1px solid #c8cacc;
`;

export const StyledFilteredResultTable = styled.table`
  display: flex;
  justify-content: start;
  width: 100%;
  padding: 1rem;

  p {
    color: #4b4d4e;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

export const BackButton = styled.button<{ creatingAssignment?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: ${props => props.creatingAssignment ? "1px solid #3361FF" : "none"};
  background: ${props => props.creatingAssignment ? "white" : "none"};
  color: ${props => props.creatingAssignment ? "#3361FF" : "#000"};
  cursor: pointer;
`;

export const SubmitButton = styled(BackButton)`
  background-color: #3361ff;
  padding: 0.4rem 3rem;
  color: white;
  border-radius: 0.25rem;
`;

export const NavigationButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
  transform: translateY(-50%);
  padding-left: 1rem;
`;

export const SearchButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
`

export const PrimaryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 200px;
  height: 35px;
  flex-shrink: 0;
  background-color: #3361ff;
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  border-color: #3361ff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const StyledOptionBoxContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #3e3e3c;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 30px;
  margin-right: 20px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  z-index: 1;

  .filter-category {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .filter-contract-number {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .filter-document-type {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

export const StyledDocumentResultsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-wrap: wrap;
`

export const MenuPropsStyles = () => ({
  "& .MuiMenu-list": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiPaper-root": {
    backgroundColor: "white",
  },
  "& .MuiButtonBase-root": {
    padding: "20px",
    alignContent: "center",
  },
  "& .MuiMenuItem-root": {
    justifyContent: "center",
    borderBottom: "1px solid #C3CAD9",
    color: "#202223",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "16px",
    ":hover": {
      backgroundColor: "#F3F6FF",
      textDecoration: "underline",
      textDecorationColor: "#3361FF",
      color: "#3361FF",
      fontSize: "18px",
      fontStyle: "normal",
      lineHeight: "16px",
    },
  },
  "& .MuiListSubheader-root": {
    borderTop: "2px solid #97A2B7",
    borderBottom: "2px solid #97A2B7",
  },
});

export const SelectStyles = () => ({
  "& .MuiFilledInput-input:focus": {
    backgroundColor: "#F3F6FF",
  },
  "& .MuiFilledInput-input": {
    textAlign: "center",
    "& span": {
      display: "inline-block",
      textAlign: "center",
    },
  },
});