import * as React from "react";
import type { IAlerts } from "../Alerts";
import NoAlerts from "./components/NoAlerts";
import RenderedAlertsPage from "./components/RenderedAlerts";
import { DashboardContext } from "..";
import useAlerts, { IAlertsParams } from "../../../hooks/useAlerts";
import SearchIcon from "@mui/icons-material/Search";
import { PaginationContainer, ArrowsContainer, PageNumbers } from "../AssignmentTable/styles";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import ErrorInputModal from "../TodayPriorities/components/ErrorInputModal";
import asModal from "../../../hoc/asModal";
import {
  AlertsContainer,
  TableContainer,
  TableFilteringOptions,
  TableHeading,
} from "./styles";

interface IAlertsPageProps {
  displayAll: boolean;
  displayAssignment: (assignmentId: string) => void;
  isOpen: boolean;
  onClose: () => void;
  modalData: IAlerts;
  range: { startDate: string; endDate: string };
}

interface IAlertFilter {
  id: string;
  start_range: string;
  end_range: string;
}

export const AlertsPage = (props: IAlertsPageProps): JSX.Element => {
  const {
    alerts,
    alertParams,
    filterAlertsCount,
    loadingAlerts,
    alertsCount,
    setAlerts,
    setAlertParams,
    markAlertAsViewed,
    fetchAlerts,
    handleSearch,
  } = useAlerts({
    limit: 50,
    offset: 0,
    date_range: {
      start_date: props.range.startDate,
      end_date: props.range.endDate,
    },
    viewed: null,
  }, false);
  const intervalRef = React.useRef<any>();
  const { displayAssignment } = React.useContext(DashboardContext);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const limit = alertParams?.limit || 50;
  const offset = alertParams?.offset || 0;
  
  const loadPreviousPage = (): void => {
    let potentialPerivious = alertParams?.offset - limit;
    potentialPerivious = potentialPerivious <= 0 ? 0 : potentialPerivious;
    if (potentialPerivious === 0) {
      return;
    }
    const newParams = {
      ...alertParams,
      offset: potentialPerivious
    }
    setAlertParams(newParams);
  }

  const loadNextPage = (): void => {
    let potentialNext = alertParams?.offset + limit;
    const lastPage = Math.floor(alertsCount / limit);
    potentialNext = potentialNext >= alertsCount ? lastPage : potentialNext;
    if (potentialNext === lastPage) {
      return;
    }
    const newParams = {
      ...alertParams,
      offset: potentialNext
    }
    setAlertParams(newParams);
  }

  const debounce = (func, delay) => {
    let inDebounce;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedSearch = React.useCallback(debounce(() => {
    fetchAlerts();
  }, 1500), []);
  
  const localHandleSearch = (keyword: string): void => {
    keyword = keyword.trim();
    setAlertParams((prev) => ({ ...prev, keyword, offset: 0 }))
  }

  const handleSearchEvent = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    localHandleSearch(e.target.value);
  }

  React.useEffect(() => {
    fetchAlerts();
    return () => clearInterval(intervalRef.current)
  }, [alertParams?.search_term]);
  React.useEffect(() => {
    debouncedSearch();
    return () => clearInterval(intervalRef.current)
  }, [alertParams?.search_term]);


  let endingRange = offset + limit;
  if (endingRange > alertsCount) {
    endingRange = alertsCount;
  }
  const displayRange = `${(offset * limit)+1}-${endingRange} of ${alertsCount}`;

  const ErrorFilteringInput = asModal(ErrorInputModal, "#alerts-page");

  return (
    <>
      <TableContainer data-testid='alerts-page-container'>
        <AlertsContainer>
          <h2 className="alerts-menu">
            Alerts
          </h2>
          <AlertsPageHeader
            alertsCount={alertsCount}
            displayRange={displayRange}
            handleSearchEvent={handleSearchEvent}
            setAlertParams={setAlertParams}
            loadNextPage={loadNextPage}
            loadPreviousPage={loadPreviousPage}
            alerts={alerts}
          />
        </AlertsContainer>
         {!alerts?.length && !loadingAlerts ? <NoAlerts />  :
        <RenderedAlertsPage
          {...props}
          loadingAlerts={loadingAlerts}
          alerts={alerts}
          limit={limit}
          displayAssignment={displayAssignment}
          alertsCount={alertsCount}
          searchTerm={alertParams?.search_term}
          filterAlertsCount={filterAlertsCount}
          setAlerts={setAlerts}
          markAlertAsViewed={markAlertAsViewed}
          handleSearch={handleSearch}
        />}
      </TableContainer>
      <ErrorFilteringInput
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        title="No Alert Found Under This Contract No."
        message="Please Enter A Valid Contract No."
      />
    </>
  );
};

interface IAlertsPageHeaderProps {
  alertsCount: number;
  displayRange: string;
  alertParams: IAlertsParams;
  handleSearchEvent: (e) => void;
  loadNextPage: () => void;
  loadPreviousPage: () => void;
  alerts: IAlerts[];
}

const AlertsPageHeader = (props: IAlertsPageHeaderProps): JSX.Element => {
  const { alerts, alertsCount, displayRange, handleSearchEvent, alertParams, loadNextPage, loadPreviousPage, } = props;
  const alertsFound =  !!alerts.length;
  const displayedAlertsCount = alertsFound ? alertsCount : 0;

  return <TableHeading>
    <h3 data-testid="todays-alerts">Alerts - {displayedAlertsCount}</h3>
    <TableFilteringOptions data-testid="filter-alerts">
      <SearchIcon />
      <input type="text" placeholder="Search by Contract No." onChange={handleSearchEvent} value={alertParams?.search_term} />
    </TableFilteringOptions>
    <PaginationContainer>
    {alertsFound && (<>
    <PageNumbers>{displayRange}</PageNumbers>
      <ArrowsContainer>
        <KeyboardArrowLeftOutlinedIcon onClick={loadPreviousPage} />
        <KeyboardArrowRightOutlinedIcon onClick={loadNextPage} />
      </ArrowsContainer>
    </>
      )}
    </PaginationContainer>
  </TableHeading>


}

const today = new Date();
const lastYear = new Date(today);
lastYear.setFullYear(today.getFullYear() - 1);


AlertsPage.defaultProps = {
  displayAll: false,
  displayAssignment: () => { },
  isOpen: false,
  onClose: () => { },
  modalData: {},
  range: { startDate: lastYear, endDate: today },
}
export default AlertsPage;