import * as React from "react";
import {
  CloseIconDiv,
  EmailTextWrapper,
  GoogleButton,
  GoogleButtonContainer,
  GoogleButtonWrapper,
} from "../styles";
import CloseIcon from "@mui/icons-material/Close";

interface IGoogleSignInProps {
  onClose: () => void;
}


const GoogleSignIn = (props:IGoogleSignInProps) => {
  const modalRef = React.useRef<HTMLDivElement>(null);
  const sendGoogleAuthenticationRequest = () => {
    const url = new URL(process.env.REACT_APP_GOOGLE_AUTHENTICATION_URL);
    url.searchParams.append(
      "response_type",
      process.env.REACT_APP_GOOGLE_RESPONSE_TYPE,
    );
    url.searchParams.append("access_type", "offline");
    url.searchParams.append(
      "client_id",
      process.env.REACT_APP_GOOGLE_CLIENT_ID,
    );
    url.searchParams.append("scope", process.env.REACT_APP_GOOGLE_SCOPE);
    url.searchParams.append(
      "redirect_uri",
      process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    );
    const redirectUrl = url.toString();
    window.location.assign(redirectUrl);
  };
  
  return (
    <GoogleButtonContainer ref={modalRef}>
    <CloseIconDiv>
            <CloseIcon onClick={props.onClose} className="modal-close" />
          </CloseIconDiv>
      <EmailTextWrapper>to continue to "Emails"</EmailTextWrapper>
      <GoogleButtonWrapper>
        <GoogleButton onClick={sendGoogleAuthenticationRequest}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="28"
            viewBox="0 0 27 28"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.9044 13.8063C26.9044 12.849 26.817 11.9286 26.6548 11.0449H13.7266V16.267H21.1141C20.7959 17.9545 19.8288 19.3842 18.375 20.3415V23.7288H22.8113C25.4069 21.3786 26.9044 17.9176 26.9044 13.8063Z"
              fill="#4285F4"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7258 27.0014C17.4321 27.0014 20.5394 25.7925 22.8105 23.7307L18.3743 20.3435C17.1451 21.1535 15.5727 21.6321 13.7258 21.6321C10.1506 21.6321 7.12445 19.2573 6.04501 16.0664H1.45898V19.5641C3.71768 23.9762 8.35987 27.0014 13.7258 27.0014Z"
              fill="#34A853"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.04607 16.0652C5.77153 15.2552 5.61555 14.39 5.61555 13.5002C5.61555 12.6105 5.77153 11.7452 6.04607 10.9352V7.4375H1.46004C0.530357 9.26 0 11.3218 0 13.5002C0 15.6786 0.530357 17.7405 1.46004 19.563L6.04607 16.0652Z"
              fill="#FBBC05"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.7258 5.36932C15.7412 5.36932 17.5506 6.05045 18.9732 7.38818L22.9104 3.51614C20.5331 1.33773 17.4259 0 13.7258 0C8.35987 0 3.71768 3.02523 1.45898 7.43727L6.04501 10.935C7.12445 7.74409 10.1506 5.36932 13.7258 5.36932Z"
              fill="#EA4335"
            />
          </svg>
          <p className="google-button-text">Sign in with Google</p>
        </GoogleButton>
      </GoogleButtonWrapper>
    </GoogleButtonContainer>
  );
};
export default GoogleSignIn;
