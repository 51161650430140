import currencyRequest from "."
import { TCurrency } from "./types/CurrencyTypes"


export const fetchCurrencyConversion = async (currencyIn:TCurrency, currencyOut: TCurrency, amount:number = null) => {
    return currencyRequest.post('/', {
        'currency_in':currencyIn,
        'currency_out': currencyOut,
        amount
    })
}
export const postManualCurrencyConversion = async (currencyIn:TCurrency, currencyOut: TCurrency, rate:number) => {
    return currencyRequest.post('/create', {
        'currency_in':currencyIn,
        'currency_out': currencyOut,
        rate
    })
}