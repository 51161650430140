import * as React from "react";
import styled from "styled-components";

export const StatusBadge = styled.div<{ status: string }>`
  border-radius: 0.25rem;
  border: 1px solid ${(props) =>
    props.status === "internal"? "#a2d1ff" : "#F27F77"};
  background-color: ${(props) =>
    props.status === "internal"? "#cce5ff" : "#F8E2E1"};
  padding: 0.25rem 1rem;
  color: #44474b;
  text-align: center;
  display: inline-block;
  font-size: 10px;
`;

interface IStatusColumnProps {
  status: string;
}

const StatusColumn = (props: IStatusColumnProps) => {
  return <StatusBadge status={props.status}>{props.status.toUpperCase()}</StatusBadge>;
};

export default StatusColumn;
