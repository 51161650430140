import * as React from 'react'
import { CreateAssignmentInput } from '../../../styles';
import PerformantAccordion from '../../../../../../../components/PerformantAccordion';
import type { InsuredSubject } from '../../../../../../../api/v1/assignments/classes/insuredSubjects';
import FormGroup from '../../../../../../../components/FormElements/FormGroup';
import { FormGroupContainer } from '../../../../ReinsuranceTerms/styles';
import type { FormikValues } from 'formik';
import ToLocaleStringInput from '../../ToLocaleStringInput';

interface IInsuredSubjectProps {
    values: any,
    handleChange: (e: React.ChangeEvent<any>) => void,
    search: (text: string, setOptions: React.Dispatch<React.SetStateAction<any[]>>) => void,
    options: any[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    key: string,
    index: number,
    _handleChange: (e: React.ChangeEvent<any>) => void,
    handleSelection: (option: any) => void,
    errors: any,
    formik: FormikValues
}

const InsuranceTerms = (props: InsuredSubject & IInsuredSubjectProps): JSX.Element => {
    const { insuredSubject, values, index, handleChange, setFieldValue } = props;
    const insuranceTerms = insuredSubject?.insuranceTerms
    const prefix = `insuredSubjects[${index}].insuranceTerms`
    const errors = props?.errors?.insuranceTerms || {}
    console.log("values", values)
    return (
        <PerformantAccordion className='child-accordion' title={'Insurance Terms'} openingKey={prefix}>
            <section>
                <ToLocaleStringInput
                    label='Sum Insured'
                    value={insuranceTerms?.sumInsured || ""}
                    name={`${prefix}.sumInsured`}
                    testId={`${prefix}.sumInsured`}
                    onChange={handleChange}
                    type="number"
                    setFieldValue={setFieldValue}
                    errors={errors?.sumInsured}
                />
                <CreateAssignmentInput
                    label='PML'
                    value={!insuranceTerms?.pml ? "" : insuranceTerms?.pml}
                    name={`${prefix}.pml`}
                    onChange={handleChange}
                    testId={`${prefix}.pml`}
                    errors={errors?.pml}
                />
                <CreateAssignmentInput
                    label='Original Rate'
                    value={!insuranceTerms?.tarif ? "" : insuranceTerms?.tarif}
                    name={`${prefix}.tarif`}
                    onChange={handleChange}
                    testId={`${prefix}.tarif`}
                    errors={errors?.tarif}
                />
            </section>
        </PerformantAccordion>

    )
}


export default InsuranceTerms