import * as React from 'react';
import {
  LoadingFilePreview,
  PreviewCover,
} from '../styles';
import { fetchDocumentBlobs } from '../../../../../api/v1/documents/get';
import { fetchDocPreview } from '../../../../../api/v1/documents/post';
import styled from 'styled-components';

interface IFilePreviewObject {
  objectURL: string;
  setObjectURL: (url: string) => void
  initiateDownload: (e: React.MouseEvent, url: string) => void;
  updateIframeContent: () => void;
  onContextMenu: (e: React.MouseEvent) => void;
  openFile: (e: React.MouseEvent) => void;
  url: string;
  fileType: string;
  fileName: string;
  fileObject: File;
}

const StyledObject = styled.iframe`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    border:none;
    pointer-events: none;
  `;

const ImageFrame = styled.div<{
  hide: boolean;
}>`
  pointer-events: none;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  border: none;
  ${({ hide }) => {
    return `
      ${StyledObject} {
        display: ${hide ? 'none' : 'block'};
      }
    `
  }}
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const FileObjectContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  pointer-events: none;
`;
const FilePreviewObject = (props: IFilePreviewObject): JSX.Element => {
  const { fileObject, openFile, url, fileName, setObjectURL, objectURL, onContextMenu } = props
  const [checkedClientRender, setCheckedClientRender] = React.useState(false);
  const [checkedServerRender, setCheckedServerRender] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const previewObject = React.useRef<HTMLIFrameElement>(null);


  const dodge = (e: React.MouseEvent): void => {
    alert('Right click disabled')
    onContextMenu(e)
  }

  const updateIframeContent = (): void => {
    const body = previewObject?.current?.contentDocument?.querySelector('body')
    if (!body) return;
    const iframe = previewObject?.current;
    const contentDocument = iframe.contentDocument || iframe.contentWindow.document;
    iframe.contentWindow.document.oncontextmenu = onContextMenu;
    iframe.contentWindow.document.onselectstart = onContextMenu;
    iframe.contentWindow.document.onmouseenter = onContextMenu;
    iframe.contentWindow.document.onmouseleave = onContextMenu;
    iframe.contentWindow.document.onmouseover = onContextMenu;
    const style = document.createElement('style');
    style.innerHTML = `
      body::after,embed::after,object::after,video::after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0); /* Transparent background */
        z-index: 1000; /* Ensure it is on top */
      }
      embed,image,object,video {
        pointer-events: none;
      }
    `;
    contentDocument.head.appendChild(style);
    body.style.position = 'relative';
    const embed = body.querySelector('embed')

    const eventsToOverride = {
      lostpointercapture: null,
      mousedown: null,
      mouseenter: null,
      mouseleave: null,
      mousemove: null,
      mouseout: null,
      mouseover: null,
      mouseup: null,
      mousewheel: null,
      paste: null,
      pause: null,
      play: null,
      playing: null,
      pointercancel: null,
      pointerdown: null,
      pointerenter: null,
      pointerleave: null,
      pointermove: null,
      pointerout: null,
      pointerover: null,
      pointerup: null,
    }
    Object.keys(eventsToOverride).forEach(event => {
      embed?.setAttribute(event, dodge)
    })
    try {
      const firstElement = body.children
      if (firstElement[0]?.tagName && firstElement[0]?.clientWidth) {
        setLoading(false)
        setCheckedServerRender(true)
      } else {
        throw new Error('No content found')
      }
    } catch (e) {
      console.error("Error accessing iframe contents:", e);
    }
  };

  const generateBlobData = (filePath: string): void => {
    fetchDocumentBlobs(filePath).then(res => {
      setObjectURL(URL.createObjectURL(res.data))
    }).catch(err => { console.error(err) })
  }

  const parseViaClientPlugin = (): boolean => {
    try {
      const splitFileName = fileName?.split('.');
      const extension = splitFileName?.length > 1 ? splitFileName[splitFileName.length - 1] : '';
      if (extension !== 'msg' && fileObject) {
        const fileURL = URL.createObjectURL(fileObject)
        setObjectURL(fileURL)
      }
      setCheckedClientRender(true)
    } catch (err) {
      return false
    }
  }

  const generateFilePreview = async (): Promise<bool> => {
    return await fetchDocPreview(fileObject).then(res => {
      const url = `${res.url}/${res.file_name}`
      generateBlobData(url)
    }).catch(err => {
      console.error(err)
      return false;
    })
  }

  const getDocumentBlob = (): void => {
    fetchDocumentBlobs(`${url}`).then(fileData => {
      if (fileData.data) {
        setObjectURL(URL.createObjectURL(fileData.data))
        setLoading(false)
      } else {
        throw new Error('File not found')
      }
    }).catch(err => {
      console.log(`Attempting to Generate File Preview ${err}`)
    })
  }

  const parseViaServerPlugin = (): void => {
    if (!url && fileObject) {
      generateFilePreview().then(() => { setLoading(false) }).catch(err => {
        console.error(err)
      })
    } else {
      getDocumentBlob()
    }
  }


  React.useEffect(() => {
    if (!previewObject?.current?.currentDocument?.children?.length && checkedClientRender && !checkedServerRender) {
      setLoading(true)
      parseViaServerPlugin()
    } else if (!checkedClientRender) {
      setLoading(true)
      parseViaClientPlugin()
    }
  }, [previewObject?.current, checkedClientRender, checkedServerRender])


  return (
    <FileObjectContainer data-testid="fileobj">
      <ImageFrame hide={loading}>
        <StyledObject
          onContextMenu={dodge}
          ref={previewObject}
          data-testid="doc-image"
          className={'image'}
          onDoubleClick={openFile}
          src={`${objectURL}#toolbar=0&scrollbar=0&navpanes=0&pagemode=thumbs&zoom=0.50`}
          onLoad={updateIframeContent}
          scale="tofit"
          referrerpolicy="origin"
          
        />
      </ImageFrame>
      <LoadingFilePreview loading={loading}>Loading...</LoadingFilePreview>
      <PreviewCover hide={!loading} />
    </FileObjectContainer>

  )
}
export default FilePreviewObject;