import * as React from "react";
import {
    TableContainer,
    TableHeading,
    TableFilteringOptions,
    Table,
    NextStepsItem,
  } from "../styles";
  import SearchIcon from "@mui/icons-material/Search";
import { Skeleton } from "@mui/material";
import { pink } from "@mui/material/colors";

const HistorySkeleton = () => {
    return (
        <TableContainer>
      <TableHeading>
        <h3>Task History</h3>
        <TableFilteringOptions>
          <SearchIcon />
          <input type="text" placeholder="Search by Contract No." />
        </TableFilteringOptions>
      </TableHeading>
        <Table>
            {Array.from({length:5}).map((_, index) => (
              <NextStepsItem key={index}>          
                <Skeleton className="history-skeleton" variant="rectangular" width="100%" height={60} animation="wave" />                
              </NextStepsItem>
        ))}
            </Table>
      </TableContainer>

    )
}

export default HistorySkeleton;