import * as React from "react";
import { Link } from "react-router-dom";
import {
  LoginPageWrapper,
  LoginSection,
  StyledButton,
  GoogleButton,
  ErrorOnLogin,
} from "./styles";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import PersonIcon from "@mui/icons-material/Person";
import { FormControlLabel } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import { LogoContainer } from "./styles";
import { UserContext } from "../../context/UserContext";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Login = () => {
  const {defaultLoginFlow, sendGoogleAuthenticationRequest} = React.useContext(UserContext)
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [error,setError] = React.useState<string>('')

  const handleLogin = async () => {
    try {
      const response = await defaultLoginFlow(username, password)
    } catch (error) {
      setError(error?.response?.data?.detail)
    }
  };
  

  
  return (
    <LoginPageWrapper>
      <LogoContainer>
        <img src={`${process.env.ORG_ASSETS}/logo/logo`} alt="Mandarin RE logo" />
      </LogoContainer>
      <LoginSection>
        <div className="top-form-container">
          <Avatar
            sx={{
              width: "100px",
              height: "100px",
              flexShrink: 0,
              backgroundColor: "#008170",
            }}
          >
            <PersonIcon style={{ width: "71px", height: "71px" }} />
          </Avatar>
          <h2 className="header-style">Sign In</h2>
        </div>

        <div className="middle-form-container">
          <FormControl variant="filled">
            <InputLabel htmlFor="component-filled"   sx={{ color: error !== "" ? "#CC0707" : "initial" }}>
              Username or Email*
            </InputLabel>
            <FilledInput
            required
              placeholder="Enter your Username or Email"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              name="username"
              value={username}
              sx={{
                width: "22rem",
                backgroundColor: "#FAFBFB",
                "& .MuiFilledInput-input:focus": {
                  backgroundColor:"#F3F6FF",
                },
                borderBottom: error !== "" ? "1px solid red" : "none",
              }}
              autoFocus={error === "true"}
              inputProps={{ "data-testid": "username-input" }}
            />
          </FormControl>

          <FormControl variant="filled">
            <InputLabel htmlFor="component-filled"  sx={{ color: error !== "" ? "#CC0707" : "initial" }}>Password*</InputLabel>
            <FilledInput
            required
              placeholder="Enter your Password"
              name="password"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              value={password}
              sx={{
                width: "22rem",
                backgroundColor: "#FAFBFB",
                "& .MuiFilledInput-input:focus": {
                  backgroundColor: "#F3F6FF",
                },
                borderBottom: error !== "" ? "1px solid red" : "none",

              }}
              inputProps={{ "data-testid": "password-input" }}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <RemoveRedEyeIcon />
            </button>
          </FormControl>

          {/* <div className="remember-me-checkbox">
            <FormControlLabel
              label="Remember me"
              control={<Checkbox size="small" />}
              sx={{ justifyContent: "start" }}
            />
          </div> */}
          {error &&
             <ErrorOnLogin>
              <ErrorIcon/>
              {error}
              </ErrorOnLogin> 
          }
          <GoogleButton
            onClick={sendGoogleAuthenticationRequest}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="28"
              viewBox="0 0 27 28"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.9044 13.8063C26.9044 12.849 26.817 11.9286 26.6548 11.0449H13.7266V16.267H21.1141C20.7959 17.9545 19.8288 19.3842 18.375 20.3415V23.7288H22.8113C25.4069 21.3786 26.9044 17.9176 26.9044 13.8063Z"
                fill="#4285F4"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7258 27.0014C17.4321 27.0014 20.5394 25.7925 22.8105 23.7307L18.3743 20.3435C17.1451 21.1535 15.5727 21.6321 13.7258 21.6321C10.1506 21.6321 7.12445 19.2573 6.04501 16.0664H1.45898V19.5641C3.71768 23.9762 8.35987 27.0014 13.7258 27.0014Z"
                fill="#34A853"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.04607 16.0652C5.77153 15.2552 5.61555 14.39 5.61555 13.5002C5.61555 12.6105 5.77153 11.7452 6.04607 10.9352V7.4375H1.46004C0.530357 9.26 0 11.3218 0 13.5002C0 15.6786 0.530357 17.7405 1.46004 19.563L6.04607 16.0652Z"
                fill="#FBBC05"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7258 5.36932C15.7412 5.36932 17.5506 6.05045 18.9732 7.38818L22.9104 3.51614C20.5331 1.33773 17.4259 0 13.7258 0C8.35987 0 3.71768 3.02523 1.45898 7.43727L6.04501 10.935C7.12445 7.74409 10.1506 5.36932 13.7258 5.36932Z"
                fill="#EA4335"
              />
            </svg>
            <p className="google-button-text">Sign in with Google</p>
          </GoogleButton>

          <div className="forgot-password-signin">
            <Link to={"/"} className="forgot-password-text">
              {/* Forgot Password ? */}
            </Link>
            <StyledButton data-testid="submit-button" className="signin-button" onClick={handleLogin} disabled={username === "" || password === ""}>
              Sign In
            </StyledButton>
          </div>
        </div>
      </LoginSection>
    </LoginPageWrapper>
  );
};

export default Login;
