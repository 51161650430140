import {api} from "../index";
import { Task } from "../types";

export const getTasks = async (assignmentId: string): Promise<Task[]> => {
  if (!assignmentId || isNaN(Number(assignmentId))) {
    return [];
  }
  try {
    const response = await api.get("/tasks/assignments", {
      params: {
        assignment_id: parseInt(assignmentId, 10)
      }
    });
    return response.data.tasks;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};
