import * as React from 'react';
import { IDocumentType } from "../../../../api/v1/documents/types/DocumentTypes";
import { StyledDocumentResultsContainer } from '../styles';
import FilePreview  from "../../AssignmentDrillDown/Documents/components/FilePreviewWrapper";
import { useFormik } from 'formik';

interface IRenderedDocumentsResultsProps {
    documents: IDocumentType[];
}
const RenderedDocumentsResults = (props:IRenderedDocumentsResultsProps) => {
    const {documents} = props
    const formik = useFormik({
        initialValues: {
            filesMetaData: [],
        },
        onSubmit: (values) => {
            console.log(values)
        }
    });
    const { setFieldValue, handleChange } = formik;
    return (
        <>
             {props.documents.map((document,index) => {
                const fileName = document.file_name;
                return (
                    <FilePreview 
                        key={index}
                        index={index}
                        formik={formik}
                        fileName={fileName}
                        fieldName={"filesMetaData"}
                        currentFiles={document?.file_name}
                        fileType={document?.type}
                        url={`${document?.url}/${document?.file_name}`}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                    />
                )
                return <FilePreview key={document.id} fileName={document.file_name} />;
             })}
        </>
    )
};

export default RenderedDocumentsResults;