import * as React from "react";
import {
    NoBroadcastMessagesContainer, TableContainer,
  } from "../styles";

const NoBroadcastMessages = () => {
    return (
        <TableContainer>
       <NoBroadcastMessagesContainer>
        No Broadcast Messages
        </NoBroadcastMessagesContainer>
        </TableContainer>

    )

}
export default NoBroadcastMessages;