import * as React from "react";
import {
  TableContainer,
  TableHeading,
} from "../../AssignmentTable/styles";
import { NoEmailsDiv, Table} from "../styles";

const NoEmails = () => {
  return (
    <TableContainer data-testid="table-container">
      <TableHeading>
        <h3>Inbox</h3>
      </TableHeading>
      <section className="table-container">
        <Table>
          <NoEmailsDiv>No Emails</NoEmailsDiv>
        </Table>
      </section>
    </TableContainer>
  );
};

export default NoEmails;

