import * as React from 'react';
import styled from 'styled-components';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { NextStepsItem } from '../../GeneralInformation/styles';
import { DashboardAlertBody } from '../../../styles/DashboardContainer';
import { Task } from '../../../../../api/v1/assignments/types/assignments';
import { markAsCompleted } from '../../../../../api/v1/tasks/complete';
import { deleteTask } from '../../../../../api/v1/tasks/delete';
import { IAssignmentComment } from '../../../../../api/v1/types';
import { getCount } from '../../../../../api/v1/comments/getCount';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import asModal from '../../../../../hoc/asModal';
import Comments from '../../../Comments';
import { EQuickView } from '../../..';
import { getPriorities } from '../../../../../api/v1/priorities/get';
import { DashboardContext } from '../../..';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { formattedDate } from '../../../../../api/v1/assignments/classes/assignment';

const ContentWrapper = styled.div`
  width: 100%;
  max-height: 20rem;
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem
  .date-time {
    display: flex;
    align-items: center;
  }
`;

const DateWrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  position: relative;
  width: 20%;
  display: flex;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  padding: 15px;
  height: 2rem;
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ReminderContainer = styled.div<{ hasReminder: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({ hasReminder }) => hasReminder ? "#3361ff" : ""};
`;

const ReminderDate = styled.div`
  font-size: 0.8rem;
  font-weight: 200;
  padding: 0.5rem;
  color: #3361ff;
  border-radius: 10px;
  border: 1px solid #3361ff;
  position: absolute;
  background-color: white;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
`;


interface ActionableTaskProps {
  task: Task;
  clickOnMessage: (id: number) => void;
  setTasks: (tasks: Task[]) => void;
  contract: string;
  selectedQuickView?: string;
}

const ActionConfirmationModal = asModal(ConfirmationModal, "#modal-root");
const StyledActionConfirmationModal = styled(ActionConfirmationModal)`
  > * {
  font-size: 1rem;
  z-index: 999;
  font-family: "Lato", sans-serif;
  h2 {
    margin-top: 0;
    }
    }
`;

const formatDateAndTime = (dateTimeString: string, createdBy: string) => {
  const date = new Date(dateTimeString);
  const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  const formattedTime = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
  return `Created on ${formattedDate} @ ${formattedTime} ${createdBy ? `by ${createdBy}` : ""}`;
};

const formatReminderDate = (dateTimeString: string) => {
  const date = new Date(dateTimeString);
  const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  const formattedTime = `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
  return `${formattedDate} ${formattedTime}`;
}

const ActionableTask = (props: ActionableTaskProps) => {
  const [comments, setComments] = React.useState<IAssignmentComment[]>([]);
  const [unseenComments, setUnseenComments] = React.useState<number>(0);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isCommentsOpen, setIsCommentsOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [action, setAction] = React.useState<() => void>(() => { });
  const [displayReminder, setDisplayReminder] = React.useState<boolean>(false);
  const { setPriorities } = React.useContext(DashboardContext);

  const formattedDateTime = props.task.reminder

  React.useEffect(() => {
    if (!props.task.id) return;
    getCount(props?.task?.id)
      .then((data) => {
        if (data) {
          setUnseenComments(data.count)
        } else {
          setUnseenComments(0)
        }
      }).catch((error) => {
        console.log(error)
      }
      )
  }, [])

  const onDeleteTask = () => {
    deleteTask(props?.task?.id)
      .then((data) => {
        props.setTasks(data)
        setIsModalOpen(false)
        getPriorities()
          .then(priorities => {
            setPriorities(priorities || []);
          })
          .catch(priorityError => {
            console.error("Failed to fetch priorities:", priorityError);
          });
      }
      ).catch((error) => {
        console.log(error)
      })
  }

  const handleConfimation = (title: string, message: string, action: () => void) => {
    setIsModalOpen(true);
    setTitle(title);
    setMessage(message);
    setAction(() => action);
  }

  const onMarkAsCompleted = () => {
    markAsCompleted(props.task.id)
      .then((data) => {
        props.setTasks(data)
        setIsModalOpen(false)
        getPriorities()
          .then(priorities => {
            setPriorities(priorities || []);
          })
          .catch(priorityError => {
            console.error("Failed to fetch priorities:", priorityError);
          });
      }
      ).catch((error) => {
        console.log(error)
      })
  }

  const handleMarkCommentsAsRead = () => {
    props.clickOnMessage(props.task.id);
    setUnseenComments(0);
  }

  return (
    <>

      <NextStepsItem>
        <ContentWrapper>

          <div onClick={() => handleConfimation("Mark Task as Completed", "Are you sure you want to mark this task as completed?", onMarkAsCompleted)} style={{ cursor: "pointer" }}>

            <TaskAltOutlinedIcon data-testid="task-complete" color="success" />
          </div>
          <div style={{ height: "100%", padding: "10px" }}>
            <div data-testid="task-description" >{props.task.description}</div>
            <div data-testid="task-date" className='date-time'><CalendarMonthOutlinedIcon data-testid="calendar-icon" fontSize="small" />{props.task.created_at}</div>
          </div>
        </ContentWrapper>
        <DateWrapper data-testid="created-date">
          {formatDateAndTime(props.task.created_at, props.task.created_by)}
        </DateWrapper>
        <IconWrapper>

          <IconContainer className="reminder-icon">
            <ReminderContainer
              onMouseEnter={() => setDisplayReminder(true)}
              onMouseLeave={() => setDisplayReminder(false)}
              hasReminder={props.task.reminder}
            >
              <AccessAlarmIcon data-testid="reminder-icon" />
              {displayReminder && props.task.reminder && (
                <ReminderDate data-testid="reminder-date">
                  {formatReminderDate(props.task.reminder)}
                </ReminderDate>
              )}
            </ReminderContainer>
          </IconContainer>

          <IconContainer>
            <DashboardAlertBody>

              {unseenComments > 0 && <div data-testid="unseen-comment" className={"alert-count"}> {unseenComments} </div>}
              <SmsOutlinedIcon data-testid="chat-icon" onClick={handleMarkCommentsAsRead} />

            </DashboardAlertBody>

          </IconContainer>
          <IconContainer><CloseOutlinedIcon data-testid="delete-task" color="error" onClick={() => handleConfimation("Delete Task", "Are you sure you want to delete this task?", onDeleteTask)} /></IconContainer>
          <StyledActionConfirmationModal
            className={'confirmation-modal'}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={action}
            title={title}
            message={message}
            data-testid="confirmation-task"
          />
        </IconWrapper>
      </NextStepsItem >

    </>
  )
};

export default ActionableTask;
