import * as React from "react";

import TableHeaderColumn from "./TableHeaderColumn";
import { ColumnsNames, ESelectedColumn, TSorOrder } from "..";

import Popover from "../../../../components/PopOver";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import FilterDropDown from "./TableBody/TableBodyRow/components/FilterDropDown";
import { IAssignment, ILegacyAssignment } from "../../../../api/v1/types";

export interface ITableHeaderProps {
  sortOrder?: TSorOrder | string;
  sortProperty?: string;
  lastSortedColumn?: string;
  statusDropDownValues?: string[];
  sortAssignment?: (property: string, order: "asc" | "desc") => void;
  selectedColumn?: any
  setSortedColumn?: React.Dispatch<React.SetStateAction<any>>
}


interface IColumnProps {
  columnLabel: keyof typeof ColumnsNames;
  sortOrder?: TSorOrder | string;
  sortProperty?: string;
  sortAssignment?: (property: string, order: "asc" | "desc") => void;
}


const Arrows = (props) => {
  const { sortOrder } = props;
  const component = {
    asc: <ArrowDropUpIcon />,
    desc: <ArrowDropDownIcon />,
  }
  const arrow = component[sortOrder]
  return (
    <div>
      {arrow}
    </div>
  )
}
const TableHeader = (props: IColumnProps) => {
  const { columnLabel, sortAssignment, sortOrder, sortProperty } = props;
  const nextSort = sortOrder === "asc" && sortProperty === columnLabel ? "desc" : "asc";
  const isSorted = sortProperty === columnLabel;

  return (
    <TableHeaderColumn
      onClick={() => {
        sortAssignment(columnLabel, nextSort);
      }}
      data-testid={columnLabel}
    >
      {ColumnsNames[columnLabel]}
      {isSorted && <Arrows sortOrder={sortOrder} />}
    </TableHeaderColumn>

  )
}

const TableHeaders = (props: ITableHeaderProps) => {
  const contractTypeDropDownValue = ["Treaty Inward", "Fac Inward"];
  const { sortProperty, sortOrder, sortAssignment } = props;
  return (
    <thead>
      <tr data-testid="header-row">
        <TableHeader
          data-testid="status-header"
          columnLabel={ESelectedColumn.status}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="type-header"
          columnLabel={ESelectedColumn.type}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeaderColumn><span /></TableHeaderColumn>
        <TableHeader
          data-testid="contract-no-header"
          columnLabel={ESelectedColumn.contractNo}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="broker-header"
          columnLabel={ESelectedColumn.broker}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="insurer-header"
          columnLabel={ESelectedColumn.insurer}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="cedent-header"
          columnLabel={ESelectedColumn.cedent}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="contract-type-header"
          columnLabel={ESelectedColumn.contractType}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="date-header"
          columnLabel={ESelectedColumn.date}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="age-header"
          columnLabel={ESelectedColumn.age}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeader
          data-testid="underwriter-header"
          columnLabel={ESelectedColumn.underwriter}
          sortOrder={sortOrder}
          sortProperty={sortProperty}
          sortAssignment={sortAssignment}
        />
        <TableHeaderColumn><span /></TableHeaderColumn>
      </tr>
    </thead>
  );
};

export default TableHeaders;
