import {api} from "../index";
import { IEmail } from "../types";

export const getEmails = async (filterType: String, searchKeyword: String, token: String) => {
  const config = {
    params: {
      filter_type: filterType,
      search_keyword: searchKeyword,
      page_token: token
    }
  };

  try {
    const response = await api.get('/emails/', config);
    return response.data as IEmail[];
  } catch (error) {
    console.error('An error occurred while fetching data: ', error);
    return [];
  }
};


export const getEmailsCount = async (count) => {
  const config = {
    params: {
      count: count
    }
  };

  try {
    const response = await api.get('/emails/count', config);
    return response.data;
  } catch (error) {
    console.error('An error occurred while fetching data: ', error);
    return {};
  }
}

