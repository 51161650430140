import {api} from "../index";
import { Task } from "../types";

export const deleteTask = async (taskId: number): Promise<Task | null> => {
  try {
    const response = await api.delete("/tasks/assignments", {
      params: {
        task_id: taskId
      }
    });
    return response.data.tasks;
  } catch (error) {
    console.error("Error deleting task:", error);
  }
};
