import * as React from "react";
import type { ILegacyAssignment } from "../../../api/v1/types";
import CreateAssignmentHeader from "./CreateAssignmentHeader";
import styled, { keyframes } from "styled-components";
import { useFormik } from 'formik';
import AssignmentStructure, {
  validationSchema, assignmentValidationOrder
} from "../../../api/v1/assignments/classes/assignment";
import { BackButton, PrimaryButton } from "../Documents/styles";
import asModal from "../../../hoc/asModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { UserContext } from "../../../context/UserContext";
import { getUnderwriter } from "../../../api/v1/assignments/get";
import Steps, { EAssignmentComponent } from "../AssignmentDrillDown/Steps";
import useEntitySearch from "../../../hooks/useEntitySearch";
import searchWrapper from "../AssignmentDrillDown/Steps/utils/searchFunctions";
import ErrorWrapper from "../AssignmentDrillDown/FormErrors";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
export interface IComponentProps {
  assignmentToCreate: ILegacyAssignment;
  setAssignmentToCreate: React.Dispatch<
    React.SetStateAction<ILegacyAssignment>
  >;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  currentPage?: number;
  handleChange?: (e: React.ChangeEvent<any>) => void;
  errors?: any;
  setSelectedUnderwriter?: React.Dispatch<React.SetStateAction<string>>;
  setIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}
const ButtonContainer = styled.div`
    margin-top: 5rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5%;
    .nav-btn {
        width: 30%;
        padding:1%;
        cursor:pointer;
        &:hover{
            opacity:.8
        }
    }
    .next {
    background-color: #186ade;
    color:white;
    border:0;
    width:100%;
    }

.disabled {
  background-color:rgba(0, 0, 0, 0.3);
  color:white;
  border:0;
  width:100%;
  &:hover{
    opacity:1
  }
}
`;

const StyledAssignmentSection = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .header {
    font-size: 1.5rem;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

const StyledForm = styled.form`
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 1.5rem;
  p {
    font-size: 1.5rem;
  }
  animation: ${fadeIn} 0.3s ease;
  > * {
    box-sizing: border-box;
  }
  .MuiAccordion-region {
    box-sizing: border-box;
    width: 100%;
    padding : 0 1rem;
  }
  .MuiAccordionDetails-root{
    padding: 0;
  }
`;
const ConfirmationModalContainer = asModal(ConfirmationModal, "#modal-root");
const StyledCreateAssignmentModal = styled(ConfirmationModalContainer) <{ cancelButtonColor: string, cancelTextColor: string, submitBorderColor: string, submitButtonText: string, buttonBackgroundColor: string }>`
  > * {
    font-size: 1rem;
    z-index: 999;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
      }
    }
  cancelButtonColor: ${props => props.cancelButtonColor};
  cancelTextColor: ${props => props.cancelTextColor};
  submitBorderColor: ${props => props.submitBorderColor};
  submitButtonText: ${props => props.submitButtonText};
  buttonBackgroundColor: ${props => props.buttonBackgroundColor};
  `;




const NextCtr = styled.div`width:30%;`


const accordionTriggered = new Event("accordionTriggered", {
  bubbles: true,
  cancelable: true,

});



const stepsLookUp = {
  "Select Type Of Contract": EAssignmentComponent.ContractType,
  "General Information": EAssignmentComponent.GeneralInformation,
  "Insured Subjects": EAssignmentComponent.Risks,
  "Documents": EAssignmentComponent.AssignmentDocuments,
  "Assignee": EAssignmentComponent.SelectUnderwriter
}

const steps: string[] = Object.keys(stepsLookUp);

const CreateAssignment = (): JSX.Element => {
  const { userHierarchy } = React.useContext(UserContext)
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)
  const [currentPage, setCurrentPage] = React.useState<number>(0)
  const [selectedUnderwriter, setSelectedUnderwriter] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const generalRef = React.useRef<HTMLDivElement>(null);
  const { search, options, setOptions } = useEntitySearch();
  const [creatingAssignment, setCreatingAssignment] = React.useState<boolean>(true);
  const navigate = useNavigate();

  console.log("checking options create", options)
  const [warning, setWarning] = React.useState<boolean>(false);

  const handleBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
      if (currentPage === 1) {
        formik.setFieldValue("contractTypeId", null);
      }
    }
  };


  const handleNext = (userCanContinue: boolean = false) => {
    if (currentPage < steps.length - 1 && userCanContinue) {
      setCurrentPage((prev) => prev + 1)
    } else {
      setWarning(true)
    }
  }

  const handleConfirm = () => {
    setIsModalOpen(false);
    setCurrentPage(0);
    handleSubmit(formik.values);
  };

  const handleSelection = (name, value) => {
    if (value.length && value.length > 1) {
      const duplicate = value.some((item, index) => value.findIndex(v => v.id === item.id) !== index);
      if (!duplicate) {
        formik.setFieldValue(name, value);
      }
      return;
    };
    formik.setFieldValue(name, value);
  }

  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };


  const handleClose = () => {
    setIsModalOpen(false);
  };

  const goToNextInput = (event: React.KeyboardEvent) => {
    const children = generalRef?.current?.children;
    const child = Array.from(children).find((child, index) => {
      const isTrue = child.contains(event.target as Node);
      return isTrue;
    });

    let sibling = child.nextSibling;
    // if sibling input is disabled, find the next input that is not disabled
    while (sibling?.querySelector(`input[disabled]`)) {
      sibling = sibling.nextSibling;
    }
    sibling
      ?.querySelector(`input:not([disabled]):not([type="submit"])`)
      ?.focus();
  };


  const handleSubmit = async (values) => {
    let assignment = new AssignmentStructure(formik?.values);

    assignment.insuredSubjects = assignment?.insuredSubjects.filter(subject => Object.keys(subject).length !== 0);

    try {
      setCreatingAssignment(true);
      const response = await assignment.createAssignment();
      if (response) {
        setCreatingAssignment(false);
        return;
      }
    } catch (error) {
      console.log("Error creating assignment", error);
    }
  }


  const formik = useFormik({
    initialValues: new AssignmentStructure(),
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });


  React.useEffect(() => {
    if (currentPage == steps.length - 1) setIsModalOpen(true);
  }, [currentPage]);

  React.useEffect(() => {
    const underwriters = Object.values(
      userHierarchy?.managing?.Underwriter?.users || {},
    )?.map((underwriter) => {
      return {
        id: underwriter?.legacy_id,
        label: underwriter?.email,
        name: underwriter?.given_name,
        lastName: underwriter?.family_name,
      };
    });
    setOptions({ ...options, underwriters });
    getUnderwriter().then((result) => {
      const underwriterId = result.data.underwriter_id;
      const selectedUnderwriter = underwriters.find(
        (u) => u.id === underwriterId,
      );
      if (selectedUnderwriter) {
        setSelectedUnderwriter(
          `${selectedUnderwriter.name} ${selectedUnderwriter.lastName}`,
        );
        formik.setFieldValue("underwriterId", { "id": underwriterId, "label": `${selectedUnderwriter.name} ${selectedUnderwriter.lastName}` });
      }
    });
    formik.setFieldValue("lastUpdateById", userHierarchy?.user_id);
  }, [userHierarchy]);



  React.useEffect(() => {
    if (formik?.values?.contractTypeId && currentPage === 0) {
      setCurrentPage(1);
    }
  }, [formik.values.contractTypeId, currentPage]);


  const pageValidationKeys = assignmentValidationOrder[currentPage]
  const currentPageErrors = Object.keys(formik.errors).filter((error) => pageValidationKeys.includes(error))
  const errorsToDisplay = currentPageErrors?.reduce((acc, key) => {
    acc[key] = formik?.errors[key]
    return acc
  }, {})



  const userCanContinue = currentPageErrors.length === 0
  const functionToRun = currentPage == steps.length - 1 ? handleConfirm : () => handleNext(userCanContinue)
  const stepToTake = stepsLookUp[steps[currentPage]]


  const searchUnderwriters = (text: string) => {
    let currentUnderwriters = Object.values({
      ...(userHierarchy?.managing?.Underwriter?.users || {}),
      ...(userHierarchy?.managing?.Applications?.users || {})
    })
    const applyFilters = () => currentUnderwriters.filter(uw => uw?.family_name?.toLowerCase().includes(text.toLowerCase()) || uw?.given_name?.toLowerCase().includes(text.toLowerCase()))
    const constructUnderwriter = () => {
      return currentUnderwriters.map(uw => ({
        id: uw?.legacy_id,
        label: `${uw?.family_name} ${uw?.given_name}`
      }))
    }
    const filteredUnderwriters = text ? applyFilters(currentUnderwriters) : currentUnderwriters
    setOptions((prev) => ({
      ...prev,
      underwriters: constructUnderwriter(filteredUnderwriters)
    }))
  }

  const extendedSearchOptons = {
    searchFunctions: {
      searchUnderwriters
    }
  }
  const searchFunctions = searchWrapper(search, extendedSearchOptons);

  React.useEffect(() => {
    searchFunctions.searchContractTypes('', 0)
  }, [])

  const contractType = formik?.values?.contractTypeId?.label
  return (
    <StyledAssignmentSection ref={generalRef}>
      <CreateAssignmentHeader
        currentStep={currentPage}
        steps={steps}
        contractType={contractType}
        contractNo={'New Contract:'}
      />
      <ErrorWrapper errors={errorsToDisplay} open={warning} setOpen={setWarning}>
        <StyledForm key={currentPage} onSubmit={formik?.handleSubmit}>
          <Steps
            assignment={formik.values}
            errors={formik?.errors}
            currentPage={stepToTake}
            handleChange={_handleChange}
            handleSelection={handleSelection}
            isLoading={isLoading}
            options={options}
            setFieldValue={formik.setFieldValue}
            searchFunctions={searchFunctions}
            goToNextInput={goToNextInput}
            setSelectedUnderwriter={setSelectedUnderwriter}
            setIsModalOpen={setIsModalOpen}
            accordionTriggered={accordionTriggered}
          />
        </StyledForm>
        {currentPage != 0 && (
          <ButtonContainer>
            {currentPage !== 0 && (
              <BackButton data-testid="back-button" creatingAssignment={creatingAssignment} className="nav-btn" onClick={handleBack}>
                Back
              </BackButton>
            )}
            {currentPage != 0 && (
              <>
                <NextCtr>
                  <PrimaryButton
                    data-testid="next-button"
                    type={currentPage == steps.length - 1 ? "submit" : "button"}
                    className={`nav-btn ${userCanContinue ? 'next' : 'disabled'}`}
                    onClick={functionToRun}>{
                      currentPage == steps.length - 1 ? "Submit" : "Next"
                    }</PrimaryButton>
                </NextCtr>

              </>
            )}
          </ButtonContainer>
        )}
        <StyledCreateAssignmentModal
          isOpen={isModalOpen}
          onClose={handleClose}
          title="Create Assignment"
          message={`Are you sure you want to assign this contract to ${selectedUnderwriter}?`}
          onConfirm={handleConfirm}
          cancelButtonColor="#CC0707"
          cancelTextColor="A30808"
          submitBorderColor="#3D8D5E"
          submitButtonText="3E7B57"
          buttonBackgroundColor="#fff"
        />
      </ErrorWrapper>
    </StyledAssignmentSection>
  );
};




export default CreateAssignment;
