import * as React from "react";
import { IEmail } from "../../../../api/v1/types";
import {
  AttachmentBodyDiv,
  EmailAvatar,
  ImageIcon,
  Line,
  Triangle,
  EmailBodyWrapper,
  EmailControlContainer,
  EmailFooter,
  EmailHeader,
  EmailHeaderContainer,
  PDF,
  Word,
  WordIcon,
  PDFIcon,
  ImageTypeDiv,
} from "../styles";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import TurnRightOutlinedIcon from "@mui/icons-material/TurnRightOutlined";
import TurnLeftOutlinedIcon from "@mui/icons-material/TurnLeftOutlined";
import Popover from "../../../../components/PopOver";
import EmailOptions from "./EmailOptions";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { ImageTypes, AttachmentType } from "../../../../global.types";
import ReplyToEmail from "./ReplyToEmail/ReplyToEmail";
import asModal from "../../../../hoc/asModal";
import styled from "styled-components";
import { EditorState } from "react-draft-wysiwyg";

interface IEmailBodyProps
 {
  email: IEmail;
  attachmentId: string;
  expandAccordion: boolean;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  handleSendEmail: (email: any) => void;
  emailContent: EditorState;
  handleEmailContent: (content: EditorState, setFieldValue: Function) => void;
  handleFileUpload: (file: File) => Promise<any>;
  emailContentValidation: (values: any) => any;
}

const isImageType = (type: string) => {
  return Object.values(ImageTypes).includes(type as ImageTypes);
};
const ReplyEmailModal = asModal(ReplyToEmail, '#modal-root');
const StyledReplyEmailModal = styled(ReplyEmailModal)`
display: flex;
align-items: center;
justify-content: center;
position: absolute;
width: 100%;
height: 100%;
padding: 3rem;
z-index: ${({ isOpen }) => (isOpen ? "1000" : "-1")};
`;
const EmailBody = (props: IEmailBodyProps) => {
  const [attachment, setAttachment] = React.useState<string>("");
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [openModal, setModalOpen] = React.useState<boolean>(false);
  const optionsContainerRef = React.useRef<HTMLDivElement>(null);
  const isReceived = props?.email?.from === "received";
  const { handleSendEmail, emailContentValidation, handleFileUpload, handleEmailContent, emailContent } = props;
  const emailTime = new Date(props?.email?.date + " " + props?.email?.time);
  const currentTime = new Date();
  const timeDifferenceInMilliseconds = currentTime - emailTime;
  const timeDifferenceInMinutes = Math.floor(
    timeDifferenceInMilliseconds / (1000 * 60),
  );

  const isToday =
    emailTime.getDate() === currentTime.getDate() &&
    emailTime.getMonth() === currentTime.getMonth() &&
    emailTime.getFullYear() === currentTime.getFullYear();

  const renderTime = isToday
    ? timeDifferenceInMinutes > 30
      ? `${currentTime.toLocaleDateString()} ${currentTime.toLocaleTimeString()}`
      : `${props?.email?.time} (${timeDifferenceInMinutes} minutes ago)`
    : `${props?.email?.date} ${props?.email?.time}`;


  const attachmentFromEmail = (attachmentId: string) => {
    setAttachment(attachmentId);
  };

  const handlePrintEmail = () => {
    const emailBodySourceCode = props?.email?.body;
    if (emailBodySourceCode) {
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Email Print</title>
            <style>
              body { font-family: Arial, sans-serif; }
            </style>
          </head>
          <body>
            ${emailBodySourceCode}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.focus();
      printWindow.onload = function() {
        printWindow.print();
        printWindow.close();
      };
    }
  };
  

  const handleOpenModal = () => {
    setModalOpen(!openModal); 
  };

  const handleOpenForward = () => {
    props.email.from = "";
    props.email.to = "";
    setModalOpen(!openModal); 
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  
  return (
    <EmailBodyWrapper
      id="emailBodyWrapper"
      onClick={(event: React.MouseEvent<Element>) => {
        event.stopPropagation();
      }}
    >
      <EmailHeaderContainer>
        <EmailHeader id="emailHeader">{props?.email?.subject}</EmailHeader>
        <EmailControlContainer>
          <UnfoldMoreIcon
            onClick={(event) => props.handleExpandAccordion(event)}
          />
          <LocalPrintshopOutlinedIcon onClick={handlePrintEmail} />
          <div ref={optionsContainerRef}>
            <Popover content={<EmailOptions email={props?.email} />} parent={optionsContainerRef}>
              <MoreVert />
            </Popover>
          </div>
        </EmailControlContainer>
      </EmailHeaderContainer>
      <div
        id="fromBodyWrapper"
        className="body-wrapper"
        onClick={(event: React.MouseEvent<Element>) => {
          event.stopPropagation();
        }}
      >
        <div className="from">
          <div className="name-time-wrapper">
              <div className="to-avatar-div">
                <EmailAvatar className={isReceived ? "received" : "sent"}>
                  <span>{props?.email?.from ? props.email.from[0] : " "}</span>
                </EmailAvatar>
            <h3 className="email-to-div">
              {props?.email?.from} <span>{props?.email?.to}</span>
            </h3>
              </div>
            <h3 className="email-time">{renderTime}</h3>
          </div>
          <iframe
            className="email-body"
            id="fromEmailBody"
            srcDoc={props?.email?.body}
          />
        </div>
        </div>  

      <AttachmentBodyDiv>
        {props?.email?.attachments?.map((attachment, index) => (
          <span key={index} onClick={() => attachmentFromEmail(attachment.id)} title={attachment.name}>
            {attachment?.type === AttachmentType.pdf && (
              <PDFIcon
                style={{
                  fontSize: "1rem",
                  border: "2px solid #DDDFE7",
                  borderRadius: "4px",
                  padding: "1rem",
                }}
              >
                PDF
              </PDFIcon>
            )}
            {attachment?.type === AttachmentType.word && (
              <WordIcon
                style={{
                  fontSize: "1rem",
                  border: "2px solid #DDDFE7",
                  borderRadius: "4px",
                  padding: "1rem",
                }}
              >
                W
              </WordIcon>
            )}
            {attachment?.type === AttachmentType.meet && (
              <span>
                <CalendarMonthOutlinedIcon
                  style={{
                    fontSize: "1rem",
                    border: "2px solid #DDDFE7",
                    borderRadius: "4px",
                    padding: "1rem",
                  }}
                />
              </span>
            )}
            {isImageType(attachment.type) && (
              <ImageTypeDiv>
                <span
                  style={{
                    fontSize: "1rem",
                    border: "2px solid #DDDFE7",
                    borderRadius: "4px",
                    padding: "1rem",
                  }}
                >
                   title={attachment.name}
                  <AttachmentIcon />
                </span>
              </ImageTypeDiv>
            )}
          </span>
        ))}
      </AttachmentBodyDiv>

      <EmailFooter id="emailFooter">
        <div className="email-footer-icon" onClick={handleOpenModal}>
          <TurnLeftOutlinedIcon />
          Reply
        </div>
        <StyledReplyEmailModal
          isOpen={openModal}
          onClose={handleCloseModal}
          email={props.email}
          onOpenModal={handleOpenModal}
          handleSendEmail={handleSendEmail}
          emailContent={emailContent}
          handleEmailContent={handleEmailContent}
          handleFileUpload={handleFileUpload}
          emailContentValidation={emailContentValidation}
        />
        {/* <div className="email-footer-icon" onClick={handleOpenForward}>
          Forward
          <TurnRightOutlinedIcon />
        </div> */}
      </EmailFooter>
    </EmailBodyWrapper>
  );
};
export default EmailBody;
