import * as React from 'react';
import {NoTaskSkeleton} from '../styles';


const NoTaskHistory = () => {
    return (
        <NoTaskSkeleton>
            
            No Tasks History
        
        </NoTaskSkeleton>
    )
}

export default NoTaskHistory;