import * as React from 'react';
import styled, {keyframes} from 'styled-components';

export const StyledExclusionsInclusionSection = styled.section`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    & > * {
        flex-basis: 100%;
    }
`;

export const StyledSubjectInfoSection = styled.section`
    display: grid:
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    

`;


export const StyledInsuredSubject = styled.section`
  border: 1px solid #e6e6e7;
  box-sizing: border-box;
  margin: 1rem 0;
  overflow-anchor: auto;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  border-radius: 0.25rem;
  border: none;
  .child-accordion {
    background-color: rgba(247, 248, 250, 0.8);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .header {
    font-size: 0.7rem;
  }
  .MuiButtonBase-root {
    display: flex;
    flex-direction: row-reverse;
  }
  .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  .parent-accordion {
    background-color: #ccd7e5;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .MuiAccordionSummary-content {
    p{
      font-size: 1rem;
    }
    h2 {
      font-size: 1rem;  
    }
  }
  .parent-summary{
    display: flex;
    font-size: 0.7rem;
    flex-direction: row;
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
        transform: rotate(180deg);
      }
  }
`;

export const StyledInsuredSubjectsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 1rem;
  &::after {
    content: " ";
    width: 100%;
    height: 200px;
    background: linear-gradient(
      360deg,
      rgb(247, 248, 250),
      rgba(220, 24, 24, 0) 70.71%
    );
    position: absolute;
    bottom: 0;
    pointer-events: none;
  }
  .add-insurer-button{
    border: none;
    background: none;
  }
  .add {
  font-size:3rem;
  background: #CCD7E5;
  filter: drop-shadow(0px 2.68px 1.68px rgba(0, 0, 0, 0.25));
  border-radius: 3.25rem;
  color:rgba(247, 248, 250, 0.8);
  &:hover {
      font-size: 3rem;
      background: rgba(247, 248, 250, 0.8);
      filter: drop-shadow(0px 2.68px 1.68px rgba(0, 0, 0, 0.25));
      border-radius: 3.25rem;
      color: #CCD7E5;       
  }
`;
export const StyledInsuredSubjectsSection = styled.section`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    scroll-behavior: smooth;
    padding-bottom: 11rem;
    gap: 1rem;

`;

export const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  z-index: 101;
  // background-color: white;
  animation: ${slideIn} 0.5s;
  animation-delay: 0.2s;
  position: relative;
  top: 0;
  nav {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    border-top-right-radius: -50%;
    border-bottom-right-radius: -50%;
  }
  button {
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
  }
`;
