import * as React from "react";
import { EastOutlined } from "@mui/icons-material";
// import { EPriority, EStatus } from '../Alerts';
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { StyledTableRow } from "../styles";
import StatusColumn from "./StatusColumn";
import { IAlerts } from "../../Alerts";
import asModal from "../../../../hoc/asModal";
import ExtendedModal from "../../../../components/ExtendedModal/ExtendedModal";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";
import { DashboardContext } from "../..";
import addTask from "../../../../api/v1/tasks/post";
import markAsTask from "../../../../api/v1/alerts/markAsTask";

interface IAlertsRowProps {
  alert: IAlerts;
  alerts: IAlerts[];
  setAlerts: (alerts: IAlerts[]) => void;
  displayAssignment: (assignmentId: string) => void;
  markAlertAsViewed: (alert_id: number) => void;
}

const CreateTaskFromAlert = asModal(ExtendedModal, "#modal-root");
const StyledCreateTaskFromAlert = styled(CreateTaskFromAlert)`
position: absolute;
width: 100%;
height: 100%;
z-index: 999;
display: flex;
justify-content: center;
align-items: center;
> * {
    padding: 1rem;
    font-size: 1.2rem;
    font-family: "Lato", sans-serif;
  }
`;


const AlertTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}
    PopperProps={{
      popperOptions: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -10],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "#4B4D4E",
    maxWidth: 220,
    border: "1px solid #dadde9",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    cursor: "pointer",
  },
}));

const AlertsRow = (props: IAlertsRowProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { displayAssignment, markAlertAsViewed } = props;
  const [contractNo, id] = props?.alert?.name.split("/").map((item) => item.trim()) || ["", ""];

  const modalData = {
    contractNo: contractNo,
    broker: props?.alert.broker,
    alert: props?.alert,
    title: "Leave a comment below to mark this alert as a task:"
  }


  const handleAddAlert = () => {
    setIsOpen(true);
  };



  return (
    <>
      <StyledTableRow data-testid="alerts-row" alert={props?.alert} >
        <td data-testid="assignment-status">
          <StatusColumn status={props?.alert?.status} />
        </td>
        <td
          data-testid="assignment-contractno"
          className={
            new Date(props?.alert?.created_at).toDateString() ===
              new Date().toDateString()
              ? "today"
              : "not-today"
          }
        >
          {contractNo}
        </td>
        <td data-testid="assignment-type">{props?.alert?.type}</td>
        <td data-testid="assignment-id">{id}</td>
        <td data-testid="assignment-description">{props?.alert?.description}</td>
        <td data-testid="assignment-broker">{props?.alert?.broker}</td>
        <td data-testid="assignment-create-date">{props?.alert?.created_at}</td>
        <td className="hide-on-print">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              width: "220px",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                textAlign: "center",
                width: "220px",
                gap: "1rem"
              }}
            >
              <AlertTooltip title="Add Alert to Task" placement="top">
                <button
                  onClick={handleAddAlert}
                  data-testid="alert-to-task-button"
                  className="alert-button"
                >
                  <AddCircleOutlineOutlinedIcon
                    className="add-alert-button"
                  />
                </button>
              </AlertTooltip>
              <StyledCreateTaskFromAlert
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                }}
                modalData={modalData}
                onConfirm={async (comment: string, selectedDateTime: Date) => {
                  markAsTask(props?.alert?.id);
                  addTask(props?.alert?.assignmentId, comment, selectedDateTime);
                  props?.setAlerts((prevAlerts) => {
                    return Object.values(prevAlerts).filter((alert) => alert.id !== props?.alert?.id);

                  });
                  setIsOpen(false);
                }
                }
              />
              <AlertTooltip
                title="Mark as Viewed"
                placement="top"
                sx={{ backgroundColor: "white", color: "black" }}
              >
                <CheckCircleOutlineOutlinedIcon
                  onClick={() => markAlertAsViewed(props?.alert?.id)}
                  className={`view-alert-button ${props?.alert?.viewed ? "viewed-alert" : ""
                    }`}
                  data-testid="mark-alert-viewed"
                />
              </AlertTooltip>
            </div>
            <div
              data-testid="go-to-assignment"
              style={{
                color: "#186ADE",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                displayAssignment(props?.alert?.assignment_id);
              }}
            >
              Go To Assignment <EastOutlined />
            </div>
          </div>
        </td>
      </StyledTableRow>
    </>
  );
};

const ConnectedAlertsRow = (props: IAlertsRowProps): JSX.Element => {
  const { displayAssignment } = React.useContext(DashboardContext);
  return (
    <AlertsRow displayAssignment={displayAssignment} alert={props.alert} setAlerts={props.setAlerts} alerts={props.alerts} markAlertAsViewed={props?.markAlertAsViewed} />
  );
};
export default ConnectedAlertsRow;