import * as React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import AutoCompleteInput from "../../AssignmentDrillDown/Steps/components/AutocompleteInput";
import useEntitySearch from "../../../../hooks/useEntitySearch";
import { UserContext } from "../../../../context/UserContext";

const Wrapper = styled.div`
  border-radius: 0.25rem;
  background: #FAFBFB;
  padding: 2rem;
  right: 0;
  height: 89%;
  width: 30%;
  overflow-y: auto;
  top: 0;
  box-shadow: -4px -4px 10px 0px rgba(0, 0, 0, 0.10), 4px 4px 10px 0px rgba(39, 50, 62, 0.05); 
  .modal-close {
    cursor: pointer;
   display: flex;
    justify-content: flex-end;
   align-items: center;
  }
  .border {
    border-top: 1px solid #b1b3c5;
  }
  }
  .dropdown {
    display: flex;
    flex-direction: column;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 67px;
    height: 34px;
  }

  .switch input {
    opacity: 0; 
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(177, 179, 197, 0.46); 
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset; 
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #91d5ff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(33px);
  }

  .slider.round {
    border-radius: 6.25rem;
  }

  .span-text {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: #3E3E3C;
    transition: 0.4s;
    font-size: 0.5rem;
    text-transform: uppercase;
  }

  .span-text.active {
    left: 50%;
    transform: translate(-100%, -50%);
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .toggle {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    margin-top: 0.2rem;
  }

  .underwriter-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0.4rem;
  }
  .header {
    padding-bottom: 1.5rem;
    font-size: 1.2rem;
    font-weight:600;
  }
  .cancel{
    border: none;
    background: none;
    cursor: pointer;
  }
  .buttons {
    display: flex;
    margin-top: auto;
    gap: 2rem;
    justify-content: end;
  }
  .date-inputs {
    display: flex;
    flex-direction: row;
    gap:1.7rem;
  }
  .date-inputs > div {
    display: flex;
    flex-direction: column;
    gap:0.2rem;
  }
  .input-header{
    padding-top: 1.4rem;
    padding-bottom: 1.1rem;
    font-size: 1rem;
    font-weight: 600;
  }

`;

export const UnderwritersChosen = styled.div`
  display: flex;
  padding: 0.5rem;
  border-radius: 20px;
  border: 1px solid #b1b3c5;
  background: #e7f2ff;
  align-items: center;
  font-size: 0.8rem;
  .icon {
    color: #323232;
    font-size: 1rem;
  }
`;

const ApplyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.6rem;
  height: 1.8rem;
  background-color: ${(props) => (props.disabled ? "#cccccc" : "#3361FF")};
  border-color: ${(props) => (props.disabled ? "#cccccc" : "#3361FF")};
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  cursor: pointer;
`;

const DateTimeRange = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #b1b3c5;
  margin-bottom: 1rem;
`;

interface IAdvancedFilterProps {
  onClose: () => void;
  values: any;
  handleSubmit: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (name: string, value: any) => void;
  setFilterKey: (value: number) => void;
  isOpen: boolean;
  setFilterApplied: (value: boolean) => void;
}

const AdvancedFilter = (props: IAdvancedFilterProps) => {
  const {
    values,
    handleSubmit,
    setSearchParams,
    setFieldValue,
    handleChange,
    isOpen,
    setFilterKey,
    setPageNumber,
    setFilterApplied,
  } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const { userHierarchy } = React.useContext(UserContext)
  const [underwriterFilterValue, setUnderwriterFilterValue] = React.useState<string>("");

  const filterRef = React.useRef(null);

  const useUnderWriter = React.useMemo(() => {
    let underwriters = Object.values({ ...(userHierarchy?.managing?.Underwriter?.users || {}), ...(userHierarchy?.managing?.Applications?.users || {}) }).map(uw => {
      const underwriterAlreadySelected = values?.underwriters?.find(u => u.id === uw?.legacy_id);

      if (values.underwriters.find(u => u.id === 'All')) {
        return null;
      }

      if (!underwriterAlreadySelected) {
        return {
          id: uw?.legacy_id,
          label: `${uw?.family_name} ${uw?.given_name}`
        };
      }
      return null;
    }).filter(uw => uw !== null);

    if (!values?.allUsersAssignments) {
      const allOption = { id: 'All', label: 'All' };
      if (!values.underwriters.find(u => u.id === 'All')) {
        underwriters = [allOption, ...underwriters];
      }
    }
    return underwriters;

  }, [userHierarchy, values?.underwriters]);

  const filteredUnderwriters = useUnderWriter?.filter(uw =>
    uw.label.toLowerCase().startsWith(underwriterFilterValue.toLowerCase())
  );


  const { search, options } = useEntitySearch({
    setIsLoading: setLoading,
  });


  const getContractType = () => {
    try {
      search(
        {
          model: "AssignmentType",
          transform: {
            id: "id,kod,Kod,KOD,KoD",
            label: "description",
          },
          detail_level: "option",
        },
        "contractTypes",
      );
    } catch (err) {
      console.error(err);
    }
  };

  const getStatus = () => {
    try {
      search(
        {
          model: "AssignmentStage",
          transform: {
            id: "id,kod,Kod,KOD,KoD",
            label: "engstatus",
          },
          detail_level: "option",
        },
        "status",
      );
    } catch (err) {
      console.error(err);
    }
  };


  const handleRemoveSelection = (selection, value, tag) => {
    const update = value.filter(
      (item) => item.id !== selection?.id,
    );
    setFieldValue(tag, update);
  };

  const handleClearFilter = () => {
    setFilterKey((prevKey) => prevKey + 1);
    setFilterApplied(false);
    setSearchParams((prev) => ({
      ...prev,
      underwriters: [],
      dateRange: null,
      allUsersAssignments: false,
      typeOf: [],
      status: [],
    }))
    setPageNumber(0);
    props?.onClose();
  };

  const addFilterText = (text: string) => {
    setUnderwriterFilterValue(text);
  }

  const useContractTypes = options?.contractTypes?.filter((type) => {
    return !values?.typeOf.find((t) => t.label.toLowerCase() === type?.label?.toLowerCase());
  });

  const useFilteredStatus = options?.status?.filter((status) => {
    return !values?.status.find((s) => s.label.toLowerCase() === status?.label?.toLowerCase());
  });

  React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        props?.onClose();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [filterRef]);

  return (
    <Wrapper data-testid="filter-container" open={isOpen} ref={filterRef}>
      <div className="modal-close" >
        <CloseIcon data-testid="close-filter" onClick={props?.onClose} />
      </div>
      <div className="header" data-testid="filter-title">
        Advanced Filter
      </div>
      <div className="border">
        <>
          <div>
            <div className="input-header">Underwriters</div>
            <AutoCompleteInput
              multiSelect
              testId="underwriters"
              name="underwriters"
              onSearch={addFilterText}
              options={filteredUnderwriters || []}
              label="Select Underwriters"
              value={values?.underwriters}
              setFieldValue={(name, value) => {
                if (value.some(v => v.id === 'All')) {
                  setFieldValue('allUsersAssignments', true);
                  setFieldValue('underwriters', []);
                } else {
                  setFieldValue('allUsersAssignments', false);
                  setFieldValue(name, value);
                }
              }}
            />

            <div data-testid="underwriters-wrapper" className="underwriter-wrapper">
              {values?.allUsersAssignments ? (
                <div>
                  <UnderwritersChosen key="All">
                    All
                    <CloseIcon
                      className="icon"
                      onClick={() => {
                        setFieldValue('allUsersAssignments', false);
                        setFieldValue('underwriters', []);
                      }}
                    />
                  </UnderwritersChosen>
                </div>
              ) : (
                values?.underwriters?.filter((uw) => !uw?.replaceable).map((underwriter) => (
                  <div key={underwriter?.id}>
                    <UnderwritersChosen>
                      {underwriter?.label}
                      <CloseIcon
                        className="icon"
                        onClick={() =>
                          handleRemoveSelection(underwriter, values?.underwriters, 'underwriters')
                        }
                      />
                    </UnderwritersChosen>
                  </div>
                ))
              )}
            </div>

          </div>
        </>
        <div className="border">
          <div className="input-header">Type Of</div>
          <AutoCompleteInput
            label="Select Type Of"
            multiSelect
            testId="typeOf"
            options={useContractTypes || []}
            onSearch={getContractType}
            name="typeOf"
            value={values?.typeOf}
            setFieldValue={setFieldValue}
          />
            <div data-testid="typeOf-wrapper" className="underwriter-wrapper">
          {!!values?.typeOf.length && (
              <div>
            {values?.typeOf?.map((typeOf) => (
              <div>
              <UnderwritersChosen key={typeOf?.id}>
              {typeOf?.label}
              <CloseIcon
              className="icon"
              onClick={() =>
                handleRemoveSelection(typeOf, values?.typeOf, 'typeOf')
              }
              />
              </UnderwritersChosen>
              </div>
            ))}
          </div>
          )}
            </div>
        </div >
        <DateTimeRange>
          <div className="input-header">Date</div>

          <div className="date-inputs">
            <div style={{ width: '100%' }}>
              <label htmlFor="start-date">From Date</label>
              <input
                type="date"
                data-testid="start-date"
                id="start-date"
                name="dateRange.startDate"
                placeholder="DD/MM/YYYY"
                onChange={handleChange}
                value={values?.dateRange?.startDate}
              />
            </div>
            <div style={{ width: '100%' }}>
              <label htmlFor="end-date">To Date</label>
              <input
                id="end-date"
                type="date"
                data-testid="end-date"
                name="dateRange.endDate"
                placeholder="DD/MM/YYYY"
                onChange={handleChange}
                value={values?.dateRange?.endDate}
              />
            </div>
          </div>
        </DateTimeRange>
        <div className="border">
          <div className="input-header">Status</div>
          <AutoCompleteInput
            label="Select Status"
            multiSelect
            testId="status"
            name="status"
            options={useFilteredStatus || []}
            onSearch={getStatus}
            value={values?.status}
            setFieldValue={setFieldValue}
          />
            <div data-testid="status-wrapper" className="underwriter-wrapper">
          {!!values?.status.length && (
            <div>
              {values?.status?.map((status) => (
                <div>
                  <UnderwritersChosen key={status?.id}>
                    {status?.label}
                    <CloseIcon
                      className="icon"
                      onClick={() =>
                        handleRemoveSelection(status, values?.status, 'status')
                      }
                    />
                  </UnderwritersChosen>
                </div>
              ))}
              </div>
          )}
            </div>
        </div>
      </div >
      <div className="buttons">
        <button data-testid="clear-filter" className="cancel" onClick={handleClearFilter}>
          Clear Filters
        </button>
        <ApplyButton data-testId="apply-filter" type="submit" onClick={handleSubmit}>
          Apply
        </ApplyButton>
      </div>
    </Wrapper >
  );
};

export default AdvancedFilter;
