import { api, apiv2 } from "..";



export const autoCompleteLocation = async (place: string) => {
    const params = encodeURIComponent(place)
    const url = `/location/autocomplete/${params}`
    const results = await api.get(url)
    return results.data
}

export const getPolygonData = async (place: string | object) => {
    try{
        if (typeof place === "object") {
            const results = await api.post(`/location/polygon`, place)
            return results.data
        }else{
            const results = await api.get(`/location/polygon/${place}`)
            return results.data
        }
    }catch(e){
        return {
            message: "No polygon data found"
         }
    }
}

export const getNearbyLocations = async (lat: number, long: number, radius: number = 100, unit: "km" | "mi" = "km") => {
    const results = await apiv2.get(`/location/nearby/${lat}/${long}/${radius}/${unit}`)
    return results.data
}

export const deleteIncludedLocation = async (locationId:number, included:boolean) => {
    return await apiv2.delete(`/location/${locationId}/${included ? "True" : "False"}`)
}

interface IAggregateLocation {
    location_ids: number[];
    distance: number;
    unit: "km" | "mi";
}
export const getAggregatedLocations = async (geoJson: IAggregateLocation) => {
    alert(
        JSON.stringify(geoJson)
    )
    // const results = await apiv2.post(`/location/aggregated`, geoJson)
    // return results.data
}