import {api} from "../index";
import {IKPIResponse } from "../../v1/types";

export interface IKPIRequest {
  level_concern?: "organization" | "individual";
  userId: number;
}

export const getKPIs = async (userId:number): Promise<IKPIResponse> => {
  const config = {
    params: {
      user_id: userId
    }
  };

  try {
    const response = await api.get(`/kpis/user`);
    return response?.data?.kpis;
  } catch (error) {
    console.error("Error fetching KPIs:", error);
    return;
  }
};

export default getKPIs;
