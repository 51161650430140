import * as React from "react";
import { IDefaultTask } from '../../../../../api/v1/assignments/types/assignments';
import { AddNewTaskItems, ContentWrapper } from './styles';
import addTask from "../../../../../api/v1/tasks/post";
import { Task } from "../../../../../api/v1/types";
import { DashboardContext } from "../../..";
import { getPriorities } from "../../../../../api/v1/priorities/get";
interface INewTaskItemsProps {
    task: IDefaultTask;
    assignmentId?: string;
    setTasks: React.Dispatch<React.SetStateAction<Task[]>>;
    handleExpandAddTaskMenu?: () => void;
}

const NewTaskItems: React.FC<INewTaskItemsProps> = ({ task, assignmentId, setTasks, handleExpandAddTaskMenu }) => {
    const { setPriorities } = React.useContext(DashboardContext);

    const onAddTask = () => {
    
        handleExpandAddTaskMenu();
        addTask(assignmentId, task?.task)
            .then(response => {
                setTasks(response);
                getPriorities()
                    .then(priorities => {
                        setPriorities(priorities || []);
                    })
                    .catch(priorityError => {
                        console.error("Failed to fetch priorities:", priorityError);
                    });
            })
            .catch(error => {
                console.log("Error:", error);
            });
    }

    return ( 
        <AddNewTaskItems data-testid="taskItems">
            <ContentWrapper onClick={onAddTask}>
                {task?.task}
            </ContentWrapper>
        </AddNewTaskItems>
     );
}
 
export default NewTaskItems;
