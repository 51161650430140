import * as React from "react";
import {
  Table,
  TableContainer,
  TableHeading,
  ResetButton,
  PaginationContainer,
  PageNumbers,
  ArrowsContainer,
} from "../styles";
import { IAssignmentsTableProps } from "..";
import TableHeader from "./TableRowHeader";
import TableBodyRow from "././TableBody/TableBodyRow";
import {
  mockAssignmentMarkAsComplete,
  mockAssignmentMarkAsEscalated,
} from "../../../../api/v1/__mocks__/assignments";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchAssignmentPropertiesTool from "../../Documents/components/SearchCompany";
import { ELegacyAssignmentProps } from "../../../../api/v1/types";
import NoAssignmentsView from "./NoAssignmentsView";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AssignmentsSkeleton from "./AssignmentsTableSkeleton";
import FilterListIcon from "@mui/icons-material/FilterList";
import asModal from "../../../../hoc/asModal";
import AdvancedFilter from "./AdvancedFilter";
import styled from "styled-components";
import { Formik } from "formik";
import { defaultSearchParams } from "../../../../hooks/useAssignments";
import AssignmentStructure from "../../../../api/v1/assignments/classes/assignment";
import { useLocation } from "react-router-dom";


export enum EOptionsLabel {
  "insurer" = "insurer",
  "cedent" = "cedent",
  "broker" = "broker",
  "contract_no" = "contract_no",
  "insured" = "insured",
}

export const OptionLabels = {
  [EOptionsLabel.insurer]: "Insurer",
  [EOptionsLabel.cedent]: "Cedent",
  [EOptionsLabel.broker]: "Broker",
  [EOptionsLabel.contract_no]: "Contract No.",
  [EOptionsLabel.insured]: "Insured",
};

const Filter = asModal(AdvancedFilter, "#main-dashboard");
const StyledFilter = styled(Filter)`
display: flex;
align-items: center;
justify-content: flex-end;
position: absolute;
width: 100%;
height: 100%;
z-index:2000;
left: 0;
padding: 1rem 0;
`;

const StyledInfoTip = styled.div<{ filterApplied: boolean }>`
  border: none;
  background: none;
  cursor: pointer;
  color: ${props => props.filterApplied ? '#3361FF' : 'inherit'};
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const RenderedAssignmentsTable = (props: IAssignmentsTableProps) => {
  const {
    searchParams,
    setSearchParams,
    loadingAssignments,
    assignments: assignmentsToView,
    goToNextPage,
    goToPrevPage,
    pageNumber,
    pageSize,
    sortParams,
    setSortParams,
    setPageNumber,
    setPriorities,
    fetchAssignments,
  } = props;
  const tableRef = React.useRef<HTMLTableElement>(null);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [lastSortedColumn, setLastSortedColumn] = React.useState(null);
  const [filterApplied, setFilterApplied] = React.useState<boolean>(false);
  const [filterKey, setFilterKey] = React.useState(0);
  const location = useLocation();
  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const contractType = query.get("contract_type");

  type ContractType = "0" | "1" | "2" | "4" | "5";
  const headings: Record<ContractType, string> = {
    "0": "Assignments",
    "1": "Fac Inward",
    "2": "Treaty Inward",
    "4": "Proposals",
    "5": "Declined"
  };

  const heading = headings[contractType as ContractType] || "Assignments";

  const handleReset = async () => {
    tableRef.current?.scrollTo(0, 0);
    setSortParams({
      sortOrder: "asc",
      sortBy: ELegacyAssignmentProps.DATA_Create,
    });
    setSearchParams(defaultSearchParams);
    setLastSortedColumn(null);
    setPageNumber(0);
  };

  const sortAssignment = async (property: string, order: "asc" | "desc") => {
    order =
      sortParams?.sortBy == property && sortParams?.sortOrder === "asc"
        ? "desc"
        : "asc";
    setSortParams({ sortBy: property, sortOrder: order });
  };

  React.useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
  }, [
    sortParams?.sortBy,
    sortParams?.sortOrder,
    searchParams,
    pageNumber,
  ]);

  let displayRangeEnd: number = pageNumber + pageSize
  displayRangeEnd = displayRangeEnd > props?.totalAssignments ? props?.totalAssignments : displayRangeEnd;
  const displayRange = `${pageNumber + 1}-${displayRangeEnd} of ${props?.totalAssignments}`;

  const handleOpenModal = (event: React.MouseEvent<HTMLElement>): void => { setOpenModal(true); };

  const closeModal = (): void => {
    if (filterApplied === false) {
      setFilterKey(filterKey + 1);
    }
    setOpenModal(false);
  }


  const handleSubmit = (values: any) => {
    const updatedValues = { ...values };
    const index = updatedValues.underwriters.findIndex(item => item.id === "All");
    if (index !== -1) {
      updatedValues.underwriters.splice(index, 1);
    }

    setOpenModal(false);
    setSearchParams(updatedValues);
    const isFilterApplied =
      (updatedValues.underwriters?.length > 0 || false) ||
      updatedValues.typeOf.length > 0 ||
      updatedValues.status.length > 0 ||
      updatedValues.dateRange !== null ||
      updatedValues.allUsersAssignments == true;
    setFilterApplied(isFilterApplied);
    setOpenModal(false);
  }

  React.useEffect(() => {
    setFilterApplied(false);
    setSearchParams(defaultSearchParams);
    setFilterKey(filterKey + 1);
  }
    , [contractType])



  return (
    <>
      <Formik
        initialValues={searchParams}
        onSubmit={handleSubmit}
        key={filterKey}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, handleChange, setFieldValue, resetForm }) => (
          <form onSubmit={handleSubmit} style={{ flexBasis: "100%", minHeight: 0 }}>

            <TableContainer data-testid="table-container">
              <TableHeading>
                <div className="table-header">
                  <h3>{heading}</h3>
                </div>
                <div className="table-input" data-testid="search-div">
                  <SearchAssignmentPropertiesTool
                    setSearchParams={setSearchParams}
                    searchParams={searchParams}
                    fetchAssignments={props?.fetchAssignments}
                    filterCategories={[
                      {
                        value: EOptionsLabel.insurer,
                        label: OptionLabels[EOptionsLabel.insurer],
                      },
                      {
                        value: EOptionsLabel.cedent,
                        label: OptionLabels[EOptionsLabel.cedent],
                      },
                      {
                        value: EOptionsLabel.broker,
                        label: OptionLabels[EOptionsLabel.broker],
                      },
                      {
                        value: EOptionsLabel.insured,
                        label: OptionLabels[EOptionsLabel.insured],
                      },
                      {
                        value: EOptionsLabel.contract_no,
                        label: OptionLabels[EOptionsLabel.contract_no],
                      },
                    ]}
                  />
                  <StyledInfoTip onClick={handleOpenModal} filterApplied={filterApplied}>
                    {filterApplied ? "Active" : ""}
                    <FilterListIcon style={{}} />
                  </StyledInfoTip>
                  <StyledFilter
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    isOpen={openModal}
                    setSearchParams={setSearchParams}
                    resetForm={resetForm}
                    onClose={closeModal}
                    position="right"
                    setFilterKey={setFilterKey}
                    setPageNumber={setPageNumber}
                    setFilterApplied={setFilterApplied}
                  />
                  <ResetButton data-testid="reset-button" onClick={handleReset}>
                    Reset
                    <RefreshIcon />
                  </ResetButton>
                  <PaginationContainer>
                    <PageNumbers>{displayRange}</PageNumbers>
                    <ArrowsContainer>
                      <KeyboardArrowLeftOutlinedIcon onClick={goToPrevPage} />
                      <KeyboardArrowRightOutlinedIcon onClick={goToNextPage} />
                    </ArrowsContainer >
                  </PaginationContainer >
                </div >
              </TableHeading >
              <section className="table-container" ref={tableRef}>
                <Table>
                  <TableHeader
                    data-testid="table-header"
                    sortOrder={sortParams.sortOrder}
                    sortProperty={sortParams.sortBy}
                    lastSortedColumn={lastSortedColumn}
                    sortAssignment={sortAssignment}
                  />
                  <tbody>
                    {loadingAssignments && <AssignmentsSkeleton />}
                    {!assignmentsToView?.length && !loadingAssignments && (
                      <tr>
                        <td colSpan={10000}>
                          <NoAssignmentsView />
                        </td>
                      </tr>
                    )}
                    <TableRows
                      loadingAssignments={loadingAssignments}
                      assignmentsToView={assignmentsToView}
                      displayAssignment={props?.displayAssignment}
                      setPriorities={props?.setPriorities}
                      setFilterApplied={setFilterApplied}
                      setSearchParams={setSearchParams}
                      fetchAssignments={fetchAssignments}
                    />
                  </tbody>
                </Table>
              </section >
            </TableContainer >
          </form >
        )}
      </Formik >
    </>
  );
};


interface ITableRows {
  assignmentsToView: AssignmentStructure[];
  loadingAssignments: boolean;
  displayAssignment: (assignment: number) => void;
  setPriorities: (assignmentId: number, priority: number) => void;
  setFilterApplied: (filterApplied: boolean) => void;
  setSearchParams: (params: any) => void;
  fetchAssignments: () => void;
}
const StyledTableBodyRow = styled(TableBodyRow) <{ shouldDisplay: boolean }>`
  display: ${props => props.shouldDisplay ? '' : 'none'};

`;
const TableRows = (props: ITableRows): JSX.Element => {
  const { setFilterApplied, assignmentsToView, displayAssignment, setPriorities, loadingAssignments } = props;
  const records = React.useMemo(() => {
    return assignmentsToView?.map((assignment, key) => {
      return (
        <StyledTableBodyRow
          key={`assignment-${key}-${assignment?.id}`}
          data-testid="table-body-row"
          shouldDisplay={assignment.id}
          assignment={assignment}
          markAsComplete={mockAssignmentMarkAsComplete}
          escalateAssignment={mockAssignmentMarkAsEscalated}
          displayAssignment={displayAssignment}
          priorityLevel={assignment?.priority}
          setPriorities={setPriorities}
          setFilterApplied={setFilterApplied}
          setSearchParams={props.setSearchParams}
          fetchAssignments={props.fetchAssignments}
        />
      );
    })
  }, [assignmentsToView])

  return loadingAssignments ? null : records
}
export default RenderedAssignmentsTable;
