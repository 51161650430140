import * as React from "react";
import { StyledPermissionRow } from "../../../styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { User } from "../../../../../../context/UserContext";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useOutletContext } from "react-router-dom";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import editUser from "../../../../../../api/v1/users/put";
import { styled } from "styled-components";
import { IUser } from "../../../../../../api/v1/users/types/user";
import { IDepartment } from "../../../../../../api/v1/types";
import InfoTip from "../../../../../../components/Tooltip";
import asModal from "../../../../../../hoc/asModal";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";


interface IPermissionRowProps {
  entity: User | IDepartment;
  handleAddRestriction?: () => void;
  deny?: {
    components: string[];
    operations: ("read" | "write" | "delete" | "update")[];
  };
  allow?: {
    components: string[];
    operations: ("read" | "write" | "delete" | "update")[];
  };
  path?: string;
}

const possibleOperationValues = ["read", "write", "delete", "update"];
const operationLabels = {
  read: "Read",
  write: "Write",
  delete: "Delete",
  update: "Update",
};

const StyledOperationRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeleteConfirmationModal = asModal(ConfirmationModal, "#modal-root");
const StyledDeleteConfirmationModal = styled(DeleteConfirmationModal)`
  > * {
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
    }
  }
`;
const PermissionRow = (props: IPermissionRowProps) => {
  const [confirmationModal, setConfirmationModalOpen] = React.useState<boolean>(false);
  const { entity, deny, path: pathName, service, setPermissionState, deletePathRule } = props;
  const deniedOpertaions = deny?.operations || [];
  
  const handleOperationEdits = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setPermissionState(
      {
        ...entity?.authorityLevel?.services,
        [service]: {
          ...entity?.authorityLevel?.services[service],
          [pathName]: {
            ...entity?.authorityLevel?.services[service][pathName],
            deny: {
              ...entity?.authorityLevel?.services[service][pathName].deny,
              operations: checked ? entity?.authorityLevel?.services[service][pathName].deny.operations.filter((operation) => operation !== name) : [...entity?.authorityLevel?.services[service][pathName].deny.operations, name]
            }

          }
        }
      })
  };

  const handleSetAllActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setPermissionState(
      {
        ...entity?.authorityLevel?.services,
        [service]: {
          ...entity?.authorityLevel?.services[service],
          [pathName]: {
            ...entity?.authorityLevel?.services[service][pathName],
            deny: {
              ...entity?.authorityLevel?.services[service][pathName].deny,
              operations: deniedOpertaions.length ? [] : ["read", "write", "delete", "update"]
            }

          }
        }
      })
  };

  const handleDeletePathRule = () => {
    setConfirmationModalOpen(true);
  };


  const allIsMarked = !(deniedOpertaions.length);
  return (
    <>
      <StyledPermissionRow
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
        }}
      >
          <td key={`all-option`}>
            <div className="toggle">
              <label className="switch">
                <input type="checkbox" 
                  name={"all"} 
                  onChange={handleSetAllActive} 
                  checked={allIsMarked}
                />
                <span className="slider round"></span>
                <span className={`span-text ${allIsMarked ? "active" : ""}`}>
                  {allIsMarked ? "Active" : "Off"}
                </span>
              </label>
            </div>
          </td>
        <td>
          <StyledOperationRow>
              <span>{pathName}</span>
          </StyledOperationRow>
        </td>
        {possibleOperationValues.map((operation, index) => {
          const isOperationDenied = deniedOpertaions.includes(operation);
          return (
            <td key={`${operation}-${index}`}>
              <div className="toggle">
                <label className="switch">
                  <input type="checkbox" 
                    name={operation} 
                    onChange={handleOperationEdits} 
                    checked={!isOperationDenied}
                  />
                  <span className="slider round"></span>
                  <span className={`span-text ${!isOperationDenied ? "active" : ""}`}>
                    {!isOperationDenied ? "Active" : "Off"}
                  </span>
                </label>
              </div>
            </td>
          )
        }
        )}
        <td>
          <div className="actions">
            <InfoTip title="Delete Path" placement='top'>
              <DeleteOutlineOutlinedIcon data-testid="delete-permission" onClick={handleDeletePathRule} />
            </InfoTip>
          </div>
        </td>
              <StyledDeleteConfirmationModal
              isOpen={confirmationModal}
              onClose= {() => setConfirmationModalOpen(false)}
              onConfirm={() => deletePathRule(service, pathName)}
              title='Delete Permission'
              message='Are you sure you want to delete this permission?'
              />
      </StyledPermissionRow>
    </>
  );
};

const ConnectedPermissionsRow = (props) => {
  const {entity} = props;
  return <PermissionRow entity={entity} {...props} />;
};
export default ConnectedPermissionsRow;
