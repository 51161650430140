import * as React from 'react';
import {NoTaskSkeleton} from '../styles';


const NoTask = () => {
    return (
        <NoTaskSkeleton>
            
            No Tasks
        
        </NoTaskSkeleton>
    )
}

export default NoTask;