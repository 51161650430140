import { apiv2 } from "../..";



interface ICompany {
    detailLevel: "basic" | "full";
    type: string;
    text: string;
}

interface ICompanyResponse {
    id: number;
    name: string;
}

export const getEntityDetails = async (props: {
    model: string,
    filter?: [{
        column: string,
        value: string
        operator: string
    }],
    detailLevel?: string
}): Promise<ICompany[]> => {
    try {
        const res = await apiv2.post(`/facts/${props?.model}`, props
        );
        return res?.data?.results;
    } catch (error) {
        console.error("Error:", error);
    }
}



interface IDisoverEntityResponse {
    name: string;
}

export const exporeEntityDescriptions = async (props: IDisoverEntityResponse): Promise<IDisoverEntityResponse[]> => {
    try {
        const res = await apiv2.get(`/discover/${props?.name}/description`);
        return res?.data?.results;
    } catch (error) {
        console.error("Error:", error);
    }

}
