import React from 'react';
import { createGlobalStyle } from 'styled-components';

export enum Layers {
    Modal = 100,
    Overlay = 50,
    Dropdown = 40,
    Navigation = 30,
    Content = 20,
    Background = 0
}


export const GlobalStyles = createGlobalStyle`
    #root {
        width: 100vw;
        height: 100vh;
        padding: 0;
        overflow-y: hidden;
        overflow-x: hidden;
        scroll-behavior: smooth;
        font-size: 100%;
        font-family: 'Open Sans', sans-serif;
    }
    body {
        width: 100%;
        height: 100%;
        overflow:hidden;
        overflow-x: hidden;
        padding: 0;
        margin: 0;
    }
    a {
        text-decoration: none;
        color: black;
    }

    button.save {
        min-width: 100px;
        color: white;
        padding: 1rem;
        border-radius: 0.25rem;
        border: 1px solid #0f90c7;
        background: #58a0bf;
    }
    .modal {z-index: ${Layers.Modal};
    .overlay {z-index: ${Layers.Overlay};
    .dropdown {z-index: ${Layers.Dropdown};
    .navigation {z-index: ${Layers.Navigation};
    .content {z-index: ${Layers.Content};
    .background {z-index: ${Layers.Background};
    }
    /* overflow-y: scroll; */
    font-family: "Lato", sans-serif;;
    max-width: 100%;
    display: grid;
`;