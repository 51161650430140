import * as React from "react";
import { RDPSettingsForm, SubmitTaskButton } from "../../../styles";
import { useOutletContext, useParams } from "react-router-dom";
import { User } from "../../../../../../context/UserContext";
import { Formik } from "formik";
import editUser from "../../../../../../api/v1/users/put";

interface IRDPSettingsProps {
  user: User;
  userId?: string;
  editUser: (user: User) => void;
}

const RDPSettings = (props: IRDPSettingsProps) => {
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const [isFormModified, setIsFormModified] = React.useState<boolean>(false);
  const { user, editUser } = useOutletContext<{
    user: User;
    editUser: (user: User) => void;
  }>();


  const validateForm = (values: any) => {
    const errors: any = {};
    if (!values.host || values.host.length < 2) {
      errors.host = "Must be at least 2 characters";
    }
    if (!values.username) {
      errors.username = "Username is required";
    }    
    if (!values.password || values.password.length < 2) {
      errors.password = "Must be at least 2 characters";
    }
    if (
      !values.port ||
      isNaN(values.port) ||
      parseInt(values.port) !== parseFloat(values.port)
    ) {
      errors.port = "Must be a valid port number";
    }
    return errors;
  };

  const handleFormSubmission = (values: any) => {
    setIsFormModified(false);
    const clonedUser = user.copyOfUser();
    clonedUser.rdpSettings = values;
    editUser(clonedUser);
  };

  return (
    <Formik
      initialValues={{
        host: props?.user?.rdpSettings?.host || "N/A",
        username: props?.user?.rdpSettings?.username || "N/A",
        password: props?.user?.rdpSettings?.password || "N/A",
        port: props?.user?.rdpSettings?.port || "N/A",
      }}
      onSubmit={handleFormSubmission}
      validate={validateForm}
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        isValid,
      }) => (
        <RDPSettingsForm onSubmit={handleSubmit}>
          <div className="input-label">
            <div className="error-div">
              <label htmlFor="host">Host</label>
              {errors?.host && touched.host && (
                <div className="invalid-input-error">{errors?.host}</div>
              )}
            </div>
            <input
              id="host"
              required
              type="text"
              name="host"
              {...getFieldProps("host")}
            />
          </div>
          <div className="input-label">
            <div className="error-div">
              <label htmlFor="username">Username</label>
              {errors?.username && touched.username && (
                <div className="invalid-input-error">{errors?.username}</div>
              )}
            </div>
            <input
              id="username"
              required
              type="text"
              name="username"
              {...getFieldProps("username")}
            />
          </div>
          <div className="input-label">
            <div className="error-div">
              <label htmlFor="password">Password</label>
              {errors?.password && touched.password && (
                <div className="invalid-input-error">{errors?.password}</div>
              )}
            </div>
            <input
              id="password"
              required
              type="text"
              name="password"
              {...getFieldProps("password")}
            />
          </div>
          <div className="input-label">
            <div className="error-div">
              <label htmlFor="port">Port</label>
              {errors?.port && touched.port && (
                <div className="invalid-input-error">{errors?.port}</div>
              )}
            </div>
            <input id="port" required type="text" {...getFieldProps("port")} />
          </div>
          <SubmitTaskButton
            type="submit"
            disabled={
              !isValid ||
              isSubmitting ||
              values.host === "N/A" ||
              values.username === "N/A" ||
              values.password === "N/A" ||
              values.port === "N/A" ||
              !values.host ||
              !values.username ||
              !values.password ||
              !values.port
            }
          >
            Submit
          </SubmitTaskButton>
        </RDPSettingsForm>
      )}
    </Formik>
  );
};

const ConnectedRDPSettings = () => {
  const { userId } = useParams();
  const contextValue = useOutletContext();
  const user = contextValue?.user || {};

  return <RDPSettings user={user} userId={userId} />;
};
export default ConnectedRDPSettings;