import * as React from "react";
import styled, { keyframes } from "styled-components";

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SuccessCircle = styled.div<{ width?: number, height?: number }>`
  width: ${props => props.width || 2.5}rem;
  height: ${props => props.height || 2.5}rem;
  border-radius: 50%;
  border: 4px solid green;
  position: relative;
  margin: 1.5rem;
  animation: ${appear} 0.5s ease forwards;
`;

const StickOne = styled.div<{ width?: number, height?: number }>`
  position: absolute;
  top: 50%;
  left: 60%;
  width: 4.5px;
  height: 30px;
  opacity: 0;
  background-color: green;
  transform: translate(-50%, -50%) rotate(45deg);
  animation: ${appear} 2s ease forwards;
`;

const StickTwo = styled.div<{ width?: number, height?: number }>`
  position: absolute;
  top: 63%;
  left: 27%;
  width: 12px;
  height: 4.5px;
  opacity: 0;
  background-color: green;
  transform: translate(-50%, -50%) rotate(225deg);
  animation: ${appear} 1s ease forwards;
`;

interface ISuccessIconProps {
  width?: number;
  height?: number;
}

export const SuccessIcon = (props: ISuccessIconProps) => (
  <SuccessCircle width={props.width} height={props.height} >
    <StickOne width={props.width} height={props.height} />
    <StickTwo width={props.width} height={props.height} />
  </SuccessCircle>
);
