import styled from "styled-components";

export const MetricsWrapper = styled.div`
  background: #f2f8ff;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
  padding: 2rem;
`;

export const MetricsTableContainer = styled.div`
  height: 18rem;
  overflow-y: auto;
`;

export const MetricsTable = styled.section`
  width: 87rem;
  font-size: 12px;
  align-items: center;
  background: #cce5ff;
  justify-content: space-between;
  box-shadow: 0px -1px 3px 0px #e1e3e5 inset;
  th,
  td {
    text-align: center;
    padding: 13px 13px;
  }
  .type-metrics {
    color: #646570;
    width: 10rem;
  }
  thead tr th:first-child,
  tbody tr td:first-child {
    position: sticky;
    left: 0;
    background: #cce5ff;
    z-index: 1;
  }
  .span-color {
    color: #2853e5;
  }
  tbody tr td {
    border-top: 1px solid #3361ff;
    border-bottom: 1px solid #3361ff;
    text-transform: uppercase;
    background-color: #cce5ff;
  }
  tr:nth-child(even) td:not(:first-child) {
    background-color: #f2f8ff;
  }
  tr:nth-child(odd) td:not(:first-child) {
    background-color: white;
  }
`;
export const CompanyCardWrapper = styled.div`
  width: 88rem;
  height: 18rem;
  flex-shrink: 0;
  display: flex;
  gap: 2rem;
  padding-top: 2rem;
`;
export const CompanyCard = styled.div`
  border-radius: 3px;
  width: 10rem;
  height: 13rem;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 1px 3px 2px 3px #e6ecf3;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .company-card-title {
    font-size: 17px;
    color: #202223;
    text-align: center;
  }
  .company-card-label {
    font-size: 0.9rem;
    font-weight: 300;
  }
  .company-card-value {
    font-size: 1.2rem;
    font-weight: 300;
  }
  .company-card-label-div {
    display: flex;
    justify-content: space-between;
  }
`;
