import * as React from 'react'

import { Switch, SwitchProps } from '@mui/material';
import { BoxBody, BoxBtmTxt, BoxContainer, BoxHeader, BoxInput, BoxTextContainer, BtmRedText, BtmText, CurrencyDetailDiv, CurrencyPageContainer, CurrencyRow, CurrencyRowContainer, LabelContainer, SmallBoxBtmContainer, SmallBoxContainer, SmallBoxHeader, TopRedTxt, TopText } from './styles/CurrencyPageStyles'

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

import { styled } from '@mui/material/styles';
import { ICurrencyResponse, TCurrency, ICurrency } from '../../../api/v1/currency/types/CurrencyTypes';
import { fetchCurrencyConversion, postManualCurrencyConversion } from '../../../api/v1/currency/post';
import { DashboardContext } from '..';
import { da } from '@faker-js/faker';

interface Irates {
    [key: number] :string
}

const CurrencyPage = () => {

    const currencyOptions: TCurrency[] = ['GBP', 'MYR', 'RUB', 'USD']

    const [currencyIn, setCurrencyIn] = React.useState<TCurrency>()
    const [currencyOut, setCurrencyOut] = React.useState<TCurrency>()
    const [amount, setAmount] = React.useState<string>()
    const [currencyData, setCurrencyData] = React.useState<ICurrencyResponse>()
    const [rates,setRates] = React.useState<Irates>({})

    const { currencyRates, setCurrencyRates }: { currencyRates: ICurrency[], setCurrencyRates: React.Dispatch<React.SetStateAction<ICurrency[]>> } = React.useContext(DashboardContext)

    const CustomSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const getCurrencyConversion = async () => {
        try {
            if (currencyIn !== currencyOut) {
                const response = await fetchCurrencyConversion(currencyIn, currencyOut, Number(amount) || 1)
                const data: ICurrencyResponse = response.data
                setCurrencyData(data)
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    React.useEffect(() => {
        if (currencyIn && currencyOut) {
            getCurrencyConversion()
        }
    }, [currencyIn, currencyOut])

    const handleToggle = async (currencyIn:TCurrency, currencyOut: TCurrency) => {
        try {
            const { data }: {
                data:ICurrencyResponse
            } = await fetchCurrencyConversion(currencyIn, currencyOut)
            if (data?.currency) {
            setCurrencyRates(currencyRates =>
                currencyRates.map(item =>
                  item.currency_in === data.currency.currency_in && item.currency_out === data.currency.currency_out
                    ? data.currency
                    : item
                ))
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    const saveCurrencyValue = async (currencyIn:TCurrency, currencyOut: TCurrency, rate:number) => {
        try {
            const response = postManualCurrencyConversion(currencyIn, currencyOut, rate)

        }
        catch (err) {
            console.error(err)
        }
    }

    return <>
        <CurrencyPageContainer>
            <BoxContainer
            >
                <BoxHeader>
                    <p>Real Time Exchange Rate</p>
                </BoxHeader>
                <BoxBody>
                    <SmallBoxContainer>
                        <SmallBoxHeader>
                            <select value={currencyIn} onChange={(e) => setCurrencyIn(e.target.value as TCurrency)} defaultValue={"From"}>
                                <option disabled>From</option>
                                {currencyOptions.map((option, a) => <option value={option} key={a}><p>{option}</p></option>)}
                            </select>
                        </SmallBoxHeader>
                        <SmallBoxBtmContainer>
                            <BoxInput type="text" placeholder="Enter Value" value={amount}
                                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key == "Enter" && currencyIn && currencyOut) {
                                        getCurrencyConversion()
                                    }

                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^0-9.]/g, '');

                                    // Allow only one decimal point
                                    const cleanedValue = numericValue.replace(/(\..*)\./g, '$1');
                                    setAmount(cleanedValue)
                                }} />
                            <BoxBtmTxt>
                                <BoxTextContainer>
                                    <TopText>Current Rate</TopText>
                                    {currencyData ? <BtmText>1 {currencyIn} = {currencyData.currency.exchange_rate.toFixed(4)} {currencyOut}</BtmText> : <BtmText>- -</BtmText>}
                                </BoxTextContainer>

                            </BoxBtmTxt>
                        </SmallBoxBtmContainer>
                    </SmallBoxContainer>
                    <SmallBoxContainer>
                        <SmallBoxHeader>
                            <select value={currencyOut} onChange={(e) => setCurrencyOut(e.target.value as TCurrency)} defaultValue={"To"}>
                                <option disabled >To</option>
                                {currencyOptions.map((option, a) => <option value={option} key={a}><p>{option}</p></option>)}
                            </select>
                        </SmallBoxHeader>
                        <SmallBoxBtmContainer>
                            <BoxInput disabled value={currencyData ? (currencyData?.value.toFixed(2) + ' ' + currencyData?.currency?.currency_out) : "- -"} />
                            <BoxBtmTxt>
                                <BoxTextContainer>
                                    <TopText>Current Rate</TopText>
                                    {currencyData ? <BtmText>1 {currencyOut} = {(1 / currencyData.currency.exchange_rate).toFixed(4)} {currencyIn}</BtmText> : <BtmText>- -</BtmText>}
                                </BoxTextContainer>
                            </BoxBtmTxt>
                        </SmallBoxBtmContainer>
                    </SmallBoxContainer>
                </BoxBody>
            </BoxContainer>
            <BoxContainer>
                <BoxHeader><p>Currencies Table</p></BoxHeader>
                <BoxBody style={{ paddingTop: 0, flexDirection: "column" }}>
                    <LabelContainer>
                        <p>
                            From
                        </p>
                        <p>
                            To
                        </p>
                        <p>
                            Exchange Rate
                        </p>
                        <p>
                            Status
                        </p>
                        <p>
                            Manual Override
                        </p>
                        <p>
                            Last Update
                        </p>
                        <p>
                            Actions
                        </p>
                    </LabelContainer>
                    <CurrencyRowContainer>
                        {currencyRates && currencyRates.map(data => {
                            return <><CurrencyRow >
                                    <td style={{display:"flex", alignItems:"center"}}>
                                    <p style={{
                                        marginBlock: 0,
                                        marginRight: "60%"
                                    }}>{data.currency_in}</p>
                                    <ArrowRightAltIcon />
                                    </td>
                                    <p>{data.currency_out}</p>
                                    <p>{data.exchange_rate.toFixed(3)}</p>
                                
                                <CustomSwitch  onChange={e=>{
                         
                                    handleToggle(data.currency_in as TCurrency, data.currency_out as TCurrency)
                                }}
                                />
                                <input value={rates[data.id]} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^0-9.]/g, '');
                                    const cleanedValue = numericValue.replace(/(\..*)\./g, '$1');
                                    setRates({...rates, [data.id]:cleanedValue})
                                }} />   
                                <p>{new Date(data.created_at).toDateString()}</p>
                                <div onClick={()=> {
                                    saveCurrencyValue(data.currency_in as TCurrency, data.currency_out as TCurrency, Number(rates[data.id]))
                                }}>
                                <CreateOutlinedIcon  />
                                <DeleteForeverTwoToneIcon />
                                </div>
                            </CurrencyRow></>
                        })}
                    </CurrencyRowContainer>
                </BoxBody>
            </BoxContainer>
        </CurrencyPageContainer>
    </>
}

export default CurrencyPage