import * as React from "react";
import {
  ERoute,
  RouteTitle,
} from "../../../../../context/BrowserContext/routes/types";
import { Link } from "react-router-dom";
import {
  StyledProfileNav,
  StyledUPNav,
} from "./styles";
import { User } from "../../../../../context/UserContext";
import { useLocation } from 'react-router-dom';
import HorizontalNavigation from "../../../../../components/HorizontalNavigation";


interface IUserProfileNavProps {
  user: User;
  userId?: string;
  selectedTab?: string;
}

type TRoute = {
  path: string;
  label: string;
  className: string;
}

const routeGenerator = (userId:string|number, tab: string|number):TRoute =>({
    path: `/user-management/user-profile/${userId}/${tab}`, 
    label: RouteTitle[tab], 
    className: tab as string
})

const routesGenerator = (userId:string|number):TRoute[] => {
  const routes = [
    ERoute.permissions,
    ERoute.userModel,
    ERoute.rdpSettings,
  ]
  return routes.map(tab => routeGenerator(userId, tab))

}

const UserProfileNav = (props: IUserProfileNavProps) => {
  const [beforeLeftPosition, setBeforeLeftPosition] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const routesToUse = routesGenerator(props.userId);
  const location = useLocation();

  const getSelectedMenuItem = () => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[pathSegments.length - 1] as ERoute;
  }
  
  const [selectedMenuItem, setSelectedMenuItem] = React.useState<ERoute>(getSelectedMenuItem());

  const handleMenuItemSelection = (selectedTab: ERoute) => {
    setSelectedMenuItem(selectedTab);
  };

  React.useEffect(() => {
    setSelectedMenuItem(getSelectedMenuItem());
  }, [location]);

  return (
    <>
        <StyledProfileNav>
            <nav>
          <StyledUPNav>
            <HorizontalNavigation
              dependencies={[selectedMenuItem]}
              routes={routesToUse}
              currentRoute={selectedMenuItem}
              label="User Profile"
            />
          </StyledUPNav>
        </nav>
      </StyledProfileNav>
    </>
  );
};

export default UserProfileNav;
