import styled from "styled-components";

export const PerformanceCardWrapper = styled.div`
  padding: 2rem;
  background-color: #dddfe7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PerformanceCardHeader = styled.h2`
display: flex;
font-size:2rem;
`
export const NoPerfKPIDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2rem;

`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  border: 1px solid #e1e3e5;
  position: relative;
  border: 1px solid #babfc3;
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;

    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
  th {
    background: white;
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 5px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
    &:first-child {
      z-index: 2;
    }
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }

`;

export const TableContainer = styled.section`
  background-color: #ffffff;
  overflow: hidden;
  height: 530px;
  overflow-y: scroll;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  .table-container {
    overflow: auto;
  }
  .skeleton-div {
    border-radius: 20px;
  }
`;
export const TableHeading = styled.section`
  padding: 1rem;
  display: flex;
  color: #6d7175;
  justify-content: space-between;
  align-items: center;
`;

export const TableFilteringOptions = styled.section`
  border: 1px solid #babfc3;
  flex-basis: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  overflow: visible;
  input {
    flex-basis: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
    min-width: 300px;
  }
`;

export const CreateNewButton = styled.button`
  width: 8rem;
  padding-right: 1.7rem;
  border: none;
  align-items: center;
  height: 1.8rem;
  background-color: #3361ff;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  border-radius: 0.25rem;
  .create-new-text {
    text-align: center;
    font-size: 0.75rem;
    align-self: center;
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export const SearchButton = styled.button`
  width: 5rem;
  height: 1.8rem;
  background-color: #fff;
  border-radius: 0.25rem;
  color: #186ade;
  .search-text {
    text-align: center;
    font-size: 0.75rem;
    align-self: center;
  }
  .disabled {
    background-color:#EBEBE4;
    border: 1px solid black
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const NoReDiv = styled.div`
display: flex;
justify-content: center;
font-size: 2rem;
text-align: center;
padding: 12rem;
`
