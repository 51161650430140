import * as React from 'react';
import ReactiveAccordion from '../ReactActiveAccordion';



const PerformantAccordion = (props) => {
    return (
        <ReactiveAccordion {...props} />
    )
}


export default PerformantAccordion;