import {User} from "../../../context/UserContext";
import auth from "..";

export const createUser = async (user: User): Promise<any> => {
  try {
    const response = await auth.post('/users/', { user : user.toAPIRequest()});
    return response.data.user;
  } catch (error) {
    throw error;
  }
};

// export const createUsers = async (users: User[]): Promise<any> => {
//   const response = await auth.post("/bulk", { users });
//   return response.data;
// };

// export default createUser;
