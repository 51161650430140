import * as React from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  AnimatedText,
  LeadershipBannerText,
  StyledLeadershipBanner,
} from "./styles";
import { getLeadershipNotifications } from "../../../api/v1/alerts/get";



const LeadershipBanner = (): JSX.Element => {
  const [notifications, setNotifications] = React.useState([]);
  const [activeNotificationIndex, setActiveNotification] = React.useState(0);
  const requestRef = React.useRef<boolean>(false);


  React.useEffect(() => {
    const fetchData = async () => {
      if (requestRef.current) return;
      requestRef.current = true;
      const leadershipNotifications = await getLeadershipNotifications();
      setNotifications(leadershipNotifications);
      requestRef.current = false;
    };
    setInterval(fetchData, 10000)
  }, []);


  React.useEffect(() => {
    const interval = setInterval(() => {
      setActiveNotification((prev) => {
        const nextIndex = prev + 1;
        if (nextIndex > notifications.length - 1) {
          return 0;
        }
        return nextIndex
      });
    }, 3000);
    return () => clearInterval(interval);
  }, [notifications]);


  const notification =
    notifications.length > 0
      ? notifications[activeNotificationIndex]
      : { text: "No notifications at this time" };
  return (
    <StyledLeadershipBanner data-testid="leadership-banner">
      <LeadershipBannerText
        key={activeNotificationIndex}
        data-testid="leadership-banner-text"
        className="slide-in"
      >
        <CampaignIcon />
        <AnimatedText>{notification?.text}</AnimatedText>
      </LeadershipBannerText>
    </StyledLeadershipBanner>
  );
};

export default LeadershipBanner;
