import * as React from 'react';
import TableBodyRow from "../../../../History/components/TableBodyRow";
import styled from 'styled-components';

interface ITaskHistoryByAssignment {
 history?: TaskHistory[];
 clickOnMessage: (id: number) => void;
}

const TaskHistoryContainer = styled.div`
  height: 100%;
`;

const TaskHistoryByAssignment = (props:ITaskHistoryByAssignment) => {
const { history, clickOnMessage } = props;
  return (
    <TaskHistoryContainer>
         {history.map((task) => <TableBodyRow key={task?.id} tasks={task} clickOnMessage={clickOnMessage}/>)}   
    </TaskHistoryContainer>
  )
}
export default TaskHistoryByAssignment;