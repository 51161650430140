import * as React from "react";
import { Task } from "../../../../../../api/v1/assignments/types/assignments";
import ActionableTask from "../ActionableTask";

interface IAssignmentTasks {
  tasks?: Task[];
  clickOnMessage: (id: number) => void;
  setTasks: (tasks: Task[]) => void;
  contract: string;
  children?: React.ReactNode;
  selectedQuickView?: string;
}
const AssignmentTasks = (props: IAssignmentTasks) => {
  return (
    <>
      {props?.tasks?.map(
        (task, id) =>
          !task.is_deleted && (
            <ActionableTask
            data-testid="priority"
              key={id}
              task={task}
              clickOnMessage={(id) => props.clickOnMessage(id)}
              setTasks={props.setTasks}
              contract={props.contract}
              selectedQuickView={props.selectedQuickView}
            />
          ),
      )}
    </>
  );
};
export default AssignmentTasks;
