import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { IEmail } from "../../../../api/v1/types";
import EmailBody from "./EmailBody";

interface IEmailAccordionProps {
  email: IEmail;
  attachmentId: string;
  expandAccordion: boolean;
  hideSummary?: boolean;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  handleSendEmail: (email: any) => void;
  emailContent: EditorState;
  handleEmailContent: (content: EditorState, setFieldValue: Function) => void;
  handleFileUpload: (file: File) => Promise<any>;
  emailContentValidation: (values: any) => any;
}

const EmailAccordion = (props: IEmailAccordionProps) => {
  const [expandAccordion, setExpandAccordion] = React.useState<boolean>(false);
  const { hideSummary, email, attachmentId,onClose, handleEmailContent, handleFileUpload, handleSendEmail, emailContent, emailContentValidation } = props;

  const closeAccordion = (event: React.MouseEvent<HTMLElement>) => {
   props.handleExpandAccordion(event);
  };

  return (
    <Accordion expanded={props.expandAccordion} sx={{ boxShadow: "none" }}>
      <AccordionSummary
        style={{ display: hideSummary ? "none" : "" }}
        onClick={(event: React.MouseEvent<Element>) => {
          props.handleExpandAccordion(event);
          event.stopPropagation();
        }}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <AccordionDetails
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            height: "25rem",
            paddingBottom: "0",
            paddingTop: "0",
          }}
        >
          <EmailBody
            email={email}
            attachmentId={attachmentId}
            expandAccordion={expandAccordion}
            handleExpandAccordion={closeAccordion}
            onClose={onClose}
            handleSendEmail={handleSendEmail}
            emailContent={emailContent}
            handleEmailContent={handleEmailContent}
            handleFileUpload={handleFileUpload}
            emailContentValidation={emailContentValidation}
          />
        </AccordionDetails>
      </AccordionSummary>
    </Accordion>
  );
};
export default EmailAccordion;
