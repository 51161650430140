import * as React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

export const Section = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

export const Title = styled.h2`
  margin-top: 0;
`;

export const Description = styled.p`
  color: #555;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  padding: 1rem;
  .subject-section {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: baseline;
  }
  .condition-action {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: baseline;

    .cta {
        position: relative;
        background-color: #f9f9f9;
        button {
            z-index: 2;
            position: relative;
            background-color: #f9f9f9;
        }

    }
    .cta .icon {
        z-index: 1;
        border: 1px solid #ccc;
        position: absolute;
        right: 100%;
        background-color: #f9f9f9;
        padding: 1rem;
        border-radius: 60%;
        transform: translateX(50%);
        z-index: 1;
        > * {
            
            z-index: 4
        }


    }
  }
`;

export const Label = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Input = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const Select = styled.select`
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const ConditionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ConditionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 5px;
  .indicator-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 0.25rem;
    background-color:  #e9e9ed;
    align-items: baseline;
    padding: 0.4rem;
    border-radius: 1rem;
    box-sizing: border-box;
    border: 2px solid #e9e9ed;
    cursor: pointer;
    }
  .indicator {
    box-sizing: border-box;
    box-shadow:
    inset 0 0 0 1px #f7f8fa40,
    inset 0 0 0 2px white;
    display: flex;
    display: block;
    background: #a2d1ff;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #f7f8fa40, 1px solid red;

  }
`;

export const AddConditionButton = styled.button`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #e0e0e0;
  cursor: pointer;
`;

export const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: ${props => (props.active ? '#4caf50' : '#ccc')};
  color: #fff;
  cursor: pointer;
`;

export const ActionIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
`;


export const Table = styled.table`
    th, td {
        padding: 0.4rem 0;
        padding-right: 1rem;
    }
    th.action-section section, td.action-section section {
        display: flex;
        gap: 0.5rem;
        text-align: right;
        justify-content: flex-end;
        button {
            background: none;
            border: none;
            cursor: pointer;
        }
    }
    th.action-section {
        text-align: right;
    }
`;

export const TableHead = styled.thead``;

export const TableHeadColumn = styled.th`
    text-align: left;
`;
export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
`;

export const TableCell = styled.td`
    &.action-section section{
    }
`;


