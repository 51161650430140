import * as React from 'react';
import styled from 'styled-components';
import { Layers } from '../../../../../../../pages/styles/GlobalStyles';

export const DropDown = styled.div<{ position: 'top' | 'bottom', open: boolean }>`
  cursor: pointer;
  overflow: scroll;
  background: none;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #B1B3C5;
  font-size: 14px;
  max-height: 250px;
  box-sizing: border-box;
  visibility: ${props => props.open ? 'visible' : 'hidden'};

`
export const DropDownItem = styled.button`
    z-index:${Layers.Modal};
    cursor:pointer;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow: scroll;
    background: none;
    width: 100%;
    border: none;
    font-size: 0.8rem;
    padding: 1rem;
    text-align: left;
    &:hover {
        background-color: #E7F2FF;
      }
`

