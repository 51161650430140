import * as React from 'react'
import { ICurrencyResponse, TCurrency } from "../../../../../../../api/v1/currency/types/CurrencyTypes"
import { ILegacyAssignment } from "../../../../../../../api/v1/types"
import { InputWithLabel } from "../../../../../../../components/FormElements/Input/Input"
import { CurrencyInformationForm, DurationInformationForm } from "../../../../ReinsuranceTerms/styles"
import Currency from "../../../../../CreateAssignment/components/Risk/components/Currency"
import { fetchCurrencyConversion } from '../../../../../../../api/v1/currency/post'
import styled from 'styled-components'
import AutoCompleteInput from '../../AutocompleteInput'

interface props {
    prefix: any
    insuredSubject: any
    handleSelection: any
    setFieldValue: any
}


const ComponentWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Section = styled.section`
    display: flex;
    justify-content: space-between;
    width: 100%;
    > * {
        flex-basis: 50%;
    }
    gap: 1rem;
`;

const CurrencyInfo = (props) => {
    const {
        prefix,
        reinsuranceTerm,
        setFieldValue,
        errors,
        searchFunctions,
        options
    } = props
    const initalLoad = React.useRef(true)
    const [currencyData, setCurrencyData] = React.useState<string>()

    const getCurrencyConversion = async (left, right) => {
        try {
            if (left && right) {
                const response = await fetchCurrencyConversion(
                    left,
                    right,
                    1)
                const data: ICurrencyResponse = response.data
                setFieldValue(`${prefix}.currency.exchangeRate`, {
                    rate: data?.value,
                    date: data?.date.split("T")[0]
                })
            }
        }
        catch (err) {
            console.error(err)
            setFieldValue(`${prefix}.currency.exchangeRate.rate`, 0)
        }
    }


    const currencyOptions = [
        {
            id: 25,
            label: "Euro (EUR)"
        },
        {
            id: 2,
            label: "U.S. Dollar (USD)"
        },
        {
            id: 3,
            label: "British Pound (GBP)"
        },
        {
            id: 88,
            label: "Malaysian Ringgit (MYR)"
        },
    ]

    const contractCurrencyLabel = reinsuranceTerm?.currency?.contractCurrencyType?.label;
    const paymentCurrencyLabel = reinsuranceTerm?.currency?.paymentCurrencyType?.label;

    const extractCurrencyCode = (label) => {
        if (!label) return '';
        const matches = label.match(/\(([^)]+)\)/);
        return matches ? matches[1] : '';
    };

    const contractCurrencyCode = extractCurrencyCode(contractCurrencyLabel);
    const paymentCurrencyCode = extractCurrencyCode(paymentCurrencyLabel);


    React.useEffect(() => {
        if (initalLoad?.current) {
            initalLoad.current = false
            return
        }
        getCurrencyConversion(
            contractCurrencyCode,
            paymentCurrencyCode
        ).catch((err) => console.error(err))
    }, [reinsuranceTerm?.currency?.contractCurrencyType, reinsuranceTerm?.currency?.paymentCurrencyType])

    return <>
        <ComponentWrapper>
            {/* {currencyData && <p>{currencyData}</p>} */}
            <Section>
                <AutoCompleteInput
                    className="contract"
                    label="Contract"
                    name={`${prefix}.currency.contractCurrencyType`}
                    value={reinsuranceTerm?.currency?.contractCurrencyType}
                    options={options?.[`currency`]}
                    onSearch={searchFunctions?.searchCurrency}
                    setFieldValue={setFieldValue}
                    testId={`${prefix}.currency.contractCurrencyType`}
                    error={errors?.currency?.contractCurrencyType}
                />
                <AutoCompleteInput
                    label="Payments"
                    name={`${prefix}.currency.paymentCurrencyType`}
                    value={reinsuranceTerm?.currency?.paymentCurrencyType}
                    options={currencyOptions}
                    onSearch={() => { }}
                    setFieldValue={setFieldValue}
                    testId={`${prefix}.currency.paymentCurrencyType`}
                    error={errors?.currency?.paymentCurrencyType}
                />

            </Section>
        </ComponentWrapper>
    </>
}

export default CurrencyInfo