import * as React from 'react'
import styled from 'styled-components'
import AutoCompleteInput from './compositeFields/AutoComplete'
import { InputWithLabel } from './compositeFields/DefaultInput'


const Components: { [key: string]: React.FC } = {
    autocomplete: AutoCompleteInput,
    input: InputWithLabel,
    'datetime-local': InputWithLabel,
    'date': InputWithLabel,
    'text': InputWithLabel,
    'number': InputWithLabel
}

const SectionStyling = styled.section`
  display: flex;
  scroll-behavior: smooth;
  gap: 1rem;
`;

interface IFieldProps {
    field: {
        label: string
        name: string
        type: string
        howToSearch: any
        canConfigure: boolean
    }
    search: (filters: any, optionName: string) => void
    values: any
    errors: any
    handleSelection: (name: string, value: any) => void
    _handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    options: any

}


const Field = (props) => {
    const {
        label,
        name,
        howToSearch,
        type,
        search,
        value,
        values,
        errors,
        onSelect,
        onChange,
        options,
        canConfigure,
        dependentOn,
        className,
        setFieldValue,
        testId,
    } = props

    const dependencies = dependentOn && dependentOn.map((dep) => values[dep])

    React.useEffect(() => {
        setFieldValue(name, null)
    }, [...(dependencies || [])])
    return (
        <FieldDirect
            className={className}
            options={options}
            label={label}
            type={type}
            name={name}
            value={value}
            values={values}
            onSelect={onSelect}
            howToSearch={howToSearch}
            onChange={onChange}
            search={search}
            errors={errors?.[name]}
            testId={testId}
        />
    )
}


const FieldDirect = (props: IFieldProps) => {
    const ComponentToUse = props && props?.type && Components[props?.type]
    return (
        <ComponentToUse {...props} />
    )
}


export default Field