import {api} from "../index";
import { IAssignmentComment } from "../types";

export const markAsRead = async (taskId: number): Promise<IAssignmentComment[]> => {
  const task_id = taskId;
  try{
    const response = await api.put(`/comments/tasks/${task_id}`);
    return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }