import styled from 'styled-components';
import * as React from 'react';

interface IFormGroup {
  title: string;
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 1px;
  position: relative;
  padding: 30px;
  box-sizing: border-box;
`;

const Title = styled.div`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7f8fa;
  padding: 0 5px;
`;

export default function FormGroup(props: IFormGroup) {
  return (
    <Container className={props.className}>
      <Title>{props.title}</Title>
      {props.children}
    </Container>
  );
}
