import styled from "styled-components";

export const UserProfileWrapper = styled.div`
  display: flex;
`;

export const StyledProfileNav = styled.div`
  display: flex;
  padding-top: 2rem;
  align-items: flex-start;
  gap: 1.2rem;
  font-size: 1.4rem;

`;

export const StyledContent = styled.div`
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 55rem;

  .close {
    grid-area: close;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
  }
  flex-grow: 1;
`;

export const Menu = styled.div`
  background: #e8e7e7;
  grid-area: menu;
`;

export const Main = styled.div`
  padding: 2.3rem;
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const StyledUPNav = styled.div<{
  width?: number;
  left?: number;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
  a {
    border-radius: 1rem 1rem 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover, &.active {
      border-bottom: 2px solid #5b5fc7;
    }
  }

`;

export const UserProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  padding: 0.2rem 0.5rem 0.2rem 0.2rem;
  background: var(--surface-default, #fff);
  .profile-nav {
    display: flex;
    flex-direction: column;
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
  }

  .username-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.2rem;
  }

  input[type="checkbox"] {
    display: none;
  }

  .toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem;
    align-items: center;

    .username-toggle {
      font-size: 0.75rem;
      color: #6d7175;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 67px;
      height: 34px;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #b1b3c5;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #48d056;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(33px);
    }

    .slider.round {
      border-radius: 6.25rem;
    }

    .span-text {
      position: absolute;
      top: 50%;
      left: 70%;
      transform: translate(-50%, -50%);
      color: white;
      transition: 0.4s;
      font-size: 0.5rem;
      text-transform: uppercase;
    }

    .span-text.active {
      left: 50%;
      transform: translate(-100%, -50%);
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  .back-button {
    cursor: pointer;
    padding-right: 0.2rem;
    display: flex;
    flex-direction: row;
    color: #000;
    gap: 0.5rem;
    border: 1px solid #000;
    border-radius: 0.25rem;
    padding: 0.2rem 3.5rem 0.2rem 3.5rem;
    align-items: center;
    fill: #fafbfb;
    stroke-width: 1px;
    stroke: #202223;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
`;
