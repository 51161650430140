import * as React from "react";
import { TaskHistoryButton } from "../styles";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Task } from "../../../../../../api/v1/assignments/types/assignments";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { useParams } from "react-router-dom";
import { getHistory } from "../../../../../../api/v1/tasks/history";
import TaskHistoryByAssignment from "./TaskHistoryByAssignment";
import AssignmentTasks from "./AssignmentTasks";
import NoTaskHistory from "./NoTaskHistory";
import NoTask from "./NoTask";
import styled from "styled-components";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  * {
  height:unset !important;
}
  `

interface IRenderedTask {
  tasks?: Task[];
  children?: React.ReactNode;
  clickOnMessage: (id: number) => void;
  setTasks: (tasks: Task[]) => void;
  contract: string;
  taskHistory?: TaskHistory[];
  titleChange?: () => void;
  displayHistory?: boolean;
  assignmentId?: string;
  selectedQuickView?: string;
}

const RenderedTask = (props: IRenderedTask) => {
  const [history, setHistory] = React.useState<TaskHistory[]>([]);
  const { displayHistory, clickOnMessage, setTasks, contract, tasks } = props;

  const getTasksFromApi = async () => {
    const result = await getHistory(props?.assignmentId);
    setHistory(result);
  };

  const handleTaskHistory = async (e) => {
    e.preventDefault()
    getTasksFromApi().then(() => {
      props.titleChange();
    });
  };

  React.useEffect(() => { }, [displayHistory]);

  const displayHistoryComponent = history.length === 0 ? (
    <NoTaskHistory />
  ) : (
    <TaskHistoryByAssignment
      history={history}
      clickOnMessage={clickOnMessage}
    />
  );

  const displayTaskComponent = tasks?.length === 0 ? (
    <NoTask />
  ) : (
    <AssignmentTasks
      tasks={tasks}
      clickOnMessage={clickOnMessage}
      setTasks={setTasks}
      contract={contract}
      selectedQuickView={props?.selectedQuickView}
    />
  );

  return (
    <StyledSection>
      {displayHistory ? (
        displayHistoryComponent
      ) : (
        displayTaskComponent
      )}
      <div className="hide-on-print">
        <TaskHistoryButton onClick={handleTaskHistory}>
          {displayHistory ? (
            <div className="arrow-switch">
              Tasks
              <KeyboardDoubleArrowLeftIcon
                style={{ fontWeight: "lighter", color: "black" }}
              />
            </div>
          ) : (
            <div className="arrow-switch">
              Tasks History
              <KeyboardDoubleArrowRightIcon
                style={{ fontWeight: "lighter", color: "black" }}
              />
            </div>
          )}
        </TaskHistoryButton>
      </div>
    </StyledSection>
  );
};

export default RenderedTask;
