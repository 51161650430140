import * as React from 'react';
import {
    PriorityAreaAsideContainer,
    PriorityAreaCardContainer,
  } from "../styles";
import { Box, Skeleton } from "@mui/material";

const PriorityAreaSkeleton = () => {
    return (
        <PriorityAreaAsideContainer data-testid='priority-container-skeleton'>
        <Skeleton variant="text" height="29px" width="50%" animation="wave" />
  
        <PriorityAreaCardContainer>
          {Array.from({ length: 6 }).map((_, index) => (
            <>
              
                <Skeleton key={index} variant="rectangular" height={150} animation="wave" />
              
            </>
          ))}
  
        </PriorityAreaCardContainer>
      </PriorityAreaAsideContainer>
    )
}

export default PriorityAreaSkeleton;