import * as React from "react";
import StatusColumn from "../StatusColumn";
import {
  IAssignment,
  ILegacyAssignment,
} from "../../../../../../api/v1/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover, { PopoverContent, PopoverTrigger } from "../../../../../../components/PopOver";
// import AssignmentOptions from "./components/MenuPopover";
import AlertsPopover from "./components/AlertsPopover";
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import AssignmentStructure from "../../../../../../api/v1/assignments/classes/assignment";
import ExtendedModal from "../../../../../../components/ExtendedModal/ExtendedModal";
import asModal from "../../../../../../hoc/asModal";
import styled from "styled-components";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";
import { deleteAssignment } from "../../../../../../api/v1/assignments/delete";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../../../..";
import { AssignmentOptions } from "../../../../../../components/AssignmentOptions";

interface ITableBodyRowProps {
  markAsComplete: (
    assignmentId?: string | number,
  ) => () => Promise<(IAssignment & ILegacyAssignment)[]>;
  escalateAssignment: (
    assignmentId?: string | number,
  ) => () => Promise<(IAssignment & ILegacyAssignment)[]>;
  viewAssignment: (assignmentId?: string | number) => () => void;
  viewLegacyAssignments: (assignmentId?: string | number) => () => void;
  rowClicked: () => void;
  handlePriorityMarking: (assignmentId?: string | number) => void;
  assignment: AssignmentStructure;
  // fetchAssignments?: () => void;
  setPriorities?: (assignments: (IAssignment & ILegacyAssignment)[]) => void;
  setFilterApplied: (filterApplied: boolean) => void;
  setSearchParams: (searchParams: { property: string; keyword: string }) => void;
  fetchAssignments: () => void;
}

type Priorities = "low" | "high" | "medium";

const ConfirmDeletion = asModal(ConfirmationModal, '#modal-root');
const StyledConfirmDeletion = styled(ConfirmDeletion)`
z-index: 1000;
  > * {
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
    }
  }
`;

const CreateExtendedModal = asModal(ExtendedModal, "#modal-root");
const StyledCreateExtendedModal = styled(CreateExtendedModal)`
position: absolute;
width: 100%;
height: 100%;
z-index: 10000;
display: flex;
justify-content: center;
align-items: center;
> * {
    padding: 1rem;
    font-size: 1.2rem;
    font-family: "Lato", sans-serif;
  }
`;

const TableBodyRow = (props: ITableBodyRowProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { displayAssignment, assignment, className, setFilterApplied } = props;
  const [openExtendedModal, setOpenExtendedModal] = React.useState<boolean>(false);
  const [markAsPriority, setMarkAsPriority] = React.useState<boolean>(false);
  const [openDeleteModal, setDeleteModal] = React.useState<boolean>(false);

  const calculatesAging = () => {
    const inceptionDate = new Date(
      props?.assignment?.createDate
    ).getTime();
    const today = new Date().getTime();
    const diffTime = today - inceptionDate;
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
  };

  const navigateToAssignment = (): void => {
    if (!openExtendedModal) {
      setFilterApplied(false);
      assignment?.id && displayAssignment(props?.assignment?.id);
    }
  }

  const amountOfDaysContractHasAge = calculatesAging();


  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  }


  return (
    <>

      <tr onDoubleClick={navigateToAssignment} className={className} style={{ backgroundColor: props?.assignment?.isLate ? "#FEECEC" : "" }}>
        <td>
          <StatusColumn
            status={props?.assignment?.statusType || "N/A"}
          />
        </td>
        <td>
          {props?.assignment?.assignmentType || "N/A"}
        </td>
        {props.assignment?.unseenComments > 0 || props.assignment?.isLate ? (
          <td>
            <PopoverAlerts
              assignment={props?.assignment}
            >
            </PopoverAlerts>
          </td>
        ) : (
          <td></td>
        )}
        <td>{props?.assignment?.contractNo || "N/A"}</td>
        <td>
          {props?.assignment?.brokerId || "N/A"}
        </td>
        <td>{props?.assignment?.insurerId || "N/A"}</td>
        {/* cedent */}
        <td>{props?.assignment?.cedentId || "N/A"}</td>
        <td>{props?.assignment?.contractType || "N/A"}</td>
        {/* inception date */}
        <td>{new Date(props?.assignment?.createDate).toLocaleDateString("en-GB") || "N/A"}</td>
        {/* aging */}
        <td>{Number.isNaN(amountOfDaysContractHasAge) || amountOfDaysContractHasAge < 0 ? 0 : amountOfDaysContractHasAge}</td>
        {/* options */}
        <td>{props?.assignment?.underwriter || "N/A"}</td>
        <td>
          <AssignmentOptions
            assignment={props?.assignment}
            navigateToAssignment={navigateToAssignment}
            setMarkAsPriority={setMarkAsPriority}
            markAsComplete={props.markAsComplete}
            escalateAssignment={props.escalateAssignment}
            setPriorities={props.setPriorities}
            handleClickEvent={props.rowClicked}
            handleOpenDeleteModal={handleOpenDeleteModal}
            setOpenExtendedModal={setOpenExtendedModal}
            openExtendedModal={openExtendedModal}
            setSearchParams={props.setSearchParams}
            fetchAssignments={props.fetchAssignments}
          />
        </td>
      </tr >
    </>
  );
};

const PopoverAlerts = (props) => {
  const { assignment } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const anchorElement = React.useRef<HTMLDivElement>(null);
  const togglePopover = (): void => { setIsOpen(!isOpen) };
  return (
    <section
      onClick={togglePopover}
    >
      <PopoverTrigger ref={anchorElement}><GppMaybeOutlinedIcon style={{ color: 'red' }} /></PopoverTrigger>
      <Popover
        isOpen={isOpen}
        anchorEl={anchorElement?.current}
      >
        <PopoverContent>
          <div className="caret"></div>
        </PopoverContent>
        <AlertsPopover
          assignment={assignment}
        />
      </Popover>
    </section>
  )
}

Popover
export default TableBodyRow;
