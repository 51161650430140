import * as React from "react";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import AssignmentLateOutlinedIcon from "@mui/icons-material/AssignmentLateOutlined";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import PeopleIcon from '@mui/icons-material/People';
import { LinkContainer } from "./styles/links";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import usePermissionsLifeCycle, { EServiceNames } from "../../../hooks/useUsersPermissions";
import InfoTip from "../../../components/Tooltip";
import ListIcon from '@mui/icons-material/List';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import styled from "styled-components";
import { ApplicationContext } from "../../Root";
import { NotificationAddOutlined, PagesOutlined } from "@mui/icons-material";


export const links: ILinks[] = [
  // {
  //   name: "Dashboard",
  //   icon: HomeRoundedIcon,
  //   url: "/",
  //   toolTip: "Dashboard",
  // },
  {
    name: "Assignments",
    icon: ListIcon,
    url: "/assignments?contract_type=0",
    toolTip: "Assignments",
    children: [
      {
        name: "All",
        url: "/assignments?contract_type=0",
        toolTip: "All Assignments",
      },
      {
        name: "Proposals",
        url: "/assignments?contract_type=4",
        toolTip: "Proposals",
      },
      {
        name: "Fac Inward",
        url: "/assignments?contract_type=1",
        toolTip: "Fac Inward",
      },
      {
        name: "Treaty Inward",
        url: "/assignments?contract_type=2",
        toolTip: "Treaty Inward",
      },
      {
        name: "Declined",
        url: "/assignments?contract_type=5",
        toolTip: "Declined",
      }
    ]
  },
  {
    name: "Today's Priorities",
    icon: AssignmentLateOutlinedIcon,
    url: "/priorities",
    toolTip: "Today's Priorities",
  },
  {
    name: "Documents",
    icon: FolderOpenOutlinedIcon,
    url: "/documents",
    toolTip: "Documents",
  },
  // {
  //   name: "Metrics",
  //   icon: BarChartIcon,
  //   url: "/metrics/company-activity",
  // toolTip: "Metrics",
  // },
  {
    name: "Alerts",
    icon: NotificationImportantIcon,
    url: "/alerts",
    toolTip: "Alerts",
  },
  {
    name: "History",
    icon: StickyNote2OutlinedIcon,
    url: "/history",
    toolTip: "History",
  },
  {
    name: "Underwriting Policies",
    icon: DescriptionOutlinedIcon,
    url: "/underwriting-policies",
    toolTip: "Underwriting Policies",
  },
  {
    name: "User Management",
    icon: PeopleIcon,
    url: "/user-management",
    toolTip: "User Management",
  },
  {
    name: "Broadcast Message",
    icon: CampaignOutlinedIcon,
    url: "/broadcast-message",
    toolTip: "Broadcast Message",
  },

  {
    name: "Create New",
    icon: AddIcon,
    url: "/create-assignment",
    toolTip: "Create New",
  },
  // {
  //   name: "Exchange Rate",
  //   icon: SwapHorizIcon,
  //   url: "/currency-table",
  // },

  // // {
  // //   name: "Renewals and Endorsements",
  // //   icon: ArticleIcon,
  // //   url: "/renewals-endorsements",
  // // },
];

export interface ILinks {
  name: string;
  icon?: string | React.FC;
  url: string;
  toolTip?: string;
  children?: ILinks[]
}

export interface INavigationProps {
  links: ILinks[];
  navigationIsHidden?: boolean;
}

const AssignmentIconWrapper = styled.span`
align-content: center;
  display: flex;
`;

const AssignmentText = styled.span`
  user-select: none;
  pointer-events: none;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    opacity: 0.8;
  }
`;

const StyledVersionContainer = styled.div<{ expandChildren?: boolean }>`
  position: ${props => props.expandChildren ? "" : "absolute"};
  bottom: 0px;
  padding: 1rem;
  opacity: 0.5;
  font-size: 0.8rem;
`
const StyledSubMenu = styled.div`
border-radius: var(--4, 4px);
border: 2px solid #202223;
color: #202223;
padding: 0.3rem;
font-weight: 800;
font-size: 1rem;
&:hover {
  border:transparent;
  border-left-color: #7886a2;
}
`;

const FirstLetterIcon = ({ children }) => {
  return (
    <StyledSubMenu>
      {children.charAt(0).toUpperCase()}
    </StyledSubMenu>
  );
};


const DashboardNavigation = (props: INavigationProps) => {
  const { navigationIsHidden } = props;
  const { user, isEndPointDenied } = React.useContext(UserContext);
  const { version } = React.useContext(ApplicationContext)
  const { isReadDenied } = usePermissionsLifeCycle(user);
  const [expandChildren, setChildrenExpanded] = React.useState(false);
  const routes = props.links;
  const filteredRoutes = routes.filter((link) => {
    const isDenied = !isReadDenied(EServiceNames.underwriting_dashboard, link.url)
    return isDenied;
  })

  const handleViewAssignmentType = (event: React.MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
    setChildrenExpanded(!expandChildren);
  }

  return (
    <>
      {filteredRoutes.map((link, index) => {
        const icon = typeof link.icon === "string" ? link.icon : <link.icon />;
        return (
          <>
            {navigationIsHidden ? (
              <InfoTip key={`infoTio${index}`} title={link.toolTip} placement="right">
                <LinkContainer key={`${link.url}-${index}`} className="link-label" to={link.url} navigationIsHidden={navigationIsHidden}>
                  {icon}
                  <span>{link.name}</span>
                  {link.name === "Assignments" && (
                    <>
                      {expandChildren ?
                        <ArrowDropDownIcon onClick={(e) => { handleViewAssignmentType(e); }}/>
                        :
                        <ArrowRightIcon onClick={(e) => {handleViewAssignmentType(e); }} />
                      }
                    </>
                  )}
                </LinkContainer>
              </InfoTip>
            ) : (
              <LinkContainer key={`${link.url}-${index}`} className="link-label" to={link.url}>
                {icon}
                {link.name === "Assignments" && (
                <AssignmentIconWrapper>
                  <AssignmentText>{link.name}</AssignmentText>
                  <IconWrapper onClick={handleViewAssignmentType}> 
                    {expandChildren ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                  </IconWrapper>
                </AssignmentIconWrapper>
              )}
                {link.name !== "Assignments" && (
                  <>
                    <span data-testid="nav-links">{link.name}</span>
                  </>
                )}
              </LinkContainer>
            )}
            <NavigationChildren index={index} key={`children${index}`} link={link} expandChildren={expandChildren} navigationIsHidden={navigationIsHidden} />
          </>
        );
      }
      )}
      <StyledVersionContainer expandChildren={expandChildren}>
        {`${version}`}
      </StyledVersionContainer>
    </>
  );
}


interface INavigationChildrenProps {
  link: ILinks;
  expandChildren: boolean;
  navigationIsHidden: boolean;
}
const NavigationChildren = (props: INavigationChildrenProps): JSX.Element => {
  const { link, expandChildren,navigationIsHidden } = props;

  return link.children && expandChildren && (
    <>
      {link.children?.map((childLink, childIndex) => (
        <NavigationChild key={`${childLink.url}-${childIndex}`} childLink={childLink} childIndex={childIndex} navigationIsHidden={navigationIsHidden} />
      ))}
    </>)
}


interface INavigationChildProps {
  childLink: ILinks;
  childIndex: number;
  navigationIsHidden: boolean;

}
const NavigationChild = (props: INavigationChildProps) : JSX.Element=> {
  const { childLink, childIndex, navigationIsHidden } = props;

  return (
    <InfoTip title={childLink.toolTip} placement="right">
      <LinkContainer key={`${childLink.url}-${childIndex}`} className="link-label child" to={childLink.url}>
        {navigationIsHidden ? (
          <FirstLetterIcon>{childLink.name}</FirstLetterIcon>
        ) : (
          <span>{childLink.name}</span>
        )}
      </LinkContainer>
    </InfoTip>
  )


};
const ConnectedDashboardNavigation = (props: INavigationProps) => {
  return <DashboardNavigation {...props} />;
};

export default ConnectedDashboardNavigation;
