import * as React from "react";
import {
  TAssignments,
  Task,
} from "../../../api/v1/assignments/types/assignments";
// import { getMockedTasks } from "../../../api/v1/__mocks__/tasks";
import { getHistory } from "../../../api/v1/tasks/history";
import { DashboardContext } from "..";
import HistorySkeleton from "./components/HistorySkeleton";
import RenderedHistory from "./components/RenderedHistory";
import NoHistory from "./components/NoHistory";

export interface IHistoryProps {
  clickOnMessage: () => void;
}

const History = (props: IHistoryProps) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [tasks, setTasks] = React.useState<Task[]>([]);

  React.useEffect(() => {
    getHistory().then((res) => {
      setTasks(res);
    }).finally(() => {
      setIsLoading(false);
    }) 
  }, []);
 

  const HistoryView = !(tasks?.length) ? <NoHistory/> : <RenderedHistory tasks={tasks} clickOnMessage={props?.clickOnMessage}/>
  const HistoryViewToUse = isLoading ? <HistorySkeleton/> : (HistoryView);

  return (
    <>
    {HistoryViewToUse}
    </>

  );
};

const ConnectHistory = () => {
  const { clickOnMessage } = React.useContext(DashboardContext);

  return <History clickOnMessage={clickOnMessage} />;
};

export default ConnectHistory;
