import {auth} from '../index';
import { User } from "../../../context/UserContext";


export const getUser = async (): Promise<any> => {
    const response = await auth.get(`/users/`);
    return response.data;
}

export const getUserHierarchy = async () => {
  try {
    return await auth.get('/users/hierarchy')
  }catch(err){
    console.error(err)
  }
}


export default getUser;

export const getUserById = async (userId: number): Promise<User> => {
    try {
      const response = await auth.get(`/users/${userId}`);
      return response.data.user;
    } catch (error) {
      console.error(`Error getting user by ID ${userId}:`, error);
      throw error;
    }
  };
  

export const getUsers = async (): Promise<User[]> => {
    const response = await auth.get(`/users/all`);
    return response.data;
}