import * as React from "react";
import {
  AddUserButton,
  Table,
  TableContainer,
  TableFilteringOptions,
  TableHeading,
} from "../styles";
import AddIcon from "@mui/icons-material/Add";
import UsersRow from "./UsersRow";
import { IUser } from "../../../../api/v1/users/types/user";
import SearchIcon from "@mui/icons-material/Search";
import UsersHeader from "./UsersHeader";
import { DashboardContext } from "../..";
import { User } from "../../../../context/UserContext";
import DepartmentPermissions from "./DeparmentPermissions";
import ConnectedUserTable from "../UserTable";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BackButton } from "../../Documents/styles";
import ReplyIcon from "@mui/icons-material/Reply";
import DepartmentNav from "./DepartmentsNav";
import { useNavigate, Outlet, useOutletContext, useParams } from "react-router-dom";
import { StyledContent, UserProfileHeader } from "./UserProfile/styles";
import asModal from "../../../../hoc/asModal";
import Modal from "./DepartmentsModal";
import { IDepartment } from "../../../../api/v1/types";
import { updateDepartments } from "../../../../api/v1/organizationHierarchy/put";

interface IDepartmentsProps {
  users: User[];
  department: IDepartment;
  permissionState: {};
  setPermissionState: React.Dispatch<React.SetStateAction<{}>>;
}

const DepartmentsModal = asModal(Modal, "#departments-page");
const Departments = (props: IDepartmentsProps) => {
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const departmentNav = useNavigate();

  const { users, permissionState, setPermissionState, deletePathRule, editUser, addUser, department, departmentId } = props;

  const handleBackButton = () => departmentNav("/user-management/departments");


  return (
    <>
      <div className="profile-nav">
        <UserProfileHeader>
          <h3>
            {department?.name}
            {/* <ArrowDropDownIcon onClick={() => setIsOpen(true)} /> */}
          </h3>
          <DepartmentsModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
          <div className="back-button" onClick={handleBackButton}>
            <ReplyIcon />
            Back
          </div>
        </UserProfileHeader>
        <DepartmentNav />
      </div>
      <StyledContent>
        <Outlet context={{ ...props }} />
      </StyledContent>

    </>
  );
};


const ConnectedDepartments = () => {
  const { departmentId } = useParams();
  const contextValue = useOutletContext();
  const departments = contextValue?.departments || {};
  const users = contextValue?.users || {};
  const department: IDepartment = (departments && departments[departmentId]) || null;

  const setPermissionState = async (permissionValue) => {
    try {
      if (!department?.authority_level) department.authority_level = {
        services: {}
      };
      department.authority_level.services = permissionValue;
      const departmentData = await updateDepartments(department);
      const newDepartments = { ...departments };
      newDepartments[departmentData.id] = departmentData;
      contextValue?.setDepartments(newDepartments);
    } catch (error) {
      console.error("Error updating department:", error);
    }
  };

  const deletePathRule = (service: string, pathName: string) => {
    const clonedState: { [key: string]: any } = {
      ...department?.authority_level?.services,
    };

    delete clonedState[service][pathName];
    // if service is empty, delete it
    if (Object.keys(clonedState[service]).length === 0) {
      delete clonedState[service];
    }
    setPermissionState(clonedState);
  }

  return <Departments 
      {...contextValue} 
      department={department} 
      departmentId={departmentId} 
      setPermissionState={setPermissionState} 
      permissionState={department?.authority_level?.services || {}} 
      deletePathRule={deletePathRule} 
    />;
};
export default ConnectedDepartments;