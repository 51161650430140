import * as React from "react";
import {
  CreateNewButton,
  SearchButton,
  SearchWrapper,
  Table,
  TableContainer,
  TableFilteringOptions,
  TableHeading,
} from "../styles";
import SearchIcon from "@mui/icons-material/Search";
import TableHeader from "../../AssignmentTable/components/TableRowHeader";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import TableBodyRow from "../../AssignmentTable/components/TableBody/TableBodyRow";




const REView = () => {
    const [selectedColumn, setSelectedColumn] = React.useState(null);
    const [RenewalsAndEndorsements, setRenewalsAndEndorsements] = React.useState([]);
    const [sortOrder, setSortOrder] = React.useState(null);
    const setSortedColumn = (column: ESelectedColumn) => {
        setSelectedColumn(column);
        setSelectedColumn((prev) => {
          if (prev === column) {
            return prev;
          }
        })
    }
    return (
        <TableContainer data-testid="re-table-container">
        <TableHeading>
          <h3>Renewals and Endorsements</h3>
          <CreateNewButton data-testid="create-new-button">
            <AddIcon />
            <Link className="create-new-text" to="/add-new">
              Create New
            </Link>
          </CreateNewButton>
          <SearchWrapper>
            <TableFilteringOptions>
              <SearchIcon />
              <input data-testid="re-filter-input" type="text" placeholder="Enter New Contract No." />
            </TableFilteringOptions>
            <SearchButton data-testid="search-button" onClick={() => {}}>
              <div className="search-text">Search</div>
            </SearchButton>
          </SearchWrapper>
        </TableHeading>
        <section className="table-container">
          <Table>
            <TableHeader
              data-testid="table-header"
              selectedColumn={selectedColumn}
              sortOrder={sortOrder}
              setSortedColumn={setSortedColumn}
            />
            <tbody>
              {RenewalsAndEndorsements?.map((assignment, key) => (
                <TableBodyRow assignment={assignment}/>
              ))}
 
            </tbody>
          </Table>
        </section>
      </TableContainer>
    );
              };
export default REView;
