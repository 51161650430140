import * as React from "react";
import styled from "styled-components";
import Dashboard from "..";
import Button from "../../../components/FormElements/Button";

export const DashboardMain = styled.div`
  grid-area: main;
  padding-left: 3rem;
  padding-right: 1rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
`;

export const DocusignButton = styled(Button)`
  padding: 0.5rem;
  border: #186ade;
  border-style: solid;
  border-radius: 0.2rem;
  border-width: medium;
  font-size: 1.25rem;
  color: #186ade;
  margin: 1rem 0;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  background: white;
`;

export const DashboardHeader = styled.header`
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  grid-area: header;
  border-bottom: 2px solid #f7f8fa;
`;

export const DashboardHelp = styled.section`
  display: flex;
  font-family: "Lato", sans-serif;
  align-items: center;
  justify-content: flex-start;
  padding: 2.8rem 0.8rem;
  gap: 0.6rem;
`;

export const DashboardContainer = styled.div<{ asideIsHidden: boolean, mainNavigationIsHidden: boolean }>`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  a {
    color: black;
  }
  button.save {
    min-width: 100px;
    color: white;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid #0f90c7;
    background: #58a0bf;
  }
  font-family: 'Open Sans', sans-serif;
  > * {
    font-family: 'Open Sans', sans-serif;
  }
  /* overflow-y: scroll; */
  background-color: #f7f8fa;
  max-width: 100%;
  display: grid;
  grid-template-columns: minmax(auto, 8rem) repeat(11, 1fr);
  grid-template-rows: 7vh minmax(auto, 93vh);
  grid-template-areas:
    "header header header header header header header header header header header header"
    "main main main main main main main main main main main main";
  main {
    width: 100%;
    grid-area: main;
    display: grid;
    grid-template-columns: 2fr repeat(8, 1fr) 2fr;
    grid-template-rows: 1fr;
    grid-template-areas: "navigation main main main main main main main main aside";
    transition: all 0.5s ease-in-out;
    .toggle-view {
      cursor: pointer;
      max-width: 32px;
      border: none;
      background: none;
      border-radius: 1rem;
      color: #186ade;
    }

    ${(props) => {
    if (props?.asideIsHidden || props?.mainNavigationIsHidden) {
      const asideSize = props?.asideIsHidden ? "0.1fr" : "2fr";
      const navigationSize = props?.mainNavigationIsHidden ? "0.1fr" : "2fr";
      const toggleView = props?.asideIsHidden ? "rotate(180deg)" : "rotate(0deg)";
      return `
            grid-template-columns: ${navigationSize} repeat(8, 1fr) ${asideSize};
            .toggle-view{
              transform: ${toggleView};
            }
            ${props?.mainNavigationIsHidden && `
              .link-label span,.help-label {
                display: none;
              }
            `}
          `
    }
  }}
    .toggle-container {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid;
      padding: 1rem 0;
      font-size: 1rem;
      button {
        background: none;
        border: none;
        color: #186ade;
        cursor: pointer;
      }
      &.aside {
        justify-content: flex-start;
      }
      ${props => props.mainNavigationIsHidden && `
        & .nav button{
            transform: rotate(180deg);
            justify-content: center;
        }
      `}
      ${props => props.asideIsHidden && `
        & .aside button{
            transform: rotate(180deg);
            justify-content: center;
        }
      `}
    }
  }

`;

export const DashboardNavigation = styled.div<{ navigationIsHidden: boolean }>`
  font-size: 1.2rem;
  grid-area: navigation;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  min-width: 5.5rem;
  ${DashboardHelp} {
    border-top: 1px solid gray;
  }
  transition: all 0.5s ease-in-out;
  .toggle-container {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid;
    padding: 1rem 0;
    button {
      background: none;
      border: none;
      color: #186ade;
      font-size: 1.5rem;
      cursor: pointer;
    }
    ${props => props.navigationIsHidden && `
     button{
        transform: rotate(180deg);
     }
     justify-content: center;
     
      `}
  }
  .navigation-dashboard{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 480px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 75vw;
    height: 100%;
    border-right: none; /* Remove the right border */
    border-bottom: 1px solid #e0e0e0; /* Optional: Add a bottom border instead */'
    background-color: white;
    width: 75vw;
    height: 100%;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
    background: #dddfe7;
  }

`;

export const DashboardGlobalActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  .email-icon-container {
    position: relative;
  }

  .email-icon {
    width: 54px;
    height: 47px;
    color: #7886a2;
  }

  .notification-dot {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #3361ff;
  }

  .settings-icon {
    width: 38.346px;
    height: 40px;
    color: #7886a2;
    cursor: pointer;
  }
`;

export const DashboardAvatar = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  color: white;
  border: 1px solid blue;
  cursor: pointer;
`;

export const DashboardAlertBody = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .alert-count {
    position: absolute;
    top: -5px;
    height: 15px;
    left: 50%;
    width: 15px;
    border-radius: 50%;
    background-color: #f30000c2;
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
  }
`;

export const DashboardInboxBody = styled.div`
  position: relative;
  display: flex;
  color: #3361ff;
  align-items: center;
  justify-content: center;
  .inbox-count {
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #3361ff;
    display: flex;
    font-size: 0.6rem;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 1;
  }
`;

export const DashboardInboxContainer = styled.div<{
  type: string;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 127px;
  height: 33px;
  background-color: ${({ type, disabled }) =>
    disabled ? "#DDDFE7" : type === "internal" ? "#CCE5FF" : "#F8E2E1"};
  border-radius: 4px;
  border: 1px solid
    ${({ type, disabled }) =>
    disabled ? "#DDDFE7" : type === "internal" ? "#A2D1FF" : "#F27F77"};
  box-shadow: ${({ disabled }) =>
    disabled ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"};
  font-weight: ${({ disabled }) => (disabled ? "normal" : "700")};
  font-size: 10px;
  color: ${({ type, disabled }) =>
    disabled ? "#999999" : type === "internal" ? "#3361FF" : "#F27F77"};
  p:nth-child(2) {
    color: ${({ disabled }) => (disabled ? "#666666" : "black")};
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    font-weight: ${({ disabled }) => (disabled ? "normal" : "900")};
    p:nth-child(2) {
      color: ${({ type, disabled }) =>
    disabled ? "#666666" : type === "internal" ? "#3361FF" : "#F27F77"};
    }
  }
  &:active {
    font-weight: ${({ disabled }) => (disabled ? "normal" : "900")};
    color: ${({ disabled }) => (disabled ? "#999999" : "#3e3e3c")};
    box-shadow: ${({ disabled }) =>
    disabled ? "none" : "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset"};
  }
`;

export const DashboardAside = styled.aside<{
  isHidden: boolean;
  value: number;
}>`
  min-width: 2rem;
  overflow: hidden;
  grid-area: aside;
  max-height: 100%;
  padding: 1rem 0rem;
  background-color: ${({ value }) => (value === 1 ? "#DDDFE7" : "#f7f8fa")};
  border-left: 1px solid #e0e0e0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .tab-panels {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .alerts-tab {
    display: flex;
    flex-direction: row;
    gap: 5px;
    z-index: 10;
    color: #000;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .alert-count {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #f30000c2;
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    color: white;
  }
  transition: all 1.5s ease-in-out;
 


  `;

export const StyledBreadCrumbs = styled.div`
    grid-area: breadcrumbs;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0rem;
    > * {
      display: flex;
      align-items: center;
      margin: 0 0.25rem;
      justify-content: space-between;
      gap: 0.5rem;
      &:not(:last-child)::after {
        content: ">";
      }
    }
  }
`;

export const StyledAssignmentDrillDownNav = styled.div`
  display: grid;
`;

export const SettingsContainer = styled.section<{ isOpen: boolean }>`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: white;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  padding: 1rem;
  ${(props) => (props.isOpen ? `visibility: visible;` : `visibility: hidden;`)}
`;

export const TabsContainer = styled.div`
  width: 100%;
`;

export const modalStyling = `
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: start;
  align-content: center;
  z-index: 1000;
`;
