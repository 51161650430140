import assignmentRequest from ".";
import { IAlerts } from "../../../pages/Dashboard/Alerts";
import { IKPIResponse } from "../types";
import { apiv2 } from "..";
import {
  IAssignment,
  ILegacyAssignment,
  IUseAssignmentsParams,
} from "./types/assignments";


export const getAssignmentInsurer = async (
  legacy_id: string
) => {
  return await assignmentRequest.get("/insured-subjects/" + legacy_id)
}
export const getAssignmentInsurerbyAssignmentId = async (
  assignmentId: string
) => {
  return await assignmentRequest.get("/insured-subjects-id/" + assignmentId)
}

export const getAssignmentsByUserId = async (userId: number) => {
  const response = await assignmentRequest.get(`/${userId}?useuserid=True`)
  return response?.data;
}

export const getAssignments = async (
  params: Omit<IUseAssignmentsParams, "getAssignmentsProp">,
  cancelToken?: AbortSignal,
): Promise<{ assignments: (IAssignment & ILegacyAssignment)[], kpis?: IKPIResponse, alerts?: IAlerts[] }> => {
  try {
    const response = await assignmentRequest.post(`/get`, params, { signal: cancelToken });
    return response?.data;
  } catch (error) {
    console.error(error);
    throw Error("Error fetching assignments");
  }
};


export const getAssignmentByContractNo = async (
  contractNo: string,
): Promise<IAssignment & ILegacyAssignment> => {
  const response = await assignmentRequest.get(
    `/get/${contractNo}`,
  );
  return response?.data?.assignment;
}

export const getRenewalsAndEndorsements = async (
  params: Omit<IUseAssignmentsParams, "getAssignmentsProp">,
): Promise<{ assignments: (IAssignment & ILegacyAssignment)[] }> => {
  const response = await assignmentRequest.post(
    `/renewals/endorsements`, {
    offset: params?.pageNumber,
    limit: params?.pageSize,
    user_id: 1,
  },
  );
  return response.data;
};

export const getAssignmentPropertyValues = async (property: string, keyword: string) => {
  if (!property || !keyword) {
    return {
      message: "Property and keyword are required",
      options: [],
    }
  }
  const results = await assignmentRequest.get(`/facts/${property}/${keyword}`)
  return results;
};

export const getUnderwriter = async () => {
  return await apiv2.get(`/assignment/recommended-underwriter/for/broker_id/2`)
}
