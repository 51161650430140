import * as React from "react";
import styled from 'styled-components';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const InputWrapper = styled.div`
  width: 40%;
  position: relative; 
`;

const StyledInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  position: relative;
  color: #333;
  width: 14rem
`;

const CalendarIcon = styled.span`
  position: absolute;
  right: -15px;
  color: gray;
  top: 10px
`;

const PickerWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 250px;
  bottom: 0;
  z-index: 1000;
  display: ${props => props.isOpen ? 'block' : 'none'};
  shadow: 4px rgba(0, 0, 0, 0.1);
`;

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  margin-top: 1rem;
`;

const TimeSection = styled.div`
  background:rgba(243, 246, 255, 1);
  padding: 20px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px 0 0 10px;
`;

const DateSection = styled.div`
  padding: 20px;
  width: 60%;
`;

const YearText = styled.div`
  font-size: 14px;
  color: #666;
`;

const TodayText = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const TimeDisplay = styled.div`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
`;


const SaveButton = styled.button`
  width: 100%;
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
`;

const MonthNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const NavButton = styled.button`
  background: none;
  border: none;
  font-size: 18px;
  color: #666;
  cursor: pointer;
`;

const MonthText = styled.div`
  font-weight: bold;
`;

const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
`;

const DayOfWeek = styled.div`
  text-align: center;
  color: #666;
  font-weight: bold;
`;

const DayButton = styled.button<{ isSelected: boolean; isCurrentMonth: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.isSelected ? '#1a73e8' : 'transparent'};
  color: ${props => {
    if (props.isSelected) return 'white';
    if (!props.isCurrentMonth) return '#bbb';
    return 'black';
  }};
  cursor: pointer;
  opacity: ${props => props.isCurrentMonth ? 1 : 0.5};
  &:hover {
    background-color: ${props => props.isSelected ? '#1a73e8' : '#f1f3f4'};
  }
`;

const TimeControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeButton = styled.button`
  font-size: 18px;
  color: #666;
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  font-size: 14px;
`;

const RadioLabel = styled.label`
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const RadioInput = styled.input`
  margin-right: 5px;
`;

interface IDateTimePicker {
  selectedDateTime: Date;
  setSelectedDateTime: (date: Date) => void;
  now: Date;
}

const DateTimePicker = (props: IDateTimePicker) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { selectedDateTime, setSelectedDateTime, now } = props;

  const [selectedAMPM, setSelectedAMPM] = React.useState(() => {
    const hours = now.getHours();
    return hours >= 12 ? 'PM' : 'AM';
  });

  const [selectedTime, setSelectedTime] = React.useState(() => {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${String(hours % 12 || 12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  });

  const wrapperRef = React.useRef(null);

  const handleInputClick = () => {
    setIsOpen(true);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleDateChange = (date) => {
    const newDate = new Date(date);
    newDate.setHours(
      selectedDateTime.getHours(),
      selectedDateTime.getMinutes(),
      selectedDateTime.getSeconds(),
      selectedDateTime.getMilliseconds()
    );
    setSelectedDateTime(newDate);
  };

  const renderCalendar = () => {
    const year = selectedDateTime.getFullYear();
    const month = selectedDateTime.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    const days = [];

    // Calculate days from previous month
    const daysFromPrevMonth = firstDay;
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevMonthYear = month === 0 ? year - 1 : year;
    const daysInPrevMonth = new Date(prevMonthYear, prevMonth + 1, 0).getDate();

    for (let i = daysInPrevMonth - daysFromPrevMonth + 1; i <= daysInPrevMonth; i++) {
      days.push(
        <DayButton
          key={`prev-${i}`}
          isSelected={false}
          isCurrentMonth={false}
          onClick={() => handleDateChange(new Date(prevMonthYear, prevMonth, i))}
        >
          {i}
        </DayButton>
      );
    }
    // Current month days
    for (let i = 1; i <= daysInMonth; i++) {
      const isSelected = i === selectedDateTime.getDate() && month === selectedDateTime.getMonth() && year === selectedDateTime.getFullYear();
      days.push(
        <DayButton
          key={i}
          isSelected={isSelected}
          isCurrentMonth={true}
          onClick={() => handleDateChange(new Date(year, month, i))}
        >
          {i}
        </DayButton>
      );
    }

    // Calculate days from next month
    const totalDays = days.length;
    const daysFromNextMonth = 42 - totalDays;
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextMonthYear = month === 11 ? year + 1 : year;

    for (let i = 1; i <= daysFromNextMonth; i++) {
      days.push(
        <DayButton
          key={`next-${i}`}
          isSelected={false}
          isCurrentMonth={false}
          onClick={() => handleDateChange(new Date(nextMonthYear, nextMonth, i))}
        >
          {i}
        </DayButton>
      );
    }

    return days;
  };


  const handleSave = () => {
    setIsOpen(false);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  const handleHourChange = (increment) => {
    const newDate = new Date(selectedDateTime);
    let hours = newDate.getHours();
    let newHours = (hours + increment + 24) % 24;

    // Update AM/PM if necessary
    if ((hours < 12 && newHours >= 12) || (hours >= 12 && newHours < 12)) {
      setSelectedAMPM(selectedAMPM === 'AM' ? 'PM' : 'AM');
    }

    newDate.setHours(newHours);
    setSelectedDateTime(newDate);

    // Update selectedTime to reflect the new hour in 12-hour format
    const displayHours = newHours % 12 || 12;
    const minutes = newDate.getMinutes();
    setSelectedTime(`${String(displayHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
  };

  const handleMinuteChange = (increment) => {
    const newDate = new Date(selectedDateTime);
    let minutes = newDate.getMinutes();
    let newMinutes = (minutes + increment + 60) % 60;

    newDate.setMinutes(newMinutes);
    setSelectedDateTime(newDate);

    // Update selectedTime to reflect the new minutes
    const displayHours = newDate.getHours() % 12 || 12;
    setSelectedTime(`${String(displayHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`);
  };

  const handleAMPMChange = (event) => {
    const newAMPM = event.target.value;
    const newDate = new Date(selectedDateTime);
    let hours = newDate.getHours();

    if (newAMPM === 'PM' && hours < 12) {
      hours += 12;
    } else if (newAMPM === 'AM' && hours >= 12) {
      hours -= 12;
    }

    newDate.setHours(hours);
    setSelectedDateTime(newDate);
    setSelectedAMPM(newAMPM);

    // Update selectedTime to reflect the new hour in 12-hour format
    const displayHours = hours % 12 || 12;
    const minutes = newDate.getMinutes();
    setSelectedTime(`${String(displayHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
  };

  const handleMonthChange = (increment) => {
    const newDate = new Date(selectedDateTime);
    newDate.setMonth(newDate.getMonth() + increment);
    setSelectedDateTime(newDate);
  };

  const getDayName = (day) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[day];
  };

  const getMonthName = (month) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July',
      'August', 'September', 'October', 'November', 'December'
    ];
    return months[month - 1];
  }



  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    const hours = selectedDateTime.getHours();
    const minutes = selectedDateTime.getMinutes();
    const newAMPM = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;

    setSelectedTime(`${String(displayHours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
    setSelectedAMPM(newAMPM);
  }, [selectedDateTime]);

  return (
    <InputWrapper ref={wrapperRef}>
      <StyledInput
        type="text"
        value={selectedDateTime.toLocaleString()}
        onClick={handleInputClick}
        readOnly
      />
      <CalendarIcon><CalendarTodayIcon sx={{ fontSize: 16 }} /></CalendarIcon>
      <PickerWrapper isOpen={isOpen}>
        <Wrapper>
          <TimeSection>
            <div>
              <YearText>{selectedDateTime.getFullYear()}</YearText>
              <TodayText>{getDayName(selectedDateTime.getDay())},</TodayText>
              <TodayText>{formatDate(selectedDateTime)}</TodayText>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <TimeControls>
                <TimeUnit>
                  <TimeButton onClick={() => handleHourChange(1)}><KeyboardArrowUpIcon /></TimeButton>
                  <TimeDisplay>{selectedTime.split(':')[0]}</TimeDisplay>
                  <TimeButton onClick={() => handleHourChange(-1)}><KeyboardArrowDownIcon /></TimeButton>
                </TimeUnit>
                <span>:</span>
                <TimeUnit>
                  <TimeButton onClick={() => handleMinuteChange(1)}><KeyboardArrowUpIcon /></TimeButton>
                  <TimeDisplay>{selectedTime.split(':')[1]}</TimeDisplay>
                  <TimeButton onClick={() => handleMinuteChange(-1)}><KeyboardArrowDownIcon /></TimeButton>
                </TimeUnit>
              </TimeControls>
              <RadioGroup>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="ampm"
                    value="AM"
                    checked={selectedAMPM === 'AM'}
                    onChange={handleAMPMChange}
                  />
                  AM
                </RadioLabel>
                <RadioLabel>
                  <RadioInput
                    type="radio"
                    name="ampm"
                    value="PM"
                    checked={selectedAMPM === 'PM'}
                    onChange={handleAMPMChange}
                  />
                  PM
                </RadioLabel>
              </RadioGroup>
            </div>
          </TimeSection>
          <DateSection>
            <MonthNavigation>
              <NavButton onClick={() => handleMonthChange(-1)}>{'<'}</NavButton>
              <MonthText>{getMonthName(selectedDateTime.getMonth() + 1)}</MonthText>
              <NavButton onClick={() => handleMonthChange(1)}>{'>'}</NavButton>
            </MonthNavigation>
            <CalendarGrid>
              {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                <DayOfWeek key={day}>{day}</DayOfWeek>
              ))}
              {renderCalendar()}
            </CalendarGrid>
          </DateSection>
        </Wrapper>
      </PickerWrapper>
    </InputWrapper>
  );
};


export default DateTimePicker;