import { IAlertsParams } from '../../../hooks/useAlerts';
import {api} from '../index';
import { IAlerts } from '../../../pages/Dashboard/Alerts';
import { getBroadcastMessages } from '../broadcastMessage/get';

const getAlerts = async (body: IAlertsParams): Promise<IAlerts[] | null> => {

  try {
    const response = await api.post('/alerts/user/get', body);
    if (response.status === 200) {
   
      return response.data as IAlerts[];
    } else {
      console.error('Failed to fetch alerts:', response.data.message);
      return null;
    }
  } catch (error) {
    console.error('An error occurred while fetching alerts:', error);
    return null;
  }
};

export default getAlerts;


export const getLeadershipNotifications = async () => {
  try {
    const res = await getBroadcastMessages();
    const broadcastMessages = res?.broadcast || [];
    
    const activeLeadershipNotifications = broadcastMessages
      .filter((message:any) => message.is_active)
      .map((message:any) => ({
        id: message.id,
        text: message.description,
      }));

    return activeLeadershipNotifications;
  } catch (error) {
    console.error("Error fetching active leadership notifications:", error);
    return [];
  }
};

