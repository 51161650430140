import styled from "styled-components";

export const StyledCard = styled.div`
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  max-width: 367px;
  max-height: 375px;
  .contract-task {
    font-size: 0.8rem;
    color: #000;
    font-weight: 700;
  }
`;

type TPriorityLevel = Record<string, string>;

export const priorityColors: TPriorityLevel = {
  high: "#F27F77",
  medium: "#FFA775",
  low: "#F5EB88",
};

const priorityText: TPriorityLevel = {
  High: "High Priority",
  Medium: "Medium Priority",
  Low: "Low Priority",
};

export const StyledPriorityLevel = styled.div<{
  priority: string;
}>`
  display: flex;
  padding: 0.125rem 0.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 0.125rem;
  color: #000000c9;
  font-weight: 600;
  background-color: ${({ priority }) => priorityColors[priority] || "gray"};
  & ::after {
    content: ${({ priority }) => `"${priorityText[priority]}"` || "gray"};
  }
`;
export const StyledPriorityLevelDates = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 6px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: #f7f7f7;
  color: #b1b3c5;
`;

export const StyledTime = styled.div`
  display: flex;
  align-items: center;
  color: #646570;
  font-size: 10px;
  font-weight: 600;
  gap: 0.25rem;
  color: #b1b3c5;
  .icon-container {
    height: 0.8rem;
    width: 0.8rem;
    & > svg {
      height: 100%;
      width: 100%;
    }
  }
  .text {
    color: black;
  }
`;
export const PriorityShortViewTitle = styled.div`
display: flex;
justify-content: center;
position: relative;
font-weight: 590;
font-size: 1rem;
width: 100%;
margin-top: 1rem;
margin-bottom: 2rem;
box-sizing: border-box;
&::before{
  content: " ";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: black;
  
}
span{
  transform: translateY(-50%);
  position: absolute;
  background: white;
}

`

export const PriorityShortView = styled.div`
position: relative;
width: 100%;
box-sizing: border-box;


`