import * as React from 'react'
import { IComponentProps } from ".."
import styled from 'styled-components'
import { UserContext } from '../../../../../context/UserContext'

export const CreateAssignmentContainer = styled.div`
width:100%;
flex-direction:column;
display:flex;
align-self:center;
justify-content: flex-start;
gap: 1rem;
margin-bottom:15rem;
& > * {
    flex-basis: 50%
}
`

const StyledButton = styled.label`
    flex-basis:30%;
    min-width: 200px;
    cursor:pointer;
    background: #E1E3E5;
    font-size:24px;
    text-align:center;
    border:0;
    padding: 10px 20px;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease, border-bottom 0.3s ease;
   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &.selected {
        background-color: #186ade;
        color:white
    }
    &:hover {
        background-color: #186ade;
        color:white
    }
`
const ContractType = (props: IComponentProps): JSX.Element => {
    const { setFieldValue } = props
    const selectedContractType = props?.assignment?.contractTypeId
    const { user } = React.useContext(UserContext)
    const contractTypes = props?.options?.contractTypes || []



    return <>
        <p data-testid="contractTypePElement">Select the type of contract you would like to create</p>
        <CreateAssignmentContainer style={{ flexDirection: "row", display:"flex", justifyContent: "center" }}>
            {
                contractTypes.map((contractType, index) => {
                    const selected = selectedContractType?.id === contractType?.id ? "selected" : ""
                    return (<ContractTypeButton
                        key={index}
                        className={`${selected}`}
                        contractType={contractType}
                        setFieldValue={setFieldValue}
                        name={'contractTypeId'}
                        // data-testid="contractType"
                    />)
                })
            }
            <input hidden name={'lastUpdatedBy'} value={user?.id} />
        </CreateAssignmentContainer>
    </>
}


const ContractTypeButton = (props: IButtonProps): JSX.Element => {
    const { contractType, className, setFieldValue, name } = props
    
    const _handleChange = (e: React.SyntheticEvent<HTMLButtonElement>) : void=> {
        setFieldValue(name, contractType)
    }
    return (
        <StyledButton data-testid="contractType" className={className} onClick={_handleChange}>
            {contractType.label}
        </StyledButton>
    )
}

export default ContractType