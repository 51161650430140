import axios from 'axios';

export const auth = axios.create({
  baseURL: `${process.env.AUTH_API}`,
  withCredentials: true,
});

export const api = axios.create({
  baseURL: `${process.env.UNDERWRITER_API}`,
  withCredentials: true,
});

export const apiv2 = axios.create({
  baseURL: `${process.env.UNDERWRITER_API_V2}`,
  withCredentials: true,
});

export const files = axios.create({
  baseURL: `${process.env.UNDERWRITER_API_BASE}/file`,
  withCredentials: true,
});


export default auth;