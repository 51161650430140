import * as React from "react";
import { SubmitTaskButton, UserModelForm } from "../../../styles";
import { useOutletContext, useParams } from "react-router-dom";
import { User } from "../../../../../../context/UserContext";
import { Formik } from "formik";
import { RemoveRedEye } from "@mui/icons-material";
import { IDepartment } from "../../../../../../api/v1/types";

interface IUserModelProps {
  user: User;
  userId?: string;
  editUser: (user: User) => void;
  departments?: { [key: string]: IDepartment };
}

const UserModel = (props: IUserModelProps) => {
  const [isFormModified, setIsFormModified] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { user, editUser } = useOutletContext<{
    user: User;
    editUser: (user: User) => void;
  }>();

  const {departments} = props;
  function formatNumber(num) {
    return num < 10 ? '0' + num : num.toString();
  }

  const handleFormSubmission = (values, formikHelpers) => {
    setIsFormModified(false);
    const clonedUser = user.copyOfUser();
    Object.keys(values).forEach((key) => {
      if (!clonedUser.hasOwnProperty(key)) {
        return;
      } else {
        // Special handling for birthday
        if (key === 'birthday' && values[key]) {
          const formattedDate = values[key] + " 00:00:00";
     
          clonedUser[key] = formattedDate;
        } else {
          clonedUser[key] = values[key];
        }
      }
    });
    editUser(clonedUser);
    formikHelpers.resetForm();
  };
  
  const validateForm = (values: any) => {
    const errors: any = {};
    if (!values.email || values.email.length < 2) {
      errors.email = "Must be at least 2 characters";
    }
    if (!values.firstName || values.firstName.length < 2) {
      errors.firstName = "Must be at least 2 characters";
    }
    if (!values.lastName || values.lastName.length < 2) {
      errors.lastName = "Must be at least 2 characters";
    }
    if (values?.password?.length < 2) {
      errors.password = "Must be at least 2 characters";
    }
    if (!values.legacyName || values.legacyName.length < 2) {
      errors.legacyName = "Must be at least 2 characters";
    }
   if(!values.department) {
      errors.department = "Department is required";
   }
    if (!values.birthday) {
      errors.birthday = "Birthday is required";
    } else {
      const isValidDate = !isNaN(new Date(values.birthday).getTime());
      if (!isValidDate) {
        errors.birthday = "Invalid datetime format";
      }  
    }
    if(!values.legacyCode || values.legacyCode.length < 2) {
      errors.legacyCode = "Legacy Code is required";
    }
    else {
      const isValidLegacyCode = !isNaN(values.legacyCode);
      if (!isValidLegacyCode) {
        errors.legacyCode = "Invalid Legacy Code Format";
      }
    }
    return errors;
  };
  return (
    <Formik
      initialValues={{
        email: props?.user?.email || "N/A",
        lastName: props?.user?.lastName || "N/A",
        firstName: props?.user?.firstName || "N/A",
        legacyName: props?.user?.legacyName || "N/A",
        birthday: props?.user?.birthday ? new Date(props.user.birthday).toISOString().split('T')[0] : "",
        password: null,
        legacyCode: props?.user?.legacyCode || "N/A",
        department: props?.user?.department || "",
      }}
      onSubmit={handleFormSubmission}
      validate={validateForm}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        getFieldProps,
        dirty
      }) => (
        <>
          <UserModelForm onSubmit={handleSubmit}>
            <div className="input-label">
              <div className="error-div">
                <label htmlFor="email">Email</label>
                {errors?.email && touched?.email &&
                  <div className="invalid-input-error">{errors.email}</div>
                }
              </div>
              <input
                id="email"
                required
                type="email"
                name="email"
                {...getFieldProps("email")}
              />
            </div>
            <div className="input-label">
              <div className="error-div">
                <label htmlFor="familyName">Family Name</label>
                {errors?.lastName && touched?.lastName &&
                  <div className="invalid-input-error">{errors.lastName}</div>
                }
              </div>
              <input
                id="lastName"
                required
                type="name"
                name="lastName"
                {...getFieldProps("lastName")}
              />
            </div>
            <div className="input-label">
              <div className="error-div">
                <label htmlFor="firstName">Given Name</label>
                {errors?.firstName && touched?.firstName &&
                  <div className="invalid-input-error">{errors?.firstName}</div>
                }
              </div>
              <input
                id="firstName"
                required
                type="name"
                name="firstName"
                {...getFieldProps("firstName")}
              />
            </div>
            <div className="input-label">
              <div className="error-div">
                <label htmlFor="legacyCode">Legacy Code</label>
                {errors?.legacyCode && touched?.legacyCode &&
                  <div className="invalid-input-error">{errors?.legacyCode}</div>
                }
              </div>
              <input
                id="legacyCode"
                required
                type="name"
                name="legacyCode"
                {...getFieldProps("legacyCode")}
              />
            </div>
            <div className="input-label">
            <div className="error-div">
              <label htmlFor="department">Department</label>
              {errors?.department && touched?.department &&
                <div className="invalid-input-error">{errors?.department}</div>
              }
            </div>
            <select
              {...getFieldProps("department")}
            >
              <option value="" disabled>Select Department</option>
              {props?.user?.department &&
                <option value={props?.user?.department}>
                  {departments[props?.user?.department].name}
                </option>
              }
              {departments && Object.keys(departments).map((deptId) => (
                deptId !== props?.user?.department &&
                <option key={deptId} value={deptId}>
                  {departments[deptId]?.name}
                </option>
              ))}
            </select>
          </div>
            <div className="input-label">
              <div className="error-div">
                <label htmlFor="birthday">Birthday</label>
                {errors?.birthday && touched?.birthday &&
                  <div className="invalid-input-error">{errors?.birthday}</div>
                }
              </div>
              <input
                id="birthday"
                required
                name="birthday"
                type="date"
                {...getFieldProps("birthday")}
              />
            </div>
            <div className="input-label">
              <div className="error-div">
                <label htmlFor="password">Change Password</label>
                {errors?.password && touched?.password &&
                  <div className="invalid-input-error">{errors?.password}</div>
                }
              </div>

              <section className="password-section" style={{
                display: 'flex',
                alignItems: 'center',
                
              }}>
                <input
                  placeholder="Enter your Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  {...getFieldProps("password")}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <RemoveRedEye />
                </button>
              </section>
            </div>
            <SubmitTaskButton
              type="submit"
              disabled={!dirty}
            >
              Submit
            </SubmitTaskButton>

          </UserModelForm>

        </>
      )}
    </Formik>
  );
};

const ConnectedUserModel = () => {
  const { userId } = useParams();
  const contextValue = useOutletContext();
  const user = contextValue?.user || {};
  const departments = contextValue?.departments || {};

  return <UserModel user={user} userId={userId} departments={departments}/>;
};
export default ConnectedUserModel;