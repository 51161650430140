import * as React from "react";
import { getRenewalsAndEndorsements } from "../../../../api/v1/assignments/get";
import { UserContext } from "../../../../context/UserContext";
import NoREView from "./NoREView";
import REView from "./REView";
import RESkeleton from "./RESkeleton";
import { ESelectedColumn } from "../../AssignmentTable";

const RenewalsTable = () => {
  const [selectedColumn, setSelectedColumn] = React.useState(null);
  const [sortOrder, setSortOrder] = React.useState(null);
  const [RenewalsAndEndorsements, setRenewalsAndEndorsements] = React.useState([],);
  const [loadingAssignments, setLoadingAssignments] = React.useState<boolean>(true);
  const { user } = React.useContext(UserContext);


  React.useEffect(() => {
    const fetchRenewalsAndEndorsements = async () => {
      try {
        const response = await getRenewalsAndEndorsements({
          pageNumber: 1,
          pageSize: 10,
          userId: user?.id,
        })
        setRenewalsAndEndorsements(response.assignments);
        setLoadingAssignments(false);
      } catch (error) {
        console.error(
          "Error fetching Renewals and Endorsements:",
          error.message,
        );
      }
    };
    fetchRenewalsAndEndorsements();
  }, []);


  const setSortedColumn = (column: ESelectedColumn) => {
    setSelectedColumn(column);
    setSelectedColumn((prev:string) => {
      if (prev === column) {
        return prev;
      }
    });
  };
  const currentUserView = !RenewalsAndEndorsements?.length ? (<NoREView />) : (<REView />);
  const viewToUse = loadingAssignments ? <RESkeleton /> : currentUserView;
  return <>{viewToUse}</>;

};
export default RenewalsTable;
