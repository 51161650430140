import * as React from "react";
import { ERoute, RouteTitle } from "../../../../context/BrowserContext/routes/types";
import { Link, useLocation, useParams } from "react-router-dom";
import { StyledProfileNav, StyledUPNav } from "./UserProfile/styles";
import HorizontalNavigation from "../../../../components/HorizontalNavigation";
const routes = [
  ERoute.userDepartment,
  ERoute.permissions,
  ERoute.departmentParents,
]
interface IDepartmentNavProps {
  selectedTab?: string;
  departmentId?: string;
}
const routeGenerator = (departmentId:string|number, tab: string|number):TRoute =>({
  path: `/user-management/departments/${departmentId}/${tab}`, 
  label: RouteTitle[tab], 
  className: tab as string
})

const routesGenerator = (departmentId:string|number):TRoute[] => {
  return routes.map(tab => routeGenerator(departmentId, tab))

}

const DepartmentNav = (props: IDepartmentNavProps) => {
  const { tab } = useParams();
  const location = useLocation();
  const getSelectedMenuItem = () => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[pathSegments.length - 1] as ERoute;
  }
  const selectedMenuItem = (getSelectedMenuItem());

  
  return (
    <>
      <StyledProfileNav selectedTab={tab}>
        <nav>
            <HorizontalNavigation
              routes={routesGenerator(props.departmentId)}
              currentRoute={selectedMenuItem}
              dependencies={[props.departmentId]}
              />
        </nav>
      </StyledProfileNav>
    </>
  );
};

const ConnectedDepartmentsNav = () => {
  const { departmentId } = useParams();
  return <DepartmentNav departmentId={departmentId} />;
}

export default ConnectedDepartmentsNav;
