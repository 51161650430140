import  React from "react";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Layers } from "../../../../styles/GlobalStyles";

const StyledColumn = styled.th`
  z-index: ${Layers.Content};
`;
const SortContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  .up {
    position: absolute;
    top: -7px;
  }
  .down {
    position: absolute;
    top: -7px;
  }
`;

const Label = styled.div`
  display: flex;  
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

interface ITableHeaderColumnProps {
  children: React.ReactNode;
  onClick?: () => void;
  // isSortable?: boolean;
  // isSelected?: boolean;
  // sortDirection?: "asc" | "desc";
  // onSort?: (columnKey: string | number, sortDirection: "asc" | "desc") => void;
  // columnKey?: string;
}

const TableHeaderColumn = (props: ITableHeaderColumnProps) => {
  return (
    <StyledColumn>
      <Label
        onClick={props.onClick}
      >
        {props.children}  
          <SortContainer>

          </SortContainer>
      </Label>
    </StyledColumn>
  );
};

export default TableHeaderColumn;
