import * as React from "react";
import {
  StyledOptionBoxContainer,
  MenuPropsStyles,
  SelectStyles,
  SearchButtonContainer,
  PrimaryButton
} from "../styles";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
} from "@mui/material";
import { Formik, Form, useFormik, type FormikValues } from "formik";
import { fetchDocuments } from "../../../../api/v1/documents/get";
import SearchCompany from "./SearchCompany";
import { IDocumentType } from "../../../../api/v1/documents/types/DocumentTypes";

interface IFilterOptions {
  setShowFilterResults: (value: boolean) => void;
  setDocuments: React.Dispatch<React.SetStateAction<IDocumentType[]>>
  contractNo: string
  setContractNo: React.Dispatch<React.SetStateAction<string>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  documentType: string
  setDocumentType: React.Dispatch<React.SetStateAction<string>>
}

const FilterOptions = (props: IFilterOptions) => {
  const [category, setCategory] = React.useState<string>('')
  const [company, setCompany] = React.useState<ICompany>()
  const { contractNo, setContractNo, setLoading, documentType, setDocumentType} = props;

  const filterDocumentType = [
    "",
    "Slip",
    "Original Proposal",
    "Cancellation Notice",
    "Decline Notice",
    "Reject Notice",
    "Confirmation Notice",
    "Inspection Report",
    "Endorsement",
    "All Documents",
  ];

  const searchDocuments = async () => {
    try {
      setLoading(true)
      const response = await fetchDocuments(contractNo, documentType, category || null, company?.KOD || null)
      if (response?.data?.documents) {
        props.setDocuments(response?.data?.documents)
      }
    }
    catch (err) {
      console.error("fetch documents error", err)
    }
    finally {
      setLoading(false)
    }
  }

  const handleFilterSubmit = (value: boolean,) => {
    searchDocuments()
    props?.setShowFilterResults(value);
  }

  return (
    <>
      <StyledOptionBoxContainer>
        {/* CATERGORY */}
        <div className="filter-category ">
          <p>{"Filter by Category (optional)"}</p>
          {/* <SearchCompany
            setCategory={setCategory}
            category={category}
            company={company}
            disabled
            setCompany={setCompany}
          /> */}
        </div>

        {/* CONTRACT NUMBER */}
        <div className="filter-contract-number">
          <p>{"Filter by Contract No."}</p>
          <label htmlFor="contract-no" style={{ color: "#CC0707" }}>
            Mandatory field *
          </label>
          <FormControl
            sx={{
              width: "90%",
            }}
          >
            <TextField
              onChange={(e) => setContractNo(e.target.value)}
              id="contract-no"
              variant="outlined"
              placeholder="Enter Contract No."
              sx={{
                width: "85%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              data-testid="contract-no-documents"
            />
          </FormControl>
        </div>

        {/* DOCUMENT */}
        <div className="filter-document-type">
          <p>{"Filter by Document Type"}</p>
          <span style={{ color: "#CC0707" }}>Mandatory field *</span>
          <FormControl
            sx={{
              width: "90%",
            }}
          >
            <InputLabel id="document">Select a Document Type</InputLabel>
            <Select
              role="select"
              onChange={e => setDocumentType(e.target.value as string)}
              labelId="document"
              label="Select a Document Type"
              sx={SelectStyles}

              MenuProps={{
                sx: MenuPropsStyles,
              }}
              data-testid="document-type-select"
            >
              {filterDocumentType.map((document, index) => (
                <MenuItem key={index} value={document}>
                  {document}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* <div className="filter-by-date"> */}
        {/* <p>{"Filter by Date (optional)"}</p> */}
        {/* TODO: ADD DATE RANGE PICKER */}
        {/* </div> */}
      </StyledOptionBoxContainer>

      <SearchButtonContainer>
        <PrimaryButton
        data-testid="search-doc"
          style={{
            backgroundColor: !contractNo || !documentType ? "#EBEBE4" : '',
            border: !contractNo || !documentType ? "1px solid black" : '',
            color: !contractNo || !documentType ? "black" : '',
          }}
          disabled={!contractNo || !documentType}
          onClick={() => {
            handleFilterSubmit(true)
          }
          }>Search</PrimaryButton>
      </SearchButtonContainer>
    </>
  );
};

export default FilterOptions;
