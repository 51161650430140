import * as React from 'react';
import PriorityCard from "../../PriorityCard";
import {
  PriorityAreaAsideContainer,
  PriorityAreaCardContainer,
} from "../styles"; 
import { ITodaysPrioritiesProps } from '..';
import { ELegacyAssignmentProps } from "../../../../api/v1/assignments/types/assignments";


const RenderedPriorityArea = (props:ITodaysPrioritiesProps) => {
    const {priorities, assignments} = props;
    return(
        <PriorityAreaAsideContainer data-testid="priority-container">
    <h2>Today's Priority</h2>
    <PriorityAreaCardContainer>
      {priorities?.map(
        (priority, index) => {
        return (
          <PriorityCard
            key={index}
            priority={priority?.priority}
            contractNo={priority?.contract_no}
            broker={priority?.broker_id}
            date={priority?.create_date}
            tasks={priority?.tasks}
          />
        );
      })}
    </PriorityAreaCardContainer>
  </PriorityAreaAsideContainer>
    )
}

export default RenderedPriorityArea;