import { api,auth } from "..";
import { IUser } from "./types/user";


export const deleteUser = async (userId: number): Promise<any> => {
    const response = await auth.delete(`/users/${userId}`);
    return response.data;
}

export const deleteUsers = async (users: IUser[]): Promise<any> => {
    const response = await auth.delete('/users/bulk', {data:{users}});
    return response.data;
}

export default deleteUser;
