import * as React from 'react';
import { Link, Route, useNavigate } from 'react-router-dom';
import {StyledAssignmentNavigation} from './style';



interface IHorizontalNavigationProps {
    dependencies: any[];
    routes: {path: string, label: string, className: string}[];
    currentRoute: string;
    label?: string;
  }
  const HorizontalNavigation = (props: IHorizontalNavigationProps) => {
    const [beforeLeftPosition, setBeforeLeftPosition] = React.useState(0);
    const [width, setWidth] = React.useState(0);
    const determineSizeofBar = () => {
      try {
        const elementToGetWitdthAndPosition = document.querySelector(
          `.${props?.currentRoute}`,
        );
        if (!elementToGetWitdthAndPosition) return;
        elementToGetWitdthAndPosition?.offsetLeft &&
          setBeforeLeftPosition(elementToGetWitdthAndPosition?.offsetLeft);
        elementToGetWitdthAndPosition?.clientWidth &&
          setWidth(elementToGetWitdthAndPosition?.clientWidth);
      } catch (e) {
        console.log(e);
      }
    };
    React.useEffect(determineSizeofBar, props?.dependencies);
    React.useEffect(() => {
      // use ResizeObserver to determine the size of the bar
      const resizeObserver = new ResizeObserver(determineSizeofBar);
      resizeObserver.observe(document.body);
    });
    return (
        <StyledAssignmentNavigation width={width} left={beforeLeftPosition}>
          <h4 data-testid="assignment-h3">{props?.label}</h4>
          {
            props?.routes?.map((route, index) => {
                return (
                    <Link
                    data-testid={route?.path}
                    className={route?.className}
                    to={route?.path}
                    >
                    {route?.label}
                    </Link>
                )
            })
          }
        </StyledAssignmentNavigation>
    );
  };

  export default HorizontalNavigation;