import React from "react";
import styled from "styled-components";

const StatusBadge = styled.div<{ status: string }>`
  border-radius: 0.25rem;
  border: 1px solid #a2d1ff;
  background: #cce5ff;
  padding: 0.25rem 1rem;
  color: #44474b;
  text-align: center;
  display: inline-block;
`;

interface IStatusColumnProps {
  status: string;
}

const StatusColumn = (props: IStatusColumnProps) => {
  return <StatusBadge>{props?.status}</StatusBadge>
};

export default StatusColumn;
