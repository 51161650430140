import * as React from "react";
import styled from "styled-components";
import FormGroup from "../../../../components/FormElements/FormGroup";
import { DashboardContext } from "../..";
import { CreateAssignmentInput } from "../Steps/styles";
import { FormGroupContainer } from "../ReinsuranceTerms/styles";
import { InsuredSubject } from "../../../../api/v1/assignments/classes/insuredSubjects";

const PaymentsFormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.3rem;
`;

const ExpectedPaymentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.3rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 2rem;
  .button {
    display: flex;
    justify-content: end;
  }
`;

const PaymentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .test {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
  }
`;

const AdditionalPaymentButton = styled.button`
  font-size: 0.8rem;
  background: #ccd7e5;
  filter: drop-shadow(0px 2.68px 1.68px rgba(0, 0, 0, 0.25));
  color: rgba(247, 248, 250, 0.8);
  border: none;
  border-radius: 10px;
  &:hover {
    font-size: 0.8rem;
    background: rgba(247, 248, 250, 0.8);
    filter: drop-shadow(0px 2.68px 1.68px rgba(0, 0, 0, 0.25));
    color: #ccd7e5;
    border: none;
    border-radius: 10px;
  }
`;

const Payments = (props: any) => {
  // const { selectedInsurer } = React.useContext(DashboardContext);
  const { assignment, formik } = props;
  const [showAddtPayments, setShowAddtPayments] = React.useState<{[key: number]: boolean}>({});
  const [expectedPayments, setExpectedPayments] = React.useState<{[key: string]: any[]}>({});

  const handleAddPayment = (
    reinsuranceIndex: number,
    subjectIndex: number,
    subject: InsuredSubject,
  ) => {
    const key = `${subjectIndex}_${reinsuranceIndex}`;
    const newExpectedPayment = { reinsuranceIndex, subjectIndex };
    setExpectedPayments(prevState => ({
      ...prevState,
      [key]: prevState[key] ? [...prevState[key], newExpectedPayment] : [newExpectedPayment]
    }));
    setShowAddtPayments(prevState => ({ ...prevState, [reinsuranceIndex]: true }));
  };


  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    subjectIndex: number,
    reinsuranceIndex: number,
    paymentIndex?: number,
  ) => {
    const { name, value } = event.target;

    let path;
    if (paymentIndex !== undefined) {
      path = `insuredSubjects[${subjectIndex}].reinsuranceTerms[${reinsuranceIndex}].paymentTerms.schedule[${paymentIndex}].${name}`;
    } else {
      path = `insuredSubjects[${subjectIndex}].reinsuranceTerms[${reinsuranceIndex}].paymentTerms.${name}`;
    }

    formik.setFieldValue(path, value);
  };

  if (!Array.isArray(assignment?.insuredSubjects?.reinsuranceTerms?.paymentTerms?.schedule)) {
    return <div>No Payments Available</div>;
  }

  return (
    <>
      {Array.isArray(assignment?.insuredSubjects) &&
        assignment?.insuredSubjects.map(
          (subject: InsuredSubject, subjectIndex: number) => (
            <PaymentsWrapper>
              <FormGroupContainer key={subjectIndex}>
                <FormGroup title={`Expected Payments ${subjectIndex}`}>
                  {Array.isArray(subject?.reinsuranceTerms) &&
                    subject?.reinsuranceTerms.map(
                      (reinsurance: any, reinsuranceIndex: number) => (
                        <ExpectedPaymentsWrapper key={reinsuranceIndex}>
                          {Array.isArray(reinsurance?.paymentTerms?.schedule) &&
                            reinsurance?.paymentTerms?.schedule.map(
                              (payment: any, paymentIndex: number) => (
                                <div key={paymentIndex} className="test">
                                  <FormGroupContainer>
                                    <FormGroup title="Amounts & Dates">
                                      <CreateAssignmentInput
                                        type="datetime-local"
                                        label={"Expected Date"}
                                        value={payment?.expectedDate || null}
                                        name="expectedDate"
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>,
                                        ) =>
                                          handleInputChange(
                                            event,
                                            subjectIndex,
                                            reinsuranceIndex,
                                            paymentIndex,
                                          )
                                        }
                                      />
                                      <CreateAssignmentInput
                                        name="expectedAmount"
                                        label={"Expected Amount"}
                                        value={payment?.expectedAmount || null}
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>,
                                        ) =>
                                          handleInputChange(
                                            event,
                                            subjectIndex,
                                            reinsuranceIndex,
                                            paymentIndex,
                                          )
                                        }
                                      />
                                      <CreateAssignmentInput
                                        type="datetime-local"
                                        label="Actual Date"
                                        value={payment?.actualDate || null}
                                        name="actualDate"
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>,
                                        ) =>
                                          handleInputChange(
                                            event,
                                            subjectIndex,
                                            reinsuranceIndex,
                                            paymentIndex,
                                          )
                                        }
                                      />
                                      <CreateAssignmentInput
                                        label="Actual Amount"
                                        name="actualAmount"
                                        value={payment?.actualAmount || null}
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>,
                                        ) =>
                                          handleInputChange(
                                            event,
                                            subjectIndex,
                                            reinsuranceIndex,
                                            paymentIndex,
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </FormGroupContainer>

                                  <FormGroupContainer>
                                    <FormGroup title="Payments Status">
                                      <PaymentsFormWrapper
                                        key={reinsuranceIndex}
                                      >
                                        <div>
                                          <div>
                                            <CreateAssignmentInput
                                              label="Earned Premium"
                                              name="earnedPremium"
                                              value={
                                                reinsurance?.paymentTerms
                                                  ?.earnedPremium
                                                  || null
                                              }
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                              ) =>
                                                handleInputChange(
                                                  event,
                                                  subjectIndex,
                                                  reinsuranceIndex,
                                                )
                                              }
                                            />
                                            <CreateAssignmentInput
                                              label="Unearned Premium"
                                              name="unearnedPremium"
                                              value={
                                                reinsurance?.paymentTerms
                                                  ?.unearnedPremium
                                                  || null
                                              }
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                              ) =>
                                                handleInputChange(
                                                  event,
                                                  subjectIndex,
                                                  reinsuranceIndex,
                                                )
                                              }
                                            />
                                            <CreateAssignmentInput
                                              label="Accepted Date"
                                              name="acceptedDate"
                                              type="datetime-local"
                                              value={
                                                reinsurance?.paymentTerms
                                                  ?.acceptedDate
                                                  || null
                                              }
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                              ) =>
                                                handleInputChange(
                                                  event,
                                                  subjectIndex,
                                                  reinsuranceIndex,
                                                )
                                              }
                                            />
                                            <CreateAssignmentInput
                                              label="Payment Status"
                                              value={
                                                reinsurance?.paymentTerms
                                                  ?.paymentStatus || null
                                              }
                                              onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                              ) =>
                                                handleInputChange(
                                                  event,
                                                  subjectIndex,
                                                  reinsuranceIndex,
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </PaymentsFormWrapper>
                                    </FormGroup>
                                  </FormGroupContainer>
                                    {expectedPayments[`${subjectIndex}_${reinsuranceIndex}`]?.map((payment, index) => (
                                      <FormGroupContainer>
                                        <FormGroup title="Additional Payments">
                                      <div key={index}>
                                        <CreateAssignmentInput
                                          type="datetime-local"
                                          label={`Expected Date ${index + 1}`}
                                          
                                          name={`expectedDate${index}`}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                          ) =>
                                            handleInputChange(
                                              event,
                                              subjectIndex,
                                              reinsuranceIndex,
                                              paymentIndex,
                                            )
                                          }
                                        />
                                        <CreateAssignmentInput
                                          label={`Expected Amount ${index + 1}`}
                                          
                                          name={`expectedAmount${index}`}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                          ) =>
                                            handleInputChange(
                                              event,
                                              subjectIndex,
                                              reinsuranceIndex,
                                              paymentIndex,
                                            )
                                          }
                                        />
                                        <CreateAssignmentInput
                                          type="datetime-local"
                                          label={`Actual Date ${index + 1}`}
                                         
                                          name={`actualDate${index}`}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                          ) =>
                                            handleInputChange(
                                              event,
                                              subjectIndex,
                                              reinsuranceIndex,
                                              paymentIndex,
                                            )
                                          }
                                        />
                                        <CreateAssignmentInput
                                          label={`Actual Amount ${index + 1}`}
                                        
                                          name={`actualAmount${index}`}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>,
                                          ) =>
                                            handleInputChange(
                                              event,
                                              subjectIndex,
                                              reinsuranceIndex,
                                              paymentIndex,
                                            )
                                          }
                                        />
                                      </div>
                                    </FormGroup>
                                    </FormGroupContainer>
                                    ))}
                                </div>
                              ),
                            )}

                          <div className="button">
                            <AdditionalPaymentButton
                              type="button"
                               onClick={() => handleAddPayment(reinsuranceIndex, subjectIndex, subject)}
                            >
                              Add Expected Payment
                            </AdditionalPaymentButton>
                          </div>
                        </ExpectedPaymentsWrapper>
                      ),
                    )}
                </FormGroup>
              </FormGroupContainer>
            </PaymentsWrapper>
          ),
        )}
    </>
  );
};

export default Payments;
