import * as React from "react";
import {
  AddUserButton,
  Table,
  TableContainer,
  TableFilteringOptions,
  TableHeading,
  StyledForm
} from "../styles";
import AddIcon from "@mui/icons-material/Add";
import UsersRow from "./UserRow";
import SearchIcon from "@mui/icons-material/Search";
import { User } from "../../../../context/UserContext";
import UsersErrorModal from "./UsersErrorModal";
import asModal from "../../../../hoc/asModal";
import { useNavigate } from "react-router-dom";
import AddUserToTable from "./AddUserToTable";
import { Formik } from "formik";
import AddUserToTableSuccess from "./UserProfile/AddUserToTableSuccess";
import { IDepartment } from "../../../../api/v1/types";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import styled from "styled-components";

interface IRenderedUsersProps {
  users: { [key: string]: User };
  setUsers: React.Dispatch<React.SetStateAction<{ [key: string]: User }>>;
  addUser: (user: User) => void;
  userAdded?: boolean;
  setUserAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId?: string;
  deleteUser?: (userId: string) => void;
  departments?: { [key: string]: IDepartment };
}

const ErrorFilteringUsers = asModal(UsersErrorModal, "#users-page");
const UserAddedSuccessfully = asModal(AddUserToTableSuccess, "#users-page");
const DeleteUserModal = asModal(ConfirmationModal, "#modal-root");
const StyledDeleteUserModal = styled(DeleteUserModal)`
  > * {
    font-size: 1rem;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
    }
  }
`; 
const RenderedUsers = (props: IRenderedUsersProps) => {
  const userProfileNav = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = React.useState<boolean>(false);
  const [addUserRow, setAddUserRow] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<string>("");
  const { users, setUsers, addUser, userAdded, setUserAdded, deleteUser, departmentId, departments  } = props;

  const listOfUsers = typeof users == "object" && Object.values(users);
  
  const filteredUsers = listOfUsers?.filter((user) => {
    const departmentMatch = departmentId ? user?.department == departmentId : true;
    return departmentMatch && (
      user.id.toString().includes(searchTerm) ||
      user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  React.useEffect(() => {
    if (searchTerm && filteredUsers.length === 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }
  , [filteredUsers, searchTerm]);
  

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const displayProfile = (id: number) => {
    userProfileNav(`/user-management/user-profile/${id}/permissions`);
  };

  const handleDelete = (userId: string) => {
    setUserId(userId);
    setIsModalDeleteOpen(true);
  }

  const memoizedUsers = filteredUsers.map((user) => {
    return (
      <UsersRow
        key={user.id}
        user={user}
        setUsers={setUsers}
        onClick={displayProfile}
        handleDelete={handleDelete}
        departments={departments}
      />
    );
  });

  const handleAddClick = () => {
    setAddUserRow(true);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsModalDeleteOpen(false);
  };
  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          department: "",
          isActive: false,
        }}
        // onSubmit={handleNewUserSubmission}
      >
        {({ values, handleSubmit }) => (
          <StyledForm onSubmit={handleSubmit}>
            <TableContainer data-testid="table-container" id={"users-page"}>
              {userAdded && (
                <UserAddedSuccessfully
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  users={users}
                />
              )}
              <TableHeading>
                <div className="table-header">
                  <h3>All Users</h3>
                </div>
                <div className="input-and-button">
                  <AddUserButton onClick={handleAddClick}>
                    <AddIcon />
                    <div className="add-user-text" data-testid="add-user">Add User</div>
                  </AddUserButton>
                  <TableFilteringOptions>
                    <SearchIcon />
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleInputChange}
                      placeholder="Search"
                      data-testid="users-search"
                    />
                      <ErrorFilteringUsers
                        isOpen={isOpen}
                        onClose={() => {
                          setIsOpen(false);
                          setError(false);
                        }}
                      />
                  </TableFilteringOptions>
                </div>
              </TableHeading>
              <section className="table-container">
                <Table>
                  <thead>
                    <tr>
                      {!addUserRow && <th>Status</th>}
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Password</th>
                      <th>Department</th>
                      <th colSpan={addUserRow && 2}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {addUserRow && (
                      <AddUserToTable
                        setUserAdded={setUserAdded}
                        departments={departments}
                        addUser={addUser}
                        onClose={() => {
                          setAddUserRow(false);
                        }}
                      />
                      
                    )}

                    {memoizedUsers}
                  </tbody>
                </Table>
                <StyledDeleteUserModal 
                  isOpen={isModalDeleteOpen}
                  onClose={() => {
                    setIsModalDeleteOpen(false);
                  }}
                  onConfirm={() => {
                    deleteUser(userId);
                    setIsModalDeleteOpen(false);
                  }}
                  title="Delete User"
                  message="Are you sure you want to delete this user?"
                />
              </section>
            </TableContainer>
          </StyledForm>
        )}
      </Formik>
    </>
  );
};
export default RenderedUsers;
