import * as React from "react";
import { Task } from "../../../../api/v1/assignments/types/assignments";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import {
  NextStepsItem,
  IconContainer,
  ContractNoLabel,
} from "../styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "../../../../components/FormElements/Button";

interface TableBodyRowProps {
  clickOnMessage: (id: string) => void;
  tasks: Task;
}

const TableBodyRow = (props: TableBodyRowProps) => {
  const { tasks, clickOnMessage } = props;
  return (
    <NextStepsItem data-testid="tasks-row">
      <div data-testid="assignment-contract-div" className="assignment-contract">
        <ContractNoLabel data-testid="assignment-contract">{tasks?.contract_no}</ContractNoLabel>
      </div>

      <div data-testid="task-icon" className="task-icon">
        {tasks?.is_completed ? (
          <TaskAltIcon style={{ color: "#61A87E" }} />
        ) : (
          <HighlightOffIcon style={{ color: "#F00" }} />
        )}
      </div>

      <div data-testid="task-description" className="task-description">
        <p>{tasks?.description}</p>
      </div>

      <div data-testid="task-date" className="task-date">
        <p> Created on {tasks?.created_at} {tasks?.created_by ? `by ${tasks?.created_by}` : ""}</p>
      </div>

      {/* this hard coded for now due to not having a task completion or deletion property */}
      <div data-testid="task-history-date" className="task-history-date">
        {tasks?.is_completed ? (
          <p style={{ color: "#61A87E" }}>
            Completed on {tasks?.updated_at} {tasks?.updated_by ? `by ${tasks?.updated_by}` : ""}
          </p>
        ) : (
          <p style={{ color: "#F00" }}>Deleted on {tasks?.updated_at} {tasks?.updated_by ? `by ${tasks?.updated_by}` : ""}</p>
        )}
      </div>

      <IconContainer>
        <SmsOutlinedIcon data-testid="sms-icon" onClick={() => clickOnMessage(props.tasks?.id)} />
      </IconContainer>
    </NextStepsItem>
  );
};

export default TableBodyRow;
