import * as  React from "react";
import Login from "../../../../pages/Authentication";
import { Navigate, RouteObject } from "react-router-dom";
import axios from "axios";
import { getAccessToken } from "../../../../api/v1/auth/callback";

export const Authentication: RouteObject[] = [
  {
    path: "/login",
    element: <Login />,
  }, {
    path: "/oauth2/callback",
    element: <Navigate to="/assignments?contract_type=4" />,
    loader: async () => {
      const url = new URL(location.href);
      const code = url.searchParams.get('code');
      let isLoggedIn = null;
      if (code) {
        isLoggedIn = await getAccessToken(code).then((response) => {
          isLoggedIn = true;
          localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
          localStorage.setItem('isGoogle', '1');
          return true
        }).catch((error) => {
          isLoggedIn = false;
          localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));
          localStorage.setItem('error', error.response.data.message);
          localStorage.removeItem('isGoogle');
        }).finally(() => true);
        return <Navigate to="/assignments?contract_type=4" state={{ isLoggedIn }} />;
      }
    },
  }];

export default Authentication;
