import * as React from 'react';
import styled from 'styled-components';

const StyledFileDropZone = styled.div`
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;


const FileDropZone = ({ onFilesDropped, children }) => {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragOver = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  

  return (
    <StyledFileDropZone
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={onFilesDropped}
    >
       {children || 'Drag & Drop files here'}
    </StyledFileDropZone>
  );
};

export default FileDropZone;
