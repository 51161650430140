import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex; 
  min-height: 10px;
`;

export const TableContainer = styled.div`
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: hidden;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  .table-container {
    height: 100%;
    overflow: scroll;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  border: 1px solid #e1e3e5;
  position: relative;
  border: 1px solid #babfc3;
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;

    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
  th {
    background: white;
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    z-index: 1;
    padding-top: 1rem;
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 5px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
    &:first-child {
      z-index: 2;
    }
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 67px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #b1b3c5;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #48d056;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(33px);
  }

  .slider.round {
    border-radius: 6.25rem;
  }

  .span-text {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: white;
    transition: 0.4s;
    font-size: 0.5rem;
    text-transform: uppercase;
  }

  .span-text.active {
    left: 50%;
    transform: translate(-100%, -50%);
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const SubmitPermissionsButton = styled.button`
border : none;
background: transparent;
cursor: pointer;
.task-icon{
  color: ${props => props.disabled ? '#B1B3C5' : '#61A87E'};
  padding: 0.2rem
}
`;


export const TableHeading = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;

  .table-input {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
  }

  .search-icon {
    position: absolute;
    right: 10px;
  }
  .input-and-button {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: center;
  }
`;

export const AddUserButton = styled.button`
  background-color: #3361ff;
  color: white;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  min-width: 5rem;
  justify-content: center;
  padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  font-size: 0.75rem;
  align-items: center;
  .add-user-text {
    text-align: center;
    font-size: 0.75rem;
    align-self: center;
  }
`;

export const StyledUsersRow = styled.tr`
  .actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }
  input {
    background-color: #cce5ff66;
    border: none;
    border-radius: 0.25rem;
    padding: 0.4rem 0.4rem 0.4rem 0.4rem;
    width: 100%;
  }
  .success-icon {
    font-size: 1.5rem;
  }
  .save-user {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  select {
    background-color: #cce5ff66;
    border: none;
    border-radius: 0.25rem;
    padding: 0.4rem 0.4rem 0.4rem 0.4rem;
    width: 100%;
  }
`;

export const UsersHeaderWrapper = styled.div`
display: flex;
padding: 1rem;
font-size: 0.75rem;
justify-content: space-around;
.users-length{
  display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-align: center;
    font-size: 0.93rem;
    color:#828382;
    &:hover{
      background: #EEF0F2;
      border-radius: 0.625rem;
      padding: 0.5rem 2rem 0.5rem 2rem;
    }
    span{
    font-size: 2rem;
    color:#343434;
    font-weight: bold;
  }
}
.vertical-line {
  height: 4.9rem;
  background: #C4C4C4;
  border-left: 1px solid #babfc3;
  margin-top: 0.5rem;
}
}
.dept-div{
  display: flex;
    flex-direction: row;
    gap: 0.2rem;
  align-items: center;
  }

  `;

export const NoUsersDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 12rem;
`;

export const TableFilteringOptions = styled.section`
  border: 1px solid #babfc3;
  flex-basis: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  overflow: visible;
  input {
    flex-basis: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
    min-width: 300px;
  }
`;

export const DepartmentWrapper = styled.div`
  display: flex;
  font-size: 1.5rem;
  gap: 2rem;
  flex-direction: column;
  background-color: #eef0f2;
  padding: 2.5rem;
  border-radius: 0.5rem;
  flex-basis: 100%;
  overflow: scroll;
`;

export const RDPSettingsForm = styled.form`
  display: grid;
  gap: 1.5rem;
  padding-top: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "host username password"
    "port . .";
  .input-label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .invalid-input-error {
    border: 1px solid red;
    border-radius: 0.25rem;
    color: red;
    font-size: smaller;
    padding: 0.2rem;
  }
  .error-div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: self-end;
  }
`;
export const UserModelForm = styled.form`
  display: grid;
  gap: 0.5rem;
  padding-top: 1rem;
  overflow-y: hidden;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    "id email familyName"
    "legacyName givenName timezone"
    "DOB legacyId UpdatedAt"
    "legacyDept legacyCode AuthLevel"
    "createdAt . .";

  label {
    margin: 0;
  }

  .input-label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .invalid-input-error {
    border: 1px solid red;
    border-radius: 0.25rem;
    color: red;
    font-size: smaller;
    padding: 0.2rem;
  }
  .error-div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const StyledPermissionRow = styled.tr`
.error{
  color: red;
  font-size:smaller;
}
.input-error{
  color: red;
}
  .toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .username-toggle {
      font-size: 0.75rem;
      color: #6d7175;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 67px;
      height: 34px;
    }
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #b1b3c5;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #91d5ff;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(33px);
    }

    .slider.round {
      border-radius: 6.25rem;
    }

    .span-text {
      position: absolute;
      top: 50%;
      left: 70%;
      transform: translate(-50%, -50%);
      color: white;
      transition: 0.4s;
      font-size: 0.5rem;
      text-transform: uppercase;
    }

    .span-text.active {
      left: 50%;
      transform: translate(-100%, -50%);
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
  .actions{
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    cursor: pointer;
  }

`;

export const PermissionsTableHeader = styled.h2`
  font-size: 1.5rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  `;

export const AddRestrictionButton = styled.button`
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 1.25rem;
  border: 1px solid #3E3E3C;
  padding: 0.4rem 0.7rem 0.4rem 0.7rem;
   &:hover {
    color: #3361FF;
    border: 1px solid #3361FF;
  }
  `;
export const UserPermissionsContainer = styled.div`
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: hidden;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  .table-container {
    overflow: auto;
  }
`;

export const UserPermissionTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  border: 1px solid #e1e3e5;
  position: relative;
  border: 1px solid #babfc3;
  overflow-y: scroll;
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;

    &:nth-child(2n) {
      background-color: ##91D5FF;
    }
  }
  th {
    background: white;
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 5px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #ebf5ff;
    &:first-child {
      z-index: 2;
    }
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }
`;

export const SubmitTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 123px;
  height: 35px;
  background-color: ${(props) => (props.disabled ? "#cccccc" : "#3361FF")};
  border-color: ${(props) => (props.disabled ? "#cccccc" : "#3361FF")};
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  cursor: pointer;
  position: absolute;
  right: 54px;
  bottom: 10rem;
`;

export const ErrorModal = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 13.8rem;
  width: 25rem;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  z-index: 1;
  text-align: center;
  .modal-text {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    padding-top: 1.5rem;
  }
  .modal-text {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    padding-top: 1rem;
  }
`;

export const UserProfileButton = styled.button`
border: none;
background: none;  
display: flex;
cursor: pointer;
 `;