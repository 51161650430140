import { apiv2 } from "../..";



interface ICompany {
    detailLevel: "basic" | "full";
    type: string;
    text: string;
}

interface ICompanyResponse {
    id: number;
    name: string;
}

export const deleteEntityDetails = async (props: {
    model: string,
    filter?: [{
        column: string,
        value: string
        operator: string
    }],
    detailLevel?: string
}): Promise<ICompany[]> => {
    try {
        const res = await apiv2.delete(`/facts/${props?.model}`, {
            data: props
        });
        return res?.data?.results;
    } catch (error) {
        console.error("Error:", error);
    }
}
