import styled from "styled-components";

export const TaskMenuContainer = styled.div<{ isAddTaskOpen: boolean }>`
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  transform: ${(props) =>
    props.isAddTaskOpen ? "translateX(0)" : "translateX(155%)"};
  transition: transform 300ms ease;
  z-index: 10;
  height: 100%;
  position: absolute;
  overflow-x: hidden;
  background-color: white;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  border: 1px solid #dddbda;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));

  .accordion-container-header {
    display: flex;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 2px solid #4d75ff;
    background: #f3f6ff;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
      drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  }

  > *:not(.accordion-container-body) {
    height: unset !important;
  }

  .accordion-container-body {
    height: 100% !important;
    width: 100%;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    .skeleton {
      margin: 0 2rem;
    }
    .custom-skeleton {
      height: 4rem !important;
    }
    .skeleton-hidden {
      display: none;
    }
    .new-task-list {
      display: none;
    }
    
    .new-task-list.open {
      display: block;
    }
    
    .default-task-list.hidden {
      display: none;
    }
  }

  * {
    height: unset !important;
  }
`;

export const AddTaskMenu = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  top: 0px;
  right: 0px;
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(155%)"};
  transition: transform 300ms ease;
  z-index: 0px;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  background-color: white;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  border: 1px solid #dddbda;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
`;

export const AccordionHeader = styled.div`
  height: 100%;
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CreateNewTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 172px;
  height: 34.492px;
  flex-shrink: 0;
  background-color: #4d75ff;
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  border-color: #4d75ff;
  cursor: pointer;
`;

export const SubmitTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 123px;
  height: 35px;
  flex-shrink: 0;
  background-color: #4d75ff;
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  border-color: #4d75ff;
  cursor: pointer;
  margin-bottom: 1rem;
`;

export const TaskHistoryButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 13.51px;
  margin-bottom: 13.51px;
  margin-left: 20px;
  gap: 0.5rem;
  width: 184px;
  flex-shrink: 0;
  background-color: #7886a230;
  border-radius: 0.25rem;
  color: black;
  border-style: solid;
  border-color: #7886a230;
  cursor: pointer;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  .arrow-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    gap: 1rem;
  }
`;

export const NextStepsItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  .task-skeleton {
    border-radius: 20px;
  }
`;

export const AddNewTaskList = styled.li`
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const FormWrapper = styled.div`
  margin: 2rem;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export const AddNewTaskItems = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
  &:hover {
    background-color: #f3f6ff;
    font-weight: bold;
    font-size: 1.1rem;
  }

  &:hover ${ContentWrapper} {
    color: #3361ff;
    &::before {
      content: "+";
      font-size: 1.6rem;
    }
  }
`;

export const NoTaskSkeleton = styled.div`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 9rem;
`;
