import * as React from "react";
import {
  ReplyContainer,
  EmailHeaderDiv,
  EmailBody,
  MinimizedView,
  SendButton,
} from "../../styles";
import MinimizeIcon from "@mui/icons-material/Minimize";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IEmail } from "../../../../../api/v1/types";
import { Form, Formik } from "formik";
import InfoTip from "../../../../../components/Tooltip";

interface IReplyToEmailProps {
  onClose: () => void;
  onOpenModal: () => void;
  email: IEmail;
  handleSendEmail: (email: any) => void;
  emailContent: EditorState;
  handleEmailContent: (content: EditorState, setFieldValue: Function) => void;
  handleFileUpload: (file: File) => Promise<any>;
  emailContentValidation: (values: any) => any;
}

const ReplyToEmail = (props: IReplyToEmailProps) => {
  const [addCC, setAddCC] = React.useState<boolean>(false);
  const [addBCC, setAddBCC] = React.useState<boolean>(false);
  const [minimized, setMinimized] = React.useState<boolean>(false);
  const {handleSendEmail, emailContentValidation, handleFileUpload, handleEmailContent, emailContent} = props;

  React.useEffect(() => {
    handleEmailContent(EditorState.createEmpty(),()=> {});
  }
  , []);

  const handleAddCC = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setAddCC(!addCC);
  };
  const handleAddBCC = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setAddBCC(!addBCC);
  };

  const handleCloseModal = () => {
    props.onClose();
  };
  const handleMinimizeEmail = () => {
    setMinimized(!minimized);
  };
  const handleOpenModal = (event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setMinimized(false);
  };
  
  const handleSend = (values: any) => {
    props.handleSendEmail(values);
    props.onClose();
  }


  return (
    <>
      {minimized ? (
        <MinimizedView data-testid="minimized-view" onClick={handleOpenModal}>
          <div className="icons">
            New Message <ArrowDropUpIcon />
            <CloseIcon data-testid="close-minimized-view" className="close" onClick={handleCloseModal} />
          </div>
        </MinimizedView>
      ) : (
        <Formik
          initialValues={{
            recipients: props?.email?.from || "",
            subject: props?.email?.subject || "",
            ccField: "",
            bccField: "",
            editorContent: EditorState.createEmpty()||" ",
          }}
          validate={emailContentValidation}
          onSubmit={handleSend}
        >
          {({ values, handleSubmit, errors, touched, getFieldProps, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <ReplyContainer
                onClick={(event: React.MouseEvent<Element>) => {
                  event.stopPropagation();
                }}
              >
                <div className="header">
                  <div>New Message</div>
                  <div className="header-icons">
                    <MinimizeIcon data-testid="minimize-window" onClick={handleMinimizeEmail} />
                    <CloseIcon data-testid="close-window" onClick={handleCloseModal} />
                  </div>
                </div>
                <EmailHeaderDiv>
                  <div className="input-div">
                    <input
                      className="input"
                      id="recipients"
                      data-testid="recipients"
                      placeholder="Recipients"
                      {...getFieldProps("recipients")}
                    />
                    {errors?.recipients && touched.recipients && (
                      <div className="email-error">{errors?.recipients}</div>
                    )}
                    <div className="link-recipient ">
                      <button type="button" onClick={handleAddCC}>
                        <InfoTip title="Add Cc Recipients" placement="top">
                          Cc
                        </InfoTip>
                      </button>
                      <button type="button" onClick={handleAddBCC}>
                        <InfoTip
                          title="Add Bcc Recipients"
                          placement="top"
                        >
                          Bcc
                        </InfoTip>
                      </button>
                    </div>
                  </div>
                  <div className="input-div">
                    <input
                      placeholder="Subject"
                      id="subject"
                      className="input"
                      data-testid="subject"
                      {...getFieldProps("subject")}
                    />
                  </div>
                  <div className="input-div">
                    {addCC && (
                      <input
                        className="input"
                        id="cc"
                        data-testid="cc"
                        placeholder="Cc"
                        {...getFieldProps("ccField")}
                      />
                    )}
                    {addCC && errors?.ccField && touched.ccField && (
                      <div className="email-error">{errors?.ccField}</div>
                    )}
                  </div>
                  <div className="input-div">
                    {addBCC && (
                      <input
                        className="input"
                        id="bcc"
                        data-testid="bcc"
                        placeholder="Bcc"
                        {...getFieldProps("bccField")}
                      />
                    )}
                    {addBCC && errors?.bccField && touched.bccField && (
                      <div className="email-error">{errors?.bccField}</div>
                    )}
                  </div>
                </EmailHeaderDiv>
                <EmailBody>
                  <Editor
                    editorState={emailContent}
                    onEditorStateChange={(editorState) => {
                      handleEmailContent(editorState, setFieldValue);
                    }}
                    webDriverTestID="editor"
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    editorStyle={{
                      maxHeight: "300px",
                      padding: "0.9rem",
                      border: "1px solid #dadde9",
                      overflowY: "auto",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "fontFamily",
                        "list",
                        "textAlign",
                        "link",
                        "image",
                        "emoji",
                        "remove",
                        "history",
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      image: {
                        uploadCallback: handleFileUpload,
                        alt: { present: false, mandatory: false },
                        previewImage: true,
                        defaultSize: {
                          height: "400",
                          width: "400",
                        },
                      },
                      history: { inDropdown: false },
                    }}
                  />
                  {errors?.editorContent && touched.editorContent && (
                    <div className="email-error">{errors?.editorContent}</div>
                  )}
                </EmailBody>
                <div className="footer">
                    <SendButton
                      type="submit"
                      data-testid="send-email-button"
                      disabled={
                        (addCC && (!values.ccField || !!errors?.ccField)) ||
                        (addBCC && (!values.bccField || !!errors?.bccField)) ||
                        !!errors?.editorContent
                      }
                    >
                      Send
                    </SendButton>
                </div>
              </ReplyContainer>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};
export default ReplyToEmail;
