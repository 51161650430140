export enum ELegacyUserProps {
  Office = "Office",
  Kod = "Kod",
  ANDR = "ANDR",
  FIO = "FIO",
  PAROL = "PAROL",
  ANDRENG = "ANDRENG",
  Admin = "Admin",
  CurWork = "CurWork",
  Stady = "Stady",
  Otdel = "Otdel",
  Family = "Family",
  FirstName = "FirstName",
  SecondName = "SecondName",
  Titul = "Titul",
  Phone = "Phone",
  Fax = "Fax",
  Birthday = "Birthday",
  ID = "ID",
  ViewOffice = "ViewOffice",
  is_andr = "is_andr",
  is_manager = "is_manager",
  is_edit = "is_edit",
  is_secure = "is_secure",
  tzone = "tzone",
}

export interface ILegacyUser {
  [ELegacyUserProps.Office]: string,
  [ELegacyUserProps.Kod]: string,
  [ELegacyUserProps.ANDR]: string,
  [ELegacyUserProps.FIO]: string,
  [ELegacyUserProps.PAROL]: string,
  [ELegacyUserProps.ANDRENG]: string,
  [ELegacyUserProps.Admin]: string,
  [ELegacyUserProps.CurWork]: string,
  [ELegacyUserProps.Stady]: string,
  [ELegacyUserProps.Otdel]: string,
  [ELegacyUserProps.Family]: string,
  [ELegacyUserProps.FirstName]: string,
  [ELegacyUserProps.SecondName]: string,
  [ELegacyUserProps.Titul]: string,
  [ELegacyUserProps.Phone]: string,
  [ELegacyUserProps.Fax]: string,
  [ELegacyUserProps.Birthday]: string,
  [ELegacyUserProps.ID]: string,
  [ELegacyUserProps.ViewOffice]: string,
  [ELegacyUserProps.is_andr]: string,
  [ELegacyUserProps.is_manager]: string,
  [ELegacyUserProps.is_edit]: string,
  [ELegacyUserProps.is_secure]: string,
  [ELegacyUserProps.tzone]: string,
}

export type TdeniedEndpoint = "read" | "write" | "update" | "delete"

export interface IUser {
  id?: number;
  username?: string;
  firstName: string;
  lastName: string;
  email?: string;
  organization_id?: number;
  password?: string;
  department?: number;
  role?: EUserRole;
  settings?: {
    view: 'leadership' | 'underwriter',
    selected_underwriter: Number
  };
  authorityLevel: {
    regions: [
      {
        "geo_tag": "America",
        "latitude": 123,
        "services": [
          {
            "coverage_limit": 1000000,
            "line_of_bussines": [
              "tech"
            ]
          }
        ],
        "longitude": 123,
        "region_name": "US"
      }
    ],
    services: [
      {
        permissions: [
          {
            allowed:
            {
              endpoint: string,
              operation: ("read" | "write" | "delete" | "update")[],
              defaultParams: string
            }[],
            denied:
            {
              endpoint: string,
              operation: ("read" | "write" | "delete" | "update")[]
              defaultParams: string
            }[]

          }
        ],
        service_name: string
      }
    ]
  },
  isActive: boolean;
}

interface UserInfo {
  name: string;
  users: {
    [key: number]: {
      email: string;
      family_name: string;
      given_name: string;
      id: number;
      legacy_id: number;
    };
  };
}

export interface IUserHierarchy {
  "id": number,
  managing: {
    Applications: any;
    accounting: UserInfo
    Underwriter: UserInfo
    Manager: UserInfo
  }
  "orgnaization_heirarchy_id": number,
  "user_id": number,
  "hierarchy_details": {
    "id": number,
    "authority_level": null | any,
    "name": string,
    "organization_id": number
  }

}

export enum EUserRole {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  UNDERWRITER = "underwriter",
  MANAGER = "manager",
  DEMO = "demo",
  LEADERSHIP = "leadership",
  CLAIMS = "claims",
  ACCOUNTING = "accounting",
  BROKER = "broker",
  READONLY = "readonly",
}
