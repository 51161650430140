import * as React from 'react';
import { NoAlertsDiv, Table } from '../styles';

const NoAlerts = () => {
  return (
    <>
        <section>
          <Table>
            <NoAlertsDiv>
              No Alerts Found
            </NoAlertsDiv>
          </Table>
        </section>
    </>

  );
};
export default NoAlerts;