import * as React from 'react';
import { IComponentProps } from "..";
import styled from 'styled-components';
import { FormControl, Autocomplete, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4rem;
   .underwriter {
        font-size: 1rem; 
    }
`;

const SelectUnderwriter = (props: IComponentProps) => {
    const { options, setFieldValue, assignment, searchFunctions } = props;

    const handleInputChange = (event: any, value: any): void => {
        if (value === null) {
            setFieldValue("underwriterId", null);
        } else {
            setFieldValue("underwriterId", value);
        }
    };

    const onFocus = (event: any): void => {
        searchFunctions?.searchUnderwriters("");
    }

    React.useEffect(() => {
        searchFunctions?.searchUnderwriters("");
    },[]);


    return (
        <>
            <p data-testid="underwriter-question" style={{ marginTop: '5rem' }}>Which underwriter would you like to assign this contract to?</p>
            <Container>
                <FormControl sx={{ width: '45ch' }}>
                    <p className='underwriter'>Underwriter</p>
                    <Autocomplete
                        id="underwriterId"
                        options={options?.underwriters || []}
                        value={assignment?.underwriterId?.label || null}
                        onChange={handleInputChange}
                        renderInput={(params) => (
                            <TextField
                                data-testid="underwriter-input"
                                {...params}
                                placeholder="Enter to Search or Select Underwriter"
                                variant="outlined"
                                name={'underwriterId'}
                                onChange={handleInputChange}
                                onFocus={onFocus}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                        freeSolo
                        renderOption={(props, option) => (
                            <li {...props}>
                                {option?.label}
                            </li>
                        )}
                    />
                </FormControl>
            </Container>
        </>
    );
}

export default SelectUnderwriter;