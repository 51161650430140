import { api } from "../index";

export const updateDocumentsRequest = async (documentId: number, documentType: string, file_name: string, is_deleted: boolean): Promise<any> => {
  try {
    const data = {
      id: documentId,
      type: documentType,
      is_deleted: is_deleted,
      file_name: file_name,
      original_name: file_name
    }
    const response = await api.put(`/documents/${documentId}`, data)
    return response.data
  } catch (e) {
    console.error("Error updating documents:", e)
  }
};