import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TableContainer, TableFilteringOptions, TableHeading, PaginationContainer, PageNumbers, ArrowsContainer } from "../AssignmentTable/styles";
import { getEmails } from "../../../api/v1/emails/get";
import { IEmail } from "../../../api/v1/types";
import { DashboardContext } from "..";
import NoEmails from "./components/NoEmails";
import RenderedEmails from "./components/RenderedEmails";
import EmailsSkeleton from "./components/EmailsSkeleton";
import { EditorState, Modifier } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { sendEmail } from "../../../api/v1/emails/post";
import InboxNav from "./components/InboxNavigation";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
interface IInboxProps {
  filterType?: String;
}

const Inbox: React.FC<IInboxProps> = (props: IInboxProps) => {
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [totalEmails, setTotalEmails] = useState<number>(0);
  const [tokens, setTokens] = useState<Record<number, string>>({0: undefined});
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const [numeration, setNumeration] = React.useState<number>(1);
  const [emailContent, setEmailContent] = React.useState<EditorState>(
    EditorState.createEmpty(),
  );
  const {filterType} = props;
  const fetchEmails = async (filterType: String, searchKeyword: String, token: String, index: Number) => {
    try {
      setLoading(true);
      const fetchedEmails = await getEmails(filterType, searchKeyword, token);
      console.log("Fetched Emails:", fetchedEmails);
      setEmails(fetchedEmails.emails);
      setTotalEmails(fetchedEmails.result_size_estimate);
      setTokens((prevTokens) => ({ ...prevTokens, [index]: fetchedEmails.next_page_token }));
    } catch (error) {
      console.error("Error fetching emails:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails(filterType, searchKeyword, tokens[0], currentIndex);
  }, [filterType, searchKeyword]);

  const loadPreviousPage = () => {
    if (numeration > 1) {
      const prevIndex = currentIndex - 1;
      setCurrentIndex(prevIndex);
      setNumeration((prevNumeration) => prevNumeration - 8);
      fetchEmails(filterType, searchKeyword, tokens[prevIndex-1], prevIndex);
    }
  };

  const loadNextPage = () => {
    const prevIndex = currentIndex + 1;
    fetchEmails(filterType, searchKeyword, tokens[currentIndex], prevIndex);
    setNumeration((prevNumeration) => prevNumeration + 8);
    setCurrentIndex(prevIndex);
  };

  const handleSendEmail = (values: any) => {
    const errors = emailContentValidation(values);
    if (Object.keys(errors).length === 0) {
      const htmlContent = stateToHTML(emailContent.getCurrentContent());
      const data = {
        to: props?.email?.from || values.recipients,
        subject: values.subject,
        body: htmlContent,
        cc: values.ccField,
        bcc: values.bccField,
      };
      sendEmail(data);
    } else {
      console.error("error");
    }
  };

  const emailContentValidation = (values: any) => {
    const errors: any = {};
    if (
      values.bccField &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.bccField)
    ) {
      errors.bccField = "Invalid email address for Bcc recipients";
    }
    if (
      values.ccField &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.ccField)
    ) {
      errors.ccField = "Invalid email address for Cc recipients";
    }
    if (values.subject.trim().length === 1) {
      errors.subject = "Subject should be longer than 1 character";
    }

    const editorContentIsEmpty = !values.editorContent
      .getCurrentContent()
      .getPlainText("")
      .trim();
    if (editorContentIsEmpty) {
      errors.editorContent = "Email content cannot be empty";
    }
    return errors;
  };


  const handleFileUpload = (file: File) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", "Client-ID 8d26ccd12712fca");
      const data = new FormData(); // eslint-disable-line no-undef
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  const handleEmailContent = (
    content: EditorState,
    setFieldValue: Function,
  ) => {
    setEmailContent(content);
    setFieldValue("editorContent", content);
  };

  const currentUserView = emails && emails.length > 0 ? (
    <RenderedEmails
      emails={emails}
      fetchEmails={fetchEmails}
      totalEmails={totalEmails}
      token={tokens[currentIndex]}
      filterType={filterType}
      loadPreviousPage={loadPreviousPage}
      loadNextPage={loadNextPage}
      numeration={numeration}
    />
  ) : (
    <NoEmails />
  );

  return (
    <TableContainer>
      <TableHeading>
      <InboxNav
          emailContent={emailContent}
          handleSendEmail={handleSendEmail}
          // onClose={onClose}
          handleFileUpload={handleFileUpload}
          handleEmailContent={handleEmailContent}
          emailContentValidation={emailContentValidation}
        />
        <TableFilteringOptions>
          <SearchIcon />
          <input type="text" placeholder="Search by date, Co.Number, Keyword" value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)} />
        </TableFilteringOptions>
        <PaginationContainer>
          <PageNumbers>{`${numeration}-${numeration + 7} of ${totalEmails}`}</PageNumbers>
          <ArrowsContainer>

            <KeyboardArrowLeftOutlinedIcon onClick={loadPreviousPage} />
            <KeyboardArrowRightOutlinedIcon onClick={loadNextPage} />
          </ArrowsContainer>
        </PaginationContainer>
      </TableHeading>

      {loading? <EmailsSkeleton /> : currentUserView}
    </TableContainer>
    )
};

const ConnectedInbox: React.FC = () => {
  const { filterType } = React.useContext(DashboardContext);
  return <Inbox filterType={filterType} />;
};

export default ConnectedInbox;
