import * as React from "react";
import { User } from "../context/UserContext";

export enum EServiceNames {
  underwriting_dashboard = "underwriting_dashboard",
  underwriter_api = "underwriter_api"

}

const usePermissionsLifeCycle = (user: User) => {
  const services: {
    [key: string]: {
      [key: string]: {
        [key: string]: {
          operations: ("read" | "write" | "delete" | "update")[];
          components: string[];
        }
      }
    }
  } = user?.authorityLevel?.services || {};

  const isReadDenied =(service: EServiceNames, pathName: string) => {
    if (pathName === "/") return false;
    const selectedService = services[service];
    const deniedRoutes  = (typeof selectedService  === "object" && Object.keys(selectedService)) || [];
    if (!selectedService || !deniedRoutes.length) return false;
    const selectedPathName = deniedRoutes.find((route)=>{
    // check if route is empty
      // check route against pathName using regex
      try{
        const regex = typeof route === "string" &&  RegExp(`(${route})`);
        const isAMatch = regex?.test(pathName);
        return isAMatch;
      }
      catch(e){
        console.log(e);
        return false;
      }
    })

    if(selectedPathName){
      const selectedPath = selectedService[selectedPathName];
      if ( selectedPath?.deny?.operations?.length === 0 || selectedPath?.deny.operations.includes("read")
      ) return true;

    }else{
      const all = selectedService["*"];
      if (all?.deny.operations.includes("read")
      ||all?.deny?.operations?.length === 0
      ) return true;
    }
    
    return false;
  }

  const isWriteDenied =(service: EServiceNames, pathName: string) => {
    const selectedService = services[service];
    if (!selectedService) return false;
    const selectedPath = selectedService[pathName];
    if (!selectedPath) return false;
    const deniedOperations = selectedPath.deny?.operations || [];
    return deniedOperations.includes("write");
  }

  const isDeleteDenied =(service: EServiceNames, pathName: string) => {
    const selectedService = services[service];
    if (!selectedService) return false;
    const selectedPath = selectedService[pathName];
    if (!selectedPath) return false;
    const deniedOperations = selectedPath.deny?.operations || [];
    return deniedOperations.includes("delete");
  }

  const isUpdateDenied =() => (service: EServiceNames, pathName: string) => {
    const selectedService = services[service];
    if (!selectedService) return false;
    const selectedPath = selectedService[pathName];
    if (!selectedPath) return false;
    const deniedOperations = selectedPath.deny?.operations || [];
    return deniedOperations.includes("update");
  }


  return {
    isReadDenied, 
    isWriteDenied,
    isDeleteDenied,
    isUpdateDenied
  }
};


export default usePermissionsLifeCycle;