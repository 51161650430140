import * as React from 'react';
import { Table, TableContainer, TableFilteringOptions, TableHeading} from "../../AssignmentTable/styles";
import { PriorityContainer, ControlContainer, ExpandTaskButton, StyledTableRow } from "../styles";
import { Skeleton } from '@mui/material';
import { ITodaysPriortiesProps } from '..';



const TodaysPrioritiesSkeleton = (props:ITodaysPriortiesProps) => {
    return (
        <>
        <PriorityContainer>
          <h2>Today's Priorities</h2>
        </PriorityContainer>
        <TableContainer>
        <TableHeading>
          <h3 data-testid="header-skeleton">Today's Priorities</h3>
          <TableFilteringOptions>
            <Skeleton className="skeletondiv" height="100%" width="100%" animation="wave" />
          </TableFilteringOptions>
        </TableHeading>
          <Table>
            <thead>
              <tr className="table-header">
                <Skeleton className="skeletondiv" height={20} variant="text" animation="wave"/>
              </tr>
            </thead>
            <tbody>
            {Array.from({length:10}).map((_, currentRow) => (
              <tr key={currentRow}>  
              <td colSpan={10}>
              <Skeleton className="skeletondiv" variant= "rectangular" height={40} animation="wave" />
              
            </td>        
              </tr>
        ))}
        </tbody>
          </Table> 
       
      </TableContainer>
      </>
    
    )
}
export default TodaysPrioritiesSkeleton;