import * as React from 'react'
import { IComponentProps } from ".."
import Payments from '../../Payments'


const PaymentInformation = (props:IComponentProps) => {
    return (
        <Payments {...props}/>
    )
}

export default PaymentInformation