import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import type { Task } from "../../../../../api/v1/assignments/types/assignments";
import { AccordionHeader } from "./styles";
import { useParams } from "react-router-dom";
import { getTasks } from "../../../../../api/v1/tasks/get";
import RenderedTask from "./components/RenderedTask";
import TasksSkeleton from "./components/TasksSkeleton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import TaskMenu from "./TaskMenu";


const AssignmentHeader = (props: {
  accordionTitle: string;
  handleExpandAddTaskMenu: () => void;
}): JSX.Element => (
  <>
    <h3>{props?.accordionTitle}</h3>
    <AddCircleOutlineOutlinedIcon
      color="primary"
      style={{ cursor: "pointer", fontSize: "1.8rem" }}
      onClick={(event) => {
        event.stopPropagation();
        props?.handleExpandAddTaskMenu();
      }}
    />
  </>
);

export interface IAccordionComponentProps {
  clickOnMessage: () => void;
  hideSummary?: boolean;
  accordionTitle?: string;
  expandAccordion?: boolean;
  setExpandAccordion?: (expandAccordion: boolean) => void;
  defaultTask: Task[];
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  handleExpandAddTaskMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  assignmentId?: string;
  setTasks: (tasks: Task[]) => void;
  tasks: Task[];
  contract: string;
  taskHistory?: taskHistory[];
  titleChange?: () => void;
  displayHistory?: boolean;
  setDisplayHistory?: (displayHistory: boolean) => void;
  selectedQuickView?: string;
  isLoading?: boolean;
}

const AccordionComponent = (props: IAccordionComponentProps): JSX.Element => {
  const {
    hideSummary,
    accordionTitle,
    clickOnMessage,
    expandAccordion,
    setExpandAccordion,
    tasks,
    setTasks,
    contract,
    taskHistory,
    titleChange,
    displayHistory,
    setDisplayHistory,
    selectedQuickView,
    defaultTask,
    isLoading,
  } = props;
  const [loading, setLoading] = React.useState(true);
  const [expandTaskMenu, setExpandTaskMenu] = React.useState(false);
  const { assignmentId } = useParams();

  const handleExpandAccordion = (): void => {
    setExpandAccordion(!expandAccordion);
    if (expandAccordion) {
      setExpandTaskMenu(false);
    }
  };

  const handleExpandAddTaskMenu = (): void => {
    setExpandTaskMenu(!expandTaskMenu);
    if (!expandAccordion) {
      setExpandAccordion(true);
    }
  };

  const getTasksFromApi = (): void => {
    getTasks(props?.assignmentId || assignmentId).then((results) => {
      setTasks(results);
      if (!setExpandAccordion) return;
      results?.length ? setExpandAccordion(true) : setExpandAccordion(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (assignmentId || props?.assignmentId) {
      getTasksFromApi();
    }
  }, [props?.assignmentId, assignmentId]);

  React.useEffect(() => {
    setExpandAccordion(expandAccordion);
  }, [expandAccordion])

  const viewToUse = loading ?
    <TasksSkeleton />
    :
    <RenderedTask
      clickOnMessage={clickOnMessage}
      tasks={tasks}
      setTasks={setTasks}
      contract={contract}
      taskHistory={taskHistory}
      titleChange={titleChange}
      displayHistory={displayHistory}
      setDisplayHistory={setDisplayHistory}
      assignmentId={assignmentId || props?.assignmentId}
      selectedQuickView={selectedQuickView}
    />

  return (
    <>
      <Accordion className={props?.className} expanded={expandAccordion}>
        <AccordionSummary
          style={{ display: hideSummary ? "none" : "" }}
          onClick={(event: React.MouseEvent<Element>) => {
            handleExpandAccordion(event);
          }}
          expandIcon={<ExpandLessIcon fontSize="large" />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <AccordionHeader>
            <AssignmentHeader
              accordionTitle={accordionTitle}
              handleExpandAddTaskMenu={handleExpandAddTaskMenu}
            />
          </AccordionHeader>
        </AccordionSummary>

        <AccordionDetails
          data-testid="accordion-details"
          style={{
            overflowY: 'scroll',
            boxSizing: 'border-box',
          }}
        >
          {viewToUse}
          <TaskMenu
            expandTaskMenu={expandTaskMenu}
            defaultTask={defaultTask}
            handleExpandAccordion={handleExpandAccordion}
            handleExpandAddTaskMenu={handleExpandAddTaskMenu}
            assignmentId={assignmentId}
            setTasks={setTasks}
            tasks={tasks}
            isLoading={isLoading}
          />
        </AccordionDetails>
      </Accordion>

    </>
  );
}


export default AccordionComponent;