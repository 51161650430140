import * as React from "react";
import { Accordion, AccordionDetails } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IKPI } from "../../../../api/v1/types";
import { IEndorsements, INewBusiness, IRenewals } from "../../Metrics";
import styled from "styled-components";

export interface IUnderwriterAccordionProps {
  expandAccordion?: boolean;
  setExpandAccordion?: boolean;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  hideSummary?: boolean;
  renewals: IRenewals;
  endorsements: IEndorsements;
  newBusiness: INewBusiness;
}

export const StyledTableRow = styled.tr<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? "table-row" : "none")};
`;

const UnderwriterAccordion = (props: IUnderwriterAccordionProps) => {
  const { expandAccordion, renewals, endorsements, newBusiness } = props;
  const renderRow = (data: any, category: string) => {
    return (
      <StyledTableRow isVisible={expandAccordion}>
        <td className="category-metrics">{category}</td>
        {Object.values(data[category]).map((metric: IKPI, index) => (
          <td key={`${category}-${index}`}>
            {metric.change === 0 ? (
              metric.value
            ) : (
              <>
                {" "}
                {metric.value} -{" "}
                <span style={{ color: "blue" }}>{metric.change}%</span>{" "}
              </>
            )}
          </td>
        ))}
      </StyledTableRow>
    );
  };

  return (
    <>
      {["renewals", "endorsements", "newBusiness"].map((category) => {
        const underwriterMetrics = {
          renewals: renewals,
          endorsements: endorsements,
          newBusiness: newBusiness,
        };

        return renderRow(underwriterMetrics, category);
      })}
    </>
  );
};
export default UnderwriterAccordion;
