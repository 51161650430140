import * as React from 'react';
import { UsersHeaderWrapper } from '../styles';
import { IUser } from '../../../../api/v1/users/types/user';
import { Link, useLocation } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { User } from '../../../../context/UserContext';
import { IDepartment } from '../../../../api/v1/types';
import Skeleton from '@mui/material/Skeleton'; // Import Skeleton if not already imported

interface IUsersHeaderProps {
    users: User[];
    departments: IDepartment[];
    loading: boolean;
};

const UsersHeader = (props: IUsersHeaderProps) => {
    const { users, departments, loading } = props;

    if (loading) {
        return (
            <UsersHeaderWrapper>
                <div>
                    <Skeleton className="skeletondiv" height='9rem' width={100} variant="text" animation="wave" />
                    <span>
                        <Skeleton className="skeletondiv" height={30} variant="text" animation="wave" />
                    </span>
                </div>
                <div>
                    <Skeleton className="skeletondiv" height='9rem' width={100} variant="text" animation="wave" />
                    <span>
                        <Skeleton className="skeletondiv" height={30} variant="text" animation="wave" />
                    </span>
                </div>
                <div>
                    <Skeleton className="skeletondiv" height='9rem' width={100} variant="text" animation="wave" />
                    <span>
                        <Skeleton className="skeletondiv" height={30} variant="text" animation="wave" />
                    </span>
                </div>
            </UsersHeaderWrapper>
        );
    }

    const arrayUsers = Object.values(users);
    const arrayOfDepartments = Object.values(departments);
    const inActiveUsers = arrayUsers.filter(user => !user.isActive).length;

    const location = useLocation();
    const isDepartmentsPage = location.pathname === '/departments';
    return (
        <UsersHeaderWrapper>
            <Link to='/user-management/departments'>
                <div className="users-length">
                    <span>{arrayOfDepartments.length}</span>
                    <div className="dept-div">Departments {isDepartmentsPage ? <KeyboardArrowDown style={{ fontSize: 10 }} /> : <KeyboardArrowRight style={{ fontSize: 10 }} />}</div>
                </div>
            </Link>
            <div className="vertical-line"></div>
            <div className="users-length">
                <span>{arrayUsers.length}</span>
                People
            </div>
            <div className="vertical-line"></div>
            <div className="users-length">
                <span>{inActiveUsers}</span>
                Inactive
            </div>
        </UsersHeaderWrapper>
    );
};
export default UsersHeader;
