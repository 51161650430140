import * as React from "react";
import {
  TableContainer,
  Table,
  TableHeading,
  BackButton,
} from "./styles";
import ReplyIcon from "@mui/icons-material/Reply";
import FilterOptions from './components/FilterOptions';
import FilteredResults from './components/FilteredResults';
import type { IDocumentType } from "../../../api/v1/documents/types/DocumentTypes";


const Documents = (): JSX.Element => {
  const [showFilterResults, setShowFilterResults] = React.useState(false);
  const [documents, setDocuments] = React.useState<IDocumentType[]>([])
  const [contractNo, setContractNo] = React.useState<string>()
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documentType, setDocumentType] = React.useState<string>('')
  
  return (
    <>
      <h2>Documents</h2>
      <TableContainer>
        <TableHeading>
          <h3>Search and Filter Documents</h3>
          {showFilterResults && (
            <BackButton data-testid="back-documents" onClick={() => { setShowFilterResults(false) }}>
            <ReplyIcon />
            Back
          </BackButton>
            )}
        </TableHeading>

        <Table>
          {showFilterResults ? (
            <FilteredResults 
              contract_no={contractNo} 
              documents={documents} 
              loading={loading} 
              documentType={documentType} 
              setDocumentType={setDocumentType} 
            />
          ) : (
            <FilterOptions
              setShowFilterResults={setShowFilterResults}
              setDocuments={setDocuments} 
              contractNo={contractNo} 
              setContractNo={setContractNo} 
              setLoading={setLoading}
              documentType={documentType}
              setDocumentType={setDocumentType}
              />
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default Documents;