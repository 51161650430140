import  * as React from "react";
import styled from "styled-components";

export interface IPillButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
}

const StyledPillButton = styled.button`
    max-width: 230px;
    margin: 1rem 0;
    font-size: 1rem;
    border: 1px solid #2d2b2b45;
    border-radius: 2rem;
    cursor: pointer;
    padding: 0.3rem 0.8rem; 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

`;

const PillButton: React.FC<IPillButton> = ({ children, onClick, ...rest }) => {
    const _onClick = (e): void => {
        e.preventDefault();
        onClick();
    }
    return (
        <StyledPillButton className="pill-button" onClick={_onClick} {...rest}>
            {children}
        </StyledPillButton>
    );
};

export default PillButton;
