import { doc } from "prettier";
import { api, files } from "../index";

export const postDocument = async (file: File, documentType: string, assignmentId: string, suppressMessage: boolean = false) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append("type", documentType)
  formData.append("assignment_id", String(assignmentId))
  formData.append("suppress_message", suppressMessage)

  try {
    const response = await api.post('/documents/', formData);
    return response.data;
  } catch (error) {
    console.error('Error uploading document:', error);
  }
};

export const fetchDocPreview = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file);
  const results = await files.post('/preview', formData).then((res) => {
    const files = res.data.file[0][1]
    return files
  })
  return results
}