import React from "react";
import styled from "styled-components";

export const StyledLabel = styled.label`
  display: flex;
  gap: 1rem;
  line-height: 1.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  font-size: 0.8rem;
  position: relative;
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;
  }
  th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 15px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }
  .styled-headers {
    background-color: #cce5ff;
  }
`;

export const TableHeading = styled.section`
  padding: 1rem;
  display: flex;
  color: #6d7175;
  justify-content: space-between;
  align-items: center;
`;

export const TableFilteringOptions = styled.section`
  border: 1px solid #babfc3;
  flex-basis: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  overflow: hidden;
  input {
    flex-basis: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
  }
`;

export const TableContainer = styled.section`
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  
  flex-direction: column;
  .table-container {
    overflow: auto;
  }
  
`;

export const Menu = styled.menu`
  margin: 0;
  padding: 0;
`;
