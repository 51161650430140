import * as React from 'react';
import Button from '../../../../components/FormElements/Button';
import styled from 'styled-components';
import { Menu } from '../../AssignmentTable/styles';
import { DashboardContext } from '../..';

//add border to the sencond button

const StyledMenu = styled(Menu)`
  
  text-align: center !important;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  div {
    padding: 10px;
  }
  .inbox-options {
    text-align: center !important;
    color: black;
    &:hover {
      color: #3361FF;
      font-weight: 500;
      background-color: #F3F6FF !important;
    }
  }
  div:nth-child(2) {
    border-top: 1px solid #ccc !important;
    border-bottom: 1px solid #ccc !important;
  }
  
`;

const FilterInboxMenu = ({setFilterType}) => {
  return(
    <StyledMenu data-testid="popover">
      <div onClick={() => setFilterType("all")}>All Emails</div>
      <div className='inbox-options' onClick={() => setFilterType("internal")}>Internal Emails Only</div>
      <div className='inbox-options' onClick={() => setFilterType("external")}>External Emails Only</div>
    </StyledMenu>
  );
}


const ConnectedFilterInboxMenu = () => {
  const { setFilterType } = React.useContext(DashboardContext);
  return(
    <FilterInboxMenu setFilterType={setFilterType}/>
  )
}

export default ConnectedFilterInboxMenu;