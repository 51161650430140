import styled from "styled-components";
import { Menu } from "../../AssignmentTable/styles";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  position: relative;
  border: 1px solid #babfc3;
  margin-top: 1.8rem;
  margin-bottom: 2.8rem;
  width: 100%;
  overflow: scroll;

  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;

    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
  th {
    background: white;
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 5px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
    // &:first-child {
    //   z-index: 2;
    // }
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }
`;

export const AttachMenuContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 32rem;
  height: 20.8rem;
  transform: translate(50%, -50%);
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fff;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  transition: height 0.3s ease;

  .container-header {
    display: flex;
    width: 30rem;
    height: 2.25rem;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
    background: var(--surface-subdued, #fafbfb);
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;

  .container-body {
    min-height: 200px;
    width: 100%;
    background-color: white;
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
`;

export const GoogleButton = styled.button`
  width: 246px;
  height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #fafbfb;
  border: 1px solid;
  border-color: #7886a2;
  border-radius: 0.25rem;
  gap: 1rem;
  cursor: pointer;
  .google-button-text {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
export const GoogleButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.6rem;
`;

export const GoogleButtonContainer = styled.div`
  padding: 0rem 2rem 2rem 2rem;
  background: #fff;
  box-shadow:
    0px 1px 120px 0px rgba(0, 0, 0, 0.2),
    4px 4px 10px 0px rgba(0, 0, 0, 0.2),
    -4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5%;
  top: 8%;
  z-index: 1000;
`;

export const EmailTextWrapper = styled.div`
  padding-top: 2rem;
  text-align: center;
  color: #202124;
  font-weight: 400;
  font-size: 1rem;
`;
export const TableFilteringOptions = styled.section`
  flex-basis: 33%;
  display: flex;
  flex-direction: row;
  margin-top: 1.7rem;
  justify-content: center;
  align-items: end;
  overflow: visible;
`;

export const PDFIcon = styled.div`
  background-color: #ff0000;
  border-radius: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.625rem;
`;

export const NoEmailsDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 12rem;
`;

export const WordIcon = styled.div`
  background-color: #186ade;
  border-radius: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 12px solid white;
  position: absolute;
`;

export const Line = styled.div`
  width: 0.625rem;
  height: 0.313rem;
  background-color: #ff0000;
  top: 0.438rem;
  transform: rotate(-45deg);
  position: absolute;
`;

export const ImageIcon = styled.div`
  background-color: #ff0000;
  border-radius: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
`;

export const DocumentTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: 0.5rem;
    max-width: 12.5rem;
  }
`;

export const TableHeaderCell = styled.th`
  width: 9.375rem;
`;

export const SubmitTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.688rem;
  height: 2.188rem;
  background-color: ${(props) => (props.disabled ? "#cccccc" : "#3361FF")};
  border-color: ${(props) => (props.disabled ? "#cccccc" : "#3361FF")};
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  cursor: pointer;
  position: absolute;
  right: 3.375rem;
  bottom: 2.5rem;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AttachmentDiv = styled.div`
  width: 3.438rem;
  display: flex;
  justify-content: space-between;
`;

export const GoToEmailDiv = styled.div`
  color: rgb(24, 106, 222);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    font-size: small;
  }
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const AllEmailsDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledEmailRow = styled.tr<{
  selected?: boolean;
}>`
  height: 3.5rem;
  cursor: pointer;
  transition: background-color 0.5s ease-out;
  background-color: ${(props) =>
    props.selected ? "aliceblue" : "transparent"};
  //need to fix
  box-shadow: ${(props) =>
    props.selected ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25);" : "none"};
`;

export const StyledAccordionTableRow = styled.tr<{
  isVisible: boolean;
}>`
  display: ${(props) => (props.isVisible ? "table-row" : "none")};
  height: 3.5rem;
  .accordion-td {
    padding: 0;
  }
`;

export const EmailHeader = styled.div`
  display: flex;
  flex-direction: flex-start;
  color: #000;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const EmailBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .email-body {
    height: max-content;
    border: none;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e5e5e5;
    font-size: 0.875rem;
    min-height: 400px;
  }

  .email-body-from {
    padding-bottom: 4rem;
    font-size: 0.875rem;
  }

  .to {
    padding-top: 2rem;
    width: 100%;
  }

  .from {
    padding-top: 2rem;
    width: 100%;
  }

  .name-time-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
  }

  .email-time {
    display: flex;
    flex-direction: flex-end;
    justify-content: space-between;
    gap: 1rem;
  }

  .email-to-div {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
  }
  .to-avatar-div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-bottom: 1rem;
  }

  .email-to-div span {
    padding-top: 0.5rem;
    font-size: 0.75rem;
    font-weight: 100;
  }

  .body-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
  }
`;

export const EmailAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  .circle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75px;
    font-weight: bold;
  }

  &.received {
    background: #f379e0;
    color: #fff;
    font-size: x-large;
    font-weight: 700;
  }

  &.sent {
    background: #5794eb;
    color: #fff;
    font-size: x-large;
    font-weight: 700;
  }
`;

export const EmailHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
`;

export const EmailControlContainer = styled.div`
  display: flex;
  flex-direction: flex-end;
  gap: 1rem;
`;

export const EmailFooter = styled.div`
  display: flex;
  flex-direction: flex-start;
  gap: 1rem;
  padding-top: 1rem;
  margin-left: 2rem;
  .email-footer-icon {
    color: #1849f2;
    border-radius: 0.25rem;
    border: 2px solid #1849f2;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
    cursor: pointer;
    gap: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledMenu = styled(Menu)`
  text-align: center !important;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  font-size: 0.75rem;
  .menu-icons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    color: #000000;
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
    }
  }
`;

export const AttachmentBodyDiv = styled.div`
  width: 3.438rem;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  margin-left: 4rem;
  gap: 0.5rem;

  span[title]:hover::after {
    // content: attr(title);
    position: absolute;
    background-color: #pink;
    color: #white;
    padding: 0.5rem;
    border: 1px solid #babfc3;
    border-radius: 4px;
    // z-index: 1;
    font-size: 0.8rem;
    top: 14rem;
  }
`;

export const ImageTypeDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
`;

export const CloseIconDiv = styled.div`
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
  }
`;

export const ReplyContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 50%;
  transform: translate(50%, -50%);
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  .header {
    border-radius: 0.25rem;
    background: #3361ff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  }
  .header-icons {
    gap: 0.4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
  }
  
}
.attach {
  font-size: 1.8rem;
  &:hover {
    background-color: lightgrey;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.8rem;
  gap: 0.8rem;
}
.formatting {
  text-decoration: none;
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  font-size: 1.6rem;
  &:hover {
    background-color: lightgrey;
  }
}
.email-error {
  color: red;
  font-size: 0.8rem;
  margin-top:  0.5rem;
}
 @media (max-width: 768px) {
    .editor-class {
      height: 10rem; 
    }
  }
`;
export const SendButton = styled.button<{disabled: any}>`
  background-color: #3361ff;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: 0.8rem 1rem 0.8rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  &:disabled {
    background-color: #B1B3C5;
    border-color: transparent;
  }
  `;
export const MinimizedView = styled.div`
position: fixed;
bottom:0;
right: 49px;
width: 17rem;
height: 2rem;
background-color: white;
border-top: 1px solid #dadde9;
border-left: 1px solid #dadde9;
border-right: 1px solid #dadde9;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
box-shadow: 0px 0px 10px 0px #dadde9;
border-radius: 5px 5px 5px 5px;
.icons {
  gap: 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
}
.close {
  font-size: 1rem;
  cursor: pointer;
  margin-left: 3rem;
}
`;

export const EmailBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: auto;
`;

export const TextArea = styled.textarea`
  height: 30rem;
  border: none;
  text-color: black;
  padding: 1rem 1rem 3rem 1rem;
  resize: none;
`;

export const EmailHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  .input-div {
    border: none;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .input {
    border: none;
    padding: 1rem;
    outline: none;
    width: 100%;
  }
  .link-recipient {
    display: flex;
    justify-content: flex-end;
    gap: 0.2rem;
    button {
      border: none;
      background: none;
      font-size: 0.8rem;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
