import {auth} from '../index';
import { IDepartment } from '../types';

export const updateDepartments = async (department: IDepartment, managing: number[] = undefined , managed: number[] = undefined): Promise<IDepartment[]> => {

  const body = {
    department,
    managing,
    managed
  }

  try {
    const response = await auth.put("/organization_hierarchy/", body);
    return response.data.organization_hierarchy;
  } catch (error) {
    console.error("Error adding department:", error);
    return []
  }
};

export const updateDepartmentsV2 = async ({department, managing, managed} : {department: IDepartment, managing: number[] , managed: number[]}): Promise<IDepartment[]> => {

  const body = {
    department,
    managing,
    managed
  }

  try {
    const response = await auth.put("/organization_hierarchy/", body);
    return response.data.organization_hierarchy;
  } catch (error) {
    console.error("Error adding department:", error);
    return []
  }
};
