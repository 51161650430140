import styled from "styled-components";
import { EAlertType } from "../../Alerts";

export const Table = styled.table<{ isOpen?: boolean }>`
  width: 100%;
  font-size: 0.8rem;
  position: relative;
  border-collapse: collapse;
  border:1px solid #babfc3;
  .table-header {
    border: 1px solid #e5e5e5;
  }
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;
    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
  th {
    background: white;
    position: sticky;
    top: 0;
  }
  th,
  td {
    color: #6d7175;
    text-align: left;
  }
  th {
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    z-index: 1;
  }
  td {
    color: #202223;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .incomplete-tasks {
    text-align: center;
    font-size: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }
  .table-container {
    overflow: auto;
  }
  .skeleton-div{
    border-radius: 20px;
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
`;

export const TableContainer = styled.section`
  background-color: #ffffff;
  overflow: hidden;
  min-height: 0px;
  overflow-y: hidden;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  box-sizing: border-box;
`;
export const TableHeading = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableFilteringOptions = styled.section`
  border: 1px solid #babfc3;
  flex-basis: 33%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem;
  overflow: visible;
  input {
    flex-basis: 100%;
    padding: 0.5rem;
    border: none;
    outline: none;
    min-width: 300px;
  }
`;

export const AlertsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  display : flex;
  padding: 1rem;
  box-sizing: border-box;
  flex-direction: column;
  .alerts-menu {
    display: flex;
    align-items: center;
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
`;

export const ExpandTaskButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 2em;
  cursor: pointer;
  width: 35%;
  &:hover {
    border: 1px solid #3e3e3c;
    border-radius: 5px;
    font-weight: bold;
  }
`;

export const StyledTableRow = styled.tr<{
  alert: string;
  selected?: boolean;
  typeAlert: EAlertType;
  isOpen?: boolean;
}>`
  height: 3.5rem;
  box-shadow: 0px -1px 0px 0px #e1e3e5 inset;

  .alert-button{
    background:none;
    border:none;
    cursor:pointer;
  }

  .add-alert-button {
    background: white;
    color: rgba(51, 97, 255, 1);
    border-radius: 0.3rem;
    box-shadow:
      0px 2px 2px 0px rgba(0, 0, 0, 0.3),
      0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    width: 2.75rem;
    height: 2.3rem;
    cursor:pointer;
  }
  .view-alert-button {
    background: white;
    color: rgba(51, 97, 255, 1);
    border-radius: 0.3rem;
    box-shadow:
      0px 2px 2px 0px rgba(0, 0, 0, 0.3),
      0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    width: 2.75rem;
    height: 2.3rem;
  }
  .viewed-alert {
    background: rgba(51, 97, 255, 1);
    color: white;
    border-radius: 0.3rem;
    box-shadow:
      0px 2px 2px 0px rgba(0, 0, 0, 0.3),
      0px 0px 1px 0px rgba(0, 0, 0, 0.25);
    width: 2.75rem;
    height: 2.3rem;
  }
  td:nth-child(2) {
    &.today {
      background-color: #91d5ff;
    }

    &.not-today {
      background-color: #cce5ff;
    }
  }
  .tooltip {
    background: white;
  }
`;
export const StatusBadge = styled.div<{ status: string }>`
  border-radius: 0.25rem;
  border: 1px solid var(--info-200, #a2d1ff);
  background: var(--info-100, #cce5ff);
  padding: 0.25rem 1rem;
  color: #44474b;
  text-align: center;
  display: inline-block;
  font-size: 10px;
`;

export const AddTaskModal = styled.div`
  width: 32rem;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  z-index: 99;
  position: relative;

  .modal-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal-header {
    display: flex;
    width: 30rem;
    height: 2.25rem;
    font-size: 0.75rem;
    padding: 10px 16px;
    gap: 5rem;
    background: var(--surface-subdued, #fafbfb);
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;
    .broker {
      width: 15rem
    }
  }
  .modal-icon {
    color: rgba(51, 97, 255, 1);
  }
  .modal-row {
    height: 56px;
    display: flex;
    padding: 10px 16px;
    gap: 5rem;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    box-shadow: 0px -1px 0px 0px #e1e3e5 inset;

    .contractNo{
      width: 9rem;
    }

    .broker {
      width: 18rem
      text-align: center
    }
  }
  .success-modal-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    text-align: center;
    padding-top: 4rem;
  }
  .sd {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
export const TaskSuccess = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 32rem;
  height: 14rem;
  z-index: 100;
  transform: translate(50%, -50%);
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  .success-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
  .span-text {
    text-decoration: underline;
  }
`;


export const ModalInput = styled.div`
padding-top: 1rem;
justify-content: center;
.modal-input:required{
  width: 30rem;
  height: 3rem;
  border-bottom: 2px solid #3361FF; 
  border-radius: 4px 4px 0px 0px;
  background: #FFF
  padding-left: 1rem;
  background:rgba(243, 246, 255, 1);
  border: none;
  border-bottom: 2px solid #3361FF; 
  padding-left: 0.5rem;
}
.input-label { 
  font-size: 15px; 
  margin-bottom: 8px;
  color: #3361FF;s
}

`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  padding-top: 3rem;
`;

export const SubmitTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 123px;
  height: 35px;
  background-color: ${(props) => (props.disabled ? "#cccccc" : props.buttonBackgroundColor || "#3361FF")};
  border-color: ${(props) => (props.disabled ? "#cccccc" : props.submitBorderColor || "#3361FF")};
  border-radius: 0.25rem;
  color: ${props => props.submitButtonText || 'white'};
  border-style: solid;
  cursor: pointer;
`;

export const WarningText = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  margin: 1rem 0;
  color: gray;
`;

export const TitleText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #202223;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 123px;
  height: 35px;
  background-color: ${props => props.buttonBackgroundColor || '#cccccc'};
  border-color:${props => props.cancelButtonColor || '#cccccc'};
  border-radius: 0.25rem;
  color: ${props => props.cancelTextColor || 'white'};
  border-style: solid;
  cursor: pointer;
`;

export const AlertsMenu = styled.div`
  cursor: pointer;
  border-radius: 4px;

  .dropdown-content {
    padding-bottom: 0.7rem;
    position: absolute;
    width: 472px;
    height: 344px;
    background: #fff;
    z-index: 1;
    box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  }

  .dropdown-item {
    padding: 18px 16px;
    border-bottom: 1px solid #dbdbdb;
    text-align: center;
    color: #000;
    font-size: 1rem;
    margin: 0;
    &:hover {
      background: #f3f6ff;
      text-decoration: underline;
      color: #3361ff;
    }
  }

  .alerts-dropdown {
    display: flex;
    justify-content: space-between;
  }

  .date-range {
    font-size: 1rem;
    color: #202223;
    padding-left: 1rem;
    padding-top: 2rem;
  }

  .date {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.75rem;
    align-items: center;
    color: rgba(62, 62, 60, 1);
  }

  .date-inputs-div {
    padding-left: 1rem;
    display: flex;
    width: 50%;
    gap: 10px;
    align-items: center;
  }

  .hyphen {
    font-size: 1rem;
    margin: 0 10px;
    color: #000;
    position: relative;
  }
  .date-input {
    width: 193px;
    border: 1px solid #dbdbdb;
    padding: 0.2rem;
    
  }
  
  .pad10 {
    padding-block:10px
  }
  
  .date-range-wrapper {
    padding-left: 1.1rem;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NoAlertsDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 12rem;
`
export const CloseIconDiv = styled.div`
  width: 29px;
  height: 26px;
  .modal-close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
  }
`;