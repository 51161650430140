import styled from "styled-components";

export const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 1.5rem;
  background-color: #f7f8fa;
  .company-name {
    color: #3e3e3c;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
  }
`;
export const LoginSection = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-shrink: 0;
  padding: 2rem;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  width: 22rem;

  .top-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;    
  }

  .header-style {
    color: #000;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Lato;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .middle-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100%;
    gap: 2.5rem;
    width: 100%;
    font-size: 0.8rem;
  }

  .remember-me-checkbox {
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%; 
  }

  .forgot-password-signin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .forgot-password-text {
      color: #008170;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Lato;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .signin-button {
      color: #FAFBFB;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: Lato;
      font-size: 1rem;
      font-style: normal;
      line-height: 16px;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
      &:hover {
        background-color: #008170;
      }
    }
  }
`;

export const StyledButton = styled.button<{disabled: any}>`
  width: 7rem;
  height: 2.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #3361ff;
  border-style: solid;
  border-color: #3361ff;
  border-radius: 0.25rem;
  cursor: pointer;
  &:disabled {
    background-color: #B1B3C5;
    border-color: transparent;
  }

`;

export const GoogleButton = styled.button`
  width: 22rem;
  height: 2.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #fafbfb;
  border: 1px solid;
  border-color: #7886a2;
  border-radius: 0.25rem;
  gap: 1rem;
  cursor: pointer;
  .google-button-text {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ErrorOnLogin = styled.div`
border-radius: 0.25rem;
background: rgba(248, 226, 225, 0.68);
color: #CC0707;
width: 100%;
height: 1.7rem;
display: flex;
gap: 1rem;
align-items: center;
padding-left: 0.8rem;
font-size: 0.8rem;
`;


export const LogoContainer = styled.div`
height: 7rem;
img {
  height: 100%;
}
`;