import {api, files} from "../index";

export const fetchDocuments =  (contractNo:string = null,  documentType:string, category:string = null, companyId:number = null ) => {
  try{
    return api.get(`/documents/?${contractNo ? `contract_no=${contractNo}&` : '' }${category && companyId ? `company_type=${category.toLowerCase()}&company_id=${companyId}&` : ''}${documentType == "All Documents"? '':`document_type=${documentType}`}` )
  }catch(e){
    console.error(e)
  }
};

export const fetchDocumentBlobs = async (path: string) => {
  try{
    return files.get(`/${path}`,{ responseType: 'blob' })
  }catch(e){
    console.error(e)
  }
}