import * as React from 'react'
import { 
  AddTaskModal,
  CloseIconDiv,
  ModalInput,
  SubmitTaskButton,
  CancelButton
 } from '../../pages/Dashboard/AlertsPage/styles';
import CloseIcon from '@mui/icons-material/Close';
interface INetworkErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string;
  title: string;
  message: string;
}
import styled from 'styled-components';
import ErrorIcon from '@mui/icons-material/Error';
export const ConfirmationContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 22rem;
  height: 13rem;
  transform: translate(50%, -50%);
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 2rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  ${CancelButton}, ${SubmitTaskButton} {
    reset: all;
  }
  .error-icon {
    color: red;
  }
  .message{
    display: flex;
    gap: 1rem;
  }
  .action-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  `;

const NetworkErrorModal = (props: INetworkErrorModalProps) => {
  const { isOpen, onClose, onConfirm, title, message, confirmLabel } = props;
  return (
    <ConfirmationContainer open={isOpen}>
      <CloseIconDiv>
        <CloseIcon onClick={onClose} className="modal-close" />
      </CloseIconDiv>
      <h2>{title}</h2>
      <p className='message'><ErrorIcon className='error-icon'/>{message}</p>
      <div className='action-container'>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <SubmitTaskButton  onClick={onConfirm}>{confirmLabel || "Confirm"}</SubmitTaskButton >
      </div>
    </ConfirmationContainer>
  );
}

export default NetworkErrorModal