import * as React from "react";
import {
  CompanyCardWrapper,
  MetricsTable,
  MetricsTableContainer,
  MetricsWrapper,
} from "./styles";
import CompanyKPI from "./components/CompanyKPI";
import { IKPI } from "../../../api/v1/types";
import RenderRow from "./components/RenderRow";
import { ControlContainerWrapper } from "../LeadershipMetrics/styles";
import { PrintOutlined } from "@mui/icons-material";
import { ControlContainer } from "../TodayPriorities/styles";

export interface IRenewalsRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

export interface IRenewals {
  IRenewalsRow: IRenewalsRow[];
}

export interface IEndorsementsRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

export interface IEndorsements {
  IEndorsementsRow: IEndorsementsRow[];
}

export interface INewBusinessRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

export interface INewBusiness {
  INewBusiness: INewBusinessRow[];
}

export interface ITotalRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

export interface ITotal {
  ITotalRow: ITotalRow[];
}

interface IMetricsProps {
  renewals: IRenewals;
  endorsements: IEndorsements;
  newBusiness: INewBusiness;
  total: ITotal;
  expandAll?: boolean;
}

export const Metrics = (props: IMetricsProps) => {
  return (
    <>
      <MetricsWrapper>
        <CompanyCardWrapper>
          <CompanyKPI
            cardTitle="Renewals"
            avgWorkloadLabel={"Avg. Workload/ Daily "}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={90}
          />
          <CompanyKPI
            cardTitle="Endorsements"
            avgWorkloadLabel={"Avg. Workload/ Daily "}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={30}
          />
          <CompanyKPI
            cardTitle="New Business"
            avgWorkloadLabel={"Avg. Workload/ Daily "}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={50}
          />
          <CompanyKPI
            cardTitle="Total"
            avgWorkloadLabel={"Avg. Workload/ Daily "}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={70}
          />
        </CompanyCardWrapper>

        <MetricsTableContainer>
          <MetricsTable>
            <thead>
              <tr>
                <th className="type-metrics">Type</th>
                <th>ACTIVE</th>
                <th>PENDING (WIP)</th>
                <th>REQUEST</th>
                <th>ESCALATE</th>
                <th>
                  ACCEPTED (Total/<span className="span-color">%</span>)
                </th>
                <th>
                  DECLINED (Total/<span className="span-color">%</span>)
                </th>
                <th>
                  RENEWED (Total/<span className="span-color">%</span>)
                </th>
                <th>INACTIVE (Not Closed)</th>
                <th>CLOSED (last 30 days)</th>
                <th>LOST (Total/%)</th>
                <th>TOTAL</th>
              </tr>
            </thead>

            <RenderRow />
          </MetricsTable>
        </MetricsTableContainer>
      </MetricsWrapper>
    </>
  );
};

export default Metrics;
