import {api} from "../index";
import { IAssignmentComment } from "../types";

export const getCount = async (taskId: number): Promise<IAssignmentComment[]> => {
  const user_id = 1;
  const task_id = taskId;
  try{
    const response = await api.get(`/comments/users/${user_id}/tasks/${task_id}`);
    return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }