import * as React from "react";
import {
  IAssignment,
  ILegacyAssignment,
} from "../../../../../../../api/v1/assignments/types/assignments";
import styled from "styled-components";
import { Menu } from "../../../../styles";
import { priorityColors } from "../../../../../PriorityCard/styles";
import { editAssignment } from "../../../../../../../api/v1/assignments/put";
import { getPriorities } from "../../../../../../../api/v1/priorities/get";
import { Box, MenuItem } from "@mui/material";
import { Assignment } from "../../../../../../../api/v1/__mocks__/assignments";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { UserContext } from "../../../../../../../context/UserContext";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import DeleteIcon from '@mui/icons-material/Delete';
import { DashboardContext } from "../../../../..";
import { deleteAssignment } from "../../../../../../../api/v1/assignments/delete";
import { useNavigate } from "react-router-dom";
import asModal from "../../../../../../../hoc/asModal";
import ConfirmationModal from "../../../../../../../components/ConfirmationModal";
import { ActionType } from "../../../../../../../components/AssignmentOptions";

const StyledMenu = styled(Menu)`
  font-color: gray;
  padding: 1rem;
   button {
    color: #3361ff;
    &:hover {
      color: black;
      font-weight: 500;
    }
  }
`;

const PriorityDivCtr = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-block:2px;
  background: #ffa775;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;

  &:hover {
	box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  }
`;
const PriorityText = styled.div`
  color: #622808;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  
  word-wrap: break-word;
`;

const DropDownContainer = styled.div`
display:flex;
flex-direction:column;
`

const OptionWrapper = styled.div<{ transfer: boolean }>`
display:flex;
align-items:center;
gap: 0.8rem;
padding: 0.5rem 0;
border-bottom: ${props => props?.transfer ? 'none' : '1px #DDDFE7 solid'};
.icon {
font-size: 1.5rem;
}
&:hover {
 background-color: ${props => props?.transfer ? 'none' : '#F2F8FF'};
 color:${props => props?.transfer ? 'none' : '#3361FF !important'};
  cursor: pointer;
}
}
`;

type Priorities = "low" | "high" | "medium";
interface IAssignmentOptionsProps {
  id: string;
  markPriority: (priority: Priorities) => void;
  markAsComplete: (id: string) => void;
  escalateAssignment: (id: string) => void;
  viewAssignment: (id: string) => void;
  viewLegacyAssignments: (id: string) => void;
  setPriority: () => void;
  assignmentId: string | number;
  setPriorities: (assignments: (IAssignment & ILegacyAssignment)[]) => void;
  togglePopover: () => void;
  setCurrentAction: (action: ActionType) => void;
  className?: string;
  handleClickEvent: (event: Event) => void;
  handleOpenDeleteModal: () => void;
  setOpenExtendedModal: (openExtendedModal: boolean) => void;
}


const priorities: Priorities[] = ["high", "medium", "low"];

const AssignmentOptionsMenu = (props: IAssignmentOptionsProps) => {
  const {
    setPriorities,
    assignmentId,
    togglePopover,
    setCurrentAction,
    className,
    handleClickEvent,
    handleOpenDeleteModal,
    setOpenExtendedModal,
  } = props;
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [transferCss, setTransferCss] = React.useState(true);

  const markPriority = async (priority: Priorities) => {
    try {
      setOpenExtendedModal(true);
      switch (priority) {
        case 'high':
          setCurrentAction(ActionType.PRIORITY_HIGH);
          break;
        case 'medium':
          setCurrentAction(ActionType.PRIORITY_MEDIUM);
          break;
        case 'low':
          setCurrentAction(ActionType.PRIORITY_LOW);
          break;
      }
      togglePopover();
    }
    catch (err) {
      console.error(err)
    }
  }


  const handleDuplicateContract = async () => {
    try {
      setOpenExtendedModal(true);
      setCurrentAction(ActionType.DUPLICATE);
      togglePopover();
    }
    catch (err) {
      console.error(err)
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
    setDropdownOpen(false);
  };


  return (
    <StyledMenu data-testid="styled-popover" className={className}>
      <p>Mark As Priority</p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "1.2rem",
          gap: "0.5rem",
        }}
      >
        {priorities?.map((priority, a) => {
          const formattedPriority =
            priority.charAt(0).toUpperCase() + priority.slice(1);
          return (
            <PriorityDivCtr
              key={a}
              data-testid="priority-div"
              onClick={(e: Event) => {
                e.stopPropagation();
                markPriority(priority);
              }}
              style={{ backgroundColor: priorityColors[priority] }}
            >
              <PriorityText>{formattedPriority}</PriorityText>
            </PriorityDivCtr>
          );
        })}
      </div>
      <OptionWrapper onClick={handleDuplicateContract}>
        <ContentCopyIcon className="icon" /> Copy Information to a New Contract
      </OptionWrapper>
      <OptionWrapper onClick={handleOpenDeleteModal}>
        <DeleteIcon className="icon" /> Delete Contract
      </OptionWrapper>
      <DropDownContainer
        onMouseLeave={() => setDropdownOpen(false)}
        onMouseEnter={() => setDropdownOpen(true)}
        style={{
          display: "flex",
        }}
        data-testid="transfer-assignment"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <OptionWrapper transfer={transferCss}>
            <CompareArrowsIcon />
            Transfer Assignment To
          </OptionWrapper>
          <Box ml={2}>
            <ArrowDropDownIcon />
          </Box>
        </div>

        {dropdownOpen && (
          <Menu
            className="underwriter-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ padding: "2rem", width: "100%" }}
          >
            <div className="underwriter-menu-list">
              <ListOfUsers
                handleClickEvent={handleClickEvent}
                assignmentId={assignmentId}
                togglePopover={togglePopover}
              />
            </div>
          </Menu>
        )}
      </DropDownContainer>
    </StyledMenu>
  );
};

interface IListOfUsersProps {
  assignmentId: string;
  togglePopover: () => void;
  handleClickEvent: (event: Event) => void;
}
const ListOfUsers = (props: IListOfUsersProps): JSX.Element[] => {
  const { assignmentId } = props;
  const { userHierarchy } = React.useContext(UserContext)
  const possibleUnderwriters = userHierarchy?.managing?.Underwriters?.users || {}
  const peers = userHierarchy?.peers || {}
  const userOptions = { ...peers, ...possibleUnderwriters }
  const userOptionsAsArray = Object.values(userOptions)
  return userOptionsAsArray.map((underwriter: any, index) => (
    <UserOptions
      assignmentId={assignmentId}
      key={index}
      underwriter={underwriter}
      index={index}
      togglePopover={props.togglePopover}
    />
  ))

}

interface IUserOptionsProps {
  underwriter: any;
  index: number;
  assignmentId: string | number;
  handleTransfer: (userId: number, legacyId: number) => void;
  togglePopover: () => void;
  handleClickEvent: (event: Event) => void;
}
const UserOptions = (props: IUserOptionsProps): JSX.Element => {
  const { underwriter, index, assignmentId } = props;
  const { handleTransfer } = React.useContext(DashboardContext);

  const handleClickEvent = (event): void => {
    event.stopPropagation();
    handleTransfer(underwriter.id, underwriter.legacy_id, assignmentId).catch((err) => { console.error(err) })
  }
  return (
    <MenuItem
      key={index}
      onClick={handleClickEvent}
      sx={{
        "&:hover": {
          backgroundColor: "#F2F8FF !important",
          color: "#3361FF !important",
          textDecoration: "underline !important",
        }
      }}
    >
      {underwriter?.given_name && underwriter?.given_name != 'Unknown' ? underwriter.given_name : ''} {underwriter?.family_name}
    </MenuItem>
  )
};
export default AssignmentOptionsMenu;
