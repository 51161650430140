import * as React from "react";
import { useState } from "react";


import { DocusignButton } from "../../pages/Dashboard/styles/DashboardContainer";
import { FetchDocusignAuth, getEmbeddedUrl } from "../../api/v1/docusign/post";
import { useNavigate, useOutletContext } from "react-router-dom";

interface Iprops {
  setDocusignUrl: React.Dispatch<React.SetStateAction<string>>
}

const DocuSignButton = (props: Iprops) => {
  const [accessToken, setAccessToken] = useState<string>("");
  const [refreshToken, setRefreshToken] = useState<string>('')
  const [embeddedUrl, setEmbeddedUrl] = useState<string>()
  const navigateTo = useNavigate()

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  const getConsent = () => {
    const getConsentUrl = `https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature&client_id=${process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}`;
    window.location.replace(getConsentUrl);
  };

  const getEmbedded = async () => {
    try {

      const response = await getEmbeddedUrl(accessToken)
      if (response?.data?.json?.url) {
        const url = response?.data?.json?.url
        props.setDocusignUrl(url)
        navigateTo('/docusign')
      }
    }
    catch (err) {
      console.error(err)
    }
  }
  const getAccessToken = async () => {
    try {
      const response = await FetchDocusignAuth(code)
      setRefreshToken(response.data?.json?.refresh_token)
      setAccessToken(response.data?.json?.access_token)
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    if (code) getAccessToken();
  }, [code]);

  React.useEffect(() => {
    if (accessToken) {
      getEmbedded()
    }
  }, [accessToken])
  return (
    <>
      <DocusignButton onClick={() => getConsent()}>DocuSign</DocusignButton>
    </>
  );
};

interface IDocusignContainerProps {
  docusignUrl: string
}

export const DocuSignContainer = (props:IDocusignContainerProps) => {
  const { docusignUrl } = useOutletContext();
  return( 
    !docusignUrl && <DocuSignButton setDocusignUrl={props.setDocusignUrl} /> ||
    docusignUrl && <iframe style={{ height: "100%", border: "none" }} src={docusignUrl} />
  )
}

export default DocuSignButton;