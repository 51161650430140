import * as React from 'react';
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { AlertsContainer, AlertCard } from '../styles';
import Alerts, { IAlerts, IAlertsProps } from '../index';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { updateAlert } from '../../../../api/v1/alerts/put';

interface IRenderedAlerts {
  alerts: IAlerts[];
  alertCount: number;
  handleAlertsClose: () => void;
  markAlertAsViewed: (alert_id: number) => void;
  alertsCount: number;
  todaysAlertsCount: number;
}

const RenderedAlerts = (props: IRenderedAlerts) => {
  const navigate = useNavigate();
  const todayAlerts = props?.alerts;
 const sortedAlerts = todayAlerts?.sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  });

  return (
    <AlertsContainer data-testid="alerts-container">
      <h2 data-testid="alerts-text" style={{ textAlign: "center" }}>
        Today's Alerts {props?.todaysAlertsCount}
      </h2>
      <section data-testid="alerts-length" className="date-sort-section" onClick={() => navigate('/alerts')} >
        <span style={{ cursor: "pointer" }}>All Alerts {props?.alertsCount}</span>
        <EastIcon data-testid="alerts-sort-icon" className='filter-button' />
      </section>
      <section >
        {sortedAlerts?.map((alert: IAlerts, key) => {
          const formattedDate = new Date(alert.created_at).toLocaleString('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          });
          return (
            <div key={alert.id} onClick={() => props?.markAlertAsViewed(alert.id)}>
              <AlertCard
                data-testid="alerts-card" onClick={() => navigate(`/alerts`)}>
                <div data-testid="alerts-name">{alert?.name}</div>
                {/* <div data-testid="alerts-contractNo">{alert?.contractNo}</div> */}
                <div data-testid="alerts-broker">{alert?.broker}</div>
                <div className="div-text" data-testid="alerts-description">{alert?.description}</div>
                <div data-testid="alerts-date" className="alert-date">{formattedDate}</div>
              </AlertCard>
            </div>
          );
        })}
      </section>
    </AlertsContainer>
  )
}
export default RenderedAlerts;