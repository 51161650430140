import type { ICreateAssignmentParams } from "../post";
import { createAssignment, createDuplicateAssignment } from "../post";
import { editAssignmentV2 } from "../put";
import { ILegacyAssignment } from "../../types";
import { IAssignment } from "../types/assignments";
import { InsuredSubject } from "./insuredSubjects";
import { postDocument } from "../../documents/post";
import * as Yup from "yup";
import { getAssignment } from "../../assignment/get";
import { fetchDocuments } from "../../documents/get";
import { deleteAssignment } from "../delete";
import { isValid } from "date-fns";
import { updateDocumentsRequest } from "../../documents/put";

export const assignmentValidationOrder = {
  0: ["contractTypeId"],
  1: [
    "brokerId",
    "cedentId",
    "insurerId",
    "startDate",
    "endDate",
    "acceptDate",
  ],
  2: [
    "insuredSubjects",
  ],
  3: ["files"],
  4: ["underwriterId"],
};

export const defaultReinsuranceTerm = [
  {
    classOfInsurance: "",
    subClass: "",
    period: {
      inception: Date.now(),
      expiry: Date.now(),
    },
    _v_o_s_t: 0,
    version: 0,
    includedRisks: [],
    excludeRisks: [],
    deductible: {
      typeId: "",
      basisId: "",
      actualValue: 0,
      valueType: "",
    },

    accounting: {
      limit: {
        min: 0,
        max: 0,
      },
      bux: 0,
      companyShare: {
        min: 0,
        max: 0,
      },
      premium: {
        min: 0,
        max: 0,
      },
      deductions: {
        min: 0,
        max: 0,
      },
      brokerage: {
        min: 0,
        max: 0,
      },
      otherDDCs: {
        min: 0,
        max: 0,
      },
      updatedBy: null,
      pAcceptedDate: null,
      cid: 0,
      lastUpdatedDate: Date.now(),
      status: 0,
      netPremium: 0,
      paid: false,
    },
    paymentTerms: {
      schedule: [
        {
          expectedDate: Date.now(),
          expectedAmount: 0,
          actualDate: Date.now(),
          actualAmount: 0,
        },
      ],
      earnedPremium: 0,
      unearnedPremium: 0,
      acceptedDate: Date.now(),
      paymentStatus: "",
    },
  },
];

const dateFormatter = new Intl.DateTimeFormat("en-CA", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
});
// Extract the date string in the YYYY-MM-DD format and the time string in the HH:MM format
const [dateString, timeString] = dateFormatter.format(new Date()).split(", ");

// Combine the date and time strings in the YYYY-MM-DDTHH:MM format
export const formattedDate = `${dateString}T${timeString}`;

export const defaultAssignment: AssignmentStructure = {
  assignmentTypeId: "",
  contractTypeId: "",
  createDate: null,
  endDate: null,
  startDate: null,
  acceptDate: null,
  comment: "",
  brokerId: null,
  insurerId: null,
  cedentId: null,
  underwriterId: "",
  insurer: "",
  broker: null,
  cedent: null,
  lastUpdateById: 0,
  statusChangeDate: null,
  statusId: { id: 1, label: "Preview" },
  insuredSubjects: [
    {
      insured: "",
      policyNumber: "",
      riskCategory: {
        category: "",
        subCategory: "",
        includedLocations: [],
        excludedLocations: [],
      },
      insuredObject: "",
      characteristics: "",
      createdDate: Date.now(),
      lastUpdatedBy: 0,
      status: 0,
      NN: 0,
      insuranceTerms: {
        sumInsured: 0,
        pml: 100,
        tarif: 0,
        period: {
          inception: Date.now(),
          expiry: Date.now(),
        },
      },
      reinsuranceTerms: defaultReinsuranceTerm,
    },
  ],
  additionalInfo: "",
  expirationDate: formattedDate,
  files: [],
  fileTypes: [],
  contractNo: "",
  underwriter: "",
  cedentNo: "",
  statusTypeId: null,
  legacy_id: 0,
  isLate: false,
  isEscalated: false,
  isAwaitingAction: false,
  unseenComments: 0,
};

export interface IAssignmentFile {
  file: File;
  is_new: boolean;
  has_changed: boolean;
  created_at: Date;
  file_name: string;
  assignment_id: number;
  id: number;
  label: string;
  type: string;
  updated_at: Date;
  url: string;
}

class AssignmentStructure {
  id?: number;
  assignmentTypeId: number;
  contractTypeId: number;
  insurer: string;
  cedent: string;
  broker: string;
  cedentId: number;
  createDate: Date;
  contractNo: string;
  startDate: Date;
  endDate: Date;
  acceptDate: Date;
  comment: string;
  brokerId: number;
  insurerId: number;
  cedentNo: string;
  underwriterId: number;
  underwriter: string;
  lastUpdateById: number;
  statusChangeDate: Date;
  statusTypeId: number;
  statusId: number;
  insuredSubjects: InsuredSubject[];
  additionalInfo: string;
  expirationDate: Date;
  contractType: string;
  files: Record<string, IAssignmentFile>;
  statusType: string;
  statusDate: Date;
  legacyId: number;
  assignmentType: string;
  fileTypes?: string[];
  isLate: boolean;
  isEscalated: boolean;
  isAwaitingAction: boolean;
  unseenComments: number;

  constructor(assignment: AssignmentStructure = defaultAssignment) {
    this.assignmentTypeId = assignment?.assignmentTypeId;
    this.assignmentType = assignment?.assignmentType;
    this.contractTypeId = assignment?.contractTypeId;
    this.createDate = assignment?.createDate;
    this.endDate = assignment?.endDate;
    this.legacyId = assignment?.legacyId;
    this.startDate = assignment?.startDate;
    this.acceptDate = assignment?.acceptDate;
    this.comment = assignment?.comment;
    this.brokerId = assignment?.brokerId;
    this.insurerId = assignment?.insurerId;
    this.contractType = assignment?.contractType;
    this.cedentId = assignment?.cedentId;
    this.cedentNo = assignment?.cedentNo;
    this.underwriterId = assignment?.underwriterId;
    this.lastUpdateById = assignment?.lastUpdateById;
    this.statusChangeDate = assignment?.statusChangeDate;
    this.statusId = assignment?.statusId;
    this.insuredSubjects = assignment.insuredSubjects;
    this.additionalInfo = assignment.additionalInfo;
    this.startDate = assignment.startDate;
    this.expirationDate = assignment.expirationDate;
    this.files = assignment.files;
    this.statusDate = assignment.statusDate;
    this.fileTypes = assignment.fileTypes;
    this.contractNo = assignment.contractNo;
    this.broker = assignment.broker;
    this.cedent = assignment.cedent;
    this.insurer = assignment.insurer;
    this.underwriter = assignment.underwriter;
    this.id = assignment.id;
    this.statusType = assignment.statusType;
    this.isLate = assignment.isLate;
    this.isEscalated = assignment.isEscalated;
    this.isAwaitingAction = assignment.isAwaitingAction;
    this.unseenComments = assignment.unseenComments;
  }

  createAssignment = async () => {
    try {
      const response = await createAssignment(
        this.convertToApiStructure(),
        "LegacyAssignments",
      );
      this.convertAPIResponeToAssignmentStructure(response);
      this.id = response?.id;
      await this.upsertDocuments();
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  duplicateAssignment = async () => {
    try {
      const response = await createDuplicateAssignment(this.id);
      return response
    } catch (err) {
      console.error(err);
    }
  }

  update = async () => {
    try {
      let assignment = this.convertToApiStructure();
      assignment.insured_subjects = assignment?.insured_subjects.filter(
        (subject) => Object.keys(subject).length !== 0,
      );
      const response = await editAssignmentV2(assignment);
      const newAssignment = this.convertAPIResponeToAssignmentStructure(response.assignment)
      newAssignment.files = this.files;
      return newAssignment
    } catch (err) {
      console.error(err);
    }
  };

  upsertDocuments = async (): Promise<void> => {
    if (!this.files) {
      return;
    }

    const fileToInsert = Object.values(this.files).filter((file) => file.is_new);
    const filesToUpdate = Object.values(this.files).filter((file) => file.has_changed && !file.is_new);

    try {
      await this.updateDocuments(filesToUpdate);
      await this.uploadDocuments(fileToInsert);
    } catch (error) {
      console.error("Error upserting documents:", error);
      throw error; // rethrow the error if you want to handle it further up the call chain
    }
  }

  updateDocuments = async (filesToUpdate: Array<AssignmentStructure["files"]>): Promise<void> => {
    try {
      if (filesToUpdate?.length) {
        for (let i = 0; i < filesToUpdate.length; i++) {
          const file = filesToUpdate[i];
          await updateDocumentsRequest(file.id, file.type, file.file_name, file.is_deleted);
        }
      }
    } catch (error) {
      console.error("Error updating documents:", error);
    }
  };

  uploadDocuments = async (filesToInsert: Array<AssignmentStructure["files"]>): Promise<void> => {
    if (!filesToInsert) {
      return;
    }
    if (filesToInsert?.length) {
      for (let i = 0; i < filesToInsert.length; i++) {
        const newFile = filesToInsert[i];
        if (newFile.file instanceof Blob) {
          newFile.file = new File([newFile?.file], newFile.file_name);
        }
        try {
          await postDocument(
            newFile?.file,
            newFile?.type,
            this.id,
            true,
          );
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      }
    }
  };

  deleteAssignment = async (id: number): Promise<IAssignment & ILegacyAssignment> => {
    try {
      const response = await deleteAssignment(id);
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  getAssignment = async (id: number): Promise<AssignmentStructure> => {
    try {
      const response = await getAssignment(id);
      const loadedAssignment = this.convertAPIResponeToAssignmentStructure(response);
      return loadedAssignment;
    } catch (err) {
      console.error(err);
    }
  };




  getDocuments = async (): Promise<unknown> => {
    try {
      const results = await fetchDocuments(this.contractNo, "All Documents")
      results?.data?.documents?.map(this.processDocuments);
      return this.files;
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  processDocuments = (document): void => {
    if (!this?.files) this.files = {};
    this.files[document.file_name] = document;
  };

  syncDocuments = async () => {
    if (!this.files) {
      return;
    }
    await this.upsertDocuments();
    await this.getDocuments();
  };

  addExpectedPayment = (subjectIndex: number, reinsuranceIndex: number) => {
    this.insuredSubjects[subjectIndex].reinsuranceTerms[
      reinsuranceIndex
    ].paymentTerms.schedule.push({
      expectedDate: new Date(),
      expectedAmount: 0,
      actualDate: new Date(),
      actualAmount: 0,
    });

  };

  convertSnakeCaseToCamelCaseRecursively = (data) => {
    if (Array.isArray(data)) {
      return data.map((value) => {
        if (typeof value === "object") {
          return this.convertSnakeCaseToCamelCaseRecursively(value);
        }
        return value;
      });
    }

    if (typeof data === "object" && data !== null) {
      return Object.keys(data).reduce((acc, key) => {
        const camelKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
        const value = data[key];
        if (typeof value === "object") {
          return {
            ...acc,
            [camelKey]: this.convertSnakeCaseToCamelCaseRecursively(value),
          };
        }
        return {
          ...acc,
          [camelKey]: value,
        };
      }, {});
    }

    return data;
  };

  convertAPIResponeToAssignmentStructure = (
    data: ILegacyAssignment & IAssignment,
  ): AssignmentStructure => {
    return new AssignmentStructure(
      this.convertSnakeCaseToCamelCaseRecursively(data),
    );
  };

  convertToApiStructure = (): ICreateAssignmentParams => {
    // convert properties from camelCase to snake_case recursively
    const convertToSnakeCase = (obj: any) => {
      return Object.keys(obj).reduce((acc, key) => {
        const currentKey = key;
        const currentVal = obj[key];
        const snakeKey = currentKey.replace(
          /[A-Z]/g,
          (letter) => `_${letter.toLowerCase()}`,
        );
        const valueKeys = Object.keys(currentVal || {});
        if (currentVal instanceof Date) {
          return { ...acc, [snakeKey]: currentVal.toISOString() };
        }
        if (currentVal === null || currentVal === undefined) {
          return { ...acc, [snakeKey]: currentVal };
        }
        if (Array.isArray(currentVal)) {
          return {
            ...acc,
            [snakeKey]: currentVal.map((value) => {
              const isValueNull = value === null || value === undefined;
              if (isValueNull) {
                console.warn("Value is null or undefined", value)
                console.warn("Current key", currentKey)
                return value;
              }
              const keys = Object.keys((value || {}));
              if (keys.includes("label") && keys.includes("id")) {
                return value?.id;
              } else {
                return convertToSnakeCase(value);
              }
            }),
          };
        }
        if (currentVal && typeof currentVal === "object") {
          if (valueKeys.includes("label") && valueKeys.includes("id")) {
            return { ...acc, [snakeKey]: currentVal?.id };
          } else {
            return { ...acc, [snakeKey]: convertToSnakeCase(currentVal) };
          }
        }
        return {
          ...acc,
          [snakeKey]: obj[key],
        };
      }, {});
    };
    return convertToSnakeCase(JSON.parse(JSON.stringify(this)));
  };

  load = async (id: number): Promise<AssignmentStructure> => {
    return await this.getAssignment(id);
  };

  dict = () => {
    return {
      assignmentTypeId: this.assignmentTypeId,
      contractTypeId: this.contractTypeId,
      createDate: this.createDate,
      startDate: this.startDate,
      endDate: this.endDate,
      acceptDate: this.acceptDate,
      comment: this.comment,
      brokerId: this.brokerId,
      insurerId: this.insurerId,
      cedentId: this.cedentId,
      cedentNo: this.cedentNo,
      underwriterId: this.underwriterId,
      lastUpdateById: this.lastUpdateById,
      statusChangeDate: this.statusChangeDate,
      statusTypeId: this.statusTypeId,
      statusId: this?.statusId,
      insuredSubjects: this.insuredSubjects,
    };
  };
}
const FieldLabels = {
  brokerId: "Broker",
  insurerId: "Insurer",
  contractTypeId: "Contract Type",
  cedentId: "Cedent",
  underwriterId: "Underwriter",
  statusId: "Status",
  createDate: "Create Date",
  startDate: "Start Date",
  endDate: "Expiration Date",
  acceptDate: "Accept Date",
  comment: "Comment",
  insuredSubjects: "Insured Subjects",
  insured: "Insured",
  insuredObject: "Insured Object",
  policyNumber: "Policy Number",
  riskCategory: "Risk Category",
  insuranceTerms: "Insurance Terms",
  reinsuranceTerms: "Reinsurance Terms",
  period: "Period",
  currency: "Currency",
  deductible: "Deductible",
  formOfRi: "Form of Reinsurance",
  insuranceClass: "Insurance Class",
  subClass: "Sub Class",
  contractCurrencyType: "Contract Currency Type",
  paymentCurrencyType: "Payment Currency Type",
  exchangeRate: "Exchange Rate",
  rate: "Rate",
  date: "Date",
  typeId: "Deductible Type",
  basisId: "Deductible Basis",
  actualValue: "Actual Value",
  valueType: "Value Type",
  sumInsured: "Sum Insured",
  pml: "PML",
  tarif: "Tarif",
  inception: "Inception Date",
  expiry: "Expiry Date",
};

const getFieldLabel = (path) => {
  const cleanedPath = path.replace(/\[\d+\]/g, '');
  const parts = cleanedPath.split('.');
  let label = FieldLabels[parts[0]] || parts[0];

  for (let i = 1; i < parts.length; i++) {
    const part = parts[i];
    label += ` > ${FieldLabels[part] || part}`;
  }

  return label;
};

const foriendFieldTest = {
  name: 'is_complete',
  skipAbsent: false,
  test(value, ctx) {
    let isIdValid = false;
    const idIsNumber = !Number.isNaN(value?.id);
    if (idIsNumber && value?.id >= 0 || value?.id) {
      isIdValid = true;
    } else if (value?.id !== undefined && value?.id === null) {
      isIdValid = true;
    }
    if (isIdValid && value?.label) {
      return true;
    }
    return ctx.createError({ message: `${getFieldLabel(ctx.path)} is required. Select or create a record`, path: ctx.path });
  }
}

const foriegnObject = Yup.object({
  id: Yup.mixed().nullable(),
  label: Yup.string().nullable()
})
const foreignFieldSchema = foriegnObject.test(foriendFieldTest)

const riskCategorySchema = Yup.object({
  category: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
});

const insuranceTermsSchema = Yup.object({
  sumInsured: Yup.number().min(1, "Sum insured must be at least 1").required(`Required*`),
  pml: Yup.number()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string' && originalValue.includes(',')) {
        return NaN;
      }
      return value;
    })
    .typeError("Please enter an integer number")
    .integer("PML must be an integer")
    .min(1, "PML must be an integer between 1 and 100")
    .max(100, "PML must be an integer between 1 and 100")
    .required("Required*"),
});


const currencySchema = Yup.object({
  exchangeRate: Yup.object({
    rate: Yup.number().min(0.00000001, "Exchange rate must be at least 0.000001").nullable().notRequired(),
    date: Yup.date().typeError("Exchange rate date must be a valid date").nullable().notRequired(),
  }),
}).nullable().notRequired();
const deductibleSchema = Yup.object({
  typeId: foriegnObject.nullable().notRequired(),
  basisId: foriegnObject.nullable().notRequired(),
  valueType: foriegnObject.nullable().notRequired()
});

const reinsuranceTermsSchema = Yup.object({
  formOfRi: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  insuranceClass: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  subClass: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  currency: currencySchema,
  deductible: deductibleSchema
});

const insuredSubjectSchema = Yup.object({
  insured: Yup.string().min(4, (ctx) => `${getFieldLabel(ctx.path)} should contain 4 characters or more`).required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  insuredObject: Yup.string().required((ctx) => `Object is required`),
  riskCategory: riskCategorySchema,
  insuranceTerms: insuranceTermsSchema,
  reinsuranceTerms: Yup.array().of(reinsuranceTermsSchema).required((ctx) => `${getFieldLabel(ctx.path)} is required`),
}).required((ctx) => `${getFieldLabel(ctx.path)} is required`);

const mustBeInDateFormat = (message: string): Yup.DateSchema => Yup.date().typeError(`${message} must be a valid date`).required(`${message} is required`);

export const validationSchema = Yup.object({
  brokerId: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  insurerId: foriegnObject.nullable().notRequired(),
  contractTypeId: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  cedentId: foriegnObject.nullable().notRequired(),
  underwriterId: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  statusId: foreignFieldSchema.required((ctx) => `${getFieldLabel(ctx.path)} is required`),
  createDate: mustBeInDateFormat(getFieldLabel("createDate")).optional(),
  startDate: mustBeInDateFormat(getFieldLabel("startDate")).optional().nullable(),
  endDate: mustBeInDateFormat(getFieldLabel("endDate")).optional().nullable(),
  acceptDate: mustBeInDateFormat(getFieldLabel("acceptDate")).nullable().optional(),
  comment: Yup.string().min(2, "Comment must be longer than 2 characters").optional(),
  insuredSubjects: Yup.array().of(insuredSubjectSchema).required((ctx) => `${getFieldLabel(ctx.path)} is required`),
});


export default AssignmentStructure;
