import * as React from "react";
import styled from "styled-components";
import { InputWithLabel } from "../../../../../components/FormElements/Input/Input";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export const ErrorDiv = styled.div`
  display: none;
`;
const onFocus = `
  .title{
      font-size: 0.8rem;
      position: absolute;
      top: -0.2rem;
      transform: revert;
      padding: 0;
  }
  input, .custom-input {
      caret-color: black;
      background-color: white;
    }
  input[type=date], input[type=datetime-local]{
    color: revert;
  }
`

export const CreateAssignmentInput = styled(InputWithLabel) <{ value: string, name: string, errors?: boolean }>`
  position: relative;
  input, .custom-input {
    background-color: rgba(247, 248, 250, 0.8);
    background-color: white;
    color: black;
    transition: all 0.6s;
    margin-top: 0.8rem;
  }
  ${(props) => !props?.value && `
    input, .custom-input {
      color: transparent;
      background-color: #d7dfe659;
      }`
  }
  ${(props) => !!props?.errors && `
    input, .custom-input {
      border-bottom: 1px solid red;
    }
    `}
  .title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 1rem;
    transition: all 0.6s;
  }
  ${(props) => props?.value != "" && onFocus}
    &:focus-within {
        ${onFocus}
      }
`;


export const DropdownArrow = styled(KeyboardArrowDownOutlinedIcon)`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

