import * as React from "react";
import { StyledBreadCrumbs } from "../../styles/DashboardContainer";
import { StyledMetricsNavigation } from "../styles";
import { Link } from "react-router-dom";
import {
  ERoute,
  RouteTitle,
} from "../../../../context/BrowserContext/routes/types";
import { ControlContainerWrapper } from "../../LeadershipMetrics/styles";
import {
  ControlContainer,
  ExpandTaskButton,
} from "../../TodayPriorities/styles";
import { PrintOutlined, SortOutlined } from "@mui/icons-material";

interface IMetricsNavigationProps {
  selectedTab?: string;
  breadcrumbs: JSX.Element[];
  setExpandAll?: React.Dispatch<React.SetStateAction<boolean>>;
  expandAll?: boolean;
}

const MetricsNavigation = (props: IMetricsNavigationProps) => {
  const [beforeLeftPosition, setBeforeLeftPosition] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const { expandAll, setExpandAll } = props;

  const expandAllUnderwriters = () => {
    setExpandAll(!expandAll);
  };

  const onPrint = () => {
    setExpandAll(true);

    // Define print-specific styles.
    const printCSS = `
        <style type="text/css">
        .hide-on-print {
          display: none !important;
        }
        [data-testid="accordion-details"] {
          overflow-y: visible !important;
          height: auto !important;
        }
        .print-area {
          border: 1px solid #babfc3;
        }
        </style>
        `;

    setTimeout(() => {
      const tableHtml = document.querySelector(".print-area").outerHTML;
      let printWindow = window.open("", "_blank");

      printWindow.document.open();
      printWindow.document.write("<html><head><title>ManITlabs</title>");

      // Include existing styles.
      Array.from(
        document.querySelectorAll("link[rel='stylesheet'], style"),
      ).forEach((el) => {
        printWindow.document.write(el.outerHTML);
      });

      // Include print-specific styles.
      printWindow.document.write(printCSS);

      printWindow.document.write("</head><body>");
      printWindow.document.write(
        '<h1 style="font-size:2em; text-align:center; color:#babfc3; margin-top: 25px">Company Activity</h1>',
      );
      printWindow.document.write(tableHtml);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.document.title = "ManITlabs";

      // Close window after printing.
      printWindow.onafterprint = () => {
        printWindow.close();
        setExpandAll(false);
      };

      printWindow.print();
    }, 1000);
  };

  const determineSizeofBar = () => {
    try {
      const elementToGetWitdthAndPosition = document.querySelector(
        `.${props.selectedTab}`,
      );
      if (!elementToGetWitdthAndPosition) return;
      elementToGetWitdthAndPosition?.offsetLeft &&
        setBeforeLeftPosition(elementToGetWitdthAndPosition?.offsetLeft);
      elementToGetWitdthAndPosition?.clientWidth &&
        setWidth(elementToGetWitdthAndPosition?.clientWidth);
    } catch (e) {
      console.error(e);
    }
  };
  React.useEffect(determineSizeofBar, [props.selectedTab]);
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(determineSizeofBar);
    resizeObserver.observe(document.body);
  });
  return (
    <div>
      <nav>
        <StyledMetricsNavigation width={width} left={beforeLeftPosition}>
          <Link
            className={ERoute.underwritersActivity}
            to={`/${ERoute.metrics}/${ERoute.underwritersActivity}`}
          >
            {RouteTitle[ERoute.underwritersActivity]}
          </Link>
          <Link
            className={ERoute.companyActivity}
            to={`/${ERoute.metrics}/${ERoute.companyActivity}`}
          >
            {RouteTitle[ERoute.companyActivity]}
          </Link>
          <StyledBreadCrumbs>{props?.breadcrumbs}</StyledBreadCrumbs>
        </StyledMetricsNavigation>
        <div>
          {props.selectedTab === ERoute.underwritersActivity && (
            <ControlContainerWrapper>
              <ControlContainer>
                <ExpandTaskButton onClick={expandAllUnderwriters}>
                  {expandAll ? "Back to List " : "All Activities "}{" "}
                  <SortOutlined />
                </ExpandTaskButton>
                <PrintOutlined onClick={() => onPrint()} />
              </ControlContainer>
            </ControlContainerWrapper>
          )}

          {props.selectedTab === ERoute.companyActivity && (
            <ControlContainerWrapper>
              <ControlContainer>
                <PrintOutlined onClick={() => onPrint()} />
              </ControlContainer>
            </ControlContainerWrapper>
          )}
        </div>
      </nav>
    </div>
  );
};

export default MetricsNavigation;
