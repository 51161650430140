import * as React from "react";
import getAlerts from "../api/v1/alerts/get";
import { IAlerts } from "../pages/Dashboard/Alerts";
import { updateAlert } from "../api/v1/alerts/put";
export interface IAlertsParams {
    limit: number;
    offset: number;
    alertType: string;
    search_term?: string;
    dateRange: {
        startDate: string;
        endDate: string;
    }
}

interface IAlertsHookReturn {
    alerts: IAlerts[];
    alertParams: IAlertsParams;
    loadingAlerts: boolean;
    error: string;
    alertsCount: number;
    todaysAlertsCount: number;
    filterAlertsCount: number;
    alreadyLoaded: boolean;
    fetchAlerts: () => void;
    setAlertParams: (params: IAlertsParams) => void;
    setAlerts: (alerts: IAlerts) => void;
    markAlertAsViewed: (alert_id: number) => void;

}

const useAlerts = (
    initialParams: IAlertsParams,
    activateLoadState = true,
    getAlertsProp = getAlerts,
): IAlertsHookReturn => {
    const [alerts, setAlerts] = React.useState<IAlerts[]>({});
    const [loadingAlerts, setLoadingAlerts] = React.useState<boolean>(false);
    const [alreadyLoaded, setAlreadyLoaded] = React.useState<boolean>(false);
    const [filterAlertsCount, setFilterAlertsCount] = React.useState<number>(0);
    const [todaysAlertsCount, setTodaysAlertsCount] = React.useState<number>(0);
    const [alertsCount, setAlertsCount] = React.useState<number>(0);
    const [error, setError] = React.useState<string>(null);
    const [alertParams, setAlertParams] = React.useState<IAlerts>(initialParams);

    const fetchAlerts = async (): Promise<void> => {
        if (loadingAlerts) return;
        try {
            if (!activateLoadState && alreadyLoaded) setLoadingAlerts(true);
            if (!alreadyLoaded) setLoadingAlerts(true);
            const result = await getAlertsProp(alertParams);
            const alertsDictionary = result?.alerts?.reduce((acc, alert) => {
                acc[alert.id] = alert;
                return acc;
            }, {});
            setAlerts({ ...alertsDictionary });
            setAlertsCount(result?.alerts_count);
            setTodaysAlertsCount(result?.todays_alerts_count);
            setFilterAlertsCount(result?.filter_alerts_count);
            setLoadingAlerts(false);
            setAlreadyLoaded(true);
        } catch (error) {
            setError(error);
        } finally {
            setLoadingAlerts(false);
        }
    }

    const markAlertAsViewed = async (alert_id: number) => {
        try {
            const alertToEdit = alerts[alert_id];
            alertToEdit.viewed = !alertToEdit.viewed;
            const response = await updateAlert(alert_id, alertToEdit)
            const newAlert: IAlerts = response.data.alert
            return newAlert;
        } catch (err) {
            console.error("mark alert viewed error", err);
        }
    }

    React.useEffect(() => {
        fetchAlerts()
    }, [alertParams])


    return {
        alerts: Object.values(alerts || {}),
        alertParams,
        loadingAlerts,
        error,
        alertsCount,
        todaysAlertsCount,
        filterAlertsCount,
        alreadyLoaded,
        fetchAlerts,
        setAlertParams,
        setAlerts,
        markAlertAsViewed,
    }
}

export default useAlerts;