import styled from "styled-components";
import AccordionComponent from "../components/Tasks/Accordion";

export const StyledAssignmentContent = styled.div``;

export const StyledAssignmentDrillDown = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  min-height: 0px;
  position: relative;
  flex-shrink: 2;
  * {
    box-sizing: border-box;
  }
  ${StyledAssignmentContent} {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    flex-basis: 100%;
  }
`;

export const StyledAccordion = styled(AccordionComponent)<{expandAccordion: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 3;

  @media screen and (max-height: 600px) {
    min-height: 8rem;
  }

  flex-basis: 5rem;
  .MuiCollapse-root {
    height: revert;
  }

  &.MuiAccordionDetails-root {
    background-color: #fafafa;
    padding: 16px;
  }
  transition: height 1s;
  .MuiCollapse-root {
    height:revert;
    height: 0px;
    transition: height 1s ease-in-out;
    * {
      height: 100%;
    }
  }
  &.Mui-expanded {
    min-height: 0px;
    flex-basis: 100%;
    .MuiCollapse-root {
      height: inherit !important;
      height: 100%;
      flex-basis: 100%;
    }
  }
  margin:0;
`;
