import * as React from "react";
import { CompanyCardWrapper } from "../Metrics/styles";
import { IKPI } from "../../../api/v1/types";
import { ControlContainer, ExpandTaskButton } from "../TodayPriorities/styles";
import { PrintOutlined, SortOutlined } from "@mui/icons-material";
import CompanyKPI from "../Metrics/components/CompanyKPI";
import UnderwriterRow from "./components/UnderwriterRow";
import { IEndorsements, INewBusiness, IRenewals } from "../../Metrics";
import {
  ControlContainerWrapper,
  UnderwriterTable,
  UnderwriterTableContainer,
  UnderwriterTableWrapper,
} from "./styles";

interface IUnderwriterRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

interface ITotalRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

interface ITotal {
  total: ITotalRow;
}

interface IEmployee {
  name: string;
  metrics: IUnderwriterRow;
}

export interface IUnderwriter {
  employees: IEmployee[];
  total: ITotal;
  expandAll?: boolean;
  expandAccordion?: boolean;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  renewals: IRenewals;
  endorsements: IEndorsements;
  newBusiness: INewBusiness;
}

const employeeData = {
  employees: [
    {
      name: "John Doe",
      total: {
        active: {
          change: 0,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 0,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      renewals: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      endorsements: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      newBusiness: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
    },
    {
      name: "Janehn Dipolo",
      total: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      renewals: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 0,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      endorsements: {
        active: {
          change: 0,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      newBusiness: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 0,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
    },
    {
      name: "Winter Adams",
      total: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      renewals: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      endorsements: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      newBusiness: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
    },
    {
      name: "Adam Liner",
      total: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      renewals: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      endorsements: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
      newBusiness: {
        active: {
          change: 2,
          label: "Active",
          value: 90,
        },
        pending: {
          change: 2,
          label: "Pending",
          value: 80,
        },
        request: {
          change: 5,
          label: "Request",
          value: 80,
        },
        escalate: {
          change: 3,
          label: "Escalate",
          value: 70,
        },
        accepted: {
          change: 4,
          label: "Accepted",
          value: 20,
        },
        declined: {
          change: 7,
          label: "Declined",
          value: 10,
        },
        renewed: {
          change: 5,
          label: "Renewed",
          value: 20,
        },
        inactive: {
          change: 4,
          label: "Inactive",
          value: 20,
        },
        closed: {
          change: 5,
          label: "Closed",
          value: 70,
        },
        lost: {
          change: 4,
          label: "Lost",
          value: 70,
        },
        total: {
          change: 0,
          label: "Total",
          value: 0,
        },
      },
    },
  ],
};

const LeadershipMetrics = (props: IUnderwriter) => {
  const [employees, setEmployees] = React.useState([]);
  const { expandAll } = props;

  React.useEffect(() => {
    setEmployees(props.employees);
  }, [props.employees]);

  const memoizedUnderwritersRow = employeeData.employees?.map(
    (employee, key) => {
      return (
        <UnderwriterRow key={key} employee={employee} expandAll={expandAll} />
      );
    },
  );
  return (
    <>
      <UnderwriterTableWrapper>
        <CompanyCardWrapper>
          <CompanyKPI
            cardTitle="John Doe"
            avgWorkloadLabel={"Avg. Workload/ Daily"}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={90}
          />
          <CompanyKPI
            cardTitle="Janehn Dipolo"
            avgWorkloadLabel={"Avg. Workload/ Daily"}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={30}
          />
          <CompanyKPI
            cardTitle="Winter Adams"
            avgWorkloadLabel={"Avg. Workload/ Daily"}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={70}
          />
          <CompanyKPI
            cardTitle="Adam Liner"
            avgWorkloadLabel={"Avg. Workload/ Daily"}
            avgWorkloadValue={30}
            avgDayLabel={"Avg. Days to Close"}
            avgDayValue={10}
            completedSLALabel={"Completed within SLA"}
            completedSLAValue={50}
          />
        </CompanyCardWrapper>
        <UnderwriterTableContainer>
          <UnderwriterTable>
            <thead>
              <tr>
                <th className="type-metrics">Underwriter</th>
                <th>ACTIVE</th>
                <th>PENDING (WIP)</th>
                <th>REQUEST</th>
                <th>ESCALATE</th>
                <th>
                  ACCEPTED (Total-<span className="span-color">%</span>)
                </th>
                <th>
                  DECLINED (Total-<span className="span-color">%</span>)
                </th>
                <th>
                  RENEWED (Total-<span className="span-color">%</span>)
                </th>
                <th>INACTIVE (Not Closed)</th>
                <th>CLOSED (last 30 days)</th>
                <th>LOST (Total/%)</th>
                <th>TOTAL</th>
              </tr>
            </thead>

            <tbody>{memoizedUnderwritersRow}</tbody>
          </UnderwriterTable>
        </UnderwriterTableContainer>
      </UnderwriterTableWrapper>
    </>
  );
};
export default LeadershipMetrics;
