import { create } from "@mui/material/styles/createTransitions";
import { IUser } from "../users/types/user";
import axios from "axios";
import { fa, faker } from "@faker-js/faker";
import {
  IAssignment,
  IAssignmentNote,
  IAssignmentComment,
  ILegacyAssignment,
  ELegacyAssignmentProps,
  EAssignmentStatus,
  EAssignmentState,
} from "../assignments/types/assignments";

const exampleKPIs = ["Total", "New Business", "Renewals"];

const priorityTypes = ["high", "medium", "low"];
const cedentAndInsurerValues = `${faker.address.city()}, ${faker.address.country()}`;

export function createRandomAssignment(): IAssignment & ILegacyAssignment {
  return {
    contract_no: faker.string.alphanumeric(9).toUpperCase(),
    cedent_no: faker.number.int(1000),
    cedent: cedentAndInsurerValues,
    broker: faker.company.name(),
    insurer: cedentAndInsurerValues,
    start_date: faker.date.future().toISOString(),
    end_date: faker.date.future().toISOString(),
    create_date: faker.date.past().toISOString(),
    accept_date: faker.date.future().toISOString(),
    comment: faker.lorem.sentence(),
    status_date: faker.date.past().toISOString(),
    status_id: faker.number.int({ min: 1, max: 100 }),
    status_type: faker.helpers.arrayElement([
      "Preview",
      "Accepted",
      "Declined",
      "Rejected",
      "Failed",
      "Confirmed",
      "Fronting",
      "In Progress",
      "Request",
      "To be approved by leadership",
      "Approved by our leadership",
      "Not approved by leadership",
      "Returned for rework by our leadership",
    ]),
    underwriter: faker.person.fullName(),
    contract_type: faker.helpers.arrayElement([
      "Fac Inward",
      "Fac Outward",
      "Treaty Inward",
      "Treaty Outward",
    ]),
    awaiting_action: faker.datatype.boolean(),
    id: faker.number.int(100),
    legacy_id: faker.datatype.uuid(),
    status: null,
    user_id: faker.datatype.number(),
    priority: null,
    assignment_type: faker.helpers.arrayElement(["NB", "Renewal"]),
    inception_date: faker.date.past({ years: 5 }),
    is_escalated: faker.datatype.boolean(),
    is_late: faker.datatype.boolean(),

    [ELegacyAssignmentProps.kod]: `P${faker.datatype.number({
      min: 1000,
      max: 9999,
    })}-TO${faker.datatype.number({ min: 10, max: 99 })}`,
    [ELegacyAssignmentProps.KOD_old]: faker.datatype.uuid(),
    [ELegacyAssignmentProps.N_DOGOVOR]: faker.datatype.uuid(),
    [ELegacyAssignmentProps.STRX_C]: "string",
    [ELegacyAssignmentProps.CEDENT]: faker.company.buzzNoun(),
    [ELegacyAssignmentProps.BROKER]: faker.person.fullName(),
    [ELegacyAssignmentProps.N_CEDENT]: faker.datatype.uuid(),
    [ELegacyAssignmentProps.Tip]: faker.datatype.uuid(),
    [ELegacyAssignmentProps.accept_date]: "string",
    [ELegacyAssignmentProps.D_BEGIN]: faker.date.past(),
    [ELegacyAssignmentProps.D_END]: faker.date.future(),
    [ELegacyAssignmentProps.PRIM]: "string",
    [ELegacyAssignmentProps.DATA_Create]: faker.date.future(),
    [ELegacyAssignmentProps.STATUS]: faker.helpers.arrayElement(
      Object.values(EAssignmentStatus),
    ),
    [ELegacyAssignmentProps.DATA_STATUS]: "string",
    [ELegacyAssignmentProps.Andr]: "string",
    [ELegacyAssignmentProps.USERS]: "string",
    [ELegacyAssignmentProps.Data_Izm]: "string",
    [ELegacyAssignmentProps.Stady]: "string",
    [ELegacyAssignmentProps.office]: faker.location.streetAddress(),
    [ELegacyAssignmentProps.cobroker]: faker.company.buzzNoun(),
    [ELegacyAssignmentProps.prenumber]: "string",
    [ELegacyAssignmentProps.sthistory]: "string",
    [ELegacyAssignmentProps.skod]: "string",
    [ELegacyAssignmentProps.scomment]: "string",
    [ELegacyAssignmentProps.sdate]: "string",
    [ELegacyAssignmentProps.vlcomment]: "string",
    [ELegacyAssignmentProps.vlcurrency]: faker.finance.currencyCode(),
    [ELegacyAssignmentProps.INSURER]: faker.company.buzzNoun(),
  };
}

export const createRandomAssignments = (count: number) => {
  return new Array(count).fill(null, 0, count).map((assignment, index) => {
    return createRandomAssignment();
  });
};

export interface Assignment {
  status_type: string;
  legac_id: string;
  priority: "low" | "high" | "medium";
  id: number;
  user_id: number;
  organization_id?: number;
}

export const getMockedAssignments = (): Promise<{
  assignments: (IAssignment & ILegacyAssignment)[];
}> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (window.localStorage.getItem("underwriterAssignments")) {
        resolve({
          assignments: JSON.parse(
            window.localStorage.getItem("underwriterAssignments"),
          ),
        });
      }
      const assignmentFilled = new Array(100)
        .fill(null, 0, 100)
        .map((assignment, index) => {
          return createRandomAssignment();
        });
      window.localStorage.setItem(
        "underwriterAssignments",
        JSON.stringify(assignmentFilled),
      );
      resolve({ assignments: assignmentFilled });
    }, 500);
  });
};

export const mockAssignmentMarkAsPriority = async (id: string) => {
  const assignments = JSON.parse(
    window.localStorage.getItem("underwriterAssignments"),
  );
  const assignment = assignments.find(
    (assignment: IAssignment & ILegacyAssignment) => assignment.id === id,
  );
  assignment.priorityLevel = faker.helpers.arrayElement(priorityTypes);
  window.localStorage.setItem(
    "underwriterAssignments",
    JSON.stringify(assignments),
  );
  return assignments;
};

export const mockAssignmentMarkAsComplete = async (id: string) => {
  const assignments = JSON.parse(
    window.localStorage.getItem("underwriterAssignments"),
  );
  const assignment = assignments.find(
    (assignment: IAssignment & ILegacyAssignment) => assignment.id === id,
  );
  assignment.isCompleted = true;
  window.localStorage.setItem(
    "underwriterAssignments",
    JSON.stringify(assignments),
  );
  return assignments;
};

export const mockAssignmentMarkAsEscalated = async (id: string) => {
  const assignments = JSON.parse(
    window.localStorage.getItem("underwriterAssignments"),
  );
  const assignment = assignments.find(
    (assignment: IAssignment & ILegacyAssignment) => assignment.id === id,
  );
  assignment.isEscalated = true;
  window.localStorage.setItem(
    "underwriterAssignments",
    JSON.stringify(assignments),
  );
  return assignments;
};
