import * as React from "react";
import {
  TableContainer,
  TableFilteringOptions,
  TableHeading,
} from "../../AssignmentTable/styles";
import {
  Table,
  PriorityContainer,
  ControlContainer,
  ExpandTaskButton,
} from "../styles";
import SearchIcon from "@mui/icons-material/Search";
import { PrintOutlined, SortOutlined } from "@mui/icons-material";
import { IAssignment, ILegacyAssignment } from "../../../../api/v1/types";


import TodayPrioritiesRow from "../TodayPrioritiesRow";
import { ITodaysPrioritiesProps } from "..";
import { Task } from "../../../../api/v1/assignments/types/assignments";
import asModal from "../../../../hoc/asModal";
import ErrorInputModal from "./ErrorInputModal";
import { getPriorities } from "../../../../api/v1/priorities/get";

const ErrorFilteringInput = asModal(ErrorInputModal, "#priorities-page");
const TodaysPrioritiesView = (props: ITodaysPrioritiesProps) => {
  const [expandAll, setExpandAll] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const expandAllTasks = () => {
    setExpandAll(!expandAll);
  };

  React.useEffect(() => {
    const getPrioritiesFromApi = async () => {
      const result = await getPriorities();
      props?.setPriorities(result);
    };
    getPrioritiesFromApi();
  }, [props?.tasks]);

  const onPrint = () => {
    setExpandAll(true);
    // Define print-specific styles.
    const printCSS = `
        <style type="text/css">
        .hide-on-print {
          display: none !important;
        }
        [data-testid="accordion-details"] {
          overflow-y: visible !important;
          height: auto !important;
        }
        .print-area {
        }

        </style>
        `;

    setTimeout(() => {
      const tableHtml = document.querySelector(".print-area").outerHTML;
      let printWindow = window.open("", "_blank");

      printWindow.document.open();
      printWindow.document.write("<html><head><title>ManITlabs</title>");

      // Include existing styles.
      Array.from(
        document.querySelectorAll("link[rel='stylesheet'], style"),
      ).forEach((el) => {
        printWindow.document.write(el.outerHTML);
      });

      // Include print-specific styles.
      printWindow.document.write(printCSS);

      printWindow.document.write("</head><body>");
      printWindow.document.write(
        '<h1 style="font-size:2em; text-align:center; color:#babfc3; margin-top: 25px">Todays Priorities</h1>',
      );
      printWindow.document.write(tableHtml);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.document.title = "ManITlabs";

      // Close window after printing.
      printWindow.onafterprint = () => {
        printWindow.close();
        setExpandAll(false);
      };
      printWindow.print();
    }, 1000);
  };

  const filteredPriorities = props?.priorities?.filter((priority) =>
    priority?.contract_no?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  React.useEffect(() => {
    if (searchTerm && filteredPriorities.length === 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [filteredPriorities, searchTerm]);


  const memoizedPriorities = filteredPriorities.map(
    (priority: IAssignment & ILegacyAssignment, key) => {
      return (
        <TodayPrioritiesRow
          key={key}
          priority={priority}
          setPriorities={props?.setPriorities}
          clickOnMessage={props?.clickOnMessage}
          expandTaskMenu={props?.expandTaskMenu}
          expandAccordion={props?.expandAccordion === key}
          setExpandAccordion={props?.setExpandAccordion}
          handleExpandAccordion={props?.handleExpandAccordion}
          handleExpandAddTaskMenu={props?.handleExpandAddTaskMenu}
          assignments={props?.assignments}
          displayAssignment={props?.displayAssignment}
          expandAll={expandAll}
          setTasks={props?.setTasks}
          tasks={props?.tasks}
        />
      );
    },
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };


  return (
    <>
      <PriorityContainer data-testid="priority-container">
        <h2>Priorities</h2>
        <ControlContainer>
          <ExpandTaskButton data-testid="expand-priorities" onClick={expandAllTasks}>
            {expandAll ? "Back to List " : "All Tasks "} <SortOutlined />
          </ExpandTaskButton>
          <PrintOutlined onClick={() => onPrint()} />
        </ControlContainer>
      </PriorityContainer>
      <TableContainer  id="priorities-page">
        <TableHeading>
          <h3 data-testid="priority-table-header">Today's Priorities</h3>
          <TableFilteringOptions>
            <SearchIcon />
            <input
              onChange={handleInputChange}
              type="text"
              data-testid="priority-search-input"
              placeholder="Search by Contract No."
            />
            
              <ErrorFilteringInput
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(false);
                 
                }}
                title="No Priority Found Under This Contract No."
                message="Please Enter A Valid Contract No."
              />
            
          </TableFilteringOptions>
        </TableHeading>
        <div className="print-area">
          <Table>
            <thead>
              <tr className="table-header">
                <th>Status</th>
                <th>Contract No.</th>
                <th>No. Incomplete Tasks</th>
                <th>Type</th>
                <th>Broker</th>
                <th className="hide-on-print">Actions</th>
              </tr>
            </thead>
            <tbody data-testid="priority-body">{memoizedPriorities}</tbody>
          </Table>
        </div>
      </TableContainer>
    </>
  );
};
export default TodaysPrioritiesView;
