import * as React from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { IAttachment } from "../api/v1/types";

const ModalContainer = styled.div<IModalProps>`
  
  ${(props) => props.containerStyle}
`;

export interface IModalProps {
  isOpen: boolean;  
  onClose: () => void;
  removeOnBackgroundClick?: boolean;
  modalContainer?: string;
  containerStyle?: string;
  position?: string;
  onConfirm?: () => void;
}
const asModal = <T extends IModalProps>(
  WrappedComponent: React.FC<T>,
  location: string,
) => {
  const ModalComponent = (props: T) =>
    props.isOpen && (
      <ModalContainer data-testid="wrapped" containerStyle={props.containerStyle} className={props?.className}>
        <WrappedComponent {...props} />
      </ModalContainer>
    );
  const WrappedWithModal = (props: T) => {
    const container = document.querySelector(location) || document.body;
    return createPortal(<ModalComponent {...props} />, container, "modal");
  };
  return WrappedWithModal;
};

export default asModal;
