import styled from "styled-components";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.8rem;
  border: 1px solid #e1e3e5;
  position: relative;
  border: 1px solid #babfc3;
  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;

    &:nth-child(2n) {
      background-color: #f5f5f5;
    }
  }
  th {
    background: white;
    position: sticky;
    top: -1px;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  }
  th,
  td {
    color: #6d7175;
    padding: 0.4rem 5px;
  }
  th {
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
    &:first-child {
      z-index: 2;
    }
  }
  td {
    color: #202223;
    padding: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }
`;

export const TableHeading = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  gap: 1rem;
  .table-input {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: end;
    width: 100%;
    
    .container {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
    }

    .dropdown {
      border-color: #BABFC3;     
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px; 
    }

    .search-container {
      position: relative;
      display: flex;
      align-items: center;
    }

    .search-bar {
      padding-right: 30px; /* Adjust as needed */
      padding: 8px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-color: #BABFC3;
      width: 100%;
    }

    .search-icon {
      position: absolute;
      right: 10px; /* Adjust as needed */
    }
  }
`;

export const TableFilteringOptions = styled.section`
  flex-basis: 53%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  overflow: visible;
`;

export const TableContainer = styled.section`
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-basis: 100%;
  height: 1000px;
  max-height: calc(95% - 3rem);
  flex-direction: column;
  .table-container {
    height: 100%;
    overflow: auto;
  }
  .skeletondiv {
    border-radius: 20px;
  }
`;

export const Menu = styled.menu`
  margin: 0;
  padding: 0;
`;

export const NoAssignmentDiv = styled.div`
  border: none;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 12rem;

`
export const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 123px;
  height: 35px;
  flex-shrink: 0;
  background-color: #3361ff;
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  border-color: #3361ff;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const PaginationContainer = styled.div`
  width: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
`;

export const ArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PageNumbers = styled.span``;

export const MenuPropsStyles = () => ({
  "& .MuiMenu-list": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff",
  },
  "& .MuiButtonBase-root": {
    padding: "20px",
    alignContent: "center",
  },
  "& .MuiMenuItem-root": {
    justifyContent: "center",
    borderBottom: "1px solid #C3CAD9",
    color: "#202223",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "16px",
    ":hover": {
      backgroundColor: "#F3F6FF",
      textDecoration: "underline",
      textDecorationColor: "#3361FF",
      color: "#3361FF",
      fontSize: "18px",
      fontStyle: "normal",
      lineHeight: "16px",
    },
  },
  "& .MuiListSubheader-root": {
    borderTop: "2px solid #97A2B7",
    borderBottom: "2px solid #97A2B7",
  },
});

export const SelectStyles = () => ({
  "& .MuiFilledInput-input:focus": {
    backgroundColor: "#F3F6FF",
  },
  "& .MuiFilledInput-input": {
    textAlign: "center",
    "& span": {
      display: "inline-block",
      textAlign: "center",
    },
  },
});
