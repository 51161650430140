import * as React from 'react';
import AccordionDetails from "@mui/material/AccordionDetails";
import { IAccordionComponentProps } from "../Accordion";
import {NextStepsItem} from "../styles";
import { Skeleton } from '@mui/material';



const TasksSkeleton = () => {
    return (
        <>
        <AccordionDetails data-testid="accordion-details" style={{ overflowY: "scroll", height: "100%" }}>
            {Array.from({ length: 5 }).map((_, index) => (
                <NextStepsItem key={index}>
                    <Skeleton className='task-skeleton' variant="rectangular" width="100%" height={60} animation="wave" />
                </NextStepsItem>

            ))}
      </AccordionDetails>
          </>
    )
}

export default TasksSkeleton;