import * as React from "react";
import StatusColumn from "./StatusColumn";
import { EastOutlined } from "@mui/icons-material";
import { IEmail } from "../../../../api/v1/types";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AttachModal from "./AttachModal";
import asModal from "../../../../hoc/asModal";
import {
  ImageIcon,
  Line,
  Triangle,
  WordIcon,
  PDFIcon,
  AttachmentDiv,
  GoToEmailDiv,
  StyledEmailRow,
  StyledAccordionTableRow,
} from "../styles";
import { ImageTypes, AttachmentType } from "../../../../global.types";
import EmailAccordion from "./EmailAccordion";
import { EditorState } from "react-draft-wysiwyg";

const isImageType = (type: string) => {
  return Object.values(ImageTypes).includes(type as ImageTypes);
};

interface IInboxRow {
  email: IEmail;
  attachmentId: string;
  hideSummary?: boolean;
  expandAccordion: boolean;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose: () => void;
  handleSendEmail: (email: any) => void;
  emailContent: EditorState;
  handleEmailContent: (content: EditorState, setFieldValue: Function) => void;
  handleFileUpload: (file: File) => Promise<any>;
  emailContentValidation: (values: any) => any;
}

const AttachedDocumentFromEmail = asModal(AttachModal, "#email-document");
const InboxRow = (props: IInboxRow) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedAttachmentId, setSelectedAttachmentId] =
    React.useState<string>("");
  const [expandAccordion, setExpandAccordion] = React.useState<boolean>(false);
  const {handleSendEmail, emailContentValidation, handleFileUpload, handleEmailContent, emailContent, onClose} = props;
  const handleAddDocument = (attachmentId: string) => {
    setIsOpen(true);
    setSelectedAttachmentId(attachmentId);
  };

  const closeAttachModal = () => {
    setIsOpen(false);
  };

  const handleExpandAccordion = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setExpandAccordion(!expandAccordion);
  };

  return (
    <>
      <StyledEmailRow
        isVisible={expandAccordion}
        selected={expandAccordion}
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          handleExpandAccordion(event)
        }
      >
        <td>
          <StatusColumn status={props?.email.status} />
        </td>
        <td>{props?.email.time}</td>
        <td>{props?.email.from}</td>
        <td>{props?.email.subject}</td>
        <td>
          <AttachmentDiv>
            {props?.email?.attachments?.map((attachment, index) => (
              <span
                key={index}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  event.stopPropagation();
                  handleAddDocument(attachment.attachment_id);
                }
                }
              >
                {attachment.type[0] === AttachmentType.pdf && (
                  <PDFIcon>PDF</PDFIcon>
                )}
                {attachment.type[0]  === AttachmentType.wordDocx && (
                  <WordIcon>W</WordIcon>
                )}
                {attachment.type[0]  === AttachmentType.meet && (
                  <CalendarMonthOutlinedIcon />
                )}
                {isImageType(attachment.type[0]) && (
                  <ImageIcon>
                    <Triangle />
                    <Line />
                  </ImageIcon>
                )}
              </span>
            ))}
            <AttachedDocumentFromEmail
              isOpen={isOpen}
              onClose={closeAttachModal}
              attachment={props?.email?.attachments?.find(
                (att) => att.attachment_id === selectedAttachmentId,
              )}
              attachmentType={
                props?.email?.attachments?.find(
                  (att) => att.attachment_id === selectedAttachmentId,
                )?.type
              }
              attachmentName={
                props?.email?.attachments?.find(
                  (att) => att.attachment_id === selectedAttachmentId,
                )?.name
              }
              messageId={props?.email?.id}
              attachmentId={selectedAttachmentId}
            />
          </AttachmentDiv>
        </td>
        <td>{props?.email.date}</td>
      </StyledEmailRow>
      <StyledAccordionTableRow
        isVisible={expandAccordion}
        selected={expandAccordion}
      >
        <td className="accordion-td" colSpan={7}>
          <EmailAccordion
            attachmentId={props.attachmentId}
            hideSummary={false}
            email={props.email}
            expandAccordion={expandAccordion}
            handleExpandAccordion={handleExpandAccordion}
            emailContent={emailContent}
            handleSendEmail={handleSendEmail}
            onClose={onClose}
            handleFileUpload={handleFileUpload}
            handleEmailContent={handleEmailContent}
            emailContentValidation={emailContentValidation}
          />
        </td>
      </StyledAccordionTableRow>
    </>
  );
};

export default InboxRow;
