import {auth} from '../index';
import { IDepartment } from '../types';

export const addDepartments = async (departmentName: string): Promise<IDepartment[]> => {

  const body = {
    name: departmentName
  }

  try {
    const response = await auth.post("/organization_hierarchy/", body);
    return response.data.organization_hierarchy;
  } catch (error) {
    console.error("Error adding department:", error);
    return []
  }
};
