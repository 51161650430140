import * as React from "react";
import {
  DashboardAvatar,
  DashboardContainer,
  DashboardGlobalActions,
  DashboardHeader,
  DashboardMain,
  modalStyling,
} from "./styles/DashboardContainer";
import { Link, useParams, useSearchParams, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { ERoute, RouteTitle } from "../../context/BrowserContext/routes/types";
import { keyframes, styled } from "styled-components";
import getKPIs from "../../api/v1/kpis/get";
import { StyledLabel } from "../../global.styles";
import LeadershipBanner from "./LeadershipBanner/LeadershipBanner";
import { getLeadershipNotifications } from "../../api/v1/alerts/get";
import { UserContext } from "../../context/UserContext";
import AlertsContextWrapper from "../../context/AlertsContext";
import useAssignments from "../../hooks/useAssignments/";
import { createBroadcastMessage } from "../../api/v1/broadcastMessage/post";
import { editAssignment } from "../../api/v1/assignments/put";
import ConnectedModal from "../../components/Modal/Modal";
import asModal from "../../hoc/asModal";
import { getEmailsCount } from "../../api/v1/emails/get";
import GoogleSignIn from "./Inbox/components/GoogleSignIn";
import Aside from "./components/Aside";
import MainNavigation from "./components/Navigation";
import { LogoContainer } from "../Authentication/styles";
import Toast from "../../components/Toast";
import axios from "axios";
import auth, { api, apiv2 } from "../../api/v1";
import { fetchLatestRates } from "../../api/v1/currency/get";
import Comments from "./Comments";
import { markAsRead } from "../../api/v1/comments/edit";
import type { IAlerts } from "./Alerts";
import { Skeleton } from "@mui/material";
import type { ILeadershipNotification } from "../../api/v1/alerts/get";
import type { IUser } from "../../api/v1/users/types/user";
import type {
  IInsuredSubect,
  IAssignment,
  ILegacyAssignment,
} from "../../api/v1/assignments/types/assignments";
import type { TAssignmentDict } from "../../hooks/useAssignments/";
import type { ICurrency } from "../../api/v1/currency/types/CurrencyTypes";
import type { IKPIResponse } from "../../api/v1/types";
import { transferAssignment } from "../../api/v1/assignment/patch";
import { DashboardInboxContainer } from "./styles/DashboardContainer";
import { MailOutlineOutlined } from "@mui/icons-material";

export enum EQuickView {
  "todayPriorities" = "todayPriorities",
  "alerts" = "alerts",
  "userSettings" = "userSettings",
  "tasksComments" = "tasksComments",
}


export const LegacyLabel = styled(StyledLabel)`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  color: #186ADE;
  .label {
    text-decoration: underline;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MainTransition = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.3s ease;
`;

interface IDashboardProps {
  user: IUser;
  alerts: IAlerts[];
  assignments: TAssignmentDict;
  setSearchParams: React.Dispatch<React.SetStateAction<{
    property: string;
    keyword: string;
  }>>;
  priorities: number[];
  setPriorities: (priorities: number[]) => void;
  loadingPriorities: boolean;
  loadingAssignments: boolean;
  setLoadingAssignments: React.Dispatch<React.SetStateAction<boolean>>;
  getLeadershipNotifications: () => Promise<ILeadershipNotification[]>;
  logout: () => void;
  kpis: IKPIResponse;
  totalAssignments: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageNumber: number;
}

const ToastAsModal = asModal(Toast, "#modal-root")
const StyledToast = styled(ToastAsModal)`
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
z-index: 10000;
> * {
    padding: 1rem;
    font-size: 1.2rem;
    font-family: "Lato", sans-serif;
  }
`;
interface IDashboardContext {
  currencyRates: ICurrency[]
  setCurrencyRates: React.Dispatch<React.SetStateAction<ICurrency[]>>
  handleTransfer: (userId: number, legacyId: number, selectAssignmentId: number) => Promise<void>
}
export const DashboardContext = React.createContext<IDashboardContext | undefined>(undefined);

const SignInWithGoogle = asModal(GoogleSignIn, "#dashboard-header");
const CommentsModal = asModal(Comments, "#modal-root");
export const Dashboard = (props: IDashboardProps) => {
  const {
    user,
    setUser,
    assignments,
    priorities,
    searchParams,
    setAssignments,
    fetchAssignments,
    setPriorities,
    setSearchParams,
    logout,
    sortParams,
    setSortParams,
    displayAssignment,
    loadingPriorities,
    loadingAssignments,
    setLoadingAssignments,
    alerts,
    kpis,
    totalAssignments,
    setPageNumber,
    pageNumber,
  } = props;

  // value for dashboard aside tabs
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedQuickView, setSelectedQuickView] = React.useState(
    EQuickView.todayPriorities,
  );
  const [selectAssignment, setSelectedAssignment] = React.useState<
    IAssignment & ILegacyAssignment
  >(null);
  const [emailsCount, setEmailsCount] = React.useState(null);
  const [isGoogleSignInOpen, setGoogleSignInOpen] = React.useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = React.useState<boolean>(false);
  const [asideIsHidden, setAsideVisibility] = React.useState(false);
  const [navigationIsHidden, setNavigationVisibility] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState(0);
  const [filterType, setFilterType] = React.useState("all");
  const [pendingActions, setPendingActions] = React.useState([]);
  const [docusignUrl, setDocusignUrl] = React.useState<string>();
  const [systemMessage, setSystemMessage] = React.useState<string>("");
  const [systemToastIsOpen, setCloseSystemMessage] = React.useState<boolean>(false)
  const [selectedInsurer, setSelectedInsurer] = React.useState<IInsuredSubect>()
  const [currencyRates, setCurrencyRates] = React.useState<ICurrency[]>()
  const currentLocation = useLocation();
  const navigation = useNavigate();
  const { assignmentId, tab } = useParams();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const legacyView = urlSearchParams.get("legacy")
  const defaultCrumbs = [ERoute.dashboard];
  const breadcrumbPaths = [
    ...defaultCrumbs,
    ...currentLocation.pathname.split("/").filter((path) => path !== ""),
  ];
  const breadcrumbsProps = breadcrumbPaths.map((path, index) => {

    const title = RouteTitle[path];
    const url = breadcrumbPaths.slice(0, index + 1);

    return {
      title: path === assignmentId ? assignments.get(Number(assignmentId))?.contractNo || selectAssignment?.contractNo || "" : title || path,
      url: `${url.join("/")}`,
    };
  });

  const breadcrumbs = breadcrumbsProps.map((breadcrumb, index) => {
    if (breadcrumb?.title) { return <Link to={breadcrumb.url}>{breadcrumb.title}</Link> }
    else return <Skeleton style={{ display: "flex", justifyContent: "flex-end" }} data-testid="alerts-skeleton" width="3rem" variant="rectangular" />;
  });

  const handleQuickViewChange = (quickView: EQuickView) => {
    setSelectedQuickView(quickView);
  };



  React.useEffect(() => {
    if (systemToastIsOpen == false) return;
    setTimeout(() => {
      setCloseSystemMessage(false);
    }, 2000)
  }, [systemMessage, systemToastIsOpen])


  const addParamToURL = () => {

    const { pathname, search } = window.location;
    const updatedParams = new URLSearchParams(search);

    const settings = updatedParams.get("settings");

    if (!settings) {
      updatedParams.set('settings', 'open');
      const newURL = `${pathname}?${updatedParams.toString()}`;
      window.history.pushState({}, '', newURL);
      const navEvent = new PopStateEvent('popstate');
      window.dispatchEvent(navEvent);
    }
    else {
      updatedParams.delete('settings');
      let newURL = `${window.location.protocol}//${window.location.host}${pathname}`;
      if (updatedParams.toString() !== '') {
        newURL += `?${updatedParams.toString()}`;
      }
      window.history.replaceState({}, '', newURL);
    }
  };

  const toggleSettings = () => {
    addParamToURL()
    if (selectedQuickView === EQuickView.userSettings) {
      handleQuickViewChange(EQuickView.todayPriorities);
      return;
    }
    handleQuickViewChange(EQuickView.userSettings);
  };

  const unviewedAlerts = alerts?.filter((alert) => !alert.viewed).length;

  const REFRESH_INTERVAL = 10000000;
  const handleRefresh = () => {
    getEmailsCount(localStorage.getItem("emailsCount")).then((res) => {
      setEmailsCount(res);
      if (res && res.total_unread_emails) {
        const count = parseInt(res.total_unread_emails, 10);
        localStorage.setItem("emailsCount", count.toString());
      }
    });
  };

  React.useEffect(() => {
    handleRefresh();
    const intervalId = setInterval(() => {
      handleRefresh();
    }, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    const isDashboard = currentLocation.pathname === "/";
    setAsideVisibility(!isDashboard)
  }, [currentLocation?.pathname])


  function checkGoogleSignIn() {
    const googleAuthCookie = localStorage.getItem("isGoogle");
    if (googleAuthCookie) {
      return true;
    }
    return false;
  }

  const clickOnMessage = (taskId: number) => {
    setIsCommentsOpen(!isCommentsOpen);
    setSelectedTask(taskId);
    markAsRead(taskId)
  }

  const toggleLegacy = () => {
    // attach query param to current url
    const { pathname, search } = window.location;
    const updatedParams = new URLSearchParams(search);
    const legacy = updatedParams.get("legacy");
    if (!legacy) {
      updatedParams.set('legacy', 'true');
      const newURL = `${pathname}?${updatedParams.toString()}`;
      navigation(newURL);
    }
  }


  React.useEffect(() => {
    checkGoogleSignIn();
    getCurrencies()
  }, []);


  const ModalPortal = asModal(ConnectedModal, "#modal-root");



  const processRequestMessages = (response: AxiosResponse) => {
    const notGet = response.config.method !== "get" && !response.config.url?.endsWith("get")
    if (notGet && response?.data?.message) {
      setSystemMessage(response.data.message);
      setCloseSystemMessage(true);
    }
    return response;
  }

  const handleRequestError = (error: AxiosError) => {
    const assignmentError = error.config.url?.endsWith("assignment/")
    if (assignmentError) {
      setSystemMessage("Unable to create");
      setCloseSystemMessage(true);
    }
    return error
  };

  const closeSystemToast = () => setCloseSystemMessage(false);

  React.useEffect(() => {
    const process = axios.interceptors.response.use(processRequestMessages, handleRequestError);
    const processAPIResponse = api.interceptors.response.use(processRequestMessages, handleRequestError);
    const processAPIV2Response = apiv2.interceptors.response.use(processRequestMessages, handleRequestError);
    const processAuthResponse = auth.interceptors.response.use(processRequestMessages);
  }, [])
  const getCurrencies = async () => {
    try {
      const response = await fetchLatestRates()
      if (response.status == 200) {
        setCurrencyRates(response?.data?.data)
      }

    }
    catch (err) {
      console.error("fetch currencies error", err)
    }
  }

  const handleTransfer = async (selectAssignmentId: string, userId: number, legacyId: number): Promise<void> => {
    try { await transferAssignment(selectAssignmentId, userId, legacyId) }
    catch (err) { console.error(err) }
  }

  return (
    <DashboardContext.Provider
      value={{
        kpis,
        user,
        alerts,
        priorities,
        filterType,
        assignmentId,
        setPriorities,
        currencyRates,
        setCurrencyRates,
        pendingActions,
        setPendingActions,
        displayAssignment,
        getKPIs,
        clickOnMessage,
        assignments,
        emailsCount,
        searchParams,
        setAssignments,
        pendingActions,
        selectedInsurer,
        displayAssignment,
        selectAssignment,
        setSelectedAssignment,
        selectedQuickView,
        loadingPriorities,
        loadingAssignments,
        setLoadingAssignments,
        fetchAssignments,
        breadcrumbs,
        tab,
        setSelectedInsurer,
        setPriorities,
        setPendingActions,
        setSearchParams,
        setFilterType,
        getKPIs,
        logout,
        setIsOpen,
        createBroadcastMessage,
        editAssignment,
        totalAssignments,
        setPageNumber,
        sortParams,
        setSortParams,
        pageNumber,
        handleTransfer,
      }}
    >
      <div id="modal-root"></div>
      <DashboardContainer
        id={"dashboard-container"}
        asideIsHidden={asideIsHidden}
        mainNavigationIsHidden={navigationIsHidden}
      >
        <DashboardHeader>
          <LogoContainer>
            <img src={`${process.env.ORG_ASSETS}/logo/navlogo`} alt="Mandarin RE logo" />
          </LogoContainer>
          <DashboardGlobalActions>
            {/* <DocuSign setDocusignUrl={setDocusignUrl} /> */}

            <SignInWithGoogle
              isOpen={isGoogleSignInOpen}
              onClose={() => setGoogleSignInOpen(false)}
            />

            <DashboardInboxContainer
              id={"dashboard-header"}
              type={"internal"}
              data-testid="internal-inbox"
              onClick={() => {
                if (!checkGoogleSignIn()) {
                  setGoogleSignInOpen(true);
                } else {
                  setFilterType("internal");
                  navigation("/inbox");
                }
              }}
              disabled={isGoogleSignInOpen}
            >
              <MailOutlineOutlined fontSize="small" />
              <p>INTERNAL</p> <p>{emailsCount?.unread_internal_emails}</p>
            </DashboardInboxContainer>

            <DashboardInboxContainer
              id={"dashboard-header"}
              type={"external"}
              data-testid="external-inbox"
              onClick={() => {
                if (!checkGoogleSignIn()) {
                  setGoogleSignInOpen(true);
                } else {
                  setFilterType("external");
                  navigation("/inbox");
                }
              }}
              disabled={isGoogleSignInOpen}
            >
              <MailOutlineOutlined fontSize="small" />
              <p>EXTERNAL</p> <p>{emailsCount?.unread_external_emails}</p>
            </DashboardInboxContainer>
            {/* <LegacyLabel onClick={toggleLegacy}><div className="label">Legacy</div> <OpenInNewIcon /></LegacyLabel> */}
            <DashboardAvatar
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              data-testid="dashboard-avatar"
            >
              <ModalPortal
                data-testid="dashboard-avatar-modal"
                isOpen={isOpen}
                onClose={() => {
                  setIsOpen(!isOpen);
                }}
                containerStyle={modalStyling}
              />
              <span>{user?.firstName && user?.firstName[0]}</span>
              <span>{user?.lastName && user?.lastName[0]}</span>
            </DashboardAvatar>
          </DashboardGlobalActions>
        </DashboardHeader >
        <main>
          <MainNavigation setNavigationVisibility={setNavigationVisibility} navigationIsHidden={navigationIsHidden} />
          <DashboardMain id="main-dashboard">
            <section style={{
              display: "flex",
              flexDirection: "column",
              height: "100%"
            }}>
              <LeadershipBanner />
              {!legacyView && <Outlet context={{ docusignUrl }} />}
              {/* <GuacamoleClient isVisible={!legacyView}/> */}
            </section>
          </DashboardMain >
          <Aside
            priorities={priorities}
            assignments={assignments}
            toggleSettings={toggleSettings}
            selectedTask={selectedTask}
            user={user}
            loadingPriorities={loadingPriorities}
            assignmentId={assignmentId}
            asideIsHidden={asideIsHidden}
            setAsideVisibility={setAsideVisibility}
            selectedQuickView={selectedQuickView}
          />
        </main >
        <StyledToast message={systemMessage} isOpen={systemToastIsOpen} isVisible={systemToastIsOpen} onClose={closeSystemToast} />
        <CommentsModal
          selectedTask={selectedTask}
          isOpen={isCommentsOpen}
          handleCommentsClose={clickOnMessage}
        />
        {/* <assignmentDrillDownModal isOpen={!!selectAssignment} assignment={selectAssignment} handleClose={closeAssignmentDrillDown}/> */}
      </DashboardContainer>
    </DashboardContext.Provider >
  );
};


export const contextsWrapper = (HOCs: HOC[], WrappedComponent: React.FC) => {
  return HOCs.reduce((acc, HOC) => HOC(acc), WrappedComponent);
}

export const WrappedDashboard = contextsWrapper([AlertsContextWrapper], Dashboard);

export const AssignmentsContenxt = React.createContext(null);
const ConnectedDashboard = (): JSX.Element => {
  const { user, allowedAPIRoutes, logout } = React.useContext(UserContext);
  const assignmentProps = useAssignments({
    pageSize: 20,
    user,
    allowedAPIRoutes
  })
  const {
    assignments,
    goToNextPage,
    goToPrevPage,
    pageSize,
    setAssignments,
    searchParams,
    fetchAssignments,
    setSearchParams,
    displayAssignment,
    priorities,
    kpis,
    setPriorities,
    loadingPriorities,
    loadingAssignments,
    setLoadingAssignments,
    totalAssignments,
    sortParams,
    setSortParams,
    pageNumber,
    setPageNumber,
  } = assignmentProps;
  return (
    <AssignmentsContenxt.Provider value={assignmentProps}>
      <WrappedDashboard
        user={user}
        kpis={kpis}
        assignments={assignments}
        searchParams={searchParams}
        loadingPriorities={loadingPriorities}
        loadingAssignments={loadingAssignments}
        totalAssignments={totalAssignments}
        pageNumber={pageNumber}
        priorities={priorities}
        sortParams={sortParams}
        displayAssignment={displayAssignment}
        fetchAssignments={fetchAssignments}
        setAssignments={setAssignments}
        logout={logout}
        setSortParams={setSortParams}
        setPriorities={setPriorities}
        setLoadingAssignments={setLoadingAssignments}
        setSearchParams={setSearchParams}
        setPageNumber={setPageNumber}
      />
    </AssignmentsContenxt.Provider>
  );
};

Dashboard.defaultProps = {
  getLeadershipNotifications,
  getKPIs,
};

ConnectedDashboard.defaultProps = {
  getLeadershipNotifications,
  getKPIs,
};

export default ConnectedDashboard;
