import { IUser } from "../users/types/user";
import { getAssignments } from "../assignments/get";

export interface IDocument {
  id: number;
  name: string;
  description: string;
  url: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  userId: number;
  departmentId: number;
  teamId: number;
  organizationId: number;
}

export interface INotification {
  id: number;
  notificationDescription: string;
  dateCreated: string;
  expirationDate: string;
}

export enum EAssignmentStatus {
  preview = "Preview",
  inProgress = "In Progress",
  request = "Request",
  toBeApproved = "TBAL",
  notApproved = "Not Approved",
  rework = "Rework",
  confirmed = "Confirmed",
  accepted = "Accepted",
  declined = "Declined",
  rejected = "Rejected",
  fronting = "Fronting",
  failing = "Failing",
}

export enum ELegacyAssignmentProps {
  kod = "id",
  KOD_old = "KOD_old",
  N_DOGOVOR = "contract_no",
  STRX_C = "insurer",
  CEDENT = "cedent",
  BROKER = "broker",
  N_CEDENT = "cedent_no",
  Tip = "contract_type",
  accept_date = "accept_date",
  D_BEGIN = "start_date",
  D_END = "end_date",
  coverage = "coverage",
  PRIM = "comment",
  DATA_Create = "create_date",
  STATUS = "status_type",
  DATA_STATUS = "DATA_STATUS",
  Andr = " ",
  USERS = "underwriter",
  Data_Izm = "Data_Izm",
  Stady = "Stady",
  office = "office",
  cobroker = "cobroker",
  prenumber = "prenumber",
  sthistory = "sthistory",
  skod = "skod",
  scomment = "scomment",
  sdate = "sdate ",
  vlcomment = "vlcomment",
  vlcurrency = "vlcurrency",
  INSURER = "insurer",
  STRX_L = "STRX_L",
  Name_object = "Name_object",
  contract_type = "assignment_type",
  policy_no_desc = "id",  
  ENGVIDOBJ = "Category_desc",
  ENGCATEGORY = "Sub_class_desc",  
  Op_Object = "Op_Object",
  territory = "territory",
  form_of_ri = "form_of_ri",
  class_of_insurance = "class_of_insurance",
  sub_class = "sub_class",
  dur_inception = "dur_inception",
  dur_expiry = "dur_expiry",
  contract_currency = "contract_currency",
  currency_for_payment = "currency_for_payment",
  roe_date = "roe_date",
  roe_rate = "roe_rate",
  r_c_premium = "r_c_premium",
  r_c_deduction = "r_c_deduction",
  r_c_brokerage = "r_c_brokerage",
  r_c_other  = "r_c_other",
  r_c_net_prm = "r_c_net_prm",
  r_c_version = "r_c_version",
  Eng_FRZ = "Eng_FRZ",
  ENG_OP = "ENG_OP",
  EngRachet = "EngRachet",
  subClass = 'sub_class'
}

export enum EAssignmentState {
  NB = "New Business",
  R = "Renewals",
  C = "Claims",
  CXL = "Cancellations",
  END = "Endorsements",
}

export interface IDeductibleTypeObject {
  [ELegacyAssignmentProps.Eng_FRZ]: string;
}

export interface IDeductibleBasisObject {
  [ELegacyAssignmentProps.ENG_OP]: string;
}

export interface IDeductibleActualValueObject {
  [ELegacyAssignmentProps.EngRachet]: string;
}

export interface IInsuredObjects {
    [ELegacyAssignmentProps.STRX_L]: string;
    [ELegacyAssignmentProps.Name_object]: string;
    [ELegacyAssignmentProps.policy_no_desc]: string;
    [ELegacyAssignmentProps.ENGVIDOBJ]: string;
    [ELegacyAssignmentProps.ENGCATEGORY]: string;
    [ELegacyAssignmentProps.Op_Object]: string;
}

export interface IContractType {
  [ELegacyAssignmentProps.contract_type]: string;
}

export interface IAssignment {
  id: string;
  state: EAssignmentState;
  age: number;
  limit: number;
  isCompleted: boolean;
  priorityLevel: string;
  isArchived: boolean;
  is_escalated: boolean;
  notes: IAssignmentNote[];
  type: "facultative" | "treaty";
  inception_date: string;
  insured_objects: IInsuredObjects;
  deductible_type: IDeductibleTypeObject;
  deductible_basis: IDeductibleBasisObject;
  deductible_actual_value: IDeductibleActualValueObject;
  contract_no:string;
  broker?:string;
  cedent?:string;
  insurer?:string;
  status_type?:any;
  contract_type?:string;
  underwriter?:string;
  is_awaiting?:boolean;
  is_late: boolean;
}

export interface ILegacyAssignment {
  [ELegacyAssignmentProps.kod]: string;
  [ELegacyAssignmentProps.KOD_old]: string;
  [ELegacyAssignmentProps.N_DOGOVOR]: string;
  [ELegacyAssignmentProps.STRX_C]: string;
  [ELegacyAssignmentProps.CEDENT]: string;
  [ELegacyAssignmentProps.BROKER]: string;
  [ELegacyAssignmentProps.N_CEDENT]: string;
  [ELegacyAssignmentProps.Tip]: string;
  [ELegacyAssignmentProps.accept_date]: string;
  [ELegacyAssignmentProps.D_BEGIN]: Date;
  [ELegacyAssignmentProps.D_END]: Date | string;
  [ELegacyAssignmentProps.PRIM]: string;
  [ELegacyAssignmentProps.DATA_Create]: Date;
  [ELegacyAssignmentProps.STATUS]: EAssignmentStatus;
  [ELegacyAssignmentProps.DATA_STATUS]: string;
  [ELegacyAssignmentProps.Andr]: string;
  [ELegacyAssignmentProps.USERS]: string;
  [ELegacyAssignmentProps.Data_Izm]: string;
  [ELegacyAssignmentProps.Stady]: string;
  [ELegacyAssignmentProps.office]: string;
  [ELegacyAssignmentProps.cobroker]: string;
  [ELegacyAssignmentProps.prenumber]: string;
  [ELegacyAssignmentProps.sthistory]: string;
  [ELegacyAssignmentProps.skod]: string;
  [ELegacyAssignmentProps.scomment]: string;
  [ELegacyAssignmentProps.sdate]: string;
  [ELegacyAssignmentProps.vlcomment]: string;
  [ELegacyAssignmentProps.vlcurrency]: string;
  [ELegacyAssignmentProps.INSURER]: string;
  [ELegacyAssignmentProps.form_of_ri]: string;
  [ELegacyAssignmentProps.class_of_insurance]: string;
  [ELegacyAssignmentProps.sub_class]: string;
  [ELegacyAssignmentProps.dur_inception]: string;
  [ELegacyAssignmentProps.dur_expiry]: string;
  [ELegacyAssignmentProps.contract_currency]: string;
  [ELegacyAssignmentProps.currency_for_payment]: string;
  [ELegacyAssignmentProps.roe_date]: string;
  [ELegacyAssignmentProps.roe_rate]: string;
  [ELegacyAssignmentProps.r_c_premium]: string;
  [ELegacyAssignmentProps.r_c_deduction]: string;
  [ELegacyAssignmentProps.r_c_brokerage]: string;
  [ELegacyAssignmentProps.r_c_other]: string;
  [ELegacyAssignmentProps.r_c_net_prm]: string;
  [ELegacyAssignmentProps.r_c_version]: string;  
  [ELegacyAssignmentProps.territory] :string;
  [ELegacyAssignmentProps.subClass] :string
}

export interface IAssignmentComment {
  id: number;
  created_by: number;
  text: string;
  created_at: string;
  is_seen: boolean;
  count: number;
  username: string;
}

export interface IAssignmentNote {
  id: number;
  created_by: IUser;
  title: string;
  description: string;
  comments: IAssignmentComment[];
  date: string;
}

export interface Task {
  id: string;
  description: string;
  date: string;
  comments?: IAssignmentComment[];
  is_completed: boolean;
  is_deleted: boolean;
  assignment_id: string;
}

export interface IDefaultTask {
  id: string;
  description: string;
}

export type TAssignment = IAssignment & ILegacyAssignment;
export type TAssignments = TAssignment[];

export interface IUseAssignmentsParams {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  user: IUser;
  getAssignmentsProp: typeof getAssignments;
}

// KPIS INTERFACES
export interface IKPI {
  label: string;
  total_renewals_offers: {
    value:number 
  }
  total_endorsements: {
    value: number 
  }
  value: number 
}

export interface IKPIResponse {
  kpis: {
    [key: string]: IKPI
  };
}

export interface IBroadcastMessage {
  id?: string;
  description: string;
  created_at: string;
  expires_at: string;
  is_broadcast: boolean;
  no_of_days?: number;
  user?: string;
  is_active?: boolean;
}

// EMAIL INTERFACES
export interface IEmail {
  id: string;
  from: string;
  to: string;
  subject: string;
  date: string;
  body: string;
  attachments: IAttachment[];
  status: string;
  time: string;
}

export interface IAttachment {
  id: string;
  name: string;
  type: string;
  label: string;
  assignmentId: string;
  messageId: string;
  attachmentId: string;
  fileName: string;
}

// DEPARTMENT INTERFACES
export class IDepartment {
  id: number;
  name: string;
  organizationId: number;
  authorityLevel: {
    services: [
      {
        service_name: string;
        permissions: [
          {
            allowed: [
              {
                endpoint: string;
                method: string[];
                defaultParams?: string;
              },
            ];
            denied: [
              {
                endpoint: string;
                method: string[];
                defaultParams?: string;
              },
            ];
          },
        ];
      },
    ];
  };

  constructor(
    id: number,
    name: string,
    organizationId: number,
    authorityLevel?: {
      services: [
        {
          service_name: string;
          permissions: [
            {
              allowed: [
                {
                  endpoint: string;
                  method: string[];
                  defaultParams?: string;
                },
              ];
              denied: [
                {
                  endpoint: string;
                  method: string[];
                  defaultParams?: string;
                },
              ];
            },
          ];
        },
      ];
    }
  ) {
    this.id = id;
    this.name = name;
    this.organizationId = organizationId;
    this.authorityLevel = authorityLevel || { services: [] };
  }

  // Example method for creating an instance from an API response
  static fromAPIResponse(response: any) {
    return new IDepartment(
      response.id,
      response.name,
      response.organization_id,
      response.authority_level
    );
  }

  // Example method for converting the instance to an API request format
  toAPIRequest() {
    return {
      id: this.id,
      name: this.name,
      organization_id: this.organizationId,
      authority_level: this.authorityLevel,
    };
  }

  // Method to create a copy of the department instance
  copyOfDepartment() {
    return new IDepartment(
      this.id,
      this.name,
      this.organizationId,
      this.authorityLevel
    );
  }
}
