import * as React from "react";
import { StatusBadge } from "../styles";

interface IStatusColumnProps {
  status: string;
}

const StatusColumn = (props: IStatusColumnProps) => {
  return (
    <StatusBadge status={props?.status}>
      {props?.status?.toUpperCase()}
    </StatusBadge>
  );
};

export default StatusColumn;
