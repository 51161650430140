import { faker } from "@faker-js/faker";
import { IUser, EUserRole } from "../users/types/user";

export const randomUser = (): IUser => {
  return {
    id: faker.number.int(),
    username: faker.internet.userName(),
    lastName: faker.person.lastName(),
    firstName: faker.person.firstName(),
    email: faker.internet.email(),
    password: faker.internet.password(),
    role: EUserRole.ADMIN,
    authorityLevel: {
      regions: [
        {
          geo_tag: "America",
          latitude: 123,
          services: [
            {
              coverage_limit: 1000000,
              line_of_bussines: ["tech"],
            },
          ],
          longitude: 123,
          region_name: "US",
        },
      ],
      services: [
        {
          permissions: [
            {
              allowed: [
                {
                  endpoint: faker.internet.url(),
                  operation: faker.helpers.arrayElements(["read", "write", "delete", "update"]),
                  defaultParams: faker.datatype.string(),
                },
              ],
              denied: [
                {
                  endpoint: faker.internet.url(),
                  operation: faker.helpers.arrayElements(["read", "write", "delete", "update"]),
                  defaultParams: faker.datatype.string(),
                },
              ],
            },
          ],
          service_name: faker.helpers.arrayElement([
            "service1",
            "service2",
            "service3",
            "service4",
            "service5",
            "service6",
            "service7",
            "service8",
            "service9",
            "service10",
          ]),
        },
      ],
  }, 
    department: faker.datatype.number(),
    active: faker.datatype.boolean(),
    settings: {
      view: faker.helpers.arrayElement(["leadership", "underwriter"]),
      selected_underwriter: faker.datatype.number(),
    },
  };
};

export const randomUsers = (count: number): IUser[] => {
  const users: IUser[] = [];
  for (let i = 0; i < count; i++) {
    users.push(randomUser());
  }
  return users;
};

export const getUserMock = async (): Promise<IUser> => {
  return randomUser();
};

export const getUsersMock = async (): Promise<IUser[]> => {
  return [randomUser(), randomUser()];
};
