import {api} from "../index";
import { Task } from "../types";

export const getDefaultTasks = async (): Promise<Task[]> => {
  try {
    const response = await api.get(`/tasks/default_tasks`);
    return response.data.tasks;
  } catch (error) {
    console.error("Error fetching tasks:", error);
  }
};
