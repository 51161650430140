import React from "react";
import { InputWithLabel } from "../../../../components/FormElements/Input/Input";
import CheckBox from "../../../../components/FormElements/CheckBox/Checkbox";
import AddCommentIcon from "@mui/icons-material/AddComment";
import {
  GeneralInformationForm,
  CurrencyInformationForm,
  DurationInformationForm,
  FormGroupContainer,
} from "./styles";

import FormGroup from "../../../../components/FormElements/FormGroup";
import { ILegacyAssignment, ELegacyAssignmentProps } from '../../../../api/v1/types';



const ReinsuranceTerms = (props: ILegacyAssignment) => {
  const [addNotes, setAddNotes] = React.useState<boolean>(false);
  return (
    <>
      <GeneralInformationForm>
        <InputWithLabel className="form" label="Form of R/I" value={props?.insured_subjects?.policy_values?.form_of_ri || 'N/A'}/>
        <InputWithLabel className="class" label="Class of insurance" value={props?.insured_subjects?.policy_values?.class_of_insurance || 'N/A'}/>
        <InputWithLabel className="subclass" label="Sub-class" value={props?.insured_subjects?.policy_values?.sub_class || 'N/A'} />
      </GeneralInformationForm>
      <FormGroupContainer>
        <FormGroup title="Duration">
          <DurationInformationForm>
            <InputWithLabel className="inception" label="Type" value={new Date(props?.insured_subjects?.policy_values?.dur_inception).toLocaleDateString('en-GB')} />
            <InputWithLabel className="basis" label="Basis" value={new Date(props?.insured_subjects?.policy_values?.dur_expiry).toLocaleDateString('en-GB')} />
          </DurationInformationForm>
        </FormGroup>
        <FormGroup title="Currency">
          <CurrencyInformationForm>
            <InputWithLabel className="contract" label="Contract." value={props?.insured_subjects?.policy_values?.contract_currency || 'N/A'}/>
            <InputWithLabel className="payments" label="Payments" value={props?.insured_subjects?.policy_values?.currency_for_payment || 'N/A'} />
          </CurrencyInformationForm>
        </FormGroup>
        <FormGroup title="R. of E.">
          <CurrencyInformationForm>
            <InputWithLabel label="Date of R. E." value={new Date(props?.insured_subjects?.policy_values?.roe_date).toLocaleDateString('en-GB')}/>
            <InputWithLabel label="Rate" value={props?.insured_subjects?.policy_values?.roe_rate || 'N/A'} />
          </CurrencyInformationForm>
        </FormGroup>
      </FormGroupContainer>
    </>
  );
};

export default ReinsuranceTerms;
