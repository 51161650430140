import * as React from 'react'
import styled from 'styled-components';
import AutoCompleteDropDown from './components/DropDown/DropDown';
import { BrandedInputWithLabel, StyledWrapper } from './styles';
import { InputWithLabel } from '../DefaultInput';

interface IProps extends HTMLInputElement {
    value?: string | number,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onSearch?: (text: string, setOptions: React.Dispatch<React.SetStateAction<any[]>>) => void
    label?: string
    onSelect?: (
        text: string,
        option: string,
        setText: React.Dispatch<React.SetStateAction<string>>,
        setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    ) => void
    width?: string
    className?: string
    open?: boolean
    errors?: any
    multiSelect?: boolean
    testId?: string
}

const AutoCompleteInput = (props: IProps) => {
    const { onChange, name, value, keepOpen, className, errors, hideSearch, search, howToSearch, values, testId } = props;
    const containerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const seachProcess = (text: string) => {
        const filtersWithValues = howToSearch && howToSearch?.filters.map((filter, index) => {
            if (filter.value) {
                const value = filter.value.split('$')[1]
                const properyMap = value.split('.')
                text = properyMap.reduce((acc, key) => {
                    if (!acc[key]) return ''
                    return acc[key]
                }, values)
            }
            return {
                ...filter,
                value: text
            }
        })
        if (howToSearch && search) {
            search({...howToSearch, filters: filtersWithValues}, howToSearch.optionName)
        }
    }

    const changeOveride = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        search && seachProcess(e.target.value)
        onChange && onChange(e)
    }
    return (
        <>
            <StyledWrapper ref={containerRef} open={keepOpen} className={className}>
                <InputWithLabel
                    hidden={hideSearch}
                    label={props.label || props?.type || props.value}
                    ref={inputRef}
                    value={value}
                    name={name}
                    onChange={changeOveride}
                    errors={errors}
                    testId={testId}
                />
                <AutoCompleteDropDown {...props} ref={containerRef} />
            </StyledWrapper>
        </>
    )
}



export default AutoCompleteInput