import * as React from 'react';
import asModal from '../../../../hoc/asModal';
import styled from 'styled-components';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useNavigate } from 'react-router-dom';




const StyledErrorAssignmentModal = styled(ConfirmationModal) <{ cancelButtonColor: string, cancelTextColor: string, submitBorderColor: string, submitButtonText: string, buttonBackgroundColor: string }>`
    min-width: 50%;
    height: unset;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    h2 {
      margin-top: 0;
      }
    cancelButtonColor: ${props => props.cancelButtonColor};
    cancelTextColor: ${props => props.cancelTextColor};
    submitBorderColor: ${props => props.submitBorderColor};
    submitButtonText: ${props => props.submitButtonText};
    buttonBackgroundColor: ${props => props.buttonBackgroundColor};
`;
const CreateErrorAssignmentModal = asModal(StyledErrorAssignmentModal, "#modal-root");
const ErrorMessageContainer = styled.div`
  flex-basis: 100%;
  max-height: 250px;
  overflow-y: auto;
`;
const ErrorWrapper = (props) => {
    const { errors, open, setOpen } = props;

    const toggleWarning = (): void => {
        setOpen(!open);
    }

    const recursiveErrorsStrings = (errors, elementName = ''): JSX.Element[] => {
        let errorElements: JSX.Element[] = [];

        if (typeof errors === 'object') {
            Object.keys((errors || {})).forEach((key) => {
                const currentKey = !Number.isNaN(Number(key)) ? `[${key}]` : `.${key}`;
                const newElementName = elementName ? `${elementName}${currentKey}` : key;
                errorElements = errorElements.concat(recursiveErrorsStrings(errors[key], newElementName));
            });
        } else {
            errorElements.push(
                <ErrorMapper location={elementName} message={errors} toggleWarning={toggleWarning} />
            );
        }

        return errorElements;
    }
    const errorString = recursiveErrorsStrings(errors)

    return (
        <>
            {props?.children}
            <CreateErrorAssignmentModal
                title="Issue with form submission"
                isOpen={open}
                onClose={toggleWarning}
                onConfirm={toggleWarning}
            >
                <ErrorMessageContainer>{errorString}</ErrorMessageContainer>
            </CreateErrorAssignmentModal>
        </>
    )
}




const StyledErrors = styled.li`
  color: red;
  cursor: pointer;
  &:hover {
    color: #ce2f2f;
  }
`
const ErrorMapper = (props): JSX.Element => {
    const { location, message, toggleWarning } = props;
    const navigate = useNavigate();
    const goToError = (e): void => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`#input-${location}`, { replace: true });
        toggleWarning();
    }
    return (
        <StyledErrors onClick={goToError}>
            <p>{message}</p>
        </StyledErrors>
    )
}


export default ErrorWrapper