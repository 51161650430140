import * as React from 'react'
import AutoCompleteInput from '../../AutocompleteInput'
import { CreateAssignmentInput } from '../../../styles';
import PerformantAccordion from '../../../../../../../components/PerformantAccordion';
import { InsuredSubject } from '../../../../../../../api/v1/assignments/classes/insuredSubjects';



const RiskCategory = (props: InsuredSubject) => {
    const { insuredSubject, handleChange, searchFunctions, options, setFieldValue, index } = props;
    const prefix = `insuredSubjects[${index}].riskCategory`
    const errors = props?.errors?.riskCategory || {}

    React.useEffect(() => {
        if (insuredSubject?.riskCategory?.category?.id) {
            searchFunctions?.searchSubCategory('', insuredSubject?.riskCategory?.category?.id)
        }
    }, [insuredSubject?.riskCategory?.category])

    const isCategoryOther = insuredSubject?.riskCategory?.category?.label === 'Other';

    return (
        <PerformantAccordion className='child-accordion' title={'Risk Category'} openingKey={prefix}>
            <AutoCompleteInput
                label='Category'
                name={`${prefix}.category`}
                value={insuredSubject?.riskCategory?.category}
                options={options?.category || []}
                onSearch={searchFunctions?.searchCategory}
                setFieldValue={setFieldValue}
                errors={errors?.category}
                testId={`${prefix}.category`}
            />
            {!isCategoryOther &&
                (
                    <AutoCompleteInput
                        label='Sub-Class'
                        name={`${prefix}.subCategory`}
                        value={insuredSubject?.riskCategory?.subCategory}
                        options={options?.subCategory || []}
                        onSearch={(text) => { searchFunctions?.searchSubCategory(text, insuredSubject?.riskCategory?.category?.id) }}
                        setFieldValue={setFieldValue}
                        errors={errors?.subCategory}
                        testId={`${prefix}.subCategory`}
                    />
                )}
            <CreateAssignmentInput
                label='Characteristics'
                name={`insuredSubjects[${index}].characteristics`}
                value={!insuredSubject?.characteristics ? "" : insuredSubject?.characteristics}
                onChange={handleChange}
                testId={`${prefix}.characteristics`}
            />
        </PerformantAccordion>
    )
}




export default RiskCategory;