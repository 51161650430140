import * as React from "react";
import { IKPI } from "../../../../api/v1/types";
import UnderwriterAccordion from "./UnderwriterAccordion";
import { StyledUnderwriterRow } from "../styles";
import { IEndorsements, INewBusiness, IRenewals } from "../../Metrics";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface IUnderwriterRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

interface ITotalRow {
  active: IKPI;
  pending: IKPI;
  request: IKPI;
  escalate: IKPI;
  accepted: IKPI;
  declined: IKPI;
  renewed: IKPI;
  inactive: IKPI;
  closed: IKPI;
  lost: IKPI;
  total: IKPI;
}

interface ITotal {
  total: ITotalRow;
}

interface IEmployee {
  total: ITotal;
  name: string;
  metrics: IUnderwriterRow;
  renewals: IRenewals;
  endorsements: IEndorsements;
  newBusiness: INewBusiness;
}

interface IUnderwriterRowProps {
  employee: IEmployee;
  expandAll?: boolean;
  expandAccordion?: boolean;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
}

const UnderwriterRow = (props: IUnderwriterRowProps) => {
  const [expandAccordion, setExpandAccordion] = React.useState<boolean>(false);

  const handleExpandAccordion = (event: React.MouseEvent<Element>) => {
    setExpandAccordion(!expandAccordion);
  };

  React.useEffect(() => {
    setExpandAccordion(props.expandAll);
  }, [props.expandAll]);

  const renderMetricValue = (metric: IKPI) => {
    if (metric.change === 0) {
      return metric.value;
    } else {
      return (
        <>
          {metric.value} -{" "}
          <span style={{ color: "blue" }}>{metric.change}%</span>
        </>
      );
    }
  };

  return (
    <>
      <StyledUnderwriterRow
        selected={expandAccordion}
        onClick={handleExpandAccordion}
        employee={props.employee.name}
        total={props.employee.total}
      >
        <td className="employee-name">
          {props.employee.name}
          {expandAccordion ? (
            <KeyboardArrowDownIcon style={{ verticalAlign: "middle" }} />
          ) : (
            <KeyboardArrowRightIcon style={{ verticalAlign: "middle" }} />
          )}
        </td>
        <td>{renderMetricValue(props?.employee?.total?.active)}</td>
        <td>{renderMetricValue(props?.employee?.total?.pending)}</td>
        <td>{renderMetricValue(props?.employee?.total?.request)}</td>
        <td>{renderMetricValue(props?.employee?.total?.escalate)}</td>
        <td>{renderMetricValue(props?.employee?.total?.accepted)}</td>
        <td>{renderMetricValue(props?.employee?.total?.declined)}</td>
        <td>{renderMetricValue(props?.employee?.total?.renewed)}</td>
        <td>{renderMetricValue(props?.employee?.total?.inactive)}</td>
        <td>{renderMetricValue(props?.employee?.total?.closed)}</td>
        <td>{renderMetricValue(props?.employee?.total?.lost)}</td>
        <td>{renderMetricValue(props?.employee?.total?.total)}</td>
      </StyledUnderwriterRow>

      <UnderwriterAccordion
        hideSummary={true}
        handleExpandAccordion={handleExpandAccordion}
        expandAccordion={expandAccordion}
        renewals={props?.employee?.renewals}
        endorsements={props?.employee?.endorsements}
        newBusiness={props?.employee?.newBusiness}
      />
    </>
  );
};
export default UnderwriterRow;
