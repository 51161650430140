import * as React from "react";
import Navigation, { links } from "../../Navigation";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import {
    DashboardHelp,
    DashboardNavigation,
} from "../../styles/DashboardContainer";
import RiskAPIs, { riskLinks } from "../../Navigation/components/RiskAPIs";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const MainNavigation = ({
    setNavigationVisibility,
    navigationIsHidden,
}) => {
    const [isSubMenuOpen, setSubMenuOpen] = React.useState(false);
    const toggleNavigation = () => setNavigationVisibility(!navigationIsHidden)

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };
    return (
        <DashboardNavigation navigationIsHidden={navigationIsHidden} data-testid="dashboard-navigation">
            <div className="toggle-container nav"><button onClick={toggleNavigation}><KeyboardArrowLeftIcon/></button></div>
            <div data-testid="navigation-dashboard">
                <Navigation links={links} navigationIsHidden={navigationIsHidden} />
                {/* <RiskAPIs links={riskLinks} isSubMenuOpen={isSubMenuOpen} toggleSubMenu={toggleSubMenu} setNavigationVisibility={setNavigationVisibility} navigationIsHidden={navigationIsHidden} /> */}
            </div>
            {/* <DashboardHelp>
                <ContactSupportIcon />
                <span className="help-label">Ask Sally</span>
            </DashboardHelp> */}
        </DashboardNavigation>
    )

}

export default MainNavigation;