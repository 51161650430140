import { IAlertsParams } from '../../../hooks/useAlerts';
import {api} from '../index';
import { IAlerts } from '../../../pages/Dashboard/Alerts';
import { getBroadcastMessages } from '../broadcastMessage/get';

interface IAlertsDeleteParams {
    alerts_ids : number[]
}
const deleteAlerts = async (body: IAlertsDeleteParams): Promise<boolean | null> => {

  try {
    const response = await api.delete('/alerts/', {
        data: body,
    });
    if (response.status === 200) {
      return true;
    } else {
      console.error('Failed to fetch alerts:', response?.data?.message);
      return false;
    }
  } catch (error) {
    console.error('An error occurred while fetching alerts:', error);
    return false;
  }
};

export default deleteAlerts;
