import * as React from 'react'
import { ILegacyAssignment } from '../../../api/v1/types'
import ContractType from './components/ContractType'
import GeneralInformation from './components/GeneralInformation'
import SelectUnderwriter from './components/SelectUnderwriter'
import Risks from './components/Risk'
import AssignmentDocuments from './components/Documents'
import PaymentInformation from './components/Payments'
import PremiumAndLimit from '../PremiumAndLimit'
import Payments from '../Payments'
import { useLocation } from 'react-router-dom'
export enum EAssignmentComponent {
  ContractType = 'ContractType',
  GeneralInformation = 'GeneralInformation',
  Risks = 'Risks',
  AssignmentDocuments = 'AssignmentDocuments',
  SelectUnderwriter = 'SelectUnderwriter',
  PremiumAndLimit = 'PremiumAndLimit',
  PaymentInformation = 'PaymentInformation',
}


export const CreateAssignmentComponents = {
  ContractType,
  GeneralInformation,
  Risks,
  AssignmentDocuments,
  SelectUnderwriter,
}


export const AssignmentDrillDownComponents = {
  ContractType,
  GeneralInformation,
  PremiumAndLimit,
  Risks,
  PaymentInformation,
  AssignmentDocuments,
  SelectUnderwriter,
}

const Steps = (props:
  {
    currentPage: number,
    values: ILegacyAssignment,
    search: ({
      model,
      filters,
      detailLevel
    }) => {}
    handleChange: (e: React.ChangeEvent<any>) => void,
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    errors: any,
    setSelectedUnderwriter: React.Dispatch<React.SetStateAction<string>>,
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  }): JSX.Element => {

  const location = useLocation()
  const createAssignment = location.pathname.includes('create-assignment')
  const ComponentToUse = createAssignment ? CreateAssignmentComponents[props?.currentPage] : AssignmentDrillDownComponents[props?.currentPage]
  return <ComponentToUse  {...props} />
}

export default Steps