import { Skeleton } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
  display: flex;
  flex-wrap: wrap; 
`;

const Column = styled.div`
  flex: 1; 
  padding: 1.5rem;
`;

const AssignmentDrillDownSkeleton = () => {
    return (
        <SkeletonContainer>
            <Column>
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
                <Skeleton data-testid="alerts-skeleton" height="6rem" variant="text" />
            </Column>
        </SkeletonContainer>
    )
}

export default AssignmentDrillDownSkeleton;