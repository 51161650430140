import * as React from "react";
import {
  StyledCard,
  StyledPriorityLevel,
  StyledPriorityLevelDates,
  StyledTime,
} from "./styles";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Notes from "../AssignmentDrillDown/components/Notes/index";
import { IAssignmentNote } from "../../../api/v1/assignments/types/assignments";
// import { randomUser } from "../../../api/v1/__mocks__/user";
import NoTasks from "./components/NoTask";
import RenderedShortView from "./components/RenderedShortView";
import ShortViewSkeleton from "./components/ShortViewSkeleton";
import { getUserMock, randomUser } from "../../../api/v1/__mocks__/user";
import { format, isValid, parseISO } from 'date-fns';
import { useNavigate } from "react-router-dom";

export interface IPriorityCardProps {
  priority: "high" | "medium" | "low";
  contractNo: string;
  broker: string;
  date: string;
  tasks: string[];
  comments: IAssignmentNote[];
  priorities: number[];
}

const formatDate = (inputDate: string) => {
  const parsedDate = parseISO(inputDate);
  if (!isValid(parsedDate)) {
    return 'N/A';
  }
  return format(parsedDate, "MMMM do, yyyy");
};


const CreateDate = (props: string) => {
  const formattedDate = props?.createDate && formatDate(props?.createDate)
  return (
    <StyledTime>
      <div className="icon-container">
        <CalendarTodayIcon />
      </div>
      <span data-testid="date-id" className="text">{formattedDate}</span>
    </StyledTime>
  );
};

const Time = () => {
  return (
    <StyledTime>
      <div className="icon-container">
        <AccessTimeIcon />
      </div>
      <span data-testid="time-id" className="text">12:00</span>
    </StyledTime>
  );
};


const PriorityCard = (props: IPriorityCardProps) => {
   const [notes, setNotes] = React.useState<IAssignmentNote[]>([
    {
      description: "Call the broker",
      comments: [],
      id: "1",
      user: randomUser(),
      title: "Call the broker",
      date: "2021-10-10T00:00:00.000Z",
      completed: false,
    }
  ]);
  const [cardExpanded, setCardExpanded] = React.useState(false);
  const [shortViewLoading, setShortViewLoading] = React.useState(true);
  console.log('priorities', props?.tasks)
  const navigate = useNavigate();

  const shortViewToUse = (shortViewLoading) ? <ShortViewSkeleton /> : (shortView);
  return (
    <StyledCard onClick={() => navigate('/priorities')} data-testid={'priority-card'}>
      <StyledPriorityLevel
        priority={props.priority}
        className={"priority-level"}
        data-testid="priority-id"
      >
        {props.priority}
      </StyledPriorityLevel>
      <div data-testid="contract-id" className={"contract-number"}>{props.contractNo}</div>
      <div data-testid="broker-id" className={"contract-broker"}>{props.broker}</div>
      {props?.tasks?.length > 0 && (
        <div data-testid="task-id" className={"contract-task"}>
          {props.tasks.length} {props.tasks.length === 1 ? 'TASK' : 'TASKS'}
        </div>
      )}
      <StyledPriorityLevelDates data-testid="start-date" className={"contract-start-date"}>
        <CreateDate createDate={props?.date}/>
      </StyledPriorityLevelDates>
{cardExpanded && 
      shortViewToUse
}
    </StyledCard>

  );
};
export default PriorityCard;