import { Skeleton } from '@mui/material';
import * as React from 'react';
import { AlertCard } from '../styles';

const AlertsSkeleton = () => {
    return (
        <>
        <Skeleton data-testid="alerts-skeleton" height="25px" width={100} variant="text" />
        
        <Skeleton data-testid="alerts-skeleton-date" style={{
            justifyContent:'flex-end',
            }} width={50} variant="text" />
       
        {Array.from({length: 5}).map((_, index) => (
            <AlertCard data-testid="alert-card-skeleton" key={index}>
                <Skeleton height="18.72px" variant="text" />
                <Skeleton height="38px"  variant="text" />
                <Skeleton height="19px"  variant="text" />
                <Skeleton height="17px"  variant="text" />
                <Skeleton height="30px" variant="text" />
            </AlertCard>
        ))}
        </>
)}

export default AlertsSkeleton;