import * as React from 'react'
import { IComponentProps } from ".."
import Documents from '../../Documents'


const AssignmentDocuments = (props:IComponentProps) => {
    return (
        <Documents  {...props} name={'newFiles'}/>
    )
}

export default AssignmentDocuments