import { fi, pl } from '@faker-js/faker';
import * as React from 'react';
import { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { fetchCountry, fetchSubdivision } from '../../api/v1/country/get';
import { autoCompleteLocation, getNearbyLocations, getPolygonData } from '../../api/v1/locations';
import AutoCompleteInput from '../../pages/Dashboard/AssignmentDrillDown/Steps/components/AutocompleteInput';
import { CreateAssignmentInput } from '../../pages/Dashboard/AssignmentDrillDown/Steps/styles';
import { useFormik } from 'formik';
import FieldGenerator from '../../components/FormElements/FieldGenerator';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { Tooltip } from '@mui/material';

const fields = [
    {
        label: "Country",
        name: "country",
        type: "autocomplete",
        canConfigure: true,
        howToSearch: {
            optionName: "countries",
            model: 'Country',
            filters: [{
                column: 'name',
                value: "",
                operation: 'like'
            }],
            transform: {
                id: "id",
                label: "name",
            },
            detail_level: "option",
        }
    },
    {
        label: "State",
        name: "state",
        type: "autocomplete",
        canConfigure: true,
        howToSearch: {
            optionName: "states",
            model: "StateProvince",
            filters: [
                {
                    column: "name",
                    value: "",
                    operation: "like",
                },
                {
                    column: 'country_id',
                    value: '$country.id',
                    operation: 'eq'

                }
            ],
            transform: {
                id: "id,kod,Kod,KOD,KoD",
                label: "name",
            },
            detail_level: "option",
        },
        dependentOn: ['country']
    },
    {
        label: "City",
        name: "city",
        type: "autocomplete",
        canConfigure: true,
        howToSearch: {
            optionName: "municipalities",
            model: "Municipality",
            filters: [
                {
                    column: "name",
                    value: "",
                    operation: "like",
                },
                {
                    column: 'state_provice_id',
                    value: '$state.id',
                    operation: 'eq'

                }
            ],
            transform: {
                id: "id,kod,Kod,KOD,KoD",
                label: "name",
            },
            detail_level: "option",
        },
        dependentOn: ['country', 'state']
    },
    {
        label: "Address",
        name: "street",
        type: "text",
        canConfigure: true,
        dependentOn: ['country', 'state', 'city']
    },



]
interface Props {
    onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
    setPolygonData?: (data: any) => void
    polygonData: {
        geometry: any
    }
}

const StyledInputWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const Sections = styled.section`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;
`;
const FormElements = styled(FieldGenerator)`
    flex-basis: 100%;
    `;

const Button = styled.button`
    background: #186ade;
    color: white;
    border: none;
    padding: 1rem;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
    max-width: 150px;
    font-size: 0.8rem;
`;

const Section = styled.section`
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem 0 0;
    > button {
        margin: 0.8rem;
    }
    ${FormElements} {
        flex-basis: 100%;
    }
    ${Button} {
        flex-basis: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
    }
   
`;




const Autocomplete: React.FC<Props> = ({
    onChange,
    setFieldValue,
    options,
    onSearch,
    name,
    placeOptions,
    onSelect,
    search,
    label,
    isSpecific,
    setIsSpecific,
    addressForm,
    testId
}) => {
    const overSetFieldValue = (name, option) => {
        addressForm?.setFieldValue(name, option)
    }

    return (
        <StyledInputWrapper>
            {/* <Sections>
                <Button onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setIsSpecific(!isSpecific)
                }}>
                    Try {
                        isSpecific ? "Autocomplete" : "Specific Location"
                    }
                </Button>
            </Sections> */}
            <div>
                {/* {!isSpecific && <AutoCompleteInput
                    type="text"
                    placeholder="Search Places..."
                    onSearch={onSearch}
                    options={placeOptions}
                    name={name}
                    multiSelect
                    setFieldValue={setFieldValue}
                    onChange={onChange}
                    onSelect={onSelect}
                    label={label}
                />} */}
                {
                    <>
                        <Section>
                            {
                                fields.map((field, index) => {
                                    const optionName = field.howToSearch?.optionName
                                    const optionsToUse = optionName && options && options[optionName] ? options[optionName] : []
                                    return <FormElements
                                        key={index}
                                        {...field}
                                        options={optionsToUse}
                                        values={addressForm.values}
                                        value={addressForm?.values[field.name]?.label || addressForm?.values[field.name]}
                                        onChange={addressForm.handleChange}
                                        onSelect={overSetFieldValue}
                                        setFieldValue={addressForm.setFieldValue}
                                        onBlur={addressForm.handleBlur}
                                        error={addressForm.errors[field.name]}
                                        search={search}
                                        testId={`${testId}.${field.name}`}
                                    />
                                })
                            }
                            <Tooltip title="Add Location">
                                <Button data-testid='add-location' onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    addressForm.handleSubmit()
                                }}>
                                    <AddLocationIcon />
                                </Button>
                            </Tooltip>
                        </Section>
                    </>
                }
            </div>
        </StyledInputWrapper>
    )
};

// Make sure to extend the Window interface to include your callback if using the callback approach
declare global {
    interface Window {
        initAutocomplete?: () => void;
    }
}

export default Autocomplete;