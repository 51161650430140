import { getAssignments } from "../get";
import { IUser } from "../../users/types/user";
import { InsuredSubject } from "../classes/insuredSubjects";

export enum EAssignmentStatus {
  preview = "Preview",
  inProgress = "In Progress",
  request = "Request",
  toBeApproved = "TBAL",
  notApproved = "Not Approved",
  rework = "Rework",
  confirmed = "Confirmed",
  accepted = "Accepted",
  declined = "Declined",
  rejected = "Rejected",
  fronting = "Fronting",
  failing = "Failing",
}

export enum ELegacyAssignmentProps {
  kod = "kod ",
  KOD_old = "KOD_old ",
  N_DOGOVOR = "contract_no ",
  STRX_C = "STRX_C ",
  CEDENT = "CEDENT ",
  BROKER = "broker ",
  N_CEDENT = "N_CEDENT ",
  Tip = "Tip ",
  accept_date = "accept_date ",
  D_BEGIN = "D_BEGIN ",
  D_END = "D_END ",
  PRIM = "PRIM ",
  DATA_Create = "DATA_Create ",
  STATUS = "STATUS ",
  DATA_STATUS = "DATA_STATUS ",
  Andr = "Andr ",
  USERS = "USERS ",
  Data_Izm = "Data_Izm ",
  Stady = "Stady ",
  office = "office ",
  cobroker = "cobroker ",
  prenumber = "prenumber ",
  sthistory = "sthistory ",
  skod = "skod ",
  scomment = "scomment ",
  sdate = "sdate ",
  vlcomment = "vlcomment ",
  vlcurrency = "vlcurrency ",
  INSURER = "INSURER ",
}

export enum EAssignmentState {
  NB = "New Business",
  R = "Renewals",
  C = "Claims",
  CXL = "Cancellations",
  END = "Endorsements",
}

export interface IAssignment {
  id: number;
  state: EAssignmentState;
  age: number;
  limit: number;
  isCompleted: boolean;
  priorityLevel: string;
  isArchived: boolean;
  isEscalated: boolean;
  isLate: boolean;
  legacy_id: string;
  notes: IAssignmentNote[];
  assignment_type_id: number;
  accepted_date: string;
  broker_id: number;
  insurer_id: number;
  cedent_id: number;
  contract_type_id: number;
  underwriter_id: number;
  last_update_by_id: number;
  status_change_date: string;
  status_id: number;
  insured_subjects: InsuredSubject[];
  type: "facultative" | "treaty";
}

export interface ILegacyAssignment {
  [ELegacyAssignmentProps.kod]: string;
  [ELegacyAssignmentProps.KOD_old]: string;
  [ELegacyAssignmentProps.N_DOGOVOR]: string;
  [ELegacyAssignmentProps.STRX_C]: string;
  [ELegacyAssignmentProps.CEDENT]: string;
  [ELegacyAssignmentProps.BROKER]: string;
  [ELegacyAssignmentProps.N_CEDENT]: string;
  [ELegacyAssignmentProps.Tip]: string;
  [ELegacyAssignmentProps.accept_date]: string;
  [ELegacyAssignmentProps.D_BEGIN]: Date;
  [ELegacyAssignmentProps.D_END]: Date;
  [ELegacyAssignmentProps.PRIM]: string;
  [ELegacyAssignmentProps.DATA_Create]: Date;
  [ELegacyAssignmentProps.STATUS]: EAssignmentStatus;
  [ELegacyAssignmentProps.DATA_STATUS]: string;
  [ELegacyAssignmentProps.Andr]: string;
  [ELegacyAssignmentProps.USERS]: string;
  [ELegacyAssignmentProps.Data_Izm]: string;
  [ELegacyAssignmentProps.Stady]: string;
  [ELegacyAssignmentProps.office]: string;
  [ELegacyAssignmentProps.cobroker]: string;
  [ELegacyAssignmentProps.prenumber]: string;
  [ELegacyAssignmentProps.sthistory]: string;
  [ELegacyAssignmentProps.skod]: string;
  [ELegacyAssignmentProps.scomment]: string;
  [ELegacyAssignmentProps.sdate]: string;
  [ELegacyAssignmentProps.vlcomment]: string;
  [ELegacyAssignmentProps.vlcurrency]: string;
  [ELegacyAssignmentProps.INSURER]: string;
}



export interface IAssignmentComment {
  id: string;
  user: IUser;
  comment: string;
}

export interface IAssignmentNote {
  id: string;
  user: IUser;
  title: string;
  description: string;
  comments: IAssignmentComment[];
  date: string;
  completed: boolean;
}

export interface Task {
  assignment_id: string;
  description: string;
  id: number;
  is_completed: boolean;
  is_deleted: boolean;
  created_at: string;
  reminder: string;
}

export interface IDefaultTask {
  id: number;
  task: string;
}

export interface IBroadcastMessage {
  id: string;
  description: string;
  createdDate: string;
  endDate: string;
  messageActiveDays: number;
  isActive: boolean;
  user: IUser;
}

export type TAssignment = IAssignment & ILegacyAssignment;
export type TAssignments = TAssignment[];

export interface IUseAssignmentsParams {
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  userId: IUser["id"];
  detail_level?: "basic" | "full";
  getAssignmentsProp?: typeof getAssignments;
  isEscalated?: boolean;
  search_params: {
    [key: string]: string | number | boolean;
  }
  properities?: ("assignments" | "kpis" | "alerts")[]
}

export interface IAssignmentPostRequest {
  "status": TStatusType | number,
  "typeOf": assignmentType,
  "contract_no": string,
  "broker": string,
  "type_of"?: string,
  "aging": string,
  "inception_date": string,
  "limit": string,
  "underwriter": string,
  "insurer": string,
  "legacy_id": string


}

export type assignmentType = "REN" | "END"



export type TStatusType = "preview" | "accepted" | "declined" | "in agreement" |
  "rejected" | "failed" | "confirmed" | "fronting" | "in progress" | "request"
  | "to be approved" | "approved" | "not approved" | "returned for rework" | "company check"

export interface IInsuredSubect {
  "fk_id": string,
  "characteristics": string,
  "VIDOBJold": string,
  "VALUT_DGR": number,
  "db_Strx": string,
  "VidObj": number,
  "KURS": number,
  "de_Strx": string,
  "id": string,
  "CATOBJ": number,
  "Date_Kurs": string,
  "Prim": string,
  "Name_object": string,
  "STATUS": number,
  "Data_status": number,
  "TypeObject": string,
  "NN": number,
  "SUMMA": number,
  "Users": number,
  "STRX_L": string,
  "TARIF": number,
  "Data_Izm": string,
  "Policy_no_desc": number | string,
  "PML": number,
  "Data_Create": string,
  "Category_desc": string,
  "Sub_class_desc": string,
  "policy_values": {
    "form_of_ri": string,
    "class_of_insurance": string,
    "sub_class": string,
    "dur_inception": string,
    "dur_expiry": string,
    "contract_currency": string,
    "currency_for_payment": string,
    "roe_rate": number,
    "roe_date": string,
    "r_c_premium": number,
    "r_c_deduction": number,
    "r_c_brokerage": number,
    "r_c_other": number,
    "r_c_net_prm": number,
    "r_c_version": number,
    "from_limit": number,
    "from_mere_share": number,
    "from_premium": number,
    "from_deductions": number,
    "from_brokerage": number,
    "from_other_ddcs": number,
    "to_limit": number,
    "to_mere_share": number,
    "to_premium": number,
    "to_deductions": number,
    "to_brokerage": number,
    "to_other_ddcs": number,
    "net_prm": number,
    "acct": string,
    "deductible_basis": string,
    "deductible_type": string,
    "deductible_value": number,
    "exclusions": string,
    "coverages": string,
    "updated_by": {
      "id": number,
      "name": string
    },
    "last_updated_date": string,
    "status": string,
    "p_accepted_date": string,
    "p_paid": string,
    "payments": string,
    "fees": string
  }
}


