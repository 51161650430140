import * as React from "react";
import HistoryItems from "./HistoryItems";
import { type IBroadcastMessage } from "../../../../api/v1/types";

interface RenderedBroadcastMessageProps {
    messages: IBroadcastMessage[];
    deleteMessage: (id: string) => void;
}


const RenderedBroadcastMessage = (props:RenderedBroadcastMessageProps) => {
    return (
             <HistoryItems messages={props.messages} deleteMessage={props.deleteMessage} /> 
    )
}
export default RenderedBroadcastMessage