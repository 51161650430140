import styled from "styled-components";
import { priorityColors } from "../../PriorityCard/styles";

export const Table = styled.table<{}>`
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  font-size: 0.8rem;
  position: relative;
  .table-header {
    border: 1px solid #e5e5e5;
  }
  th {
    background: white;
    position: sticky;
    top: 0;
  }
  th,
  td {
    color: #6d7175;
    text-align: left;
  }
  th {
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
  }
  td {
    color: #202223;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .incomplete-tasks {
    text-align: center;
    font-size: 1rem;
  }
  tbody tr:hover {
    cursor: pointer;
  }
`;

export const PriorityContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ControlContainer = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
`;

export const ExpandTaskButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 2em;
  cursor: pointer;
  width: 35%;
  &:hover {
    border: 1px solid #3e3e3c;
    border-radius: 5px;
    font-weight: bold;
  }
`;

export const StyledAccordionTableRow = styled.tr<{
  priority: string;
  selected?: boolean;
}>`
  border: ${({ selected, priority }) =>
    selected ? `4px solid ${priorityColors[priority]}` : "1px solid #E5E5E5"};
  border-width: ${({ selected }) => (selected ? "4px 0px" : "1px 0px")};
`;

export const StyledTableRow = styled.tr<{
  priority: string;
  selected?: boolean;
}>`
  height: 3.5rem;
  transition: background-color 0.5s ease-out;
  &:nth-of-type(4n + 1) {
    background-color: ${({ selected, priority }) =>
      selected ? `rgba(${hexToRgb(priorityColors[priority])}, 0.2)` : "white"};
  }
  &:nth-of-type(4n + 3) {
    background-color: ${({ selected, priority }) =>
      selected
        ? `rgba(${hexToRgb(priorityColors[priority])}, 0.2)`
        : "#FAFBFB"};
  }
  .colored-contract {
    background-color: ${({ priority }) => priorityColors[priority] || "white"};
  }
`;

const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16,
      )}`
    : null;
};

export const NoPrioritiesDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 12rem;
`;

export const ErrorModal = styled.div`
position: absolute;
top: 50%;
right: 50%;
transform: translate(50%, -50%);
  height: 13.8rem;
  width: 25rem;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  z-index: 1;
  text-align: center;
  .modal-text {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    padding-top: 1.5rem;
  }
  .modal-text {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    padding-top: 1rem;
  }
`;
