import {api} from "../index";
import { Task } from "../types";

export const markAsCompleted = async (taskId: number): Promise<Task | null> => {
  try {
    const response = await api.put(`/tasks/assignments?task_id=${taskId}`);
    return response.data.tasks;
  } catch (error) {
    console.error("Error editing task:", error);
  }
};
