import {api} from '../index';

interface IMarkAlertAsTaskParams {
    alert_id : number;

}
const markAsTask = async (alert_id: IMarkAlertAsTaskParams): Promise<boolean | null> => {
  try {
    const response = await api.delete(`/alerts/users/${alert_id}`);
    if (response.status === 200) {
      return true;
    } else {
      console.error('Failed to delete alerts:', response?.data?.message);
      return false;
    }
  } catch (error) {
    console.error('An error occurred while deleting alerts:', error);
    return false;
  }
};

export default markAsTask;
