import * as React from 'react';
import { IKPI } from '../../../../api/v1/types';

const tableData = {
    renewals: {     
                active: {
                  change: 0,
                  label: 'Active',
                  value: 90,
                },
                pending: {
                  change: 2,
                  label: 'Pending',
                  value: 80,
                },
                request: {
                  change: 0,
                  label: 'Request',
                  value: 80,
                },
                escalate: {
                  change: 3,
                  label: 'Escalate',
                  value: 70,
                },
                accepted: {
                  change: 4,
                  label: 'Accepted',
                  value: 20,
                },
                declined: {
                  change: 7,
                  label: 'Declined',
                  value: 10,
                },
                renewed: {
                  change: 5,
                  label: 'Renewed',
                  value: 20,
                },
                inactive: {
                  change: 4,
                  label: 'Inactive',
                  value: 20,
                },
                closed: {
                  change: 5,
                  label: 'Closed',
                  value: 70,
                },
                lost: {
                  change: 4,
                  label: 'Lost',
                  value: 70,
                },
                total: {
                  change: 0,
                  label: 'Total',
                  value: 0,
                },  
    },
    endorsements:
        {
          active: {
            change: 2,
            label: 'Active',
            value: 90,
          },
          pending: {
            change: 2,
            label: 'Pending',
            value: 80,
          },
          request: {
            change: 5,
            label: 'Request',
            value: 80,
          },
          escalate: {
            change: 3,
            label: 'Escalate',
            value: 70,
          },
          accepted: {
            change: 4,
            label: 'Accepted',
            value: 20,
          },
          declined: {
            change: 7,
            label: 'Declined',
            value: 10,
          },
          renewed: {
            change: 5,
            label: 'Renewed',
            value: 20,
          },
          inactive: {
            change: 0,
            label: 'Inactive',
            value: 20,
          },
          closed: {
            change: 5,
            label: 'Closed',
            value: 70,
          },
          lost: {
            change: 0,
            label: 'Lost',
            value: 70,
          },
          total: {
            change: 0,
            label: 'Total',
            value: 0,
          }, 


    },
    newBusiness: {

      active: {
        change: 2,
        label: 'Active',
        value: 90,
      },
      pending: {
        change: 2,
        label: 'Pending',
        value: 80,
      },
      request: {
        change: 5,
        label: 'Request',
        value: 80,
      },
      escalate: {
        change: 0,
        label: 'Escalate',
        value: 70,
      },
      accepted: {
        change: 4,
        label: 'Accepted',
        value: 20,
      },
      declined: {
        change: 7,
        label: 'Declined',
        value: 10,
      },
      renewed: {
        change: 5,
        label: 'Renewed',
        value: 20,
      },
      inactive: {
        change: 4,
        label: 'Inactive',
        value: 20,
      },
      closed: {
        change: 5,
        label: 'Closed',
        value: 70,
      },
      lost: {
        change: 4,
        label: 'Lost',
        value: 70,
      },
      total: {
        change: 0,
        label: 'Total',
        value: 0,
      }, 

    },
    total: {
          active: {
            change: 0,
            label: 'Active',
            value: 0,
          },
          pending: {
            change: 0,
            label: 'Pending',
            value: 0,
          },
          request: {
            change: 0,
            label: 'Request',
            value: 0,
          },
          escalate: {
            change: 0,
            label: 'Escalate',
            value: 0,
          },
          accepted: {
            change: 0,
            label: 'Accepted',
            value: 0,
          },
          declined: {
            change: 0,
            label: 'Declined',
            value: 0,
          },
          renewed: {
            change: 0,
            label: 'Renewed',
            value: 0,
          },
          inactive: {
            change: 0,
            label: 'Inactive',
            value: 0,
          },
          closed: {
            change: 0,
            label: 'Closed',
            value: 0,
          },
          lost: {
            change: 0,
            label: 'Lost',
            value: 0,
          },
          total: {
            change: 0,
            label: 'Total',
            value: 0,

      }

    }
}


const CompanyRows = () => {
    const renderRow = (tableData, category: string) => {
            return (
              <tr key={category}>
                <td>{category}</td>
                {Object.values(tableData[category]).map((metric: IKPI, index) => (
                  <td key={`${category}-${index}`}>
                    {metric.change === 0 ? (
                metric.value
              ) : (
                <>
                 {metric.value} - <span style={{ color: 'blue' }}>{metric.change}%</span>
                </>
              )}
                    </td>
                ))}
              </tr>
            );
          };
        return (

    <tbody>
        {['renewals', 'endorsements', 'newBusiness', 'total'].map(category => {
            return renderRow( tableData, category);
        })}
      </tbody>


        );


}

export default CompanyRows