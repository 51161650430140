import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import UserContextWrapper, { UserContext } from "../context/UserContext";
import { GlobalStyles } from "./styles/GlobalStyles";
import withPermissionGate from "../hoc/withPermissionGate";
import axios from "axios";
import versionEndPoint from '../../src/version.txt';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

export const ApplicationContext = React.createContext({
  version: "0.0.0"
});

const  shouldForwardProp = (propName: string, target: unknown) : boolean => {
  if (typeof target === "string") {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}


const ApplicationWrapper = (): JSX.Element => {
  const { isLoggedIn } = React.useContext(UserContext);
  const [version, setVersion] = React.useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();


  React.useEffect(() => {
    axios.get(versionEndPoint).then((response) => {
      setVersion(response.data);
    }).catch((error) => {
      console.error(error);
    });
  }, [])

  React.useEffect(() => {
    const isNotOnLoginScreen = location.pathname !== "/login";
    //  navigate to login screen
    if (!isLoggedIn && isNotOnLoginScreen) navigate("/login");
    if (isLoggedIn && !isNotOnLoginScreen) navigate("/");
  }, [isLoggedIn])




  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ApplicationContext.Provider value={{ version }}>
        <Outlet />
        <div id="modal-root"></div>
        <GlobalStyles />
      </ApplicationContext.Provider>
    </StyleSheetManager>
  );
};

type HOC = <T extends object>(WrappedComponent: React.FC<T>) => React.FC<T>;

export const contextsWrapper = (HOCs: HOC[], WrappedComponent: React.FC): React.FC => {
  return HOCs.reduce((acc, HOC) => HOC(acc), WrappedComponent);
}

export const Root = contextsWrapper([withPermissionGate, UserContextWrapper], ApplicationWrapper);
