import * as React from 'react';
import { CloseIconDiv } from '../../../Inbox/styles';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from 'styled-components';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { User } from '../../../../../context/UserContext';
import { useNavigate } from 'react-router-dom';

interface IAddUserToTableSuccessProps {
    onClose: () => void;
    users: { [key: string]: User };
}

export const ModalHeader = styled.h1`
  text-align: center;
  padding-top: 3rem;
  `

 export const Modal = styled.div`
 position: absolute;
 top: 50%;
 right: 50%;
 transform: translate(50%, -50%);
 height: 13.8rem;
 width: 25rem;
 flex-shrink: 0;
 border-radius: 4px;
 background: #fff;
 padding: 0.8rem 0.8rem 0.8rem 0.8rem;
 box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
 z-index: 1;
 text-align: center;
 .modal-text {
    display: inline-flex;
    &:hover {
        cursor: pointer;
        color: #3361ff;
    };
 }
 `; 
const AddUserToTableSuccess = (props:IAddUserToTableSuccessProps) => {
  const userProfileNav = useNavigate();

    const handleNavigateToUserProfile = () => {
        const newestUser = Object.keys(props.users).length;
        userProfileNav(`user-management/user-profile/${newestUser}`);
    }
    return (
        <Modal>
            <CloseIconDiv>
            <CloseIcon onClick={props.onClose} className="modal-close" />
          </CloseIconDiv>
        <ModalHeader>User added successfully!</ModalHeader>
        <div className='modal-text' onClick={handleNavigateToUserProfile}>View User's Profile <ArrowRightAltIcon/></div>
        </Modal>
    );
};
export default AddUserToTableSuccess;