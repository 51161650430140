import * as React from 'react';
import styled from 'styled-components';

export const NoDocumentsDiv = styled.div`
  border: none;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  padding: 2rem;
  align-items: center;
`;
const NoDocumentsView = () => {
  return (
    <NoDocumentsDiv>
      <h1>No Documents Found</h1>
    </NoDocumentsDiv>
  );
};

export default NoDocumentsView;