import * as React from 'react';
import {
  TaskMenuContainer,
  AddTaskMenu,
  CreateNewTaskButton,
  AddNewTaskList,
  SubmitTaskButton,
  FormWrapper,
} from "../styles";
import { Form, Formik, Field } from "formik";
import { TextField } from "@mui/material";

interface ICustomTaskListProps {
    expandCreateNewTaskMenu: boolean;
    handleSend: (values: any, { setSubmitting, resetForm }: any) => void;
    formikRef: any;
  }
  
  const CustomNewTaskList = (props: ICustomTaskListProps) => {
    const { expandCreateNewTaskMenu, handleSend, formikRef } = props;
    return (
      <AddTaskMenu
        isOpen={expandCreateNewTaskMenu}
        className={`new-task-list ${expandCreateNewTaskMenu ? 'open' : ''}`}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
        }}
      >
        <AddNewTaskList>
          <FormWrapper>
            <Formik
              initialValues={{ task1: "", task2: "", task3: "", task4: "" }}
              onSubmit={handleSend}
              innerRef={formikRef}
            >
              {({ handleSubmit }) => (
                <Form id="task-form">
                  <Field name="task1">
                    {({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="New Task"
                        variant="filled"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="task2">
                    {({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="New Task 2"
                        variant="filled"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="task3">
                    {({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="New Task 3"
                        variant="filled"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  </Field>
                  <Field name="task4">
                    {({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="New Task 4"
                        variant="filled"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  </Field>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <SubmitTaskButton
                      type="submit"
                      form="task-form"
                      onClick={handleSubmit}
                    >
                      Submit
                    </SubmitTaskButton>
                  </div>
                </Form>
              )}
            </Formik>
          </FormWrapper>
        </AddNewTaskList>
      </AddTaskMenu>
    );
  };

  export default CustomNewTaskList;