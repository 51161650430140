import { Link } from "react-router-dom";
import styled from "styled-components";

export const LinkContainer = styled(Link) <{navigationIsHidden: boolean}>`
  color: rgba(32, 34, 35, 0.8);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: ${(props) => (props.navigationIsHidden ? '0' : '0.8rem')};
  padding-right: ${(props) => (props.navigationIsHidden ? '0' : 'inherit')};
  padding: 0.8rem;
  margin: 0 0.8rem;
  align-items: center;
  border-left: 2px solid transparent;
  .link-label {
    font-size: 1rem;
    margin: 0 0.625rem;
  }
  &:hover {
    background-color: #dddfe7;
    border-left-color: #7886a2;
    border-radius: 0.25rem;
  }
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const RiskAPIsContainer = styled('div')<{ isOpen: boolean,navigationIsHidden:boolean }>`
  border-top: 1px solid #c3cad9;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 1rem;
  color: #3e3e3c;
  gap: 0.8rem;
  padding: 0.8rem;
  ${
    props => props.navigationIsHidden && `
    span {
      display: none;
    }
    `
  }
  .external-apis-text {
    display: flex;
    align-items: center;
  }
  .submenu {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem;
  }
  .submenu-item {
    gap: 0.8rem;
    padding: 0.8rem;
  }
  .submenu-item:hover {
    background-color: #dddfe7;
    border-left: 2px solid #7886a2;
    border-radius: 4px;
  }
`;
