import {api} from "../index";
import { IAssignmentComment } from "../types";

export const addComment = async (taskId: number, text: string): Promise<IAssignmentComment[]> => {

  const data = {
    comments: {
    task_id: taskId,
    text: text,
    }
  }

  try{
    const response = await api.post(`/comments/`, data);
    return response.data.comments;
  } catch (error) {
    console.error(error);
    return [];
   }  
}
