import * as React from 'react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import StatusColumn from '../../AssignmentTable/components/TableBody/StatusColumn';
import type AssignmentStructure from '../../../../api/v1/assignments/classes/assignment';
import FilePreview from './components/FilePreviewWrapper';
import {
  StyledDiv,
  StyledDocuments,
  StyledDocumentsContainer,
  StyledDocumentModal,
  StyledFilesDiv,
  StyledAttachButton,
  TableContainer,
  Table,
  IconWrapper,
  DocumentUploadWrapper
} from './styles';
import type { FormikValues } from 'formik';
import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import FileDropZone from '../../../../components/FormElements/File/DragandDrop';
import { IAssignmentFile } from '../../../../api/v1/assignments/classes/assignment';

export interface IDocumentsProps {
  assignment: AssignmentStructure;
  name: string;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formik: FormikValues;
  loadingDocuments: boolean;
}

const LoaderContainer = styled.div`
  position: absolute;
  width: 99%;
  margin: 0 auto;
  bottom: 7px;
  right: 0;
  left: 0;
`
const DocumentPlaceHolder = styled.div<{ totalDocuments: number }>`
  z-index: 1;
  visibility: ${props => props.totalDocuments === 0 ? 'visibile' : 'hidden'};
`;

const Documents = (props: IDocumentsProps) => {
  const { name, setFieldValue, handleChange, formik, assignment, loadingDocuments } = props
  const files = assignment?.files


  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e?.preventDefault();
    e?.stopPropagation();
    const newFiles = e?.target?.files || e?.dataTransfer?.files;
    const currentFiles = { ...files };

    const newFilesData = Array.from(newFiles || []).reduce((acc, file) => {
      acc[file.name] = { 
        file,
        file_name: file.name,
        is_new: true,
        has_changed: true,
      };
      return acc;
    }, {});

    setFieldValue('files', {
      ...currentFiles,
      ...newFilesData
    });
  };

  const handleFileEdit = (values, orginalFileNameOnLoad) : void => {
    const cloneOfValues = { ...assignment?.files };
    const fileToUpdate =  cloneOfValues[orginalFileNameOnLoad];
    setFieldValue('files', {
      ...cloneOfValues,
      [orginalFileNameOnLoad]: { ...fileToUpdate, ...values},
    });
  };

  const numberOfNonHiddenFiles = Object.keys(files || {}).filter((fileName) => !files[fileName].is_deleted).length;

  return (
    <StyledDocumentsContainer>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <StyledAttachButton data-testid="attach-doc-button">
          <input type="file" multiple onChange={handleFileUpload} hidden id="fileInput" name={name} />
          <label htmlFor="fileInput" style={{ display: 'flex', cursor: 'pointer' }}>
            Attach Documents
            <IconWrapper>
              <AttachFileIcon />
            </IconWrapper>
          </label>
        </StyledAttachButton>
      </div>
      <StyledDocuments>
        <LoaderContainer>
          {loadingDocuments && (
            <>
              <div>Fetching Metadata ...</div>
              <LinearProgress />
            </>
          )}
        </LoaderContainer>
        <FileDropZone onFilesDropped={handleFileUpload}>
          <FilesContainer
            files={assignment?.files}
            formik={formik}
            handleFileEdit={handleFileEdit}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
          />
          <DocumentPlaceHolder totalDocuments={numberOfNonHiddenFiles}> 
            <AddBoxOutlinedIcon style={{ fontSize: '15rem' }} />
            <p style={{ color: '#3E3E3C', fontSize: '24px' }}>
              Drag or <span style={{ color: '#186ADE', textDecoration: 'underline' }}>Upload</span> a Document
            </p>
          </DocumentPlaceHolder>
        </FileDropZone>
      </StyledDocuments>
    </StyledDocumentsContainer>
  );
};

interface IFileContainer {
  files: IAssignmentFile;
  formik: FormikValues;
  handleFileEdit: (value: any, fileName: string,  fileObject: File) => void;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

}

const FilesContainer = (props: IFileContainer): JSX.Element => {
  const { files, formik, handleChange, handleFileEdit } = props
  return (<StyledFilesDiv >
    {
      Object.keys(files || {}).map((fileName, index) => {
        const metaData = files[fileName];
        if (metaData.is_deleted) return null;
        return (
          <FilePreview
            key={fileName}
            formik={formik}
            isNew={metaData?.is_new}
            orginalFileName={fileName}
            fileName={metaData?.file_name}
            index={index}
            url = {metaData?.url ? `${metaData?.url}/${metaData?.file_name}` : null}
            handleFileEdit={handleFileEdit}
            fileType={metaData?.type}
            fileObject={metaData?.file}
            handleChange={handleChange}
          />
        );
      })
    }
  </StyledFilesDiv>
  )


}



export default Documents
