import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import usePermissionsLifeCycle, { EServiceNames } from "../hooks/useUsersPermissions";



const withPermissionGate = (WrappedComponent: React.ComponentType<any>) => {
    const withPermissionGate = (props: any) => {
        const { user } = React.useContext<{ user: User }>(UserContext);
        const permissionObject = usePermissionsLifeCycle(user);
        const location = useLocation();
        const isReadDenied = permissionObject?.isReadDenied(EServiceNames.underwriting_dashboard, location.pathname);
        if (isReadDenied) {
            if (location.pathname === "/") return <WrappedComponent {...props} />
            return <Navigate to="/" />
        }else{
            return <WrappedComponent {...props} />
        }

    };
    return withPermissionGate
};

export default withPermissionGate;