import { api, apiv2 } from "../index";
import { Assignment } from "../__mocks__/assignments";

export const editAssignment = async (
  assignmentId: number,
  data: Assignment,
) => {
  console.log("editAssignment", assignmentId, data)
  const response = await api.put(`/assignments/update/${assignmentId}`, {
    ...data,
  });
  return response.data
};


export const editAssignmentV2 = async (
  data: Assignment,
) => {
  const response = await apiv2.put(`/assignment/`, {
    ...data,
  });
  return response.data
};