import * as React from 'react'

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    Autocomplete
} from "@mui/material";

import {
    MenuPropsStyles,
    SelectStyles,
} from "../styles";

import SearchIcon from "@mui/icons-material/Search";
import { FetchSearchForCompany, SearchFacts } from '../../../../api/v1/facts/get';
import { Category, RedoSharp } from '@mui/icons-material';
import { getAssignmentPropertyValues } from '../../../../api/v1/assignments/get';
import { OptionLabels } from '../../AssignmentTable/components/RenderedAssignmentsTable';

interface SearchCompanyProps {
    setCategory: React.Dispatch<React.SetStateAction<string>>
    company: ICompany,
    disabled?: boolean,
    filterCategories?: {
        value: string,
        label: string
    }[],
    searchParams?: {
        keyword: string,
        searchKey: string
    },
    setSearchParams?: React.Dispatch<React.SetStateAction<{}>>
    setCompany: React.Dispatch<React.SetStateAction<ICompany>>
}

interface predictionLabel {
    label: string
}
const SearchAssignmentPropertiesTool = (props: SearchCompanyProps) => {
    const [predictions, setPredictions] = React.useState<ICompany[] & predictionLabel[]>([])
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const { filterCategories: options, setSearchParams, searchParams } = props;
    const searchRef = React.useRef<any>(null)


    const handleSearch = () => {
        try {
            if (searchRef.current) clearTimeout(searchRef.current)
            setLoading(true);
            setPredictions([])
            searchRef.current = setTimeout(() => {
                if (!searchParams?.searchTerm?.length) return setLoading(false);
                getAssignmentPropertyValues(searchParams?.searchKey, searchParams?.searchTerm).then((res) => {
                    const options = res?.data?.options ? Object.values(res?.data?.options) : []
                    const uniqueOptions = Array.from(new Set(options.map(option => option.label)))
                        .map(label => {
                            return options.find(option => option.label === label)
                        });
                    setPredictions(uniqueOptions)
                    setLoading(false);
                })
            }, 500)
        } catch (err) {
            console.error(err)
        }
    };

    React.useEffect(() => { handleSearch() }, [searchParams?.searchTerm])
    return (
        <FormControl
            sx={{
                width: "90%",
                display: "flex",
                gap: "1rem"
            }}
        >
            <InputLabel id="category" data-testid="select-category">Select a Category</InputLabel>
            <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Select
                    style={{ width: "100%" }}
                    disabled={props.disabled}
                    value={searchParams?.searchKey || ""}
                    onChange={(e) => setSearchParams((prevState) => ({
                        searchKey: e.target.value
                    }))}
                    role="select"
                    inputProps={{ "data-testid": "select" }}
                    labelId="category"
                    label="Select a Category"
                    sx={SelectStyles}
                    MenuProps={{
                        sx: MenuPropsStyles,
                    }}
                    size='small'
                >
                    {options?.map((category, index) =>
                        <MenuItem key={index} value={category.value} >
                            {category.label}
                        </MenuItem>
                    )}
                </Select>
                <Autocomplete
                    open={open}
                    style={{ width: "100%" }}
                    onBlur={() => setOpen(false)}
                    onFocus={() => setOpen(true)}
                    disabled={!searchParams?.searchKey}
                    onChange={(e, value) => {
                        setSearchParams((prevState) => ({
                            ...prevState,
                            searchTerm: value?.label || ""
                        }))
                    }}
                    loading={loading}
                    options={searchParams?.searchTerm && predictions || []}
                    value={searchParams?.searchTerm || ""}
                    id="contract-no"
                    noOptionsText={searchParams?.searchTerm && searchParams.searchTerm.length > 0 ? "Not found" : "Type to search"}
                    renderInput={
                        (params) =>
                            <TextField
                                data-testid="search"
                                {...params}
                                label={OptionLabels[searchParams?.searchKey] || "Search"}
                                onChange={(e) => setSearchParams((prevState) => ({
                                    ...prevState,
                                    searchTerm: e.target.value
                                }))}
                            />
                    }
                />
            </div>
        </FormControl>
    )
}


const ConnectedAssignmentPropertiesTool = (props: SearchCompanyProps) => {
    return (
        <SearchAssignmentPropertiesTool {...props} />
    )
}

ConnectedAssignmentPropertiesTool.defaulProps = {
    filterCategories: [],
    searchFactTable: SearchFacts
};
export default SearchAssignmentPropertiesTool