import * as React from "react";
import {
  TableContainer,
  PermissionsTableHeader,
  UserPermissionTable,
  AddRestrictionButton,
  Table,
} from "../../../styles";
import PermissionRow from "./PermissionRow";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { User } from "../../../../../../context/UserContext";
import AddIcon from "@mui/icons-material/Add";
import NewPermissionsRow from "./NewPermissionsRow";
import asModal from "../../../../../../hoc/asModal";
import { styled } from "styled-components";
import PermissionsTableHead from "./PermissionsTableHead";
import AddPermissionsView from "./AddPermissionsView";
import { IDepartment } from "../../../../../../api/v1/types";
import NoPermissionsSet from "./NoPermissionsSet";


export enum EServices {
  "underwriting_dashboard" = "underwriting_dashboard",
  "underwriting_api" = "underwriting_api",
  "claims_dashboard" = "claims_dashboard",
  "claims_api" = "claims_api",
  "finance_dashboard" = "finance_dashboard",
  "finance_api" = "finance_api",
}

const serviceLabels: { [key: string]: string } = {
  [EServices.underwriting_dashboard]: "Underwriting Dashboard",
  [EServices.underwriting_api]: "Underwriting API",
  [EServices.claims_dashboard]: "Claims Dashboard",
  [EServices.claims_api]: "Claims API",
  [EServices.finance_dashboard]: "Finance Dashboard",
  [EServices.finance_api]: "Finance API",
};



export const ServiceOptions = Object.keys(EServices).map((service) => {
  return {
    value: service,
    label: serviceLabels[service],
  };
});

interface IPermissionsProps {
  user: User;
  department: IDepartment;
  handleAddRestriction?: () => void;
}

interface IEndPoint {
  endpoint: string;
  components: string[];
  operations: ("read" | "write" | "delete" | "update")[];
}

interface IPermissionsProps {
  permissionState: {};
  deletePathRule: (service: string, pathName: string) => void;
  setPermissionState: React.Dispatch<React.SetStateAction<{}>>;
  allowed: { [key: string]: IEndPoint };
  denied: { [key: string]: IEndPoint };
  openAddRestrictionModal: () => void;
}

export interface IAddRestrictionsProps extends IPermissionsProps {
  addPermissionToUser: () => void;
}

interface IPermissionFields {
  type: string;
  path: string;
  component: string[];
  operation: ("read" | "write" | "delete" | "update")[];
  service: string;
  permissionType: string;
}

const UserRestrictedViewAsModal = asModal(AddPermissionsView, "#modal-root");
const StyleAddRestrictions = styled(UserRestrictedViewAsModal)`
position: absolute;
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 100;
.restriction-view {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px #000;

`;
const NoPermissionsModal = asModal(NoPermissionsSet, "#modal-root");
const StyledNoPermission = styled(NoPermissionsModal)`
position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fff;
  padding: 2.8rem 2.8rem 2.8rem 2.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  z-index: 1;
  transition: height 0.3s ease;
`;
const Permissions = (props: IPermissionsProps) => {
  const [addRestrictionModalIsOpen, setAddRestrictionModalIsOpen] = React.useState<boolean>(false);
  const [noPermissionModal, setNoPermissionModalOpen] = React.useState<boolean>(true);
  const { entity, permissionState, setPermissionState, deletePathRule } = props;

  const memoizedPermissions = Object.keys(permissionState)?.map((service: string, index) => {
    const servicePaths = Object.keys(permissionState[service]);
    if (servicePaths.length === 0) {
      return null;
    }
    const rows = servicePaths.map((path) => {
      const rules = permissionState[service][path];
      return <PermissionRow data-testid="permission-row" key={service.id} entity={entity} {...rules} path={path} setPermissionState={setPermissionState} permissionState={permissionState} service={service} deletePathRule={deletePathRule} />;
    });
    return (
      <>
        <h2>{serviceLabels[service]}</h2>
        <UserPermissionTable data-testid="permissions-table">
          <PermissionsTableHead />
          <tbody key={`${service}-${index}`}>
            {rows}
          </tbody>
        </UserPermissionTable>
      </>
    )
  });


  const handleAddRestriction = (values: IPermissionFields) => {
    const clonedPermissions = { ...permissionState };
    const { service, permissionType, path, component, operation } = values;
    const currentService = clonedPermissions[service];
    if (currentService) {
      const currentPath = currentService[path];
      if (currentPath) {
        const currentPermissionType = currentPath[permissionType];
        if (currentPermissionType) {
          currentPermissionType.operations = operation;
          currentPermissionType.components = component;
        } else {
          currentPath[permissionType] = {
            operations: operation,
            components: [component],
          };
        }
      } else {
        currentService[path] = {
          [permissionType]: {
            operations: operation,
            components: [component],
          },
        };
      }
    } else if (service && permissionType && path) {
      clonedPermissions[service] = {
        [path]: {
          [permissionType]: {
            operations: operation,
            components: [component],
          },
        },
      };
    }
    setPermissionState({
      ...permissionState,
      ...clonedPermissions,
    });
  };

  const openAddRestrictionModal = () => { setAddRestrictionModalIsOpen(!addRestrictionModalIsOpen) };
  const havePermissions = Object.values(permissionState).some(service => Object.keys(service).length > 0);
  const closeNoPermissionModal = () => { setNoPermissionModalOpen(!noPermissionModal) };

  React.useEffect(() => {
    if (!havePermissions) {
      setNoPermissionModalOpen(true);
    }
  }, [havePermissions]);
  return (
    <>
      <PermissionsTableHeader>
        Paths and Components
        <AddRestrictionButton data-testid="add-restriction" onClick={openAddRestrictionModal}>
          <AddIcon />
          Add Restriction
          <StyleAddRestrictions
            isOpen={addRestrictionModalIsOpen}
            onClose={openAddRestrictionModal}
            handleAddRestriction={handleAddRestriction}
          />
        </AddRestrictionButton>
      </PermissionsTableHeader>
      {havePermissions ? memoizedPermissions : <StyledNoPermission isOpen={noPermissionModal} onClose={closeNoPermissionModal} message="No Permissions Set for this User." />}
    </>
  );
};

const ConnectedPermissions = () => {
  const { userId } = useParams();
  const { user, permissionState, setPermissionState, deletePathRule, department } = useOutletContext();
  const location = useLocation();
  const { pathname } = location;
  const isDepartmentsPage = pathname.includes("departments");
  const entity = isDepartmentsPage ? department : user;
  return <Permissions entity={entity} permissionState={permissionState} setPermissionState={setPermissionState} deletePathRule={deletePathRule} />;
}
export default ConnectedPermissions;
