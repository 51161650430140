import * as React from 'react'
import AutoCompleteInput from '../../AutocompleteInput'
import styled from 'styled-components';
import { CreateAssignmentInput } from '../../../styles';
import type { InsuredSubject } from '../../../../../../../api/v1/assignments/classes/insuredSubjects';
import FormGroup from '../../../../../../../components/FormElements/FormGroup';
import { FormGroupContainer, DurationInformationForm } from '../../../../ReinsuranceTerms/styles';
import CurrencyInfo from '../components/CurrencyInfo';
import { StyledExclusionsInclusionSection } from '../styles';
import { defaultReinsuranceTerm } from '../../../../../../../api/v1/assignments/classes/assignment';
import CloseIcon from '@mui/icons-material/Close';
import {
  deleteCoveredRisk,
  deleteExcludedRisk
} from '../../../utils/deleteFunctions';
import { UnderwritersChosen } from '../../../../../AssignmentTable/components/AdvancedFilter';
import ReactiveAccordion from '../../../../../../../components/ReactActiveAccordion';
import ToLocaleStringInput from '../../ToLocaleStringInput';
import { useLocation } from 'react-router-dom'

const StyledWrapper = styled.section`
    margin: 1rem 0;
`;

const StyledRiskSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const StyledRiskCoverage = styled.section`
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  flex-wrap: wrap;
  .MuiAutocomplete-root {
    width: 100%;
  }
  p {
   font-size: 0.8rem !important;
    display: flex;
    }
    p:not(:last-child)::after {
      content: ",";
      }

    p:nth-child(5n) {
    flex-basis: 100%;
  }
      
      .icon {
  cursor: pointer;
  font-size: 1rem;
  }
`;

interface IComponentProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search: (query: any, name: string) => void;
  options: any;
  setFieldValue: (name: string, value: any) => void;
  key: string;
  index: number;
  _handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelection: (name: string, value: any) => void;
  errors: any;
  addReinsuranceTerm: (e: Event) => void;
  searchFunctions: Record<string, (searchTerm: string) => void>;

}
const ReinsuranceTerms = (props: InsuredSubject & IComponentProps): JSX.Element => {
  const { insuredSubject, setFieldValue, index, searchFunctions, accordionTriggered } = props;
  const errors = props?.errors?.reinsuranceTerms || {};
  const reinsuranceTerms = insuredSubject?.reinsuranceTerms || [defaultReinsuranceTerm]
  const prefix = `insuredSubjects[${index}].reinsuranceTerms[0]`
  const initialLoad = React.useRef(true)
  const currentTab = reinsuranceTerms[0]?.id || 0
  const oneTerm = [reinsuranceTerms?.[0]]
  const accordioRef = React.useRef<HTMLDivElement>(null)

  const addTerm = (e?: Event): void => {
    e?.preventDefault()
    e?.stopPropagation()
    setFieldValue(prefix, [...(reinsuranceTerms || []), { defaultReinsuranceTerm, id: reinsuranceTerms?.length || 0 }])
  }


  React.useEffect(() => {
    if (initialLoad.current && reinsuranceTerms?.length > 0) {
      initialLoad.current = false
    } else {
      addTerm()
    }
  }, [])


  return (
    <StyledRiskSection ref={accordioRef}>
      <ReactiveAccordion openingKey={prefix} title={"Reinsurance Terms"} className='child-accordion'>
        {
          oneTerm?.map((reinsuranceTerm: InsuredSubject, index: number) => (
            <RiskTerm key={index} {...props} index={index} reinsuranceTerm={reinsuranceTerm} errors={errors[index]} prefix={prefix} currentTab={currentTab} searchFunctions={searchFunctions} />
          ))
        }

      </ReactiveAccordion>
    </StyledRiskSection>
  )
}


interface IRiskTermsProps {
  values: InsuredSubject;
  setFieldValue: (name: string, value: any) => void;
  index: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  search: (query: any, name: string) => void;
  options: any;
  _handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelection: (name: string, value: any) => void;
  errors: any;
  reinsuranceTerm: InsuredSubject;
  currentTab: number;
  searchFunctions: Record<string, (searchTerm: string) => void>;
  prefix: string;
}

const RiskTerm = (props: InsuredSubject & IRiskTermsProps) => {
  const { prefix, handleChange, options, setFieldValue, index, _handleChange, handleSelection, errors, reinsuranceTerm, searchFunctions } = props;
  const formattedValue = !reinsuranceTerm?.deductible?.actualValue ? "" : reinsuranceTerm?.deductible?.actualValue?.toLocaleString()

  const location = useLocation()
  const createAssignmentPath = location.pathname.includes('create-assignment')

  const filteredExclusionOptions = options?.exclusions ? options?.exclusions?.reduce((acc, curr) => {
    const existsInInsuredObjects = reinsuranceTerm?.excludeRisks?.some(obj => obj.label === curr.label);
    const existsInAccumulator = acc.some(obj => obj.label === curr.label);
    if (!existsInInsuredObjects && !existsInAccumulator) {
      acc.push(curr);
    }
    return acc;
  }, []) : [];

  const filteredInclusionOptions = options?.inclusions ? options?.inclusions?.reduce((acc, curr) => {
    const existsInInsuredObjects = reinsuranceTerm?.includedRisks?.some(obj => obj.label === curr.label);
    const existsInAccumulator = acc.some(obj => obj.label === curr.label);
    if (!existsInInsuredObjects && !existsInAccumulator) {
      acc.push(curr);
    }
    return acc;
  }, []) : [];

  return (
    <>
      <AutoCompleteInput
        className="form"
        label='Form or R/I'
        name={`${prefix}.formOfRi`}
        options={options?.[`forms_of_re_insurance`]}
        value={reinsuranceTerm?.formOfRi}
        setFieldValue={setFieldValue}
        onSearch={searchFunctions?.searchFormOfReinsurance}
        errors={errors?.formOfRi}
        testId={`${prefix}.formOfRi`}
      />
      <AutoCompleteInput
        className="class" label='Class of Insurance'
        name={`${prefix}.insuranceClass`}
        value={reinsuranceTerm?.insuranceClass}
        options={options?.[`classOfInsurance`]}
        onSearch={searchFunctions?.searchClassOfInsurance}
        setFieldValue={setFieldValue}
        testId={`${prefix}.insuranceClass`}
        errors={errors?.insuranceClass}
      />
      <AutoCompleteInput
        className="subclass"
        label='Sub-Class'
        name={`${prefix}.subClass`}
        value={reinsuranceTerm?.subClass}
        options={options?.[`subclass`]}
        setFieldValue={setFieldValue}
        onSearch={searchFunctions?.searchSubclass}
        errors={errors?.subClass}
        testId={`${prefix}.subClass`}
      />
      {
        !createAssignmentPath && (
          <>
            <FormGroupContainer>
              <FormGroup title="Currency">
                <DurationInformationForm>
                  <CurrencyInfo
                    reinsuranceTerm={reinsuranceTerm}
                    prefix={prefix}
                    handleSelection={handleSelection}
                    setFieldValue={setFieldValue}
                    searchFunctions={searchFunctions}
                    options={options}
                    errors={errors}
                  />
                </DurationInformationForm>
                <div>
                  <CreateAssignmentInput
                    className="exchangeRate"
                    label="Exchange Rate"
                    name={`${prefix}.currency.exchangeRate.rate`}
                    value={!reinsuranceTerm?.currency?.exchangeRate?.rate ? "" : reinsuranceTerm?.currency?.exchangeRate?.rate}
                    onChange={handleChange}
                    testId={`${prefix}.currency.exchangeRate.rate`}
                    errors={errors?.currency?.exchangeRate?.rate}
                  />
                  <CreateAssignmentInput
                    type='date'
                    className="exchangeRate"
                    label="Rate Date"
                    name={`${prefix}.currency.exchangeRate.date`}
                    value={reinsuranceTerm?.currency?.exchangeRate?.date}
                    onChange={handleChange}
                    testId={`${prefix}.currency.exchangeRate.date`}
                    errors={errors?.currency?.exchangeRate?.date}
                  />
                </div>
              </FormGroup>
            </FormGroupContainer>

            <ReactiveAccordion className="child-accordion" title={"Deductible Information"} openingKey={`${prefix}.deductible`}>
              <StyledWrapper>
                <ToLocaleStringInput
                  label="Actual Value"
                  onChange={handleChange}
                  setFieldValue={setFieldValue}
                  type="number"
                  name={`${prefix}.deductible.actualValue`}
                  value={reinsuranceTerm?.deductible?.actualValue}
                  errors={errors?.deductible?.actualValue}
                  testId={`${prefix}.deductible.actualValue`}
                />

                <AutoCompleteInput
                  label="Deductible Type"
                  name={`${prefix}.deductible.typeId`}
                  value={reinsuranceTerm?.deductible?.typeId}
                  onSearch={searchFunctions?.searchDeductibleType}
                  options={options?.[`deductibleType`]}
                  setFieldValue={setFieldValue}
                  errors={errors?.deductible?.typeId}
                  testId={`${prefix}.deductible.typeId`}
                />
                <AutoCompleteInput
                  label="Basis"
                  name={`${prefix}.deductible.basisId`}
                  value={reinsuranceTerm?.deductible?.basisId}
                  onSearch={searchFunctions?.searchDeductibleBasis}
                  options={options?.[`deductibleBasis`]}
                  setFieldValue={setFieldValue}
                  errors={errors?.deductible?.basisId}
                  testId={`${prefix}.deductible.basisId`}

                />
                <AutoCompleteInput
                  label="Value Type"
                  name={`${prefix}.deductible.valueType`}
                  value={reinsuranceTerm?.deductible?.valueType}
                  type="text"
                  onSearch={searchFunctions?.searchDeductibleValueType}
                  options={options?.[`deductibleValueType`]}
                  setFieldValue={setFieldValue}
                  errors={errors?.deductible?.valueType}
                  testId={`${prefix}.deductible.valueType`}
                />
              </StyledWrapper>
            </ReactiveAccordion>

            <ReactiveAccordion className='child-accordion' title={"Asset Coverage I/E"} openingKey={
              [`${prefix}.includedRisks`, `${prefix}.excludeRisks`]
            }>
              <StyledWrapper>
                <StyledExclusionsInclusionSection style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
                  data-testid="coverage-lists-included"
                >
                  <StyledExclusionsInclusionSection style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                    data-testid="coverage-lists-excluded"
                  >
                    <ListInput
                      label='Included Coverage'
                      name={`${prefix}.includedRisks`}
                      multiSelect
                      value={reinsuranceTerm?.includedRisks}
                      options={filteredInclusionOptions}
                      onSearch={searchFunctions?.searchInclusions}
                      deleteItem={deleteCoveredRisk}
                      setFieldValue={setFieldValue}
                      testId={`${prefix}.includedRisks`}
                    />
                    <ListInput
                      label='Excluded Coverage'
                      name={`${prefix}.excludeRisks`}
                      multiSelect
                      value={reinsuranceTerm?.excludeRisks}
                      options={filteredExclusionOptions}
                      onSearch={searchFunctions?.searchExclusions}
                      deleteItem={deleteExcludedRisk}
                      setFieldValue={setFieldValue}
                      testId={`${prefix}.excludeRisks`}
                    />
                  </StyledExclusionsInclusionSection>
                </StyledExclusionsInclusionSection>

              </StyledWrapper>
            </ReactiveAccordion>
          </>
        )}
    </>


  )
}

const ListInput = (props: {
  name: string;
  values: Array<{
    label: string;
    value: string;
  }>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  search: (query: any, name: string) => void;
  options: any[];
  setFieldValue: (name: string, value: any) => void;
  key: string;
  index: number;
  handleSelection: (name: string, value: any) => void;
  errors: any;
  testId: string;
}): JSX.Element => {
  const {
    name,
    label,
    value,
    onSearch,
    options,
    setFieldValue,
    deleteItem,
    removeOption,
    testId,
  } = props;


  return (
    <>
      <div>
        <AutoCompleteInput
          label={label}
          name={name}
          multiSelect
          setFieldValue={setFieldValue}
          removeOption={removeOption}
          options={options || []}
          onSearch={onSearch}
          testId={testId}
          value={value}
        />
        <StyledRiskCoverage>
          {value?.map(
            (
              item: {
                label: string;
                deleteId?: string | number;
                value: string;
              },
              index,
            ) => {
              return (
                <UnderwritersChosen key={index}>
                  {item.label}
                  <TrashCan
                    name={name}
                    deleteItem={deleteItem}
                    item={item}
                    setFieldValue={setFieldValue}
                    exisitingValues={value}
                  />
                </UnderwritersChosen>);
            },
          )}
        </StyledRiskCoverage>
      </div>
    </>
  );
};

interface ITrashCanProps {
  item: {
    label: string;
    deleteId?: string | number;
    value: string;
  };
  setFieldValues: (name: string, value: any) => void;
  deleteItem: (item: any, e: Event) => Promise<boolean>;
  name: string;
  exisitingValues: Array<{
    label: string;
    deleteId: string | number;
    value: string;
  }>;
}

const TrashCan = (props: ITrashCanProps): JSX.Element => {
  const { item, deleteItem, name, exisitingValues, setFieldValue } = props;

  const onClickEvent = (e: Event): void => {
    deleteItem(item, e).then(((results) => {
      console.log(results)
      if (results) {
        setFieldValue(name, exisitingValues.filter((obj) => obj.deleteId !== item.deleteId))
      }
    })).catch((error) => {
      console.error(error)
    })

  }
  return (
    <CloseIcon className="icon" onClick={onClickEvent} />
  )
}

export default ReinsuranceTerms