import * as React from "react";
import { DepartmentWrapper } from "../styles";
import { Link, useOutletContext, useNavigate } from "react-router-dom";
import { IUser } from "../../../../api/v1/users/types/user";
import UsersHeader from "./UsersHeader";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { addDepartments } from "../../../../api/v1/organizationHierarchy/post";
import { updateDepartments } from "../../../../api/v1/organizationHierarchy/put";
import { IDepartment } from "../../../../api/v1/types";
interface IDepartmentsProps {
  users: IUser[];
  departments: IDepartment[];
  handleAddDepartment: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleUpdateDepartment: (id: number, e: React.KeyboardEvent<HTMLInputElement>) => void;
  newDepartmentName: string;
  setNewDepartmentName: React.Dispatch<React.SetStateAction<string>>;
  isAddingNew: boolean;
  setIsAddingNew: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledLink = styled(Link) <{ isEditing: boolean }>`
  span {
    color: #828382;
    font-size: 1rem;
  }
  &:hover {
    background-color: #F7F8FA;
    border-radius: 0.25rem;
    padding: 0.4rem;
    .edit-icons {
      display: flex;
    }
  }
  .edit-icons {
    display: none;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    color: #828382;
  }
  .add-new-dept {
    display: flex;
    align-items: center;
    color: #828382;
  }
  .link-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .edit-input {
    border: none;
    background-color: #f7f8fa;
    width: 100%;
  }
`;

const Departments = (props: IDepartmentsProps) => {
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [currentDepartment, setCurrentDepartment] = React.useState<any>();
  const navigation = useNavigate();
  const { users, departments, handleAddDepartment, newDepartmentName, setNewDepartmentName, isAddingNew, setIsAddingNew, handleUpdateDepartment } = props;

  const handleIconClick = (departmentId: number, e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsEditing(true);
    setCurrentDepartment(departmentId);
  };

  const handleMouseEnter = () => {
    if (isEditing) {
      return;
    }
  };

  const handleMouseLeave = () => {
    setIsEditing(false);
  };


  const handleNavigate = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, departmentId: number) => {
    if (isEditing) return;
    e.preventDefault();
    e.stopPropagation();
    navigation(`/user-management/departments/${departmentId}/user-department`);
  }


  return (
    <>
      <UsersHeader users={users} departments={departments} />
      <DepartmentWrapper>
        {Object.values(departments).map((department) => (
          <>
            <StyledLink
              isEditing={isEditing && currentDepartment === department.id}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={(e) => handleNavigate(e, department.id)}
            >
              <div className="link-edit">
                {isEditing && currentDepartment === department.id ? (
                  <input
                    type="text"
                    className="edit-input"
                    placeholder={department.name}
                    onChange={(e) => setNewDepartmentName(e.target.value)}
                    onKeyDown={(e) => handleUpdateDepartment(department.id, e)}
                    onClick={(e) => e.preventDefault()}
                  />
                ) : (
                  <span>{department.name}</span>
                )}
                <div className="edit-icons" onClick={(e) => handleIconClick(department.id, e)}>
                  <CreateOutlinedIcon />
                  {/* <DeleteIcon /> */}
                </div>
              </div>
            </StyledLink>

          </>
        ))}

        <StyledLink
          isEditing={isAddingNew}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="link-edit">
            {isAddingNew ? (
              <input
                type="text"
                className="edit-input"
                placeholder="New Department"
                onChange={(e) => setNewDepartmentName(e.target.value)}
                onKeyDown={handleAddDepartment}
              />
            ) : (
              <span className="add-new-dept" onClick={() => setIsAddingNew(true)}>
                <AddIcon /> Add a New Department
              </span>
            )}
          </div>

        </StyledLink>

      </DepartmentWrapper>
    </>
  );
};
const ConnectedDepartmentsNavigation = () => {
  const contextValue = useOutletContext();
  const users = contextValue?.users || {};
  const departments = contextValue?.departments || [];
  const [newDepartmentName, setNewDepartmentName] = React.useState<string>("");
  const [isAddingNew, setIsAddingNew] = React.useState<boolean>(false);

  const handleAddDepartment = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newDepartmentName.trim() !== '') {
      try {
        const departmentData = await addDepartments(newDepartmentName);
        const newDepartments = { ...departments };
        newDepartments[departmentData.id] = departmentData;
        contextValue?.setDepartments(newDepartments);
        setIsAddingNew(false);
        setNewDepartmentName('');

      } catch (error) {
        console.error("Error adding department:", error);
      }
    }
  };


  const handleUpdateDepartment = async (id: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newDepartmentName.trim() !== '') {
      try {
        const currentDepartment = departments[id];
        if (!currentDepartment) {
          console.error("Department not found");
          return;
        }
        const updatedDepartment = { ...currentDepartment, name: newDepartmentName };
        const departmentData = await updateDepartments(updatedDepartment);
        const newDepartments = { ...departments };
        newDepartments[departmentData.id] = IDepartment.fromAPIResponse(departmentData);
        contextValue?.setDepartments(newDepartments);
        setIsAddingNew(false);
        setNewDepartmentName('');

      } catch (error) {
        console.error("Error adding department:", error);
      }
    }
  };

  return <Departments users={users} departments={departments} handleAddDepartment={handleAddDepartment} newDepartmentName={newDepartmentName} setNewDepartmentName={setNewDepartmentName} isAddingNew={isAddingNew} setIsAddingNew={setIsAddingNew} handleUpdateDepartment={handleUpdateDepartment} />;
};

export default ConnectedDepartmentsNavigation;
