import { createSearchParams, useSearchParams } from "react-router-dom"
import * as React from 'react';
interface IToUrlSearchParams {
  underwriters: [],
  dateRange: {
    startDate: null,
    endDate: null,
  },
  typeOf: [],
  status: [],
}

export const toUrlSearchParams = (values) => {
  const [urlSearchParams, _setSearchParams] = useSearchParams();
  const setSearchParams = () => {
    values = convertToApiStructure(values)
    const queryString = createSearchParams(values)
    _setSearchParams(queryString);
  }
  React.useEffect(() => {
    setSearchParams()
  }, [values])

  const camelCasedValues = convertSnakeCaseToCamelCaseRecursively(urlSearchParams)
  return { urlSearchParams: camelCasedValues }
}

const serialize = (obj, prefix) => {
  const queryString = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const prefixedKey = prefix ? `${prefix}[${key}]` : key;

      if (typeof value === 'object' && value !== null) {
        // Recursively serialize nested objects
        queryString.push(serialize(value, prefixedKey));
      } else {
        // Encode the key-value pair and push it to the results
        queryString.push(encodeURIComponent(prefixedKey) + "=" + encodeURIComponent(value));
      }
    }
  }
  // Join all parts with "&" to form the final query string
  return queryString.join("&");
}


const convertSnakeCaseToCamelCaseRecursively = (data: Object): Object => {
  if (Array.isArray(data)) {
    return data.map((value) => {
      if (typeof value === "object") {
        return convertSnakeCaseToCamelCaseRecursively(value);
      }
      return value;
    });
  }

  if (typeof data === "object" && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      const camelKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase());
      const value = data[key];
      if (typeof value === "object") {
        return {
          ...acc,
          [camelKey]: convertSnakeCaseToCamelCaseRecursively(value),
        };
      }
      return {
        ...acc,
        [camelKey]: value,
      };
    }, {});
  }
  return data;
};
export const convertToApiStructure = (obj: Object) => {
  const convertToSnakeCase = (obj: any): Object => {
    return Object.keys(obj).reduce((acc, key) => {
      const currentKey = key;
      const currentVal = obj[key];
      const snakeKey = currentKey.replace(
        /[A-Z]/g,
        (letter) => `_${letter.toLowerCase()}`,
      );
      const valueKeys = Object.keys(currentVal || {});
      if (currentVal instanceof Date) {
        return { ...acc, [snakeKey]: currentVal.toISOString() };
      }
      if (currentVal === null || currentVal === undefined) {
        return { ...acc, [snakeKey]: currentVal };
      }
      if (Array.isArray(currentVal)) {
        return {
          ...acc,
          [snakeKey]: currentVal.map((value) => {
            const keys = Object.keys(value);
            if (keys.includes("label") && keys.includes("id")) {
              return value?.id;
            } else {
              if (typeof value === "object") {
                return convertToSnakeCase(value);
              } else {
                return value;
              }
            }
          }),
        };
      }
      if (currentVal && typeof currentVal === "object") {
        if (valueKeys.includes("label") && valueKeys.includes("id")) {
          return { ...acc, [snakeKey]: currentVal?.id };
        } else {
          return { ...acc, [snakeKey]: convertToSnakeCase(currentVal) };
        }
      }
      return {
        ...acc,
        [snakeKey]: obj[key],
      };
    }, {});
  };
  return convertToSnakeCase(JSON.parse(JSON.stringify(obj)));
};