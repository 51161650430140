import React, { useState, useEffect } from "react";
import { IUser } from "../../../api/v1/users/types/user";
import { getUsers } from "../../../api/v1/users/get";
import { User } from "../../../context/UserContext";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import UsersHeader from "./components/UsersHeader";
import { getDepartments } from "../../../api/v1/organizationHierarchy/get";
import { IDepartment } from "../../../api/v1/types";
import deleteUser from "../../../api/v1/users/delete";
import NetworkErrorModal from "../../../components/NetworkUserErrorModal";
import asModal from "../../../hoc/asModal";
import { createUser } from "../../../api/v1/users/post";


const NetworkErrorAsModal = asModal(NetworkErrorModal, "#modal-root")

const ConnectedUsersManagement = (props) => {
  const {deleteUser: deleteUserRequest ,getUsers} = props;
  const [users, setUsers] = React.useState<{[key:string]:User}>({});
  const [departments, setDepartments] = useState<{[key:string]:IDepartment}>({});
  const [networkError, setError] = React.useState(null)
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const navigate = useNavigate()

  const showUsersHeader = location.pathname === "/user-management";
  
  const deleteUser = async (userId: string) => {
    try{
      const deleteUserResponse = await deleteUserRequest(userId);
      const userCopy = {...users};
      delete userCopy[userId];
      setUsers(userCopy);
    }catch(error){
      console.error("Error deleting user:", error);
    }
  };

  const addUser = async (user: User) => {
    try {
      const userData = await createUser(user);
      const newUsers = { ...users };
      newUsers[userData.id] = User.fromAPIResponse(userData);
      setUsers(newUsers);
      await fetchUsers(() => navigate(`user-profile/${userData?.id}/permissions`))
      
      return userData;
    } catch (error) {
      setError(error?.response?.data?.detail)
      console.error("Error creating user:", error);
    }
  };

  const confirmNetworkError = ( ) => {
    navigate(`user-profile/${networkError?.user?.id}/permissions`);
    setError(null);
  }

  const cancelError = ( ) => setError(null)
  const fetchUsers = async (navigateNext:Function = null) => {
    try {
      setLoading(true)

      const [userData, departmentData] = await Promise.all([getUsers(), getDepartments()]);

      const mappedUsers = 
        userData?.users?.reduce((acc:{[key:string]: User} , user: IUser) => {
          const userToAdd = User.fromAPIResponse(user);
          acc[userToAdd.id] = userToAdd;
          return acc;
        }, {});

      const mappedDepartments = 
        departmentData.reduce((acc: {[key:string]: IDepartment}, department: IDepartment) => {
          acc[department.id] = department;
          return acc;
        }, {});

      setUsers(mappedUsers);
      setDepartments(mappedDepartments);
      setLoading(false);
      if (navigateNext) {
        navigateNext()
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  React.useEffect(() => {

    fetchUsers();
  }, []);

  return (
    <>
      {showUsersHeader && <UsersHeader users={users} departments={departments} loading={loading} />}
      <NetworkErrorAsModal 
        message={networkError?.message} 
        onConfirm={confirmNetworkError} 
        onClose={cancelError}
        confirmLabel={"Go To Account"} 
        isOpen={networkError}  
        isVisible={networkError} />
      <Outlet context={{ users, setUsers, loading, departments, setDepartments, addUser, deleteUser }} />
    </>
  );
};

ConnectedUsersManagement.defaultProps = {
  users: {},
  loading: true,
  setUsers: () => {},
  deleteUser: deleteUser || (() => {}),
  getUsers: getUsers || (() => {}),
};

export default ConnectedUsersManagement;
