import * as React from 'react';
import { TableContainer, TableHeading } from "../../AssignmentTable/styles";
import { PriorityContainer, ControlContainer, NoPrioritiesDiv, StyledTableRow } from "../styles";

const NoPrioritiesPage = () => {
  return (
    <>
      <PriorityContainer>
        <h2>Today's Priorities</h2>
        <ControlContainer>
        </ControlContainer>
      </PriorityContainer>
      <TableContainer>
        <TableHeading>
          <h3>Today's Priorities</h3>
        </TableHeading>
        <StyledTableRow>
          <NoPrioritiesDiv>
            No Priorities
          </NoPrioritiesDiv>
        </StyledTableRow>
      </TableContainer>
      <PriorityContainer />
    </>
  )
}
export default NoPrioritiesPage;