import * as React from "react";
import {
    DashboardAside,
} from "../../styles/DashboardContainer";
import PriorityArea from "../../PriorityArea";
import Alerts, { IAlerts } from "../../Alerts";
import Settings from "../../Settings";
import Comments from "../../Comments";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { EQuickView } from "../..";
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}


const Aside = ({
    priorities,
    assignments,
    toggleSettings,
    selectedTask,
    user,
    loadingPriorities,
    assignmentId,
    asideIsHidden,
    setAsideVisibility,
    selectedQuickView,
}) => {
    const [value, setValue] = React.useState(0);
    const hideAside = () => setAsideVisibility(!asideIsHidden);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <DashboardAside value={value} isHidden={asideIsHidden}>
            <div className="toggle-container aside"><button className="toggle-view" onClick={hideAside}><KeyboardArrowRight /></button></div>
            <Box
                sx={{
                    borderRadius: "8px",
                    listStyle: "none",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    paddingInline: "1rem",
                    paddingBlockEnd: "7rem",
                    boxSizing: "border-box",
                }}
            >

                <AppBar
                    sx={{
                        backgroundColor: "#E8EAEE",
                        color: "black",
                        padding: "3px",
                        marginTop: "22px",
                        borderRadius: "10px",
                    }}
                    position="static"
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="inherit"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "white",
                                height: "100%",
                                borderRadius: "10px",
                                zIndex: 0,
                            },
                        }}
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab
                            data-testid="alerts-tab"
                            label={<div className="alerts-tab">ALERTS</div>}
                        />
                        <Tab
                            data-testid="priorities-tab"
                            label={<div className="alerts-tab">Priorities</div>}
                        />
                    </Tabs>
                </AppBar >

                <TabPanel style={{
                    height: "100%",
                    overflowY: "auto",
                }} data-testid="alerts-panel" value={value} index={0}>
                    <Alerts
                        assignments={assignments}
                        data-testid="dashboard-alerts"
                    />
                </TabPanel>

                <TabPanel data-testid="priorities-panel" value={value} index={1}
                    style={{
                        height: "100%",
                        overflowY: "auto",
                    }}
                >
                    <PriorityArea
                        data-testid="dashboard-priorities"
                        priorities={priorities}
                        assignments={assignments}
                        user={user}
                        loadingPriorities={loadingPriorities}
                    />
                </TabPanel>
            </Box >
        </DashboardAside >
    )
}


export default Aside