import * as React from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  AddTaskModal,
  CloseIconDiv,
  ModalInput,
  SubmitTaskButton,
  WarningText,
  TitleText,
} from "../../pages/Dashboard/AlertsPage/styles";
import CloseIcon from "@mui/icons-material/Close";
import { IAlerts } from "../../pages/Dashboard/Alerts";
import { SuccessIcon } from "../SuccessIcon";
import addTask from "../../api/v1/tasks/post";
import markAsTask from "../../api/v1/alerts/markAsTask";
import DateTimePicker from "../DateTimePicker/DateTimePicker";
interface IExtendedModalProps {
  modalData: {
    contractNo: string;
    broker: string;
    alert: IAlerts;
    setAlerts: (alerts: IAlerts[]) => void;
    title?: string;
  };
  onClose: () => void;
  onConfirm: (comment: string, selectedDateTime: Date) => void;
}

const ExtendedModal = (props: IExtendedModalProps) => {
  const [comment, setComment] = React.useState<string>("");
  const now = new Date();
  const [selectedDateTime, setSelectedDateTime] = React.useState<Date>(now);
  const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const { contractNo, broker, alert, setAlerts, title, assignmentId } = props?.modalData;

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const commentValue = event.target.value;
    setComment(commentValue);
    setIsFormValid(commentValue.trim() !== "");
  };

  const handleCommentSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onClose();
    setComment("");
    setIsFormValid(false);
    props.onConfirm(comment, selectedDateTime);

  }

  const truncateBroker = (name: string, maxLength: number = 90) => {
    return name && name.length > maxLength ? `${name.substring(0, maxLength)}...` : name;
  };

  return (
    <>
      <AddTaskModal
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation();
        }}
      >
        <CloseIconDiv>
          <CloseIcon onClick={props.onClose} className="modal-close" />
        </CloseIconDiv>
        <h2 className="modal-text" data-testid="modal-text">
          {title}
        </h2>
        <div style={{ padding: "1rem 0" }}>
          <thead data-testid="modal-header" className="modal-header">
            <th>Select</th>
            <th>Contract No</th>
            <th className="broker">Broker</th>
          </thead>
          <tr className="modal-row">
            <td>
              <CheckCircleOutlineOutlinedIcon data-testid="alerts-select" className="modal-icon" />
            </td>
            <td className="contractNo">{contractNo}</td>
            <td className="broker" title={broker}>{truncateBroker(broker)}</td>
          </tr>
        </div>
        <ModalInput>
          <div className="input-label">Comments*</div>
          <input
            data-testid="modal-input"
            className="modal-input"
            value={comment}
            onChange={handleCommentChange}
            required
            placeholder="Type your comment here"
          />
        </ModalInput>
        <WarningText>
          <ErrorOutlineIcon sx={{ fontSize: 15 }} style={{ marginRight: "0.3rem" }} />
          You will find this comment in the Task accordion.
        </WarningText>

        <TitleText> Set a Reminder: </TitleText>
        <DateTimePicker selectedDateTime={selectedDateTime} setSelectedDateTime={setSelectedDateTime} now={now} />

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            paddingTop: "1rem",
          }}
        >
          <SubmitTaskButton
            type="submit"
            data-testid="submit-task-button"
            disabled={!isFormValid}
            onClick={handleCommentSubmit}
          >
            Submit
          </SubmitTaskButton>
        </div>
      </AddTaskModal>
      {/* )} */}
    </>
  );

};

export default ExtendedModal;