import * as React from "react";
import { ErrorModal } from "../styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CloseIconDiv } from "../../AlertsPage/styles";
import CloseIcon from "@mui/icons-material/Close";

interface IErrorInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

const ErrorInputModal = (props: IErrorInputModalProps) => {
  const { isOpen, onClose, title, message } = props;
  return (
    <ErrorModal>
      <CloseIconDiv>
        <CloseIcon onClick={onClose} className="modal-close" />
      </CloseIconDiv>
      <div className="modal-icon">
        <CancelOutlinedIcon
          style={{ fontSize: "3rem", color: "#ff0000" }}
        />
      </div>
      <div className="modal-text">
        {title}
      </div>
      <div className="modal-text">{message}</div>
    </ErrorModal>
  );
};

export default ErrorInputModal;
