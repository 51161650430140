import * as React from "react";
import styled from "styled-components";
import ConfirmationModal from "../../../../../components/ConfirmationModal";
import asModal from "../../../../../hoc/asModal";
import AutoCompleteInput from "../../Steps/components/AutocompleteInput";

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  fill: var(--white-100, #fff);
  filter: drop-shadow(4px 4px 10px rgba(39, 50, 62, 0.05))
    drop-shadow(-4px -4px 10px rgba(0, 0, 0, 0.1));
  gap: 1rem;
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid #babfc3;
  > div:hover {
    background-color: #cce5ff66;
    padding: 0.2rem;
  }
`;
const FileConfirmationModal = asModal(ConfirmationModal, "#modal-root");
const StyledFileConfirmationModal = styled(FileConfirmationModal)`
  > * {
    font-size: 1rem;
    font-family:
      "Open Sans",
      sans-serif h2 {
      margin-top: 0;
    }
  }
`;


const StyledAutoCompleteInput = styled(AutoCompleteInput)<{keepOpen:boolean}>`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  margin: 0;
  pointer-events: ${props => props.keepOpen ? 'all' : 'none'};
  input {
    border-radius: var(--4, 4px);
    font-family: "Open Sans", sans-serif;
    border: none
    background: var(--white-100, #fff);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    margin: unset;
  }
`;
const documentTypeOption = [
  { label: "Slip", id: "Slip" },
  { label: "Original Proposal", id: "Original Proposal" },
  { label: "Cancellation Notice", id: "Cancellation Notice" },
  { label: "Decline Notice", id: "Decline Notice" },
  { label: "Reject Notice", id: "Reject Notice" },
  { label: "Confirmation Notice", id: "Confirmation Notice" },
  { label: "Inspection Report", id: "Inspection Report" },
  { label: "Endorsement", id: "Endorsement" },
];

interface IFileOptions {
  initiateDownload: () => void;
  objectURL: string;
  showDocumentTypOpt: boolean;
  setShowDocumentTypeOpt: (value: boolean) => void;
  toggleEdit: () => void;
  fileName: string;
  handleDeleteFile: () => void;
  openFile: (e: React.MouseEvent) => void;
  editFileTypes: (name: string, value: any) => void;
  handleEditName: () => void;
  fileType: string;
  setIsEditing: (value: boolean) => void;
}



const FileOptions = (props: IFileOptions): JSX.Element => {
  const {
    fileType,
    openFile,
    editFileTypes,
    initiateDownload,
    handleDeleteFile,
    handleEditName,
  } = props;

  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectingDocumentType, setSelectingDocumentType] = React.useState(false)

  const handleConfirmDelete = (e): void => { 
    e?.preventDefault()
    setIsModalOpen(false)
    handleDeleteFile()
  }
  const downloadFile = (e): void =>  {
    e?.preventDefault()
    initiateDownload()
  }
  const openModel = (e): void =>  {
    e?.preventDefault()
    setIsModalOpen(true)
  }
  const closeModel = (e): void =>  {
    e?.preventDefault()
    setIsModalOpen(false)
  }
  const toggleDocumentType = (e): void =>  {
    setSelectingDocumentType(!selectingDocumentType)
  }
  
  const editFile = (e): void => { 
    handleEditName()
  }

  const _setFieldValue = (name, value) => {
    editFileTypes(name, value)
    setSelectingDocumentType(false)
  }

  return (
    <>
      
      <StyledMenu>
        <div onClick={downloadFile}> Download  </div>
        <div onClick={openFile}> Preview </div>
        <div onClick={editFile}> Rename </div>
        <div onClick={openModel}> Delete </div>
        <div onClick={toggleDocumentType}> Select Document Type </div>
      </StyledMenu>
      <StyledAutoCompleteInput
        testId="edit-dropdown"
        keepOpen={selectingDocumentType}
        hideSearch={true}
        label=""
        name={`type`}
        value={fileType}
        options={documentTypeOption || []}
        setFieldValue={_setFieldValue}
      />
      <StyledFileConfirmationModal
        isOpen={isModalOpen}
        title={"Delete File"}
        message={"Are you sure you want to delete this file?"}
        onClose={closeModel}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default FileOptions;
