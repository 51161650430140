import styled from "styled-components";

export const PriorityAreaAsideContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  .priority-header {
    text-align: center;
  }
`;

export const PriorityAreaCardContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
`;

export const NoPrioritiesDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  height: 100%;
`