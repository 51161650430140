import styled from "styled-components";

export const AlertsContainer = styled.section<{ isOpen: boolean }>`
  left: 0;
  transition: left 0.3s ease-in-out;
  top: 0;
  z-index: 10;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 1rem;
  }
  .date-sort-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    color: #3361FF;
    text-decoration: underline;
  }
`;

export const AlertCard = styled.li`
  max-width: 400px;
  max-height: 200px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid #91d5ff;
  background: #e6f7ff;
  margin: 1rem 0;
  padding: 1rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    align-items: center;
    weight: 200px;
  }
  .alert-date {
    font-size: 12px;
    text-align: right;
  }
  .div-text {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .contract-no {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:hover {
    background: #bae7ff;
    cursor: pointer;
    .div-text {
      overflow: visible;
      white-space: normal;
      text-overflow: unset;
    }
  }  div {
    &:first-child {
      text-decoration: underline;
    }
  }
`;


export const NoAlertsDiv = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.75rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  margin: auto;

`