import { api } from "..";
import { IAssignment, ILegacyAssignment } from "./types/assignments";

export const deleteAssignment = async (
  assignment: number,
): Promise<IAssignment & ILegacyAssignment> => {

  const response = await api.delete(`assignments/delete/${assignment}`);
  return response.data;
};

// export const deleteAssignments = async (
//   assignments: number[],
// ): Promise<IAssignment & ILegacyAssignment[]> => {
//   const response = await assignmentRequest.delete("/bulk", {
//     data: { assignments },
//   });
//   return response.data;
// };
