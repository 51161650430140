import * as React from "react";
import { Skeleton } from "@mui/material";
import styled from "styled-components";

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IAssignmentDefinitionSkeleton {
  className?: string;
}

const AssignmentDefinitionSkeleton = (props: IAssignmentDefinitionSkeleton) => {
  const { className } = props;
  return (
    <SkeletonWrapper className={className}>
      <Skeleton variant="text" width={100} height={20} />
      <Skeleton variant="text" width={130} height={40} />
    </SkeletonWrapper>
  );
}

export default AssignmentDefinitionSkeleton;