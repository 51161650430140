import { deleteEntityDetails } from "../../../../../api/v1/v2/entities/delete"


export const deleteCoveredRisk = async (item) => {
    try {
      if (!item?.deleteId.includes('mock')) {
        const results = await deleteEntityDetails({
          model: "CoveredRisk",
          filters: [{
            column: 'KOD',
            value: item.deleteId,
            operation: 'eq'
          }],
          transform: {}
        })
      }
      return true;
    }
    catch (err) {
      console.error(err)
      return false
    }

  }

export const deleteExcludedRisk = async (item) => {
    try {
      if (!item?.deleteId?.includes('mock')) {
        const results = await deleteEntityDetails({
          model: "ExcludedRisk",
          filters: [{
            column: 'KOD',
            value: item.deleteId,
            operation: 'eq'
          }],
          transform: {}
        })
      }
      return true;
    }
    catch (err) {
      console.error(err)
      return false
    }
  }

  // const updatedValues = insuredSubject?.includedRisks.filter(risk => risk.deleteId != item.deleteId)
  // setFieldValue(`${prefix}.excludeRisks`, insuredSubject?.excludeRisks?.filter(risk => risk.deleteId != item.deleteId))
  // setFieldValue(`${prefix}.includedRisks`, updatedValues)