import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SuccessIcon } from '../SuccessIcon';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// Define the props for the toast component
type ToastProps = {
  message: string;
  onClose: () => void;
};

// Styled components
const ToastContainer = styled.div<{ isVisible: boolean }>`
  width: 32rem;
  min-height: 16rem;
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  
  .success-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
  }
  .success-modal-text {
    text-align: center;
    margin-bottom: 1rem;
  }
  .span-text {
    text-decoration: underline;
  }
`;

const CloseButton = styled.button`
  margin-left: 15px;
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
`;

const Toast = ({ message }: ToastProps) => {

  return (

    <ToastContainer onClick={(event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
    }}>
      <div data-testid="success-div" className="success-div">
        <h2 className="success-modal-text">
          {message}
        </h2>
        {message == "Unable to create" ? <CancelOutlinedIcon color='error' fontSize='large' /> : <SuccessIcon />}
      </div>
    </ToastContainer>
  );
};

export default Toast;
