import * as React from 'react'
import styled from 'styled-components';
import {
  CloseIconDiv,
  SubmitTaskButton,
  CancelButton,
  ButtonContainer,
} from '../../pages/Dashboard/AlertsPage/styles';
import CloseIcon from '@mui/icons-material/Close';
interface IConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  cancelButtonColor?: string;
  cancelTextColor?: string;
  submitBorderColor?: string;
  submitButtonText?: string;
  buttonBackgroundColor?: string;
}

export const ConfirmationContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 22rem;
  height: 13rem;
  transform: translate(50%, -50%);
  flex-shrink: 0;
  border-radius: 4px;
  background: #fff;
  padding: 2rem;
  z-index: 10000;
  box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
  z-index: 9999;
  `;


const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {
    isOpen,
    title,
    message,
    cancelButtonColor,
    cancelTextColor,
    buttonBackgroundColor,
    submitBorderColor,
    submitButtonText,
    children,
    className,
    onClose,
    onConfirm,
  } = props;

  return (
    <ConfirmationContainer className={className} data-testid="confirmation-modal" open={isOpen}>
      <CloseIconDiv data-testid="close-confirmation">
        <CloseIcon onClick={onClose} className="modal-close" />
      </CloseIconDiv>
      <h2 data-testid="confirmation-title">{title}</h2>
      {children || <p data-testid="confirmation-message">{message}</p>}
      <ButtonContainer>
        <CancelButton data-testid="cancel-action" onClick={onClose} buttonBackgroundColor={buttonBackgroundColor} cancelButtonColor={cancelButtonColor} cancelTextColor={cancelTextColor}>Cancel</CancelButton>
        <SubmitTaskButton data-testid="confirm-action" onClick={onConfirm} buttonBackgroundColor={buttonBackgroundColor} submitBorderColor={submitBorderColor} submitButtonText={submitButtonText}>Confirm</SubmitTaskButton >
      </ButtonContainer>
    </ConfirmationContainer>
  );
}

export default ConfirmationModal