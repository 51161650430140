import * as React from 'react';
import { IAlerts } from '../../pages/Dashboard/Alerts';
import { createRandomAlerts } from '../../api/v1/__mocks__/alerts';
import useAlerts from '../../hooks/useAlerts';
import { UserContext } from '../UserContext';
interface IAlertsContext {
    alerts: IAlerts[];
    setAlerts: React.Dispatch<React.SetStateAction<IAlerts[]>>;
    markAlertAsViewed: (alert_id:number) => void;
    todaysAlerts: IAlerts[];
    loadingAlerts: boolean;
    error: string;
    fetchAlerts: () => void;
    alreadyLoaded: boolean;
}

export const AlertsContext = React.createContext<IAlertsContext>({})

const AlertsContextWrapper = <T extends object>( WrappedComponent: React.FC<T>) : JSX.Element => {
    
    const AlertsContextWrapper = (props: T) => {
        const { user } = React.useContext(UserContext);

        const openSocketConnection = (): TCloseConnection  => {
            if (!user) return () => {};
            console.log('opening socket connection to' + process.env.NOTIFICATION_API);
            const ws = new WebSocket(process.env.NOTIFICATION_API);
            ws.onopen = () => {
                console.log('connected');
            }
            ws.onmessage = (e) => {
                console.log('message received', e.data);
            }
            return () => {ws.close()};
        };


        React.useEffect(openSocketConnection,[user?.id])

        return (
            <AlertsContext.Provider value={{}}>
                <WrappedComponent {...props} />
            </AlertsContext.Provider>
        )
    }
    return AlertsContextWrapper;
}

export default AlertsContextWrapper;