import * as React from "react";
import {
  Table,
} from "../styles";
import type { IAlerts } from "../../Alerts";
import AlertsRow from "../components/AlertsRow";
import AlertsPageSkeleton from "./AlertsPageSkeleton";
import styled from "styled-components";

interface IRenderedAlertsPageProps {
  alerts: IAlerts[];
  setAlerts: (alerts: IAlerts[]) => void;
  displayAssignment: (assignmentId: string) => void;
  numeration: number;
  loadPreviousPage: () => void;
  loadNextPage: () => void;
  markAlertAsViewed: (alert_id: number) => void;
  handleSearch: (searchTerm: string) => void;
  searchTerm: string;
  setNumeration: (numeration: number) => void;
  alertsCount: number;
  filterAlertsCount: number;
}


const AlertsContainer = styled.div`
  min-height: 0px;
  flex-basis: 100%;
  overflow: scroll;
`
const RenderedAlertsPage = (props: IRenderedAlertsPageProps): JSX.Element => {

  const {
    alerts,
    setAlerts,
    markAlertAsViewed,
    loadingAlerts,
    displayAssignment,
  } = props;

  return (
    <>
      <AlertsContainer className="print-area" id={"alerts-page"}>
        <Table>
          <thead>
            <tr data-testid="alerts-page-th" className="table-header">
              <th>Status</th>
              <th>Contract No.</th>
              <th>Type of</th>
              <th>ID</th>
              <th>Description</th>
              <th>Broker</th>
              <th>Date</th>
              <th className="hide-on-print">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loadingAlerts ?
              <AlertsPageSkeleton /> :
              <MappedAlerts
                alerts={alerts}
                setAlerts={setAlerts}
                displayAssignment={displayAssignment}
                markAlertAsViewed={markAlertAsViewed}
              />}

          </tbody>
        </Table>
      </AlertsContainer>

    </>
  );
};

const MappedAlerts = (props: {
  alerts: { [key: string]: IAlerts };
  setAlerts: (alerts: IAlerts) => void;
  displayAssignment: (assignmentId: string) => void;
  markAlertAsViewed: (alert_id: number) => void;
}): JSX.Element => {
  const { alerts, setAlerts, displayAssignment, markAlertAsViewed } = props;
  return <>
  {Object.values(alerts)
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    .map((alert) => (
      <CreateAlertJSX
        alert={alert}
        setAlerts={setAlerts}
        displayAssignment={displayAssignment}
        markAlertAsViewed={markAlertAsViewed}
      />
    ))}
  </>
}




const CreateAlertJSX = (props): JSX.Element => {
  const {
    alert,
    setAlerts,
    displayAssignment,
    markAlertAsViewed,
  } = props;
  return (<AlertsRow
    data-testid="alerts-main-row"
    key={alert?.id}
    alert={alert}
    setAlerts={setAlerts}
    displayAssignment={displayAssignment}
    markAlertAsViewed={markAlertAsViewed}
  />)

}


export default RenderedAlertsPage;