import * as React from 'react';
import { DropDown, DropDownItem } from './styles';

const AutoCompleteDropDown = React.forwardRef((props: Iprops, ref) => {
    const { options, onClick, keepOpen, name } = props;
    const determineAbsolutePosition = (containerRef: React.RefObject<HTMLDivElement>) => {
        const container = containerRef.current;
        if (container) {
            const { top, bottom } = container.getBoundingClientRect();
            const windowHeight = window.innerHeight;
            const spaceBelow = windowHeight - bottom;
            const spaceAbove = top;
            return spaceBelow >= spaceAbove ? "bottom" : "top"
        }
    }
    return (
        <DropDown className="drop-down" position={determineAbsolutePosition(ref)} open={keepOpen}>
            <AutoCompleteDropDownList {...props} />
        </DropDown>
    )
})

const AutoCompleteDropDownList = (props) => {
    const { options, onSelect, name } = props;
    return (
        <>
            {options?.length > 0 && options?.map((option, index) => (
                <DropDownItem key={index}  
                    onClick={
                        (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onSelect && onSelect(name, option)
                        }
                    }>
                    {option.label}
                </DropDownItem>
            ))}
        </>
    )
} 


export default AutoCompleteDropDown;