import * as  React from "react";
import { ELegacyAssignmentProps, IAssignment, ILegacyAssignment } from "../../../api/v1/assignments/types/assignments";
import { IUser } from "../../../api/v1/users/types/user";
import PriorityAreaSkeleton from "./components/PriorityAreaSkeleton";
import RenderedPriorityArea from "./components/RenderedPriorityArea";
import NoPriorities from "./components/NoPriorities";

export interface ITodaysPrioritiesProps {
  user: IUser;
  priorities: number[];
  assignments: { [key: string]: IAssignment & ILegacyAssignment };
  loadingPriorities: boolean;
}

const PriorityArea = (props: ITodaysPrioritiesProps) => {
  const {loadingPriorities} = props;
  
  const currentPriorityView = !(props.priorities.length) ? <NoPriorities/> : <RenderedPriorityArea {...props}/>
  const priorityViewToUse = loadingPriorities ? <PriorityAreaSkeleton/> : currentPriorityView;

  return (
    <>
      {priorityViewToUse}
    </>

  );
};

export default PriorityArea;
