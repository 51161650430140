import * as React from "react";
import { ErrorModal } from "../styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CloseIconDiv } from "../../AlertsPage/styles";
import CloseIcon from "@mui/icons-material/Close";

interface IErrorInputModalProps {
  onClose: () => void;
}

const UsersErrorModal = (props: IErrorInputModalProps) => {
  return (
    <ErrorModal>
      <CloseIconDiv>
        <CloseIcon onClick={props.onClose} className="modal-close" />
      </CloseIconDiv>
      <div data-testid="modal-icon" className="modal-icon">
        <CancelOutlinedIcon
          style={{ fontSize: "3rem", color: "#ff0000" }}
        />
      </div>
      <div data-testid="modal-text" className="modal-text">
        No User Found
      </div>
      <div data-testid="modal-subtext" className="modal-text">Please Enter Valid User Information</div>
    </ErrorModal>
  );
};

export default UsersErrorModal;
