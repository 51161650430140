import * as React from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { CreateNewButton } from "../../RenewalsAndEndorsements/styles";
import asModal from "../../../../hoc/asModal";
import ReplyToEmail from "./ReplyToEmail/ReplyToEmail";
import { Editor, EditorState } from "react-draft-wysiwyg";

const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: center;
  .inbox {
    border: none;
    background-color: transparent;
  }
`;

const NavButton = styled.button<{ isActive: boolean }>`
  display: flex;
  background-color: transparent;
  border: none;
  border-bottom: ${(props) => (props.isActive ? "2px solid #3361ff" : "none")};
  &:hover {
    cursor: pointer;
  }
`;

const Inbox = styled.div<{ isActive: boolean }>`
  display: flex;
  background-color: none;
  border: none;
  border-bottom: ${(props) => (props.isActive ? "2px solid #3361ff" : "none")};
  &:hover {
    cursor: pointer;
  }
`;

const NewEmail = asModal(ReplyToEmail, "#modal-root");
const StyledNewEmailButton = styled(NewEmail)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3rem;
  z-index: 1000;
`;

interface IInboxNavProps {
  handleSendEmail: (email: any) => void;
  onClose: () => void;
  handleFileUpload: (file: File) => Promise<any>;
  emailContent: EditorState;
  handleEmailContent: (content: any, setFieldValue: Function) => void;
  emailContentValidation: (values: any) => any;
}
const InboxNav = (props:IInboxNavProps) => {
  const [activeButton, setActiveButton] = React.useState("inbox");
  const [openModal, setModalOpen] = React.useState<boolean>(false);
  const {handleSendEmail, emailContent, onClose, handleFileUpload, handleEmailContent, emailContentValidation} = props;
  const handleOpenModal = () => {
    setModalOpen(!openModal);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleNavButtonClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    buttonType: string,
  ) => {
    setActiveButton(buttonType);
    //then depending on the nav button clicked, we will fetch for those emails
  };

  return (
    <div>
      <NavigationContainer>
        <button className="inbox">
          <Inbox
            isActive={activeButton === "inbox"}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
              handleNavButtonClick(e, "inbox")
            }
          >
            <h2>Inbox</h2>
          </Inbox>
        </button>
        {/* <NavButton
          isActive={activeButton === "sent"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleNavButtonClick(e, "sent")
          }
        >
          Sent
        </NavButton>
        <NavButton
          isActive={activeButton === "draft"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleNavButtonClick(e, "draft")
          }
        >
          Draft
        </NavButton>
        <NavButton
          isActive={activeButton === "scheduled"}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            handleNavButtonClick(e, "scheduled")
          }
        >
          Scheduled
        </NavButton> */}
        <CreateNewButton
          data-testid="create-new-button"
          onClick={handleOpenModal}
        >
          <AddIcon />
          <div className="create-new-text">New Email</div>
        </CreateNewButton>
        <StyledNewEmailButton
          isOpen={openModal}
          emailContent={emailContent}
          onClose={handleCloseModal}
          onOpenModal={handleOpenModal}
          handleSendEmail={handleSendEmail}
          handleFileUpload={handleFileUpload}
          handleEmailContent={handleEmailContent}
          emailContentValidation={emailContentValidation}
        />
      </NavigationContainer>
    </div>
  );
};

export default InboxNav;