import {api} from "../index";
import { IBroadcastMessage } from "../types";

export const getBroadcastMessages = async ():Promise<IBroadcastMessage[]>=> {
    try{
        const response = await api.get('/alerts/broadcast');
        return response.data;
    } catch (error) {
       throw error;
    }
}
