import { api } from "..";

export const transferAssignment = async (
  userId:number,
  legacyUserId:number,
  assignmentId:string,
  ) => {
    const response = await api.patch(`/assignment/transfer`, {
      user_id:userId,
      legacy_user_id:legacyUserId,
      assignment_id:assignmentId,
    });
    return response?.data;
  };
  
