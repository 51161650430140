import * as React from 'react';
import {
  NoAssignmentDiv
} from "../styles";
import SearchIcon from "@mui/icons-material/Search";

const NoAssignmentsView = () => {
  return (
    <NoAssignmentDiv>
      No Assignments Available
    </NoAssignmentDiv>)
}
export default NoAssignmentsView;