import * as React from "react";
import { useState, useEffect } from "react";
import { IBroadcastMessage } from "../../../api/v1/types";
import { DashboardContext } from "..";
import {
  TableContainer,
  TableHeading,
  CreateNewMessageContainer,
  FormContainer,
  SubmitTaskButton,
} from "./styles";
import TextArea from "../../../components/FormElements/TextArea/TextArea";
import { Formik, Form } from "formik";
import { createBroadcastMessage } from "../../../api/v1/broadcastMessage/post";
import { getBroadcastMessages } from "../../../api/v1/broadcastMessage/get";
import { getLeadershipNotifications } from "../../../api/v1/alerts/get";
import NoBroadcastMessages from "./components/NoBroadcastMessages";
import RenderedBroadcastMessage from "./components/RenderedBroadcastMessages";
import BroadcastMessageSkeleton from "./components/BroadcastMessageSkeleton";
import deleteAlerts from "../../../api/v1/alerts/delete";
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import timezones from 'timezones-list';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IBroadcastMessageProps {
  setNotifications: (notifications: string) => void;
  createBroadcastMessage: (message: IBroadcastMessage) => Promise<IBroadcastMessage>;
  getBroadcastMessages: () => Promise<IBroadcastMessage[]>;
  getLeadershipNotifications: () => Promise<IBroadcastMessage[]>;
}

const BroadcastMessage: React.FC<IBroadcastMessageProps> = (props: IBroadcastMessageProps) => {
  const [messages, setMessages] = useState<IBroadcastMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentTimezone, setTimeZone] = useState<string>(
    dayjs.tz.guess()
  );
  

 
  const handleDeleteMessage = async (id: string) => {
    try {
      const res = await deleteAlerts({
        alert_ids: [id]
      })
      getNotifications();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const submitBroadcastMessage = async (values) => {
    try {
      let created_at = `${values.fromDate}` 
      let expires_at = `${values.toDate}`
      const convertedUTC = dayjs.tz(created_at, currentTimezone);
      const convertedUTC2 = dayjs.tz(expires_at, currentTimezone);
      const res = await props?.createBroadcastMessage({
        description: values.message,
        created_at: convertedUTC,
        expires_at: convertedUTC2,
        is_broadcast: true,
      });

      getNotifications();
    } catch (error) {
      console.error("Error:", error);
    } 
  };

  const getNotifications = async () => {
    try {
      const res = await props?.getBroadcastMessages();
      setMessages(res?.broadcast || []);

      const activeLeadershipNotifications = await props?.getLeadershipNotifications();
      props?.setNotifications(activeLeadershipNotifications);

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getNotifications();
  }, []);

  const currentBroadcastMessageView = !messages || messages.length === 0 ? (
    <NoBroadcastMessages />
  ) : (
    <RenderedBroadcastMessage messages={messages} deleteMessage={handleDeleteMessage} />
  );

  const viewToUse = loading ? <BroadcastMessageSkeleton /> : currentBroadcastMessageView;
  const timeZoneOptions = timezones.map((timezone) => { 
    return <option value={timezone.tzCode}>{timezone.label}</option>
  });
  return (
    <>
    <h2>Broadcast Message</h2>
      <TableContainer>
        <TableHeading>
          <h3>History of Broadcast Message</h3>
        </TableHeading>
        {viewToUse}
      </TableContainer>

      <CreateNewMessageContainer>
        <h4 className="title-name">Create New Broadcast Message</h4>

        <FormContainer className="form-container">
          <Formik initialValues={{
            message: "",
            fromDate: null,
            toDate: null
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.message || values.message.length < 1) {
              errors.message = "Message is required";
            }
            if (values.fromDate && values.toDate) {
              const fromDate = new Date(values.fromDate);
              const toDate = new Date(values.toDate);
              if (fromDate > toDate) {
                errors.fromDate = "From date must be before to date";
              }
            }
            if (!values.fromDate || values.fromDate.length < 1) {
              errors.fromDate = "From date is required";
            }
            if (!values.toDate || values.toDate.length < 1) {
              errors.toDate = "To date is required";
            }
            return errors;
          }} 
          onSubmit={submitBroadcastMessage}
          >
             {({ values, handleChange, handleSubmit, errors }) =>(
              <Form data-testid="form-element" onSubmit={handleSubmit}>
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "start",
                  gap: "2rem",
                }}>
                  <div className="date-inputs">
                    <div>
                      <label htmlFor="created-date">From Date</label>
                      <input
                        type="datetime-local"
                        id="created-date"
                        data-testid="created-date"
                        name="fromDate"
                        onChange={handleChange}
                        value={values?.fromDate}
                        />
                    </div>
                    <div>
                      <label htmlFor="end-date">To Date</label>
                      <input
                        type="datetime-local"
                        id="end-date"
                        data-testid="end-date"
                        name="toDate"
                        onChange={handleChange}
                        value={values?.toDate}
                        />
                    </div>
                    <div>
                      <label htmlFor="time-zone">Time Zone</label>
                      <select
                        id="time-zone"
                        data-testid="time-zone"
                        name="timeZone"
                        onChange={(e) => setTimeZone(e.target.value)}
                        value={currentTimezone}
                        >
                        {timeZoneOptions}
                      </select>
                    </div>
        
                  </div>
                  <div>
                    {Object.keys(errors).map((key, index) => (
                      <div key={index} className="error" style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "5px"
                      }}>
                        * {errors[key]}
                      </div>
                    ))}
                  </div>

                </div>

                <div className="textarea-container">
                  <label htmlFor="textarea-input" className="title-name">
                    Broadcast Message
                  </label>
                  <TextArea
                    className="textarea-input"
                    id="message-input"
                    data-testid="message-input"
                    placeholder="Write a Note"
                    name="message"
                    onChange={handleChange}
                    value={values?.message}
                  />
                </div>

                <div style={{ display: "flex", width: "100%", justifyContent: "flex-start" }}>
                  <SubmitTaskButton type="submit" data-testid="submit-button">
                    Submit
                  </SubmitTaskButton>
                </div>
              </Form>
             )}
          </Formik>
        </FormContainer>
      </CreateNewMessageContainer>
    </>
  );
};

BroadcastMessage.defaultProps = {
  getBroadcastMessages: getBroadcastMessages,
  getLeadershipNotifications: getLeadershipNotifications
};

const ConnectedBroadcastMessage: React.FC = () => {
  const { setNotifications, createBroadcastMessage } = React.useContext(DashboardContext);
  return <BroadcastMessage setNotifications={setNotifications} createBroadcastMessage={createBroadcastMessage} />;
};

export default ConnectedBroadcastMessage;
