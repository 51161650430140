import * as React from "react";
import { useParams } from "react-router-dom";
import { Main, StyledAssignment } from "./styles/StyleAssignmentModal";
import { getDefaultTasks } from "../../../api/v1/tasks/get_default";
import type { TAssignment, Task } from "../../../api/v1/types";
import DrillDownNavigation from "./components/Navigation/Navigation";
import { useFormik } from "formik";
import AssignmentStructure, { validationSchema } from "../../../api/v1/assignments/classes/assignment";
import useEntitySearch from "../../../hooks/useEntitySearch";
import searchWrapper from "./Steps/utils/searchFunctions";
import { StyledAssignmentDrillDown, StyledAccordion } from "./styles"
import SelectableTabs from "./Tabs";
import ErrorWrapper from "./FormErrors";
import { LinearProgress } from "@mui/material";
import { UserContext } from "../../../context/UserContext";

export interface IAssignmentDrillDownProps {
  assignment: TAssignment;
  assignments: Map<number, AssignmentStructure>;
  setAssignments: (assignment: Map<number, AssignmentStructure>) => void;
  breadcrumbs: JSX.Element[];
  assignmentId?: number | string;
  selectedTab?: string;
  clickOnMessage: () => void;
  expandTaskMenu?: boolean;
  expandAccordion?: boolean;
  setExpandAccordion?: boolean;
  selectAssignment?: AssignmentStructure;
  setSelectedAssignment?: (assignment: AssignmentStructure) => void;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  selectedQuickView?: string;
  setPriorities: (priorities: Map<number, string>) => void;
}


const AssignmentDrillDown = (props: IAssignmentDrillDownProps): JSX.Element => {
  const { assignmentId, breadcrumbs, selectedQuickView, assignments, setAssignments, selectAssignment, setSelectedAssignment, setPriorities } = props;
  const { userHierarchy } = React.useContext(UserContext);
  const { tab } = useParams();
  const { search, options, setOptions } = useEntitySearch();
  const [expandAccordion, setExpandAccordion] = React.useState(false);
  const [defaultTask, setDefaultTask] = React.useState([]);
  const [history, setHistory] = React.useState<Task[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [loadingDocuments, setLoadingDocuments] = React.useState<boolean>(true);
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [accordionTitle, setAccordionTitle] = React.useState<string>("Tasks");
  const [displayHistory, setDisplayHistory] = React.useState(false);
  const currentAssignemtFilesLength = Object.keys((selectAssignment?.files || {}))?.length || 0;
  const underwriters = userHierarchy?.managing?.Underwriter?.users || {};
  const peers = userHierarchy?.peers || {};
  const underwriterOptions = { ...peers, ...underwriters };

  const loadTask = (): void => {
    setIsLoading(true);
    getDefaultTasks()
      .then((data) => {
        if (data) {
          setDefaultTask(data);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      }
      );
  }

  const loadDocuments = async (data: AssignmentStructure): Promise<AssignmentStructure> => {
    await data.getDocuments().then(() => {
      setLoadingDocuments(false);
    });
    return data;
  }

  const setAssignmentData = (data: AssignmentStructure): AssignmentStructure => {
    setSelectedAssignment(data);
    return data;
  }

  const loadAssignment = (): void => {
    if (assignmentId) {
      setIsLoading(true);
      const assignmentStructure = new AssignmentStructure();
      assignmentStructure.load(assignmentId)
        .then(setAssignmentData)
        .then(loadDocuments)
        .then(loadTask).catch((error) => {
          console.error(error);
        }).finally(() => {
          setIsLoading(false);
        })
    }
  };

  const updateDocuments = async (data: AssignmentStructure): Promise<AssignmentStructure> => {
    await data.upsertDocuments().then(() => {
      setLoadingDocuments(false);
    }).catch((error) => {
      console.error(error);
    });
  }


  const updateAssignment = (values: AssignmentStructure): void => {
    const assignment = new AssignmentStructure(values)
    setIsUpdating(true);
    assignment.update().then(setAssignmentData).then(updateDocuments).catch((error) => {
      console.error(error)
    }).finally(() => {
      setIsUpdating(false);
    })
  };


  const handleTitleChange = (): void => {
    setDisplayHistory(!displayHistory);
    setAccordionTitle(!displayHistory ? "Tasks History" : "Tasks");
  };


  const searchUnderwriters = (text: string) => {
    let currentUnderwriters = Object.values({
      ...(userHierarchy?.managing?.Underwriter?.users || {}),
      ...(userHierarchy?.managing?.Applications?.users || {})
    })
    const applyFilters = (currentUnderwriters) => currentUnderwriters.filter(uw => uw?.family_name?.toLowerCase().includes(text.toLowerCase()) || uw?.given_name?.toLowerCase().includes(text.toLowerCase()))
    const constructUnderwriter = (currentUnderwriters) => {
      return currentUnderwriters.map(uw => ({
        id: uw?.legacy_id,
        label: `${uw?.family_name} ${uw?.given_name}`
      }))
    }
    const filteredUnderwriters = text ? applyFilters(currentUnderwriters) : currentUnderwriters
    setOptions((prev) => ({
      ...prev,
      underwriters: constructUnderwriter(filteredUnderwriters)
    }))
  }
  console.log('checking options', options)
  const extendedSearchOptons = {
    searchFunctions: {
      searchUnderwriters
    }
  }

  const searchFunctions = searchWrapper(search, extendedSearchOptons);

  React.useEffect(loadAssignment, [assignmentId]);

  return (
    <StyledAssignment data-testid="content">
      <AssignmentForm
        updateAssignment={updateAssignment}
        isLoading={isLoading}
        isUpdating={isUpdating}
        loadingDocuments={loadingDocuments}
        currentAssignemtFilesLength={currentAssignemtFilesLength}
        breadcrumbs={breadcrumbs}
        options={options}
        assignmentId={assignmentId}
        selectAssignment={selectAssignment}
        searchFunctions={searchFunctions}
        tab={tab}
        underwriterOptions={underwriterOptions}
        setPriorities={setPriorities}
      />
      <StyledAccordion
        className="taskAccordion"
        clickOnMessage={props.clickOnMessage}
        expandAccordion={expandAccordion}
        setExpandAccordion={setExpandAccordion}
        assignmentId={assignmentId}
        setTasks={setTasks}
        accordionTitle={accordionTitle}
        tasks={tasks}
        defaultTask={defaultTask}
        taskHistory={setHistory}
        titleChange={handleTitleChange}
        displayHistory={displayHistory}
        setDisplayHistory={setDisplayHistory}
        selectedQuickView={selectedQuickView}
        isLoading={isLoading}
        contract={selectAssignment?.contractNo}
        style={{ margin: 0 }}
      />
    </StyledAssignment>

  );
};


interface ISelectAbleTabs {
  selectAssignment: AssignmentStructure;
  updateAssignment: (values: AssignmentStructure) => void;
  handleSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  isUpdating: boolean;
  options: any;
  searchFunctions: any;
  tab: string;
  breadcrumbs: JSX.Element[];
  assignmentId?: number | string;
  currentAssignemtFilesLength: number;
  loadingDocuments: boolean;
  underwriterOptions?: any;
  setPriorities: (priorities: any) => void;
}

const AssignmentForm = (props: ISelectAbleTabs): JSX.Element => {
  const {
    selectAssignment, updateAssignment, handleSelection, isLoading, isUpdating,
    options, searchFunctions, tab, breadcrumbs, assignmentId, currentAssignemtFilesLength, loadingDocuments, underwriterOptions,
    setPriorities
  } = props;
  const [warning, setWarning] = React.useState(false);

  const formik = useFormik({
    initialValues: selectAssignment,
    onSubmit: (value: AssignmentStructure): void => {
      const errorExist = Object.keys(formik.errors).length > 0;
      if (errorExist) {
        setWarning(true);
        return;
      }
      updateAssignment(value);
    },
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
  })
  const _handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    const { name, value } = event.target;
    formik.setFieldValue(name, value).catch((error) => {
      console.error(error);
    });
  }


  React.useEffect(() => {
    if ((formik.isSubmitting || formik.isValidating) && Object.keys(formik.errors).length > 0) {
      setWarning(true)
    } else if ((formik.isSubmitting || formik.isValidating) && Object.keys(formik.errors).length === 0) {
      setWarning(false)
    }
  }, [formik.isSubmitting])
  const addReinsuranceTerm = (): void => { }



  return (
    <StyledAssignmentDrillDown >
      <ErrorWrapper errors={formik?.errors} open={warning} setOpen={setWarning}>
        <DrillDownNavigation
          breadcrumbs={breadcrumbs}
          selectedTab={tab}
          contractType={selectAssignment?.contractType}
          contractNo={selectAssignment?.contractNo}
          selectAssignment={selectAssignment}
          assignmentId={assignmentId}
          isLoading={isLoading}
          handleSubmit={formik.handleSubmit}
          underwriterOptions={underwriterOptions}
          setPriorities={setPriorities}
        />
        <Main>
          <SelectableTabs
            key={tab}
            assignment={formik.values}
            currentTab={tab}
            formik={formik}
            loadingDocuments={loadingDocuments}
            currentAssignemtFilesLength={currentAssignemtFilesLength}
            handleChange={_handleChange}
            handleSelection={handleSelection}
            isLoading={isLoading}
            options={options}
            searchFunctions={searchFunctions}
            addReinsuranceTerm={addReinsuranceTerm}
          />
        </Main>

      </ErrorWrapper>
      {isUpdating && <LinearProgress className="update-loader" />}
    </StyledAssignmentDrillDown>
  )
}



export default AssignmentDrillDown;