import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  padding-bottom: 2.5rem;
  gap: 1rem;
  position: relative;
  height: 20rem;
  width: 22rem;
  background-color: white;
  margin-right: 6px;
  margin-top: 100px;
  border-radius: 10px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);

  .currentuser-name {
    color: #202223;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 88.889% */
  }

  .currentuser-email {
    color: #3e3e3c;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 88.889% */
    text-decoration-line: underline;
  }
`;

export const AvatarIcon = styled.div`
  width: 123px;
  height: 123px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3361ff;
  color: white;
  border: 1px solid blue;

  .icon-initials {
    color: #fff;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Lato;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const StyledButton = styled.button`
  width: 145px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #3361ff;
  border-style: solid;
  border-color: #3361ff;
  border-radius: 0.25rem;
  cursor: pointer;

  .signout-button {
    color: #fafbfb;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
  }
`;

export const CloseIconButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
`;
