import * as React from "react";
import FilePopover from "./FilePopover";
import FilePreviewObject from "./FilePreviewObject";
import { StyledFile, StyledDescription } from "../styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CloseIcon from "@mui/icons-material/Close";
import InfoTip from "../../../../../components/Tooltip";
import { Edit } from "@mui/icons-material";

interface IFilePreview {
  setFieldValue: (name: string, value: any) => void;
  fileName: string;
  fileType: string;
  fileObject: File;
  isNew: boolean;
  url: string;
  handleFileEdit: (value: any, fileName: string, fileObject: File) => void;
}

interface IRenameFile {
  fileName: string;
  editFileName: boolean;
  className: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  setEditFileName: (value: boolean) => void;
}

export const RenameFile = (props: IRenameFile): JSX.Element => {
  const { fileName, className, onChange, editFileName, handleSave, setEditFileName } = props;

  const _onChange = (e) => {
    onChange(e);
  }

  const _handleSave = (e) => {
    e.preventDefault();
    handleSave();
  }
  const _handleCancel = (e) => {
    e.preventDefault();
    setEditFileName(false);
  }

  return (
    <div className="wrapper">
      <input
      data-testid="rename-file"
        type="text"
        className={className}
        value={fileName}
        onChange={_onChange}
      />
      <button onClick={_handleSave} data-testid="confirm-rename">
        <InfoTip title="Save File Name" placement="top">
          <TaskAltIcon className={editFileName ? 'show' : 'hide'} style={{ color: "#61A87E", fontSize: "1rem", cursor: "pointer" }} />
        </InfoTip>
      </button>
      <button onClick={_handleCancel} data-testid="cancel-rename">
        <InfoTip title="Cancel" placement="top" >
          <CloseIcon className={editFileName ? 'show' : 'hide'} style={{ fontSize: "1rem", cursor: "pointer" }} />
        </InfoTip>
      </button>
    </div>
  );
};

const FilePreview = (props: IFilePreview): JSX.Element => {
  const ref = React.useRef(null);
  const {
    url,
    fileObject,
    orginalFileName,
    fileName,
    fileType,
    isNew,
    handleFileEdit
  } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const [objectURL, setObjectURL] = React.useState(null);
  const [editFileName, setEditFileName] = React.useState(false);
  const [newFileName, setNewFileName] = React.useState(fileName);
  const [showDocumentTypOpt, setShowDocumentTypeOpt] = React.useState(false);


  const openFile = (e): void => {
    e.preventDefault();
    window.open(objectURL, "_blank");
  };

  const initiateDownload = (url): void => {
    const link = document.createElement("a");
    link.href = isNew ? objectURL : url;
    link.setAttribute("download", fileName || "unknown");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const editFileTypes = (name, value): void => {
    handleFileEdit(
      { type: value?.label, has_changed: true }, orginalFileName
    );
  };

  const editFile = (e) : void => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleEditName = () : void => {
    handleFileEdit(
      { has_changed: true }, orginalFileName
    );
    setEditFileName(true);
    setIsEditing(false);
  }

  const handleFileRename = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewFileName(e.target.value);
    setIsEditing(false);
  };

  const handleSave = (): void => {
    handleFileEdit(
      {
        file_name: newFileName,
        has_changed: true
      },
      orginalFileName
    )
    setEditFileName(false);
  };

  const handleDeleteFile = (fileName: string): void => {
    handleFileEdit(
      {
        file_name: newFileName,
        has_changed: true,
        is_deleted: true
      },
      orginalFileName
    )
    setEditFileName(false);
  };

  return (
    <>
      <StyledFile isEditing={isEditing} onContextMenu={editFile} data-testid="styled-file">
        <FilePopover
          file={fileObject}
          ref={ref}
          editFileTypes={editFileTypes}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          initiateDownload={initiateDownload}
          objectURL={objectURL}
          fileName={fileName}
          openFile={openFile}
          handleEditName={handleEditName}
          handleDeleteFile={handleDeleteFile}
          fileType={fileType}
          showDocumentTypOpt={showDocumentTypOpt}
          setShowDocumentTypeOpt={setShowDocumentTypeOpt}
        />
        <div style={{
          pointerEvents: "none",
          zIndex:0
        }}>
          <FilePreviewObject
            onContextMenu={editFile}
            initiateDownload={initiateDownload}
            openFile={openFile}
            objectURL={objectURL}
            setObjectURL={setObjectURL}
            url={url}
            fileObject={fileObject}
            fileName={orginalFileName}
            fileType={fileType}
          />
        </div>
        <StyledDescription>
          <h4
            className={editFileName ? "hide" : "show"}
            data-testid="file-name"
            title={fileName}
          >
            {newFileName}
          </h4>
          <RenameFile
            className={editFileName ? "show" : "hide"}
            fileName={newFileName}
            editFileName={editFileName}
            onChange={handleFileRename}
            handleSave={handleSave}
            setEditFileName={setEditFileName}
          />
          <h5 data-testid="file-type" title={fileName}>
            {fileType || "Document Type Not Assigned"}
          </h5>
        </StyledDescription>
      </StyledFile>
    </>
  );
};

export default FilePreview;
