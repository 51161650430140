import * as React from "react";
import { TaskAltOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import {
  StyledPermissionRow,
  SubmitPermissionsButton,
  Table,
  TableContainer,
} from "../../../styles";
import PermissionsTableHead from "./PermissionsTableHead";
import AddPermissionsView from "./AddPermissionsView";
import { useFormik } from "formik";
import InfoTip from "../../../../../../components/Tooltip";



interface INewPermissionsRowProps {
  path: string;
  operations: ("read" | "write" | "delete" | "update")[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  handleAddRestriction: (values) => void;
  onClose: () => void;
  handleSubmit: () => void;
}

const NewPermissionsRow = (props: INewPermissionsRowProps) => {
  const { handleChange, setFieldValue, onClose, errors, values, handleSubmit } = props;
  const { operation } = values;
  const isReadActive = !operation?.includes("read");
  const isDeleteActive = !operation?.includes("delete");
  const isWriteActive = !operation?.includes("write");
  const isUpdateActive = !operation?.includes("update");
  const [allActive, setAllActive] = React.useState<boolean>(false);

  const handleOperationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    const propertyExists = operation.includes(name);
    if (!propertyExists){
      setFieldValue("operation", [...operation, name]);
      return;
    }else{
      const newOperations = operation.filter((op) => op !== name);
      setFieldValue("operation", newOperations);
      return;
    }
  };

  const handleSetAllActive = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setAllActive(checked);
    let newOperations =  ["read", "write", "delete", "update"];
    if (operation.length === 4){
      newOperations = [];
    }
    setFieldValue("operation", newOperations);
  };

  const handleClose = () => {
   props.onClose();
  };

  const allIsMarked = !(operation?.length);
  return (
    <>
      <StyledPermissionRow data-testid="permission-row">
        <td>
          <div className="toggle">
            <label className="switch">
              <input
              name="all-active"
                type="checkbox"
                data-testid="all-active"
                onChange={handleSetAllActive}
                checked={allIsMarked}
              />
              <span className="slider round"></span>
              <span className={`span-text ${allIsMarked ? "active" : ""}`}>
                {allIsMarked ? "Active" : "Off"}
              </span>
            </label>
          </div>
        </td>
        <td>
          <input
            type="text"
            name="path"
            placeholder="Path Name"
            value={props?.path}
            data-testid="path"
            onChange={handleChange}
            className={errors?.path && touched?.path ? "input-error" : ""}
            />
          {errors?.path && <div className="error">{errors?.path}</div>}
        </td>
        <td>
          <div className="toggle">
            <label className="switch">
              <input
                type="checkbox"
                name="read"
                data-testid="read"
                checked={isReadActive}
                onChange={handleOperationChange}
              />
              <span className="slider round"></span>
              <span className={`span-text ${isReadActive ? "active" : ""}`}>
                {isReadActive ? "Active" : "Off"}
              </span>
            </label>
          </div>
        </td>
        <td>
          <div className="toggle">
            <label className="switch">
              <input
                type="checkbox"
                name="write"
                data-testid="write"
                checked={isWriteActive}
                onChange={handleOperationChange}
              />
              <span className="slider round"></span>
              <span className={`span-text ${isWriteActive ? "active" : ""}`}>
                {isWriteActive ? "Active" : "Off"}
              </span>
            </label>
          </div>
        </td>
        <td>
          <div className="toggle">
            <label className="switch">
              <input
                type="checkbox"
                name="delete"
                data-testid="delete"
                checked={isDeleteActive}
                onChange={handleOperationChange}
              />
              <span className="slider round"></span>
              <span className={`span-text ${isDeleteActive ? "active" : ""}`}>
                {isDeleteActive ? "Active" : "Off"}
              </span>
            </label>
          </div>
        </td>
        <td>
          <div className="toggle">
            <label className="switch">
              <input
                type="checkbox"
                name="update"
                data-testid="update"
                checked={isUpdateActive}
                onChange={handleOperationChange}
              />
              <span className="slider round"></span>
              <span className={`span-text ${isUpdateActive ? "active" : ""}`}>
                {isUpdateActive ? "Active" : "Off"}
              </span>
            </label>
          </div>
        </td>
        <td>
          <div className="actions">
            <InfoTip title="Add Restriction" placement="top">
            <SubmitPermissionsButton data-testid="submit-permission" type="submit" disabled={!!errors?.path}>
              <TaskAltOutlined className="task-icon" />
            </SubmitPermissionsButton>
            </InfoTip>
            <InfoTip title="Cancel" placement="top">
            <CloseIcon data-testid="close-permission" onClick={handleClose} />
            </InfoTip>
          </div>
        </td>
      </StyledPermissionRow>
    </>
  );
};
export default NewPermissionsRow;
