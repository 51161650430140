import * as React from "react";
import { StyledPremiumTableHead, PremiumTableDivider, PremiumLimitTable } from "./styles";
import { TableContainer, Table } from "../../../../global.styles";
import { StyledInsuredSubjectsSection, StyledInsuredSubjectsWrapper } from "../Steps/components/Risk/styles";
import type { InsuredSubject } from "../../../../api/v1/assignments/classes/insuredSubjects";
import StatusColumn from "../../AssignmentTable/components/TableBody/StatusColumn";
import PremiumLimitForATerm from "./components/PremiumLimitTable";


interface IStatus {
  status: string;
  statusDate: string;
  changed: string;
  received: string;
  user: string;
}

interface IFromLimitRow {
  from_limit: number;
  from_mere_share: number;
  from_premium: number;
  from_deductions: number;
  from_brokerage: number;
  from_other_ddcs: number;
  net_prm: number;
  acct: string;
}

interface IToLimitRow {
  to_limit: number;
  to_mere_share: number;
  to_premium: number;
  to_deductions: number;
  to_brokerage: number;
  to_other_ddcs: number;
  net_prm: number;
  acct: string;
}

interface ILimit {
  from: IFromLimitRow;
  to: IToLimitRow;
}

interface ISubject {
  share: number;
  premium: number;
  deductions: number;
  brokerage: number;
  otherddcs: number;
  netPremium: number;
  recieved: number;
  return: number;
}

interface ITotal {
  subjects: ISubject[];
}

interface IPremiumAndLimitProps {
  statusObject?: IStatus;
  limitObject?: ILimit;
  total?: ITotal;
  assignment: IStatus & IFromLimitRow & IToLimitRow & ITotal;
}

interface ITerm {
  accounting: {
    limit: {
      min: number;
      max: number;
    };
    companyShare: {
      min: number;
      max: number;
    };
    premium: {
      min: number;
      max: number;
    };
    deductions: {
      min: number;
      max: number;
    };
    brokerage: {
      min: number;
      max: number;
    };
    otherDDCs: {
      min: number;
      max: number;
    };
    paid: boolean;
    netPremium: number;
    lastUpdatedDate: string;
  };
}
const PremiumAndLimit = (props: any) => {
  const { assignment, handleChange } = props;

  const contractRow = assignment.insuredSubjects.reduce((acc, subject) => {
    const share = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => Number(Number(subAcc) + Number(term?.accounting?.companyShare?.max)).toFixed(2), 0);
    const premium = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => Number(Number(subAcc) + Number(term?.accounting?.premium?.max)).toFixed(2), 0);
    const deductions = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => Number(subAcc) + Number(term?.accounting?.deductions?.max).toFixed(2), 0);
    const brokerage = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => Number(Number(subAcc) + Number(term?.accounting?.brokerage?.max)), 0);
    const otherDDCs = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => {
      return Number(subAcc) + ((Number(term?.accounting?.otherDDCs?.min) / 100) * (Number(premium) - Number(deductions)))
    }, 0);
    const received = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => term?.accounting?.paid ? Number(Number(subAcc) + Number(term?.accounting?.netPremium)).toFixed(2) : Number(subAcc).toFixed(2), 0);
    const returns = subject.reinsuranceTerms.reduce((subAcc: number, term: ITerm) => term?.accounting?.paid ? Number(Number(subAcc) + Number(term?.accounting?.netPremium)).toFixed(2) : Number(subAcc).toFixed(2), 0);
    const netPremium = premium - brokerage - deductions - otherDDCs
    return { share, premium, deductions, brokerage, otherDDCs, netPremium, received, return: returns }
  }, 0);


  const share = contractRow?.share ? Number(contractRow?.share).toLocaleString() : 0
  const premium = contractRow?.premium ? Number(contractRow?.premium).toLocaleString() : 0
  const deductions = contractRow?.deductions ? Number(contractRow?.deductions).toLocaleString() : 0
  const brokerage = contractRow?.brokerage ? Number(contractRow?.brokerage).toLocaleString() : 0
  const otherDDCs = contractRow?.otherDDCs ? Number(contractRow?.otherDDCs).toLocaleString() : 0
  const netPremium = contractRow?.netPremium ? Number(contractRow?.netPremium).toLocaleString() : 0
  const received = contractRow?.received ? Number(contractRow?.received).toLocaleString() : 0
  const returns = contractRow?.return ? Number(contractRow?.return).toLocaleString() : 0


  const insuredSubjectsExist = Array.isArray(assignment?.insuredSubjects) && assignment?.insuredSubjects.length > 0
  return (
    <StyledInsuredSubjectsWrapper>

      <StyledInsuredSubjectsSection >
        <TableContainer>
          <section className="table-container">
            <Table>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Status Date</th>
                  <th>Accepted</th>
                  <th>Start Date</th>
                  <th>Underwriter</th>
                </tr>
              </thead>

              <tbody data-testid="table1">
                <tr>
                  <td data-testid="status-value">
                    <StatusColumn status={assignment?.statusType} />
                  </td>
                  <td data-testid="statusDate-value">
                    {new Date(assignment?.statusDate).toLocaleDateString("en-GB")}
                  </td>
                  <td data-testid="changed-value">
                    {new Date(assignment?.acceptDate).toLocaleDateString("en-GB")}
                  </td>
                  <td data-testid="received-value">
                    {new Date(assignment?.startDate).toLocaleDateString("en-GB")}
                  </td>
                  <td data-testid="user-value">{assignment?.underwriter || "N/A"}</td>
                </tr>
              </tbody>
            </Table>
          </section>
        </TableContainer>
        <PremiumTableDivider>
          <div className="relative">
            <div className="divider">
              <p className="text">TOTAL</p>
            </div>
          </div>
        </PremiumTableDivider>

        <TableContainer>
          <section className="table-container">
            <PremiumLimitTable>
              <StyledPremiumTableHead>
                <tr>
                  <th></th>
                  <th>MrRE Share</th>
                  <th>Premium</th>
                  <th>Deductions</th>
                  <th>Brokerage</th>
                  <th>Other Ddcs</th>
                  <th>Net Prm.</th>
                  <th>Received</th>
                  <th>Return</th>
                </tr>
              </StyledPremiumTableHead>

              <tbody data-testid="table3">
                <tr>
                  <td className="styled-headers">Contract</td>
                  <td>{share}</td>
                  <td>{premium}</td>
                  <td>{deductions}</td>
                  <td>{brokerage}</td>
                  <td>{otherDDCs}</td>
                  <td>{netPremium}</td>
                  <td>{received}</td>
                  <td>{returns}</td>
                </tr>
              </tbody>
            </PremiumLimitTable>
          </section>
        </TableContainer>
        {insuredSubjectsExist && <PremiumLimitCards {...props} handleChange={handleChange} />}
      </StyledInsuredSubjectsSection>
    </StyledInsuredSubjectsWrapper>
  )
};





interface IPremiumAndLimitCards {
  assignment: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, subjectIndex: number, reinsuranceIndex: number) => void;
}
const PremiumLimitCards = (props: IPremiumAndLimitCards): JSX.Element => {
  const { assignment, handleChange, setFieldValue } = props;

  return assignment?.insuredSubjects.map(
    (subject: InsuredSubject, subjectIndex: number) => (
      <PremiumAndLimitCard
        key={subjectIndex}
        subject={subject}
        subjectIndex={subjectIndex}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
      />)
  );
};


interface IPremiumAndLimitCardProps {
  prefix: string;
  handleChange: any;
  setFieldValue: any;
}
const PremiumAndLimitCard = (props: IPremiumAndLimitCardProps): JSX.Element => {
  const { subject, subjectIndex, handleChange, setFieldValue } = props;
  const prefix = `insuredSubjects[${subjectIndex}].reinsuranceTerms`;

  return <div key={subjectIndex}>
    {subject?.insured?.label}
    {subject?.reinsuranceTerms?.map(
      (reinsuranceTerm: any, reinsuranceIndex: number) => (
        <PremiumLimitForATerm
          key={reinsuranceIndex}
          accounting={reinsuranceTerm?.accounting}
          prefix={`${prefix}[${reinsuranceIndex}]`}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      )
    )}



  </div>
}

export default PremiumAndLimit;
