import * as React from "react";
import styled from "styled-components";
import { ErrorDiv } from "../../../pages/Dashboard/CreateAssignment/styles";
import { useLocation } from "react-router-dom";
import * as accounting from 'accounting-js';


const StyledLabel = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  label {
    pointer-events: none;
  };
  input, .custom-input {
    outline: none;
    padding-block: 1rem;
    box-sizing: border-box;
    border: none;
    width: 100%;
    border-radius: 0.8rem;
    font-size: 0.8rem;
    padding: 1rem;
    border-bottom: 1px solid #2d2b2b45;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  };
  .error {
    color: red;
    font-size: 0.7rem;
  };
  .title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;

    align-items: center;
    pointer-events: none;
  }
`

const StyledInput = styled.input<{ type: string }>`
  width: 100%;
`;

const StyledCustomInput = styled.div`
  width: 100%;
`;

const Input = (props, ref: React.RefObject<HTMLElement> = null): JSX.Element => {
  const isNumber = props.type === "number";
  return (
    <>
      {isNumber && <CurrencyInput isNumber={isNumber} {...props} data-testid={props.testId} className={`${props.className} custom-input `} />}
      {!isNumber && <StyledInput {...props} ref={ref} data-testid={props.testId}  isNumber={isNumber} onWheel={(e) => e.currentTarget.blur()} autoComplete={"off"} />}
    </>
  );
};




const InputWithRef = React.forwardRef(Input);

interface IInputWithLabelProps extends HTMLInputElement {
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  ref?: any
  errors?: any
  testId?: string
}

export const InputWithLabelWrapper = (props: IInputWithLabelProps): JSX.Element => {
  const { className, label, errors, testId, name,onFocus, ...rest } = props;
  const inputFocusRef = React.useRef(null);

  const { hash } = useLocation();

  const displayPickerOnFocus = (e): void => {
    try {
      inputFocusRef?.current?.showPicker();
      if(onFocus){
        onFocus(e);
      }
    }
    catch (error) {
      console.log("ELement is not supported:", error)
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const fieldIsRequired = function () {
    try {
      if (errors && typeof errors === "string") {
        if (errors.includes("is required")) {
          return "Required*";
        } else {
          return errors;
        }
      }
      return "Required*";
    } catch (error) {
      console.error("Error in getFieldErrorMessage:", error);
      return "Required*";
    }
  }

  React.useEffect(() => {
    if (hash.includes(name)) {
      setTimeout(() => {
        setTimeout(() => {
          inputFocusRef?.current?.focus();
        }, 300)
        inputFocusRef?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 500);
    }
  }, [inputFocusRef?.current, hash])


  return (
    <StyledLabel id={`input-${name}`} className={className}>
      <label data-testid="error-label-wrapper" className="title error-label">
        {props.label && <span data-testid="label">{props.label}</span>}
        {errors && <span data-testid="error" className="error">{fieldIsRequired()}</span>}
      </label>
      <InputWithRef  {...rest}  ref={inputFocusRef} onFocus={displayPickerOnFocus} name={name}testId={testId} onKeyDown={handleKeyPress} />
    </StyledLabel>
  );
};



const CurrencyInput = ({ value, onChange, name, className, testId }) => {
  const inputRef = React.useRef(null);
  const cursorPositionRef = React.useRef(null);
  const formatValue = React.useCallback((val) => {
    return accounting.formatMoney(val, { symbol: "", precision: 2 });
  }, []);

  const handleChange = React.useCallback((e) => {
    const inputElement = e.target;
    cursorPositionRef.current = getCaretPosition(inputElement);
    const numericValue = accounting.unformat(inputElement.innerText);
    e.target.value = numericValue;
    e.target.name = name;
    onChange(e);
  }, [onChange, name]);


  const setText = (): void => {
    setTimeout(() => {
      if (inputRef.current) {
        const formattedValue = formatValue(value);
        const previousFormattedValue = inputRef.current.innerText;
        inputRef.current.innerText = !Number(value) ? 0 : formattedValue;
        if (cursorPositionRef.current !== null) {
          const newCaretPosition = adjustCaretPosition(
            cursorPositionRef.current,
            previousFormattedValue,
            formattedValue
          );
          setCaretPosition(inputRef.current, newCaretPosition);
        }
      }
    }, 5)
  }

  const getCaretPosition = (element) => {
    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let sel;
    if (typeof win.getSelection !== "undefined") {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        const range = sel.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
    }
    return caretOffset;
  };

  const setCaretPosition = (element, position) => {
    const range = document.createRange();
    const sel = window.getSelection();
    if (element.childNodes[0]) {
      range.setStart(element.childNodes[0], Math.min(position, element.childNodes[0].length));
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const adjustCaretPosition = (currentPosition, previousValue, newValue) => {
    let previousNonDigitCount = 0;
    let newNonDigitCount = 0;

    for (let i = 0; i < currentPosition; i++) {
      if (/\D/.test(previousValue[i])) {
        previousNonDigitCount++;
      }
    }

    for (let i = 0; i < newValue.length; i++) {
      if (/\D/.test(newValue[i])) {
        newNonDigitCount++;
      }
    }

    const nonDigitDifference = (newNonDigitCount - previousNonDigitCount) - 1;
    const possiblePosition = currentPosition + (nonDigitDifference > 0 ? nonDigitDifference : 0);
    return possiblePosition;
  };


  React.useEffect(setText, [value])


  return (
    <div
      ref={inputRef}
      role="textbox"
      className={className}
      contentEditable={true}
      onInput={handleChange}
      data-testid={testId}
      onBlur={() => {
        if (value && inputRef?.current?.innerText) {
          inputRef.current.innerText = formatValue(value);
        } else {
          inputRef.current.innerText = 0;
        }
      }}
      onFocus={(e) => {
        if (!value) {
          e.preventDefault();
          e.stopPropagation();
          e.target.innerText = "";
        }
      }}
    />
  );
};


export const InputWithLabel = React.forwardRef(InputWithLabelWrapper);

export default Input;
