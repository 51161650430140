import * as React from "react";
import {
  ModalContainer,
  AvatarIcon,
  StyledButton,
  CloseIconButton,
} from "./styles";
import { type IUser } from "../../api/v1/users/types/user";
import { DashboardContext } from "../../pages/Dashboard";

import { FilledInput, InputAdornment } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CloseIcon from "@mui/icons-material/Close";

interface IModalProps {
  user: IUser;
  logout: () => Promise<void>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = (props: IModalProps) => {
  return (
    <ModalContainer
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
    >
      <CloseIconButton
        onClick={() => {
          props?.setIsOpen(false);
        }}
      >
        <CloseIcon />
      </CloseIconButton>
      <AvatarIcon>
        <div data-testid="avatar-initials" className="icon-initials">
          <span>{props?.user?.firstName[0]}</span>
          <span>{props?.user?.lastName[0]}</span>
        </div>
      </AvatarIcon>

      <p data-testid="avatar-usersname" className="currentuser-name">
        {props?.user?.firstName} {props?.user?.lastName}
      </p>
      <p data-testid="avatar-email" className="currentuser-email">{props?.user?.email}</p>
      {/* <FilledInput
        id="input-with-icon-textfield"
        placeholder="Add another Account"
        sx={{
          marginTop: "62px",
          marginBottom: "66px",
        }}
        startAdornment={
          <InputAdornment id="out" position="start" style={{ width: "250px" }}>
            <PersonAddAlt1Icon />
          </InputAdornment>
        }
      /> */}

      <StyledButton onClick={props?.logout}>
        <p data-testid="signout" className="signout-button">Sign out</p>
      </StyledButton>
    </ModalContainer>
  );
};

const ConnectedModal = () => {
  const { user, logout, setIsOpen } = React.useContext(DashboardContext);

  return <Modal user={user} logout={logout} setIsOpen={setIsOpen} />;
};

export default ConnectedModal;
