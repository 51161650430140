import React from 'react';
import { useParams } from 'react-router-dom';


interface IFileProps {
    file: string
}

const File = ( ) => {
    let {"*": fileName} = useParams();
    // remove last / from file name
    fileName = fileName.slice(0, -1);
    return (
        <iframe  style={{width:"100vw", height: "100vh", border:"none"}} src ={`${process.env.API}/file/${fileName}`}/>
    )
}

export default File
