import * as React from "react";
import { IBroadcastMessage } from "../../../../api/v1/types";
import { HistoryItemsContainer, HistoryItem } from "../styles";
import Delete from "@mui/icons-material/Delete";
import styled from "styled-components";
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import ConfirmationModal from "../../../../components/ConfirmationModal";
import asModal from "../../../../hoc/asModal";

dayjs.extend(utc);
dayjs.extend(timezone);

interface HistoryItemsProps {
  messages: IBroadcastMessage[];
  deleteMessage: (id: string) => void;
}
const DeleteButton = styled(Delete)`
  position: absolute;
  right: 0;
  bottom: 0;
  translate: (50%, -50%);
`;

const ActionConfirmationModal = asModal(ConfirmationModal, "#modal-root");
const StyledActionConfirmationModal = styled(ActionConfirmationModal)`
  > * {
    font-size: 1rem;
    z-index: 999;
    font-family: "Lato", sans-serif;
    h2 {
      margin-top: 0;
    }
  }
`;


const HistoryChild = (props: IBroadcastMessage & {deleteMessage: (id:string) => void}) => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [action, setAction] = React.useState<() => void>(() => {});

  const handleConfimation = (title: string, message: string, action: () => void) => {
    setIsModalOpen(true);
    setTitle(title);
    setMessage(message);
    setAction(() => action);
  }
  return (
    <>
      <HistoryItem isActive={props?.is_active} key={props?.id}>
        <div className="description-date-row">
          <p>{props?.description}</p>
          <p>
            {props?.created_at && dayjs.utc(props?.created_at).local().format('MM/DD/YYYY')}
          </p>
        </div>

        <div className="activedays-username-row">
          <span>No of Days: {props?.no_of_days}</span>
          <span>Created By: {props?.user}</span>
        </div>

        <DeleteButton
          className="delete-icon"
          onClick={() => handleConfimation("Delete Broadcast Message", "Are you sure you want to delete this message?", () => props?.deleteMessage(props?.id))}/>
      </HistoryItem>
      <StyledActionConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={action}
        title={title}
        message={message}
      />
    </>
  )
}

const HistoryItems = (props: HistoryItemsProps) => {


  return (
    <>
      <HistoryItemsContainer data-testid="history-container" className="grid">
        {props.messages &&
          props?.messages.map((message) => (
            <HistoryChild
              key={message?.id}
              deleteMessage={props.deleteMessage}
              {...message}
            />
          ))}
      </HistoryItemsContainer>
    </>
  );
};

export default HistoryItems;
