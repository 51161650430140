import * as React from 'react'
import { getEntityDetails } from '../api/v1/v2/entities/get';

interface IModelToSearch {
    model: string,
    filters: Array<{
        column: string,
        value: string | number,
        operation: string
    }>,
    transform?: {
        [key: string]: string | ((item: any) => string)
    },
    detail_level: string
}

type TSearchFunction = (values: IModelToSearch, optionKey: string) => Promise<void>

const useEntitySearch = (): {
    search: TSearchFunction,
    options: any,
    setOptions: React.Dispatch<React.SetStateAction<any>>,
    loadingOptions: string[],
    setLoadingOptions: React.Dispatch<React.SetStateAction<string[]>>
} => {
    const [options, setOptions] = React.useState({})
    const [loadingOptions, setLoadingOptions] = React.useState<string[]>([])

    const search: TSearchFunction = async (values: IModelToSearch, optionKey: string): Promise<boolean> => {
        setLoadingOptions(prev => [...prev, optionKey])
        try {
            const results = await getEntityDetails(values)

            let processedResults = results;

            // Special processing for currency
            if (optionKey === 'currency') {
                processedResults = results?.map(item => ({
                    ...item,
                    label: `${item.description} (${item.abbreviation})`
                }));
            }

            setOptions((prev) => ({
                ...prev,
                [optionKey]: processedResults
            }))
            return true;
        } catch (err) {
            console.error(err)
            return false;
        } finally {
            setLoadingOptions(prev => prev.filter(item => item !== optionKey))
        }
    }

    return {
        search,
        setOptions,
        options,
        loadingOptions,
        setLoadingOptions
    }
}

export default useEntitySearch