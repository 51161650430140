import * as React from "react";
import styled from "styled-components";
import { BrandedInputWithLabel, StyledInput } from "./AutoComplete/styles";

export const Input = (props) => {
  return <StyledInput data-testid={props.testId} {...props} />;
};

interface IInputWithLabelProps extends HTMLInputElement {
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  ref?: any
  errors?: any
  testId?: string
}
export const InputWithLabel = (props: IInputWithLabelProps) => {
  const { className, label, errors, value, testId, ...rest } = props;
  const inputFocusRef = React.useRef(null);


  const displayPickerOnFocus = () => {
    try {
      inputFocusRef?.current?.showPicker();
    }
    catch (error) {
      console.error("ELement is not supported:", error)
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };


  return (
    <BrandedInputWithLabel className={className} ref={props.ref} value={value}>
      <div className="title error-div">
        <span>{props.label}</span>
        <span className="error">{errors}</span>
      </div>
      <Input autocomplete="off" ref={inputFocusRef} onFocus={displayPickerOnFocus} {...rest} value={value} testId={testId} onKeyPress={handleKeyPress} />
    </BrandedInputWithLabel>
  );
};

export default Input;
