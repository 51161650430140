import * as React from "react";
import {
  AttachMenuContainer,
  TableFilteringOptions,
  ImageIcon,
  Line,
  Triangle,
  WordIcon,
  PDFIcon,
  DocumentTypeContainer,
  TableHeaderCell,
  SubmitTaskButton,
  SubmitButtonWrapper,
  Table,
  MenuWrapper,
} from "../styles";
import CloseIcon from "@mui/icons-material/Close";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { SuccessIcon } from "../../../../components/SuccessIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { CloseIconDiv, TaskSuccess } from "../../AlertsPage/styles";
import { ImageTypes, AttachmentType } from "../../../../global.types";
import { getAssignmentByContractNo } from "../../../../api/v1/assignments/get";
import { saveAttachment } from "../../../../api/v1/emails/post";
import { IAssignment } from "../../../../api/v1/types";
export interface IAttachModalProps {
  expandAttachMenu: boolean;
  handleExpandAttachMenu: () => void;
  onClose: () => void;
  attachment?: any;
  attachmentType: string;
  filename?:string
  attachmentName: string;
  isSuccess:boolean;
  assignment?:IAssignment;
  getAssignmentByContractNo?: (contractNo: string) => Promise<IContract>;
  messageId: string;
  handleSubmit :(documentType:string) => void;
  attachmentId: string;
}

interface IContract {
  contract_no: string;
  broker: string;
}

const isImageType = (type: string) => {
  return Object.values(ImageTypes).includes(type as ImageTypes);
};

const AttachModal = (props: IAttachModalProps): JSX.Element => {
  const { attachment } = props;
  const [selectedAttachmentId, setSelectedAttachmentId] =
    React.useState<string>("");
  const [selectedRow, setSelectedRow] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const initialDocumentType = "Select Document Type";
  const [selectedDocumentType, setSelectedDocumentType] =
    React.useState(initialDocumentType);
  const [attachmentSaved, setAttachmentSaved] = React.useState<boolean>(false);
  const filterDocumentType = [
    "Slip",
    "Original Proposal",
    "Cancellation Notice",
    "Decline Notice",
    "Reject Notice",
    "Confirmation Notice",
    "Inspection Report",
    "Endorsement",
  ];
const rowRef = React.useRef(null);
  const [contracts, setContracts] = React.useState<IContract[]>([
    { number: "123456", broker: "Broker 1" },
    { number: "123457", broker: "Broker 2" },
    { number: "123458", broker: "Broker 3" },
  ]);
  const [filteredContracts, setFilteredContracts] = React.useState<IContract>(
    null,
  );

  const handleSubmit = () => {
    if (selectedRow === null) {
      return;
    }
    const documentInformation = {
      label: 'label',
      fileName: props?.attachmentName,
      assignmentId: filteredContracts?.id,
      messageId: props?.messageId,
      attachmentId: props?.attachmentId,
      type: selectedDocumentType,
    };

    saveAttachment(documentInformation)
    props.onClose();
  };

  const handleSearch = async(e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
        try {
          const response = await props?.getAssignmentByContractNo(searchTerm);
          setFilteredContracts(response);
        } catch (error) {
          console.error("Error searching by Contract No:", error.message);
        }
    }
  };

  const handleSearchIconClick = () => {
    const filtered = contracts.filter(
      (contract) =>
        contract.number.includes(searchTerm) ||
        contract.broker.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setFilteredContracts(filtered);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    attachmentId: React.SetStateAction<string>,
  ) => {
    const clicked = rowRef.current;
    
    setAnchorEl(anchorEl ? null : clicked );
    setSelectedAttachmentId(attachmentId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = () =>
    (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      setSelectedRow(true);
      handleClick(event, attachment?.id);
    };

  const renderAttachmentIcon = (type: string) => {
    switch (type) {
      case AttachmentType.pdf:
        return <PDFIcon>PDF</PDFIcon>;
      case AttachmentType.wordDoc || AttachmentType.wordDocx:
        return <WordIcon>W</WordIcon>;
      case AttachmentType.meet:
        return <CalendarMonthOutlinedIcon />;
      default:
        return null;
    }
  };

  

  return (
    <>
        <AttachMenuContainer
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
          }}
        >
          <div className="container-header">
            <FilePresentOutlinedIcon />
            Attach document to Assignment
            <CloseIcon onClick={props.onClose} style={{ cursor: "pointer" }} />
          </div>
          <div className="container-body">
            <>
              <TableFilteringOptions>
                {!props.assignment && 
                <>
                <SearchIcon onClick={handleSearchIconClick} />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleSearch}
                  placeholder="Search by Contract No."
                /></>
}
              </TableFilteringOptions>
              <Table>
                <thead>
                  <tr className="table-header">
                    <TableHeaderCell>Select</TableHeaderCell>
                    <TableHeaderCell>Co.Number</TableHeaderCell>
                    <TableHeaderCell>Broker</TableHeaderCell>
                    <TableHeaderCell>
                      <MenuWrapper onClick={handleClick}  ref={rowRef}>
                        {selectedDocumentType} <ArrowDropDownIcon />
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}

                        >
                          {filterDocumentType.map((document, index) => (
                            <MenuItem
                              key={index}
                              value={document}
                              onClick={
                                document === initialDocumentType
                                  ? null
                                  : () => {
                                      handleClose();
                                      setSelectedDocumentType(document);
                                    }
                              }
                            >
                              {document}
                            </MenuItem>
                          ))}
                        </Menu>
                      </MenuWrapper>
                    </TableHeaderCell>
                  </tr>
                </thead>
                <tbody>
                  {filteredContracts || props?.assignment ? (
                      <tr
                        onClick={handleRowClick()} // set selected row on click
                      >
                        <td>
                          {selectedRow === true ? ( 
                            <TaskAltOutlinedIcon color="success" />
                          ) : (
                            <CircleOutlinedIcon color="success" />
                          )}
                        </td>
                        <td>{searchTerm}</td>
                        <td>{props.assignment? props.assignment.broker : filteredContracts?.broker_description}</td>
                        <td>
                          <DocumentTypeContainer>
                            {isImageType(props.attachmentType) ? (
                              <ImageIcon>Image</ImageIcon>
                            ) : (
                              renderAttachmentIcon(props.attachmentType)
                            )}
                            <span>{props?.filename ? props.filename : attachment && props.attachmentName}</span>
                          </DocumentTypeContainer>
                        </td>
                      </tr>
                    
                  ) : (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <SubmitButtonWrapper>
                <SubmitTaskButton
                  type="submit"
                  onClick={(event)=> {
                    event.stopPropagation();
                    props.handleSubmit? props.handleSubmit(selectedDocumentType) : handleSubmit()}}
                  form="task-form"
                  disabled={selectedRow === false || selectedRow === null}
                >
                  Submit
                </SubmitTaskButton>
              </SubmitButtonWrapper>
            </>
          </div>
        </AttachMenuContainer>
      
    </>
  );
};

AttachModal.defaultProps = {
  getAssignmentByContractNo: getAssignmentByContractNo,
};

export default AttachModal;
