import * as React from "react";
import LocationForm from "./locationForm";
import styled from "styled-components";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Polygon,
  Polyline,
  Rectangle,
  Circle,
  Marker,
  useMapEvents,
} from "react-leaflet";
import * as L from "leaflet";
import {
  autoCompleteLocation,
  deleteIncludedLocation,
  getAggregatedLocations,
  getNearbyLocations,
  getPolygonData,
} from "../../api/v1/locations";
import { useFormik } from "formik";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Slider from "@mui/material/Slider";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledLocationInput = styled.section<{ isSelected: boolean, showArrow: boolean }>`
  position: relative;
  .selection-container {
    position: absolute;
    background: #ffffffdb;
    padding: 1rem;
    font-size: 0.8rem;
    right: 0;
    box-sizing: content-box;
    margin: 1rem;
    max-height: 80%;
    border-radius: 0.5rem;
    min-width: 320px;
    min-height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    .selected-locations {
      flex-basis: 100%;
      flex-grow: 1;
      .selected-location {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        justify-content: start;
        &:hover {
          background-color: ${(props) =>
            props.isSelected ? "#E7F2FF" : "transparent"};
          cursor: pointer;
        }

        &.selected {
          background-color: ${(props) =>
            props.isSelected ? "#E7F2FF" : "transparent"};
        }
        button {
          margin: 0 5px;
        }
      }
    }
    button.view-more-insured-objects {
      padding: 0.8rem;
      border-radius: 1.25rem;
      box-shadow: 0px 0px 0px 0px transparent;
      border: none;
      font-weight: 600;
      cursor: pointer;
    }
  }
  .aggregation-options {
    position: absolute;
    background: #ffffffdb;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    font-size: 0.8rem;
    left: 0;
    box-sizing: content-box;
    margin: 1rem;
    max-height: 80%;
    border-radius: 0.5rem;
    max-width: 320px;
    min-height: 240px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      list-style: none;
      margin: 0;
    }
  }
  .location-form {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background: #ffffffe8;
    width: 100%;
    box-sizing: border-box;
    form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      .submitSection {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .delete-location {
    font-size: 0.8rem;
    border: none;
    background: transparent;
  }
  .close-icon {
    position: absolute;
    top: 8px;
    right: -4px;
    width: 36px;
  }
  .icon {
    font-size: 1.5rem;
    color: #323232;
  }
  .location {
    font-size: 1rem;
  }
  .contract-no {
    font-size: 0.75rem;
    color: rgba(0, 0, 0, 0.6);
    margin-left: 1rem;
    a {
      color: #3361ff;
    }
  }
  .list {
    padding: 0;
    h5 {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
    }
  }
  .slider-input {
    input {
      border-radius: 1.3rem;
      border: 1.08px #8b8b8b solid;
      padding: 0.6rem 0.2rem;
      width: 80%;
    }
  }
  .range-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
  }
  .range {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  .body {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 19rem;
    }
  .body::-webkit-scrollbar {
  display: none;
    }

  .body {
  -ms-overflow-style: none;
  scrollbar-width: none; 
  }
  .MuiSlider-valueLabel {
     background: #2280EF;
     color: white; 
     border-radius: 0.5rem;
  }
${(props) => props.showArrow && `
  .arrow {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.3);
  flex-direction: column;
  }
  `}
  .MuiSlider-root {
    width: 80% !important;
  }
  
  .MuiSlider-rail {
    width: 100% !important;
  }
`;
const mockOtherLocations = [
  {
    label: "Telluride, CO 81435, USA",
    id: 1,

  },
  {
    label: "Miami, CO 81435, USA",
    id: 2,
  },
  {
    label: "Rodeo, CO 81435, USA",
    id: 3,
  },
];

const mockPlaces = [
  {
    label: "New York, NY, USA",
    id: 1,
    formatted_address: "New York, NY, USA",
    street_address: "123 Main St",
    municipality: "New York",
    state_province: "NY",
    country: "USA",
  },
];
// map geojson shapes to leaflet components
const AvailableShapes = {
  Polygon,
  Polyline,
  Rectangle,
  Circle,
  LineString: Polyline,
};

const MapWrapper = ({
  setFieldValue,
  name,
  values,
  label,
  multiSelect,
  options,
  search,
  included,
  testId,
}): JSX.Element => {
  const [openMap, setOpenMap] = React.useState(false);
  const [currentFocus, setPolygonData] = React.useState([]);
  const [selectedPlace, setSelectedPlace] = React.useState<
    google.maps.places.PlaceResult[]
  >([]);
  const [nearByLocation, setNearbyLocations] = React.useState<any[]>([]);
  const [currentPlaces, setCurrentPlaces] = React.useState<any[]>([]);
  const timeoutRef = React.useRef(null);
  const polygonTimeoutRef = React.useRef(null);
  const mapRef = React.useRef(null);
  const [openStructuredInserts, setOpenStructuredInserts] =
    React.useState(false);
  const initialLoad = React.useRef(false);
  const [isSpecific, setIsSpecific] = React.useState(false);
  const [selectedLocation, setSelectedLocation] = React.useState<boolean>(true);
  const [aggregationOptions, setAggregationOptions] =
    React.useState<boolean>(false);

  const aggregationOptionsForm = useFormik({
    initialValues: {
      distance: 10,
      unit: "km",
    },
  });
  const addressForm = useFormik({
    initialValues: {
      street: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
    },
    onSubmit: async (locationToAdd) => {
      const params = {
        street: locationToAdd?.street || undefined,
        city: locationToAdd?.city?.label || locationToAdd?.city || undefined,
        state: locationToAdd?.state?.label || locationToAdd?.state || undefined,
        country:
          locationToAdd?.country?.label || locationToAdd?.country || undefined,
      };
      const polygon = await getPolygonData(params);
      const geoData = polygon?.geo_data;
      addressForm.resetForm(values);
      setFieldValue(name, [...values, geoData]);
    },
  });

  const findSinglePoint = (coordinates) => {
    if (!Array.isArray(coordinates[0])) return coordinates;
    for (let i = 0; i < coordinates.length; i++) {
      if (Array.isArray(coordinates[i])) {
        const potentialPoint = findSinglePoint(coordinates[i]);
        if (potentialPoint && !Array.isArray(potentialPoint[0])) {
          return potentialPoint;
        }
      }
    }
  };

  const goToPoint = (place) => {
    if (!place) return;
    const coordinates = place?.geoJson?.features[0].geometry.coordinates;
    const [lng, lat] = findSinglePoint(coordinates);
    const map = mapRef.current;
    if (map) {
      map.flyTo([lat, lng], 10, {
        animate: true,
        duration: 1.0,
      });
    }
  };

  const runAutoCompleteLocation = (e) => {
    const text = e.target.value;
    if (timeoutRef?.current) window.clearTimeout(timeoutRef?.current);
    timeoutRef.current = setTimeout(async () => {
      const results = await autoCompleteLocation(text);
      const newOptions = results?.places?.map((result) => {
        return {
          label: result.formatted_address,
          id: result.place_id,
          value: result,
        };
      });
      setCurrentPlaces(newOptions);
    }, 1000);
  };

  const getPolygonForSelectedPlace = async (place: string) => {
    const results = await getPolygonData(place?.formatted_address);
    return results;
  };

  const findNearbyLocations = async (
    lat: number,
    long: number,
    radius: number = 100,
    unit: "km" | "mi" = "km",
  ) => {
    if (!lat || !long) return;
    const response = await getNearbyLocations(lat, long, radius, unit);
    setNearbyLocations(response?.results);
  };

  const handleUpdates = (name, value) => {
    if (name === "country") {
      addressForm.setFieldValue("state", "");
      addressForm.setFieldValue("city", "");
    }
    if (name === "state") {
      addressForm.setFieldValue("city", "");
    }
    addressForm.setFieldValue(name, value);
  };

  const onSelect = async (
    name: string,
    value: google.maps.places.PlaceResult[] | google.maps.places.PlaceResult,
  ) => {
    if (multiSelect) {
      const lastValue = value[value.length - 1];
      const polygon = await getPolygonForSelectedPlace(
        lastValue?.value?.formatted_address,
      );
      const geoData = polygon?.geo_data;
      handleUpdates(name, geoData);
    } else {
      handleUpdates(name, value);
    }
  };

  const recusivelyInverseElementsInArray = (array: any[]) => {
    if (Array.isArray(array && array[0])) {
      return array?.map(recusivelyInverseElementsInArray);
    }
    return array?.reverse();
  };

  const flyToBounds = (geoJSONData) => {
    const bounds = L.geoJSON(geoJSONData).getBounds();
    mapRef.current?.flyToBounds(bounds);
  };

  React.useEffect(() => {
    if (values.length > 0 && !initialLoad?.current) {
      initialLoad.current = true;
      const geoJSON = values?.map((place) => place?.geo_json);
      const lastLocation = geoJSON[geoJSON.length - 1];
      if (geoJSON?.length > 0 && lastLocation) flyToBounds(lastLocation);
    }
  }, [values]);

  const handleDeleteLocation = async (
    locationId: number,
    e: React.MouseEvent,
  ) => {
    try {
      e.stopPropagation();
      e.preventDefault();

      deleteIncludedLocation(Number(locationId), included);
      setFieldValue(
        name,
        values.filter((val) => val?.id !== locationId),
      );
    } catch (err) {
      console.error(err);
    }
  };

  const openAggregateOptions = (e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    setAggregationOptions(!aggregationOptions);
  };

  const handleSelectionClick = (e: React.MouseEvent, place: any) => {
    goToPoint(place?.geoJSON);
    setSelectedLocation(true);
  };

  const handleCloseAggregationOptions = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAggregationOptions(false);
  };

  const handleUnitChange = (event) => {
    const newUnit = event.target.value;
    const distanceInKm = newUnit === "km" ? aggregationOptionsForm.values.distance : aggregationOptionsForm.values.distance * 1.60934;
    aggregationOptionsForm.setFieldValue("unit", newUnit);
    aggregationOptionsForm.setFieldValue("distance", distanceInKm);
  };

  const handleSliderChange = (event, newValue) => {
    aggregationOptionsForm.setFieldValue("distance", newValue);
  };

  React.useEffect(() => {
    setTimeout(() => {
      getAggregatedLocations({
        ...aggregationOptionsForm.values,
        location_ids: mockPlaces.map((val) => val?.id),
      });
    }, 500);
  }, [aggregationOptionsForm.values]);

  const shouldShowArrow = mockOtherLocations.length > 1;
  return (
    <StyledLocationInput isSelected={selectedLocation} showArrow={shouldShowArrow}>
      <LocationForm
        className={"location-form"}
        name={name}
        search={search}
        placeOptions={currentPlaces}
        options={options}
        onChange={isSpecific ? () => {} : runAutoCompleteLocation}
        polygonData={currentFocus}
        setPolygonData={setPolygonData}
        setFieldValue={onSelect}
        label={label}
        isSpecific={isSpecific}
        setIsSpecific={setIsSpecific}
        multiSelect={multiSelect}
        addressForm={{ ...addressForm }}
        testId={testId}
      />
      <section
        hidden={openMap}
        style={{
          position: "relative",
          zIndex: 1000,
          overflow: "hidden",
        }}
      >
        {aggregationOptions && (
          <div
            className="aggregation-options"
            data-testid="selection-container"
            style={{
              zIndex: 2,
            }}
          >
            <div className="close-icon">
              <CloseIcon
                className="icon"
                onClick={handleCloseAggregationOptions}
              />
            </div>
            <h3>
              Please choose the distance range from the selected location for
              your search:
            </h3>
            <div className="body">
            <section>
              <label>
                <input type="radio" value="mi" name="unit" checked={aggregationOptionsForm.values.unit === 'mi'} onChange={handleUnitChange} /> Miles
              </label>
              <label>
                <input type="radio" value="km" name="unit" checked={aggregationOptionsForm.values.unit === 'km'} onChange={handleUnitChange} /> Kilometers
              </label>
            </section>
            <section className="range">
              <Slider
                name="distance"
                getAriaLabel={() => "Temperature range"}
                value={aggregationOptionsForm?.values?.distance || ""}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                />
              <div className="range-input">
                <div className="slider-input">
                  <input
                    type="text"
                    name="distance"
                    value={aggregationOptionsForm?.values?.distance}
                    onChange={aggregationOptionsForm.handleChange}
                    />
                </div>
              </div>
            </section>
            <section className="other">
              <h3>Other Locations Found:</h3>
              <ul className="list">
                <li>
                    <div>
                  <h5>
                    <LocationOnOutlinedIcon className="location" /> {mockOtherLocations[0].label}</h5>
                    </div>
                  <div className="contract-no">
                    Contract No: <a>F0465-T023</a>
                  </div>
                </li>
                <li>
                    <div>
                  <h5>
                    <LocationOnOutlinedIcon className="location" /> {mockOtherLocations[1].label}</h5>
                    </div>
                  <div className="contract-no">
                    Contract No: <a>F0465-T023</a>
                  </div>
                </li>
                <li>
                    <div>
                  <h5>
                    <LocationOnOutlinedIcon className="location" /> {mockOtherLocations[2].label}</h5>
                    </div>
                  <div className="contract-no">
                    Contract No: <a>F0465-T023</a>
                  </div>
                </li>
              </ul>
            </section>
                    </div>
                    {shouldShowArrow && (
                      <div className="arrow">
                    <KeyboardArrowDownIcon />
                    </div>
                    )}
          </div>
        )}
        <div
          className="selection-container"
          data-testid="selection-container"
          style={{
            zIndex: 2,
          }}
        >
          <label>Selected Locations :</label>
          <section className="selected-locations">
            {(mockPlaces || [])?.map(
              (place: (typeof mockPlaces)[0], index: number) => {
                return (
                  <div
                    className="selected-location"
                    key={index.id}
                    onClick={(e) => handleSelectionClick(e, place)}
                  >
                    <LocationOnOutlinedIcon />
                    <div data-testid="location">
                      {`${
                        place?.street_address || place?.streetAddress || ""
                      } ${place?.municipality || ""} ${
                        place?.state_province || place?.stateProvince || ""
                      } ${place?.country}`}
                    </div>
                    <button
                      className="delete-location"
                      data-testid="delete-location"
                      onClick={(e) => handleDeleteLocation(place?.id, e)}
                    >
                      X
                    </button>
                  </div>
                );
              },
            )}
          </section>
          <button
            className="view-more-insured-objects"
            onClick={openAggregateOptions}
          >
            View More Insured Objects in the Area
          </button>
        </div>
        <MapContainer
          center={[24.5551, 81.78]}
          zoom={4}
          maxBounds={[
            [90, 180],
            [-90, -180],
          ]}
          ref={mapRef}
          style={{
            minHeight: "700px",
            maxHeight: "800px",
            width: "100%",
            zIndex: "1",
          }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {[...(values || []), ...(currentFocus || [])]?.map((place, index) => {
            if (!place) return null;
            const geoJson = place?.geoJson || place?.geo_json || {};
            const geoCoding = (geoJson && geoJson["features"]) || geoJson;
            return <MapEntities key={index} features={geoCoding} />;
          })}
        </MapContainer>
      </section>
    </StyledLocationInput>
  );
};

const MapEntities = ({ features }) => {
  if (!features || !Array.isArray(features)) return <></>;
  return features?.map((feature, index) => {
    const list = {
      nearByLocation:
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|abcdef&chf=a,s,ee00FFFF",
      selectedLocation:
        "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF",
    };
    const icon = new L.Icon({
      iconUrl: list.selectedLocation,
      iconSize: [20, 20],
      iconAnchor: [10, 10],
    });
    return (
      <>
        <GeoJSON key={index} data={feature} pathOptions={{ color: "red" }} />
      </>
    );
  });
};

export default MapWrapper;
