import * as React from "react";
import { UnderwritingPoliciesContainer, PDFViewContainer } from "./styles";
import file from "./assets/MandarinReUWPolicy.pdf";

const UnderwritingPolicies = () => {
  // const defaultPlugin = defaultLayoutPlugin();

  return (
    <>
      <h2>Underwriting Policies</h2>

      {/* <UnderwritingPoliciesContainer>
        <PDFViewContainer>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
            <Viewer 
              fileUrl={file} 
              plugins={[defaultPlugin]} 
              defaultScale={1.3} 
            />
          </Worker>
        </PDFViewContainer>
      </UnderwritingPoliciesContainer> */}
    </>
  );
};

export default UnderwritingPolicies;
