import * as React from 'react';
import { TableContainer } from "../../../../../global.styles";
import {
    StyledTableData,
    StyledTableFC,
    PremiumLimitInput,
    PremiumLimitTable
} from "../styles";




interface PremiumLimitTableProps {
    prefix: string;
    handleChange: (e: React.ChangeEvent<any>) => void;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    accounting: any
}
const PremiumLimitForATerm = (props: PremiumLimitTableProps): JSX.Element => {
    const { prefix, handleChange, setFieldValue, accounting } = props;

    const handleCalculations = (): void => {
        const percent = (Number(accounting?.companyShare?.min) / 100)
        const difference = Number(accounting?.limit.max) - Number(accounting?.limit?.min)
        const newMreShare: number = percent * difference
        const newPremium = (Number(accounting?.premium.min) / 100) * newMreShare
        const deductions = (Number(accounting?.deductions?.min) / 100) * newPremium
        const brokerage = (Number(accounting?.brokerage?.min) / 100) * (newPremium - deductions)
        const newDDcs = (Number(accounting?.otherDDCs?.min) / 100) * (newPremium - deductions)
        const netPremium = (newPremium - deductions - brokerage - newDDcs)
        setFieldValue(`${prefix}accounting.companyShare.max`, newMreShare.toFixed(2))
        setFieldValue(`${prefix}accounting.premium.max`, newPremium.toFixed(2))
        setFieldValue(`${prefix}accounting.deductions.max`, deductions.toFixed(2))
        setFieldValue(`${prefix}accounting.brokerage.max`, brokerage.toFixed(2))
        setFieldValue(`${prefix}accounting.otherDDCs.max`, newDDcs.toFixed(2))
        setFieldValue(`${prefix}accounting.netPremium`, netPremium.toFixed(2))
    }

    React.useEffect(() => {
        handleCalculations()
    }, [
        accounting?.companyShare?.min,
        accounting?.limit?.min,
        accounting?.limit?.max,
        accounting?.premium?.min,
        accounting?.deductions?.min,
        accounting?.brokerage?.min,
        accounting?.otherDDCs?.min
    ])

    return (
        <div>
            <TableContainer>
                <section className="table-container">
                    <PremiumLimitTable>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Limit</th>
                                <th></th>
                                <th>MrRe Share</th>
                                <th>Premium</th>
                                <th>Deductions</th>
                                <th>Brokerage</th>
                                <th>Other Ddcs</th>
                                <th>Net Prm.</th>
                                <th>Acct.</th>
                            </tr>
                        </thead>
                        <tbody data-testid="table2">
                            <tr>
                                <StyledTableFC>From</StyledTableFC>
                                <td data-testid="from-limit-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.limit.min`}
                                        value={(((accounting?.limit?.min || 0)))}
                                    />
                                </td>
                                <StyledTableData className='styledtd'>%</StyledTableData>
                                <td data-testid="from-share-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.companyShare.min`}
                                        value={accounting?.companyShare?.min || 0}
                                    />
                                </td>
                                <td data-testid="from-premium-value">
                                    <PremiumLimitInput
                                        type="number" 
                                        onChange={handleChange}
                                        name={`${prefix}accounting.premium.min`}
                                        value={accounting?.premium?.min || 0}
                                    />
                                </td>
                                <td data-testid="from-deductions-value">
                                    <PremiumLimitInput
                                        type="number" 
                                        onChange={handleChange}
                                        name={`${prefix}accounting.deductions.min`}
                                        value={accounting?.deductions?.min ||  0}
                                    />
                                </td>
                                <td data-testid="from-brokerage-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.brokerage.min`}
                                        value={accounting?.brokerage?.min || 0}
                                    />
                                </td>
                                <td data-testid="from-otherddcs-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.otherDDCs.min`}
                                        value={accounting?.otherDDCs?.min || 0}
                                    />
                                </td>
                                <td data-testid="from-netPremium-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.netPremium`}
                                        value={accounting?.netPremium || 0}
                                    />
                                </td>
                                <td data-testid="from-acct-value">
                                    {new Date(accounting?.lastUpdatedDate).toLocaleDateString()}
                                </td>
                            </tr>
                            <tr>
                                <StyledTableFC>To</StyledTableFC>
                                <td data-testid="to-limit-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.limit.max`}
                                        value={accounting?.limit?.max}
                                    />
                                </td>
                                <StyledTableData>q.</StyledTableData>
                                <td data-testid="to-share-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.companyShare.max`}
                                        value={accounting?.companyShare?.max}
                                    />
                                </td>
                                <td data-testid="to-premium-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.premium.max`}
                                        value={accounting?.premium?.max}
                                    />
                                </td>
                                <td data-testid="to-deductions-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.deductions.max`}
                                        value={accounting?.deductions?.max}
                                    />
                                </td>
                                <td data-testid="to-brokerage-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.brokerage.max`}
                                        value={accounting?.brokerage?.max}
                                    />
                                </td>
                                <td data-testid="to-otherddcs-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.otherDDCs.max`}
                                        value={accounting?.otherDDCs?.max}
                                    />
                                </td>
                                <td data-testid="to-netPremium-value">
                                    <PremiumLimitInput
                                        type="number"
                                        onChange={handleChange}
                                        name={`${prefix}accounting.netPremium`}
                                        value={accounting?.netPremium }
                                    />
                                </td>
                                <td data-testid="to-acct-value">
                                    {new Date(accounting?.lastUpdatedDate).toLocaleDateString()}
                                </td>
                            </tr>
                        </tbody>
                    </PremiumLimitTable>
                </section>
            </TableContainer>
        </div>
    )
};


export default PremiumLimitForATerm