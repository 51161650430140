import * as React from "react";
import { IAssignment, ILegacyAssignment } from "../../../api/v1/assignments/types/assignments";
import { AssignmentsContenxt, DashboardContext } from '..';
import { filterBySearchTerm, handleSearch } from "../../../utils/inputSearch/inputSearch";
import AssignmentsSkeleton from "./components/AssignmentsTableSkeleton";
import RenderedAssignmentsTable from "./components/RenderedAssignmentsTable";
import { StyledRightPanel } from "../../../components/Panel/RightPanel";
import styled from "styled-components";
import AssignmentDrillDown from "../AssignmentDrillDown";
import { Outlet, useOutletContext } from "react-router-dom";
import AssignmentStructure from "../../../api/v1/assignments/classes/assignment";
import { set } from "date-fns";


export interface IAssignmentsTableProps {
  assignments: (AssignmentStructure)[];
  priorities: string[] | number[];

  searchParams: {
    property: string;
    keyword: string;
  };
  markAssigmnetAsPriority: (assignmentId: string) => void;
  displayAssignment: (assignmentId: string) => void;
  selectedAssignment: IAssignment;
  setSelectedAssignment: React.Dispatch<
    React.SetStateAction<IAssignment>
  >;
  loadingAssignments: boolean;
  setAssignments: (assignment: IAssignment) => void;
  setSearchParams: (searchParams: {
    property: string;
    keyword: string;
  }) => void;
  setAlerts: (alerts: any) => void;
  totalAssignments: number;
  setPageNumber: (pageNumber: number) => void;
  pageNumber: number;
  pageSize: number;
  goToNextPage: () => void;
  goToPrevPage: () => void;
  setLoadingAssignments: (loading: boolean) => void;
  setPriorities: (priorities: string[] | number[]) => void;
}

export type TSorOrder = "asc" | "desc";

export enum ESelectedColumn {
  "contractNo" = "contract_no",
  "contractType" = "contract_type",
  "date" = "create_date",
  "priority" = "priority",
  "broker" = "broker",
  "kodDgr" = "kod-dgr",
  "dogovor" = "dog-ovor",
  "insurer" = "insurer",
  "cedent" = "cedent",
  "cedentNo" = "cedentNo",
  "underwriter" = "underwriter",
  "age" = "age",
  "status" = "status_type",
  "duration" = "duration",
  "type" = "type",
  "createdDate" = "created-date",
  "startDate" = "start-date",
  "endDate" = "end-date",
  "acceptedDate" = "accepted-date",
  "advanced" = "advanced",
  "limit" = "limit",
}

export const ColumnsNames = {
  [ESelectedColumn.contractNo]: "Contract No.",
  [ESelectedColumn.type]: "Type",
  [ESelectedColumn.contractType]: "Type of",
  [ESelectedColumn.date]: "Inception Date",
  [ESelectedColumn.priority]: "Priority",
  [ESelectedColumn.broker]: "Broker",
  [ESelectedColumn.kodDgr]: "Kod Dgr",
  [ESelectedColumn.dogovor]: "Dogovor",
  [ESelectedColumn.insurer]: "Insurer",
  [ESelectedColumn.cedent]: "Cedent",
  [ESelectedColumn.cedentNo]: "Cedent No.",
  [ESelectedColumn.underwriter]: "Underwriter",
  [ESelectedColumn.age]: "Aging",
  [ESelectedColumn.status]: "Status",
  [ESelectedColumn.duration]: "Duration",
  [ESelectedColumn.createdDate]: "Inception Date",
  [ESelectedColumn.startDate]: "Start Date",
  [ESelectedColumn.endDate]: "End Date",
  [ESelectedColumn.acceptedDate]: "Accepted Date",
  [ESelectedColumn.advanced]: "Advanced",
  [ESelectedColumn.limit]: "Limit",
};


const StyledRightPanelForAssignment = styled(StyledRightPanel)`
  flex-direction: column;
  gap: 1rem;
  background: #f7f8fa;
  padding: 0;
  
  nav {
    padding: 0 2.5rem;
    background: white;
  }
`;

const ConnectedAssignmentTable = (): JSX.Element => {
  const {
    assignments,
    clickOnMessage,
    assignmentId,
    breadcrumbs,
    tab,
    selectAssignment,
    setSelectedAssignment,
    fetchAssignments,
    setAssignments,
    searchParams,
    setSearchParams,
    setAlerts,
    priorities,
    setPriorities,
    displayAssignment,
    loadingAssignments,
    setLoadingAssignments,
    totalAssignments,
    setPageNumber,
    sortParams,
    setSortParams,
    selectedQuickView,
  } = React.useContext(DashboardContext)
  const { goToNextPage, goToPrevPage, pageSize, pageNumber } = React.useContext(AssignmentsContenxt)
  const mappedAssignments: Map<number, IAssignment & ILegacyAssignment> = assignments;
  const assignmentsArray = mappedAssignments instanceof Map ? Array.from(mappedAssignments.values()) : [];

  return (
    <>
      <RenderedAssignmentsTable
        assignments={assignmentsArray}
        fetchAssignments={fetchAssignments}
        setPageNumber={setPageNumber}
        setAssignments={setAssignments}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setAlerts={setAlerts}
        priorities={priorities}
        setPriorities={setPriorities}
        displayAssignment={displayAssignment}
        setSelectedAssignment={setSelectedAssignment}
        loadingAssignments={loadingAssignments}
        goToNextPage={goToNextPage}
        goToPrevPage={goToPrevPage}
        setLoadingAssignments={setLoadingAssignments}
        totalAssignments={totalAssignments}
        pageNumber={pageNumber}
        pageSize={pageSize}
        sortParams={sortParams}
        setSortParams={setSortParams}
        selectedQuickView={selectedQuickView}
      />
      <StyledRightPanelForAssignment isOpen={assignmentId}>
        <Outlet
          context={{
            selectAssignment,
            setSelectedAssignment,
            assignments,
            assignmentId,
            setAssignments,
            clickOnMessage,
            breadcrumbs,
            setPriorities,
            tab,
          }}
        />
      </StyledRightPanelForAssignment>
    </>
  )

}

interface IAssignmentDrillDownProps {
  assignments: AssignmentStructure[];
  assignmentId: number;
  selectAssignment: AssignmentStructure;
  setAssignments: (assignment: IAssignment) => void;
  clickOnMessage: () => void;
  breadcrumbs: any;
  tab: string;
  selectedQuickView: string;
  setPriorities: (priorities: any) => void;
}

export const StyledAssignmentDrillDown = (): JSX.Element => {
  const {
    assignments,
    assignmentId,
    selectAssignment,
    setSelectedAssignment,
    setAssignments,
    clickOnMessage,
    breadcrumbs,
    tab,
    selectedQuickView,
    setPriorities
  } = useOutletContext<IAssignmentDrillDownProps>();
  return (
    <AssignmentDrillDown
      clickOnMessage={clickOnMessage}
      assignmentId={assignmentId}
      assignments={assignments}
      selectAssignment={selectAssignment}
      setSelectedAssignment={setSelectedAssignment}
      breadcrumbs={breadcrumbs}
      selectedTab={tab}
      setAssignments={setAssignments}
      selectedQuickView={selectedQuickView}
      setPriorities={setPriorities}
    />

  )
}

export default ConnectedAssignmentTable;
