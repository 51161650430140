import styled from "styled-components";

export const TableContainer = styled.section`
  background-color: #ffffff;
  border: 1px solid #babfc3;
  margin-top: 1rem;
  overflow: auto;
`;

export const TableHeading = styled.section`
  padding: 1rem;
  display: flex;
  background-color: #dddfe7;
  color: black;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  justify-content: space-between;
  align-items: center;
`;

export const HistoryItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-y: auto;
  height:18.75rem;
  .description-date-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .activedays-username-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const HistoryItem = styled.div<{
  isActive?: boolean;
}>`
  display: inline-flex;
  padding: 15px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  font-size: 10px;
  color: #333;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.1px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-width: 3px;
  background: ${({ isActive }) => isActive && "#CCE5FF"};
  border-left: ${({ isActive }) => isActive && "3px solid #3361FF"};
  border-radius: ${({ isActive }) => isActive && "5px"};
`;

export const CreateNewMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  .title-name {
    margin-top: 37px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .date-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  label {
    color: #3e3e3c;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .date-inputs > div {
    display: flex;
    flex-direction: column;
  }

  .textarea-input {
    display: flex;
    width: 95%;
    height: 100%;    
    padding: 6px 12px 38px 12px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--foundation-gray-05, #dddbda);
    background: var(--foundation-gray-01, #fff);
    margin-bottom: 20px;
  }

  .textarea-input::placeholder {
    font-family: Lato;
    color: #24242480;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .textarea-container {
    margin-top: 20px;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubmitTaskButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 123px;
  height: 35px;
  flex-shrink: 0;
  background-color: #3361FF;;
  border-radius: 0.25rem;
  color: white;
  border-style: solid;
  border-color: #3361FF;;
  cursor: pointer;
`;

export const NoBroadcastMessagesContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 200px;
`