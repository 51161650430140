import * as React  from 'react'

import styled from 'styled-components'


export const CurrencyPageContainer = styled.div`
padding:2%;
`
export const BoxContainer = styled.table`
width:100%;
border-radius: 0px 0px 10px 10px;
background: rgba(217, 217, 217, 0.00);
margin-bottom:2%;
`

export const BoxHeader = styled.div`
border-radius: 8px 8px 0px 0px;
border: 1px solid #B1B3C5;
background: var(--Surface-Default, #FFF);
box-shadow: 0px -1px 0px 0px #E1E3E5;
padding-block:2%;
padding-inline:2%;
display:flex;
flex-direction:row;
`

export const BoxBody = styled.div`
border-radius: 0px 0px 10px 10px;
border-right: 1px solid #B1B3C5;
border-bottom: 1px solid #B1B3C5;
border-left: 1px solid #B1B3C5;
padding-block:2%;
display:flex;
flex-direction:row;
justify-content:space-around;
`

export const SmallBoxContainer = styled.div`
border-radius: 10px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
width:46%;
`



export const SmallBoxHeader = styled.div`
border-radius: 10px 10px 0px 0px;
background: #C6EBD5;
display:flex;
justify-content:center;
align-items:center;
padding-block:1%;
`
export const SmallBoxBtmContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
padding-block:1%;
padding-inline:10%;
flex-direction:column;

`

export const BoxInput = styled.input`
outline:none;
border:none;
border-bottom:2px solid black;
text-align:center;
padding-block:1%;
width:100%;
margin-top:3%
`

export const BoxBtmTxt = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
padding-top:5%;
padding-bottom:10%;
`
export const BoxTextContainer = styled.div`
text-align:center;
display:flex;
flex-direction:column;
`
export const TopText = styled.p`
color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
margin:0;

`
export const BtmText = styled.p`
color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; 
`
export const BtmRedText = styled.p`
color: #A51818;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 20px; /* 125% */
`

export const TopRedTxt = styled.p`
color: #CC0707;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Lato;
font-size: 24px;
margin:0;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 83.333% */

`

export const LabelContainer = styled.tr`
background: #C6EBD5;

display:flex;
flex-direction:row;
justify-content:space-between;
padding-inline:3%;

`

export const CurrencyRow = styled.tr`
display:flex;
flex-direction:row;
justify-content:space-between;
gap:10px;
align-items:center;
padding-inline:3%;
`
export const CurrencyRowContainer = styled.div`
width:100%;
overflow:scroll;
`

export const CurrencyDetailDiv = styled.div`
display:flex;
flex-direction:row;
align-items:center;
`