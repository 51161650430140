import * as React from "react";

const PermissionsTableHeader = () => {
  return (
    <tr>
      <th>Status</th>
      <th>Path</th>
      <th>Read</th>
      <th>Write</th>
      <th>Delete</th>
      <th>Update</th>
      <th>Actions</th>
    </tr>
  );
};

export default PermissionsTableHeader;
