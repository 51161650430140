import * as React from "react";
import PerformanceContainer from "./components/PerformanceContainer";
import RenewalsTable from "./components/RenewalsTable";

export const RenewalsAndEndorsements = () => {

    return (
        <>
            <PerformanceContainer/>
            <RenewalsTable/>
        </>
    );

}

export default RenewalsAndEndorsements;
