import {auth} from '../index';


// @description - This function uses the code from an authorization provider to get an access token
// @param {string} code - The code from the authorization provider
// @returns {Promise<string>} - The access token
export const getAccessToken = async (code: string): Promise<string> => {
    const authResponse = await auth.post('/google/token',{code});
    return authResponse?.data?.access_token;
};
