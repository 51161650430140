import styled from "styled-components";

export const NextStepsItem = styled.li`
  display: flex;
  padding: 0.1rem 0.5rem;
  min-height: 5rem;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: #EFF2F5;
  }
`;

export const NextStepsContainer = styled.ul`
  margin: 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #d8d8d8;
  background: #fcfcfc;
`;

export const StyledAddNotes = styled.div`
  margin-top: 1rem 1px 1px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border: 1px solid #d8d8d8;
  gap: 1px;
  textarea {
    flex-basis: 100%;
    border: none;
    border-right: 1px solid #d8d8d8;
  }
  button {
    flex-basis: 20%;
    background: none;
    border: none;
    box-sizing: border-box;
  }
`;

export const GeneralInformationForm = styled.form`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-template-areas:
    "contract insurer cedent broker"
    "cedentNo start-date end-date accepted-date"
    "comment comment create-date  type"
    "underwriter advanced . . ";
  .contract {
    grid-area: contract;
  }
  .comment {
    grid-area: comment;
  }
  .insurer {
    grid-area: insurer;
  }
  .cedent {
    grid-area: cedent;
  }
  .cedentNo {
    grid-area: cedentNo;
  }
  .broker {
    grid-area: broker;
  }
  .underwriter {
    grid-area: underwriter;
  }
  .type {
    grid-area: type;
  }
  .created-date {
    grid-area: createdDate;
  }
  .start-date {
    grid-area: start-date;
  }
  .end-date {
    grid-area: end-date;
  }
  .accepted-date {
    grid-area: accepted-date;
  }
  .advanced {
    grid-area: advanced;
  }

`;

export const GeneralInformationFormExample = styled.form`
display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas:
    "comment insurer cedent broker"
    "comment comment create-date  type";
  .contract {
    grid-area: contract;
  }
  .comment {
    grid-area: comment;
  }
  .insurer {
    grid-area: insurer;
  }
  .cedent {
    grid-area: cedent;
  }
  .cedentNo {
    grid-area: cedentNo;
  }
  .broker {
    grid-area: broker;
  }
  .underwriter {
    grid-area: underwriter;
  }
  .type {
    grid-area: type;
  }
`

export const ActionableStepsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AddNotesButton = styled.button`
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  svg {
    width: 1rem;
    height: 1rem;
  }
  span {
    font-size: 0.8rem;
  }
`;

export const StyledNotes = styled.div`
  display: flex;
  gap: 1rem 0.5rem;
  background: white;
  width: 50%;
  max-height: 50%;
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.25rem;
  label.note {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: 100%;
    textarea {
      flex-basis: 100%;
      width: 100%;
    }
  }
  section.action {
    display: flex;
    justify-content: flex-end;
  }
`;

export const SelectStyles = () => ({
  "& .MuiSelect-select": {
    textAlign: "center",
    padding: "0",
    backgroundColor: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#6f6f6f",
    borderRadius: "2.5px"
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  },
});

export const MenuPropsStyles = () => ({
  "& .MuiMenu-list": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& .MuiPaper-root": {
    backgroundColor: "#ffffff",
  },
  "& .MuiButtonBase-root": {
    padding: "10px",
    alignContent: "center",
  },
  "& .MuiMenuItem-root": {
    justifyContent: "center",
    borderBottom: "1px solid #C3CAD9",
    color: "#202223",
    fontSize: "16px",
    fontStyle: "normal",
    lineHeight: "16px",
    ":hover": {
      backgroundColor: "#F3F6FF",
      textDecoration: "underline",
      textDecorationColor: "#3361FF",
      color: "#3361FF",
      fontStyle: "normal",
      lineHeight: "16px",
    },
  },
  "& .MuiListSubheader-root": {
    borderTop: "2px solid #97A2B7",
    borderBottom: "2px solid #97A2B7",
  },
});

// this styling is for the asModal HOC
// controls the position of this modal
export const cancelledModalStyle = `
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1000;
`;

// this styling is for the asModal HOC
// controls the position of this modal
export const declinedModalStyle = `
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;
