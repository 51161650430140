import { api } from "../index";
import { Task } from "../assignments/types/assignments";

const prepareTaskData = (assignmentId: string, task: string | string[], selectedDateTime: string) => {

  if (Array.isArray(task)) {
    return { tasks: task.map(t => ({ title: t.task, description: t.task, assignment_id: Number(assignmentId), reminder: selectedDateTime })) };
  } else {
    return { tasks: [{ title: task, description: task, assignment_id: Number(assignmentId), reminder: selectedDateTime }] };
  }
};

export const addTask = async (assignmentId: string | number, task: string | string[], selectedDateTime: string): Promise<Task[]> => {
  const taskData = prepareTaskData(assignmentId, task, selectedDateTime);
  try {
    const response = await api.post("/tasks/assignments", taskData);
    return response.data.tasks;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default addTask;
