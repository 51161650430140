import styled from 'styled-components';
import { CreateAssignmentInput, DropdownArrow } from '../styles';
import * as React from 'react'
import { Popover } from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import NoPermissionsSet from '../../../UserManagement/components/UserProfile/components/NoPermissionsSet';
import asModal from '../../../../../hoc/asModal';
import { Layers } from '../../../../styles/GlobalStyles';

interface IAutocompleteInputProps extends HTMLInputElement {
    value?: string | number,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onSearch?: (text: string, setOptions: React.Dispatch<React.SetStateAction<any[]>>) => Promise<boolean>
    label?: string
    width?: string
    options?: Array<{
        label: string,
        id: string,
    }>,
    className?: string
    open?: boolean
    errors?: any
    multiSelect?: boolean
    testId?: string
    disabled?: boolean
}


const DropDown = styled.div<{ position: 'top' | 'bottom', open: boolean }>`
  cursor: pointer;
  overflow: auto;
  background: none;
  width: 100%;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #B1B3C5;
  font-size: 14px;
  max-height: 250px;
  box-sizing: border-box;
  top: 100%;
  visibility: ${props => props.open ? 'visible' : 'hidden'};

`
const DropDownItem = styled.button`
    z-index:150;
    cursor:pointer;
    display: flex;
    flex-direction: column;
    max-height: 250px;
    overflow: auto;
    background: none;
    width: 100%;
    border: none;
    font-size: 0.8rem;
    padding: 1rem;
    text-align: left;
    &:hover {
        background-color: #E7F2FF;
      }
`

const StyledWrapper = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    position: relative;
    ${DropDown} {
        pointer-events: none;
        ${(props) => {
        if (props.open) {
            return `
                    pointer-events: all;
                    visibility: visible;
                `
        }
        return `visibility: hidden;`
    }
    }
    }
    &:hover {
        z-index: ${Layers.Content};
        ${DropDown} {
            pointer-events: all;
            visibility: visible;
        }
        .error {
            display: block;
            color: red;
            font-size: 0.8rem;
        }
    }
    

`;

export const InfoText = styled.div`
  font-size: 0.8rem;
  color: #6c757d;
  margin-bottom: 1rem;
  margin-top: 0;
`;


const NoPermissionsModal = asModal(NoPermissionsSet, "#modal-root");
const StyledNoPermission = styled(NoPermissionsModal)`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: #fff;
    padding: 2.8rem 2.8rem 2.8rem 2.8rem;
    box-shadow: 0px 1px 20px 0px rgba(137, 148, 153, 0.2);
    transition: height 0.3s ease;
    `;

const AutoCompleteInput = (props: IAutocompleteInputProps): JSX.Element => {
    const {
        options,
        onSearch,
        name,
        value,
        multiSelect,
        setFieldValue,
        className,
        errors,
        testId,
        disabled,
        hideSearch = false,
        keepOpen = false,
        isForeign = true
    } = props;
    const [inputIsAvailable, setInputIsAvailable] = React.useState<boolean>(!hideSearch);
    const [showOptions, setShowOptions] = React.useState<boolean>(keepOpen);
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [modalDisplayed, setModalDisplayed] = React.useState(true);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const previousOption = React.useRef(null)
    let textValue = ""
    if (Array.isArray(value) && value.length) {
        const lastElement = value[value.length - 1]
        textValue = lastElement?.replaceable ? value[value.length - 1]?.label : ""
    } else if (value?.label) {
        textValue = value?.label
    } else if (typeof value === "string") {
        if (value.length) {
            textValue = value
        }
    }

    const handleSelect = (event,
        option: {
            label: string,
            id: string,
        }): void => {
        event?.preventDefault()
        event?.stopPropagation()
        if (!isForeign) {
            setFieldValue(name, option?.label)
            return
        }
        if (multiSelect) {
            const internalValue = option
            if (!internalValue?.deleteId) {
                internalValue.deleteId = `${option.label}${Math.random()}_mock`
            }
            const lengthOfCurrentValues = value?.length
            const editedValue = [...(value || [])]
            const isReplaceable = editedValue[lengthOfCurrentValues - 1]?.replaceable
            if (lengthOfCurrentValues > 0 && isReplaceable) {
                editedValue[lengthOfCurrentValues - 1] = internalValue
                if (editedValue[lengthOfCurrentValues - 1].label === "") {
                    editedValue.pop()
                }
                setFieldValue && setFieldValue(name, editedValue)
            } else {
                editedValue.push(option)
                setFieldValue && setFieldValue(name, editedValue)
            }
        }
        else {
            setFieldValue && setFieldValue(name, option);
        }
    }

    const handleOnSearch = (text: string) => {
        !!onSearch && onSearch(text, name)?.then(
            ()=>{
                setTimeout(()=>{
                    modalValidation()
                },60)
            }
        ).catch((e) => {
            console.log(e)
         })
        setShowOptions(true)
    };

    const changeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (!isForeign) {
            setFieldValue(name, e.target.value)
            return
        }
        if (multiSelect) {
            const internalValue = e.target.value
            const lengthOfCurrentValues = value?.length
            const editedValue = [...(value || [])]
            const deleteId = `${internalValue}${Math.random()}_mock`
            const isReplaceable = editedValue[lengthOfCurrentValues - 1]?.replaceable
            if (lengthOfCurrentValues > 0 && isReplaceable) {
                editedValue[lengthOfCurrentValues - 1].label = internalValue
                editedValue[lengthOfCurrentValues - 1].deleteId = deleteId
                if (editedValue[lengthOfCurrentValues - 1].label === "") {
                    editedValue.pop()
                }
                setFieldValue && setFieldValue(name, editedValue)
            } else {
                editedValue.push({ label: internalValue, id: 0, replaceable: true, deleteId })
                setFieldValue && setFieldValue(name, editedValue)
            }
        }
        else {
            setFieldValue(name, {
                id: null,
                label: e.target.value,
                replaceable: true,
            })

        }
        handleOnSearch(e.target.value)
    }



    const modalValidation = (): void => {
        if (options?.length === 1) {
            if (previousOption?.current?.label === options[0]?.label) return;
            previousOption.current = options[0];
            handleSelect(null, options[0]);
            setModalDisplayed(false);
        } else {
            setShowOptions(true);
            setModalDisplayed(false);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter") {
            event.preventDefault();
        }
    };

    const handleFocusEvent = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.preventDefault()
        event.stopPropagation()
        handleOnSearch("")
    }

    React.useEffect(() => {
        modalValidation()
    }, [options])




    const label = props.label === undefined ? props.label || props?.type || props.value : props.label

    return (
        <>
            <StyledWrapper ref={containerRef} open={keepOpen} className={className} >
                {inputIsAvailable &&
                    <div style={{ position: 'relative' }}>
                        <CreateAssignmentInput
                            hidden={hideSearch}
                            label={label}
                            ref={inputRef}
                            value={textValue}
                            name={name}
                            errors={errors}
                            autocomplete="off"
                            testId={testId}
                            disabled={disabled}
                            onFocus={handleFocusEvent}
                            onKeyDown={handleKeyDown}
                            onChange={changeText}
                        />
                        <DropdownArrow />
                    </div>}
                {
                    showOptions && !!options?.length && <AutoCompleteDropDown data-testid="dropdown" {...props} ref={inputRef}>
                        {options?.filter(option => option.label)?.map((option, index) => (
                            <DropDownItem
                                data-testid="dropdown-item"
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    handleSelect(e, option)
                                    setShowOptions(false)
                                }}
                                key={index}
                            >
                                {option.label}
                            </DropDownItem>
                        ))}
                    </AutoCompleteDropDown>
                }

                {/* <InfoText>
                    <InfoOutlined style={{ fontSize: '0.8rem', marginRight: '0.2rem' }} />
                    {`Enter the ${label} or select from the dropdown`}
                </InfoText> */}

                <StyledNoPermission
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    message={`Select the ${label} from the dropdown`}
                />
            </StyledWrapper>
        </>
    )
}

const StyledButton = styled.button`
  display: flex;
  gap: 0.5rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.25rem;
  width: auto;
  .remove {
    cursor: pointer;
  }
`;

const StyledSelectedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.5rem;   
  ${StyledButton} {
    background-color: #e4eced;
    opacity: 0.8;
  }
`


const DropDownWrapper = (props: Iprops, ref): JSX.Element => {
    return (
        <DropDown>
            {props?.children}
        </DropDown>
    )

}

const AutoCompleteDropDown = React.forwardRef(DropDownWrapper)

export default AutoCompleteInput
