import styled, { keyframes } from "styled-components";

export const StyledAssignment = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;


  .update-animation {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .close {
    grid-area: close;
    position: absolute;
    right: 0;
    top: 0;
    margin: 1rem;
  }
  flex-grow: 1;
`;

export const Menu = styled.div`
  background: #e8e7e7;
  grid-area: menu;
`;

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }

`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

`;

export const Main = styled.div`
  padding: 2.5rem;
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-basis: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  animation: ${fadeIn} 1.2s ease-in-out;
  position: relative;
  .update-loader {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
`;

export const AssignmentFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AssignmentDetailContainer = styled.div`  
  flex-grow: 1;
`

export const assignmentReadonly = styled.div``;


export const StyledAssignmentContent = styled.div``;

export const StyledAssignmentDrillDown = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  * {
    box-sizing: border-box;
  }
  ${StyledAssignmentContent} {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    flex-basis: 100%;
  }
`;
