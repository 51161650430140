import { AxiosResponse } from "axios";
import assignmentRequest from ".";
import { IAssignment, ILegacyAssignment, IAssignmentPostRequest, TStatusType } from "./types/assignments";
import { apiv2 } from "..";

const statusList: any = {
  Preview: 1,
  Accepted: 2,
  declined: 3,
  "in agreement": 4,
  rejected: 5,
  failed: 6,
  confirmed: 7,
  fronting: 8,
  "in progress": 9,
  request: 10,
  "to be approved": 11,
  approved: 12,
  "Not Approved": 13,
  "returned for rework": 14,
  "company check": 15,
}


export interface ICreateAssignmentParams {
  contract_no: string;
  cedent_no: string;
  cedent_id: number;
  broker_id: number;
  insurer_id: number;
  start_date: Date;
  end_date: Date;
  create_date: Date;
  accepted_date: Date;
  comment: string;
  status_date: Date;
  status_id: number;
  underwriter_id: number;
  contract_type_id: number;
  status_type_id: number;
  insured_subjects?: Record<string, any>;
}

export const createAssignment = async (
  assignment: ICreateAssignmentParams, model: string
): Promise<IAssignment & ILegacyAssignment> => {

  const response = await apiv2.post("/assignment/", { assignment, model });
  return response?.data?.assignment;
};

export const createDuplicateAssignment = async (
  assignmentId: number
): Promise<IAssignment & ILegacyAssignment> => {
  const response = await apiv2.post(`/assignment/duplicate/${assignmentId}`);
  return response.data;
}