import {api} from "../index";
import { IAssignment, ILegacyAssignment } from "../types";

export const searchPriority = async (contractNo: string): Promise<IAssignment | ILegacyAssignment> => {
    const response = await api.post(`/assignments/priorities/${contractNo}`);
    return response.data.priority;
}

export const getPriorities = async (): Promise<any> => {
    const response = await api.post(`/assignments/priorities/get`);
    return response.data.priorities;
}
