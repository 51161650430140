import * as React from "react";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../../../../pages/Dashboard";
import UnderwritingPolicies from "../../../../pages/Dashboard/UnderwritingPolicies";
import History from "../../../../pages/Dashboard/History";
import AssignmentsTable, { StyledAssignmentDrillDown } from "../../../../pages/Dashboard/AssignmentTable";
import TodaysPriorties from "../../../../pages/Dashboard/TodayPriorities";
import BroadcastMessage from "../../../../pages/Dashboard/BroadcastMessage";
import { Root } from "../../../../pages/Root";
import Default from "../../../../pages/Dashboard/Default";
import RenewalsAndEndorsements from "../../../../pages/Dashboard/RenewalsAndEndorsements";
import AddNew from "../../../../pages/Dashboard/NewRenewalEndors";
import Inbox from "../../../../pages/Dashboard/Inbox";
import AlertsPage from "../../../../pages/Dashboard/AlertsPage";
import Documents from "../../../../pages/Dashboard/Documents";
import LeadershipKPIs from "../../../../pages/Dashboard/LeadershipKPIs";
import GuacamoleClient from "../../../../pages/Legacy";
import UserManagement from "../../../../pages/Dashboard/UserManagement";
import UserProfile from "../../../../pages/Dashboard/UserManagement/components/UserProfile";
import UserModel from "../../../../pages/Dashboard/UserManagement/components/UserProfile/components/UserModel";
import Permissions from "../../../../pages/Dashboard/UserManagement/components/UserProfile/components/Permissions";
import RDPSettings from "../../../../pages/Dashboard/UserManagement/components/UserProfile/components/RDPSettings";
import UserTable from "../../../../pages/Dashboard/UserManagement/UsersTable";
import File from "../../../../components/File";
import ConnectedDepartmentsNavigation from "../../../../pages/Dashboard/UserManagement/components/Departments";
import ConnectedDepartments from "../../../../pages/Dashboard/UserManagement/components/DepartmentsPage";
import DepartmentPermissions from "../../../../pages/Dashboard/UserManagement/components/DeparmentPermissions";
import DocuSign, { DocuSignContainer } from "../../../../components/Docusign";
import CurrencyPage from "../../../../pages/Dashboard/CurrencyPage";
import CreateAssignment from "../../../../pages/Dashboard/CreateAssignment";
import ManagingDepartments from "../../../../pages/Dashboard/DepartmentHierarchy/Managing";
import AlertsManagementPage from "../../../../pages/Dashboard/AlertsManagementPage";

export const routes = [
  {
    path: "/",
    element: <Default />,
  },
  {
    path: "/assignments",
    element: <AssignmentsTable />,
    children: [
      {
        path: "/assignments/:assignmentId",
        children: [
          {
            path: "/assignments/:assignmentId/:tab",
            element: <StyledAssignmentDrillDown />,
            children: [
              {
                path: "/assignments/:assignmentId/:tab/:riskId",
                element: <></>
              }
            ]
          },
        ],
      },
    ],
  },
  {
    path: "/workflow-management",
    element: <AlertsManagementPage />,
  },
  {
    path: "/priorities",
    element: <TodaysPriorties />,
  },
  {
    path: "/alerts",
    element: <AlertsPage />,
  },
  {
    path: "/documents",
    element: <Documents />,
  },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/underwriting-policies",
    element: <UnderwritingPolicies />,
  },
  {
    path: "/user-management",
    element: <UserManagement />,
    children: [
      {
        path: "/user-management",
        element: <UserTable />,
      },
      {
        path: "/user-management/departments",
        element: <ConnectedDepartmentsNavigation />,
      },
      {
        path: "/user-management/departments/:departmentId",
        element: <ConnectedDepartments />,
        children: [
          {
            path: "/user-management/departments/:departmentId/permissions",
            element: <Permissions />
          },
          {
            path: "/user-management/departments/:departmentId/user-department",
            element: <UserTable />,
          },
          {
            path: "/user-management/departments/:departmentId/department-parents",
            element: <ManagingDepartments />,
          },
        ],
      },
      {
        path: "/user-management/user-profile/",
        element: <UserProfile />,
        children: [
          {
            path: "/user-management/user-profile/:userId",
            children: [
              {
                path: "/user-management/user-profile/:userId/user-model",
                element: <UserModel />,
              },
              {
                path: "/user-management/user-profile/:userId/rdp-settings",
                element: <RDPSettings />,
              },
              {
                path: "/user-management/user-profile/:userId/permissions",
                element: <Permissions />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/broadcast-message",
    element: <BroadcastMessage />,
  },
  {
    path: "/renewals-endorsements",
    element: <RenewalsAndEndorsements />,
  },
  {
    path: "/create-assignment",
    element: <CreateAssignment />,
  },
  {
    path: "/currency-table",
    element: <CurrencyPage />,
  },
  { path: "/inbox", element: <Inbox /> },
  {
    path: "/metrics/",
    element: <LeadershipKPIs />,
    children: [
      {
        path: "/metrics/:tab",
      },
    ],
  },
  {
    path: "/docusign",
    element: <DocuSignContainer />
  },
];

export const ApplicationRoute: RouteObject[] = [
  {
    path: "/",
    element: <Dashboard />,
    children: routes,
  },
  {
    path: "/file/*",
    element: <File />
  },


];

export default ApplicationRoute;