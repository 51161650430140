


type TSearch = Record<string, (text: string, parentId: number) => Promise<boolean>>



type TSearchFunction = (text: string) => Promise<boolean>


interface ISearchOptons {
  searchFunctions?: TSearchFunction
}

const searchWrapper = (search:TSearchFunction, options: ISearchOptons = {}): TSearch => {

  const searchClassOfInsurance = async (text: string): Promise<boolean> =>   {
    return await search(
      {
        model: 'SprClasses',
        filters: [{
          column: 'class_name',
          value: text,
          operation: 'like'
        }],
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'class_name',
        },
        detail_level: 'option',
      }, `classOfInsurance`
    )}

  const searchInclusions = async (text: string): Promise<boolean>  =>  {
    return await search(
      {
        model: "Coverage",
        filters: [
          {
            column: "english_text",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "legacy_id",
          label: "english_text",
        },
        detail_level: "option",
      },
      "inclusions",
    )
  };
  
  const searchExclusions = async (text: string): Promise<boolean> => {
    return await search(
      {
        model: "Coverage",
        filters: [
          {
            column: "english_text",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "legacy_id",
          label: "english_text",
        },
        detail_level: "option",
      },
      "exclusions",
    )
  };

  const searchFormOfReinsurance = async (text: string): Promise<boolean> => {
    return await search(
      {
        model: 'SPR_REVID',
        filters: [{
          column: 'ENGVIDRE',
          value: text,
          operation: 'like'
        }],
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'ENGVIDRE',
        },
        detail_level: 'option'
      }, `forms_of_re_insurance`
    )
  }

  const searchCoverage = async (text: string): Promise<boolean> => {
    return await search(
      {
        model: "Coverage",
        filters: [
          {
            column: "english_text",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "legacy_id",
          label: "english_text",
        },
        detail_level: "option",
      },
      "inclusions",
    )
  };

  const searchSubclass = async (filters): Promise<boolean> => {
    return await search(
      {
        model: 'SPR_STRXVID',
        filters: [...filters],
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'eng',
        },
        detail_level: 'option',
      }, `subclass`
    )
  }

  const searchDeductibleType = async (text): Promise<boolean> => {
    return await search(
      {
        model: 'Spr_FRZ',
        filters: [{
          column: 'Eng_FRZ',
          value: text,
          operation: 'like'
        }],
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'Eng_FRZ',
        },
        detail_level: 'option'
      }, `deductibleType`
    )
  }

  const searchDeductibleBasis = async (text:string): Promise<boolean> => {
    return await search(
      {
        model: 'SPR_FRZOPREDEL',
        filters: [{
          column: 'ENG_OP',
          value: text,
          operation: 'like'
        }],
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'ENG_OP',
        },
        detail_level: 'option'
      }, `deductibleBasis`
    )
  }

  const searchDeductibleValueType = async (text:string): Promise<boolean> => {
    return await search(
      {
        model: 'SPR_TIPRASHET',
        filters: [{
          column: 'Engrachet',
          value: text,
          operation: 'like'
        }],
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'Engrachet',
        },
        detail_level: 'option'
      }, `deductibleValueType`
    )
  }



  const searchBroker = async (text: string): Promise<boolean> => {
    return await search(
      {
        model: "COMPANY",
        filters: [
          {
            column: "NAME_COMPANY",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "id,kod,Kod,KOD,KoD",
          label: "NAME_COMPANY",
        },
        detail_level: "option",
      },
      "brokers",
    )
  }

  const searchInsurer = async (text:string): Promise<boolean> => {
    return await search(
      {
        model: "COMPANY",
        filters: [
          {
            column: "NAME_COMPANY",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "id,kod,Kod,KOD,KoD",
          label: "NAME_COMPANY",
        },
        detail_level: "option",
      },
      "insurers",
    )
  }

  const searchCedent = async (text:string): Promise<boolean> => {
    return await search(
      {
        model: "COMPANY",
        filters: [
          {
            column: "NAME_COMPANY",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "id,kod,Kod,KOD,KoD",
          label: "NAME_COMPANY",
        },
        detail_level: "option",
      },
      "cedents",
    )
  }


  const searchInsuredObject = async (text:string): Promise<boolean> => {
    return await search(
      {
        model: "AssignmentRisks",
        filters: [
          {
            column: "Name_object",
            value: text,
            operation: "like",
          },
        ],
        transform: {
          id: "id,kod,Kod,KOD,KoD",
          label: "Name_object",
        },
        detail_level: "option",
      },
      "insuredObjects",
    )
  }

  const searchAssignmentStatus = async (text:string): Promise<boolean> => {
    return await search(
      {
        model: "AssignmentStage",
        transform: {
          id: "id,kod,Kod,KOD,KoD",
          label: "engstatus",
        },
        detail_level: "option",
      },
      "assignmentStatuses",
    )
  }

  const searchCategory = async (text:string): Promise<boolean> => {
    return await search(
    {
      model: 'SPR_OBJVID',
      filters: [{
        column: 'ENGVIDOBJ',
        value: text,
        operation: 'like'
      }],
      transform: {
        id: 'id,kod,Kod,KOD,KoD',
        label: 'ENGVIDOBJ',
      },
      detail_level: 'option'
    }, 'category'
  )
  }


  const searchSubCategory = async (text: string, parentId: number): Promise<boolean> => {
    const textBody = text && {
      column: 'Engcategory',
      value: text,
      operation: 'like'
    } || null
    const category = parentId && {
      column: 'VidObj',
      value: `${parentId}` || '',
      operation: 'eq'
    } || null
    const filters = [textBody, category].filter((item) => !!item)
    return await search(
      {
        model: 'SPR_OBJCATEGORY',
        filters: filters,
        detail_level: 'option',
        transform: {
          id: 'id,kod,Kod,KOD,KoD',
          label: 'Engcategory'
        }
      }, 'subCategory'
    )
  }


  const searchInsuredSubjectName = async (text: string): Promise<boolean> => {
    return await search(
    {
      model: 'AssignmentRisks',
      filters: [{
        column: 'STRX_L',
        value: text,
        operation: 'like'
      }],
      transform: {
        id: 'STRX_L',
        label: 'STRX_L',
      },
      detail_level: 'option'
    }, 'insuredSubjects'
  )
}
  const searchCurrency = async (text: string): Promise<boolean> => {
    return await search(
    {
      model: 'AssignmentPaymentForms',
      filters: [{
        column: 'EngValut',
        value: text,
        operation: 'like'
      },
      {
        column: 'CheckKurs',
        value: 1,
        operation: 'like'
      }],
      transform: {
        id: 'id,kod,Kod,KOD,KoD',
        abbreviation: 'Valut',
        description: 'EngValut',
        active: 'CheckKurs'
      },
      detail_level: 'option'
    }, 'currency');
  }

  const searchContractTypes = async (text?: string): Promise<boolean> => {
    try {
      return await search(
        {
          model: "AssignmentType",
          filters: [{
            column: 'id',
            value: '3',
            operation: 'neq'
          }],
          transform: {
            id: "id,kod,Kod,KOD,KoD",
            label: "description",
          },
          detail_level: "option",
        },
        "contractTypes",
      );
    } catch (err) {
      console.error(err);
    }
  };

  return {
    searchClassOfInsurance,
    searchExclusions,
    searchFormOfReinsurance,
    searchCoverage,
    searchSubclass,
    searchDeductibleType,
    searchDeductibleBasis,
    searchDeductibleValueType,
    searchInclusions,
    searchBroker,
    searchInsurer,
    searchCedent,
    searchAssignmentStatus,
    searchInsuredSubjectName,
    searchCategory,
    searchSubCategory,
    searchCurrency,
    searchInsuredObject,
    searchContractTypes,
    ...(options?.searchFunctions || [])
  }
}

export default searchWrapper