import * as React from "react";
import { User } from "../../../../context/UserContext";
import { StyledUsersRow } from "../styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelOutlined from "@mui/icons-material/CancelOutlined";
import { useNavigate } from "react-router-dom";
import { useFormikContext, useFormik } from "formik";
import * as Yup from 'yup';
import { IDepartment } from "../../../../api/v1/types";
import { DashboardContext } from "../..";
import InfoTip from "../../../../components/Tooltip";

interface IAddUserToTableProps {
  addUser: (user: User) => void;
  onClose?: () => void;
  addedUser?: boolean;
  setUserAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  isValid?: boolean;
  departments?: { [key: string]: IDepartment };
}


const AddUserToTable = (props: IAddUserToTableProps) => {
  const { addUser, onClose, setUserAdded, isValid, departments } = props;
  const { user } = React.useContext(DashboardContext);
  const navigate = useNavigate();
  const [save, setSave] = React.useState<boolean>(isValid);

  const handleAddUser = async (values) => {
    const newUser = new User();
    for(let key in values){
      newUser[key] = values[key]
    }
    newUser.organizationId = user?.department
    await addUser(newUser);
  }
  
 const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
  department: Yup.string().required("Required")
});


  const formik = useFormik({
    initialValues: {
      isActive: true,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      department: ''
    },
    onSubmit: handleAddUser,
    validationSchema,
    validateOnChange: true,
  });


  const { errors, touched, handleBlur, handleSubmit, handleChange, values } = formik;
  const hasError = typeof errors === "object" && Object.keys(errors).length > 0;
  const hasValues = Object.values(values).every(value => value !== '');

  
  const isFormValid = hasValues && !hasError;


  return (
    <StyledUsersRow
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
      }}
      data-testid="add-user-row"
    >
      <td>
        <input
          type="text"
          data-testid="um-firstName"
          value={values?.firstName}
          onChange={handleChange}
          name="firstName"
        />
        {errors?.firstName && <div className="error">{errors?.firstName}</div>}
      </td>
      <td>
        <input
          type="text"
          data-testid="um-lastName"
          value={values?.lastName}
          onChange={handleChange}
          name="lastName"
        />
        {errors?.lastName && <div className="error">{errors?.lastName}</div>}
      </td>
      <td>
        <input
          type="text"
          data-testid="um-email"
          value={values?.email}
          onChange={handleChange}
          name="email"
        />
        {errors?.email && <div className="error">{errors?.email}</div>}
      </td>
      <td>
        <input
          type="text"
          data-testid="um-password"
          value={values?.password}
          onChange={handleChange}
          name="password"
        />
        {errors?.password && <div className="error">{errors?.password}</div>}
      </td>
      <td>
        <select
          value={values?.department}
          onChange={handleChange}
          name="department"
          data-testid="um-department"
        > <option value="">Select</option> 
          {Object.keys(departments).map((deptId) => (
            <option key={deptId} value={deptId}>
              {departments[deptId].name}
            </option>
          ))}
        </select>
        {errors?.department && (
          <div className="error">{errors?.department}</div>
        )}
      </td>
      <td  colSpan={2}>
        <div className="actions">
          <InfoTip placement="top" title="Add User">
            <button data-testid="add-user-button" className="save-user"onClick={handleSubmit}>
              <TaskAltIcon
                style={{
                  color: isFormValid ?  "#61A87E" : "#B1B3C5" ,
                  padding: "0.2rem",
                  cursor: isFormValid ? "pointer" : "not-allowed" ,
                }}
              />
            </button>
          </InfoTip>
          <InfoTip placement="top" title="Cancel">
            <CancelOutlined data-testid="cancel-add-user" onClick={onClose} />
          </InfoTip>
        </div>
      </td>
    </StyledUsersRow>
  );
};
export default AddUserToTable;
