import * as React from 'react';
import { CompanyCard } from '../styles';

interface ICompanyKPI {
    cardTitle: string;
    avgWorkloadLabel: string;
    avgWorkloadValue: number;
    avgDayLabel: string;
    avgDayValue: number;
    completedSLALabel: string;
    completedSLAValue: number;

}


const CompanyKPI = (props:ICompanyKPI) => {
    return (

            <CompanyCard>
                <h3 className='company-card-title'>{props.cardTitle}</h3>
                <div className='company-card-label-div'>
                <span className='company-card-label'>{props.avgWorkloadLabel}</span>
                <span className='company-card-value'>{props.avgWorkloadValue}</span>
                </div>
                <div className='company-card-label-div'>
                <span className='company-card-label'>{props.avgDayLabel}</span>
                <span className='company-card-value'>{props.avgDayValue}</span>
                </div >
                <div className='company-card-label-div'>
                <span className='company-card-label'>{props.completedSLALabel}</span>
                <span className='company-card-value'>{props.completedSLAValue}%</span>  
                </div>
            </CompanyCard>

    );
};
export default CompanyKPI;