import * as React from 'react';
import { Skeleton } from '@mui/material';

const AlertsPageSkeleton = () => {
  return (
    <>

              {Array.from({ length: 50 }).map((_, currentRow) => (
                <tr key={currentRow}>
                  <td colSpan={100000}>
                    <Skeleton className="skeleton-div" variant="rectangular" height={40} animation="wave" />
                  </td>
                </tr>
              ))}
    </>

  );
};
export default AlertsPageSkeleton;