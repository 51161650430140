import {auth} from "../index";

export const FetchDocusignAuth = (code: string) => {
  const headerToken = process.env.REACT_APP_DOCUSIGN_INTEGRATION_KEY 
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const data = {
    header_token:
      headerToken,
    code: code,
  };
  return auth.post("/docusign/", data, config)
}

export const getEmbeddedUrl = async (accessToken:string) => {
  return auth.post("/docusign/embed", {
    access_token:accessToken,
    account_id:process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID
  })

}