import * as React from "react";
import { RouteObject } from "react-router-dom";
import KPICards from "../KPICards/";
import AssignmentsTable from "../AssignmentTable/";
import Comments from "../Comments";

export const Default: React.FC = () => {
    return (
        <>
            {/* <KPICards /> */}
            <AssignmentsTable />
        </>
    )
}

export default Default;