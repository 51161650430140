import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthenticationRoute from "./routes/AuthenticationRoute";
import ApplicationRoute from "./routes/ApplicationRoute";
import { Root } from "../../pages/Root";

export const routes = [
    ...ApplicationRoute,
    ...AuthenticationRoute
]
export const RootRoute  = {
    path: "/",
    element: <Root/>,
    children: routes
}
const BrowserRouter = createBrowserRouter([RootRoute],{
    basename: "/",
});

export const Routes = <RouterProvider router={BrowserRouter} />;
