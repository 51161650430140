import * as React from "react";
import Popover from "@mui/material/Popover";
import styled from "styled-components";
import FileOptions from "./FileOptions";

const StyledPopover = styled(Popover)`
padding: 1rem;
.MuiPaper-root{
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
  position: relative;
  min-height: 16px;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  outline: 0;
  width: 15rem;
  overflow:unset;
`;

interface IFilePreviewPopover {
  ref: any;
  editFileTypes: (name: string, value: any) => void;
  setIsEditing: (value: boolean) => void;
  isEditing: boolean;
  initiateDownload: (e: React.MouseEvent, url: string) => void;
  objectURL: string;
  openFile: (e: React.MouseEvent) => void;
  fileName: string;
  handleEditName: () => void;
  handleDeleteFile: (fileName: string) => void;
  fileType: string;
  showDocumentTypOpt: boolean;
  setShowDocumentTypeOpt: (value: boolean) => void;
}

const FilePreviewPopover = (props: IFilePreviewPopover): JSX.Element => {
  const {
    fileType,
    objectURL,
    isEditing,
    showDocumentTypOpt,
    handleDeleteFile,
    editFileTypes,
    setIsEditing,
    initiateDownload,
    openFile,
    handleEditName,
    setShowDocumentTypeOpt,
  } = props;
  const ref = React.useRef(null);
  const toggleEdit = (e): void => {
    e.preventDefault();
    setIsEditing(!isEditing);
    setShowDocumentTypeOpt(false);
  };

  return (
    <div ref={ref} data-testid="popover-wrapper">
      <StyledPopover
        id="mouse-over-popover"
        open={isEditing}
        anchorEl={ref?.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={toggleEdit}
        disableRestoreFocus
        style={{ zIndex: 999 }}
        >
        <FileOptions
          setIsEditing={setIsEditing}
          fileType={fileType}
          handleEditName={handleEditName}
          openFile={openFile}
          editFileTypes={editFileTypes}
          handleDeleteFile={handleDeleteFile}
          toggleEdit={toggleEdit}
          initiateDownload={initiateDownload}
          objectURL={objectURL}
          showDocumentTypOpt={showDocumentTypOpt}
          setShowDocumentTypeOpt={setShowDocumentTypeOpt}
        />
      </StyledPopover>
    </div>
  );
};

export default FilePreviewPopover;
