import {api} from "../index";
import { Task } from "../types";

export const getHistory = async(assignmentId?: any): Promise<Task[]> => {
  assignmentId = assignmentId ? "/"+assignmentId : "/";
   try {
     const response = await api.get(`tasks/history${assignmentId}`); 
     return response.data.tasks;
   } catch (error) {
     console.error('Error retrieving history tasks:', error);
   }
 };