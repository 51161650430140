import {api} from "../index";
import { IAssignmentComment } from "../types";

export const getComments = async (taskId: number): Promise<IAssignmentComment[]> => {
  try{
    const response = await api.get(`/comments/tasks/${taskId}`);
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
   }  
}
