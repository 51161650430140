import * as React from 'react';
import Button from "../../../../../../../components/FormElements/Button";
import styled from 'styled-components';
import { Menu } from '../../../../styles';

const StyledMenu = styled(Menu)`
	padding: 1rem;
	button {
		color: #3361FF;
		&:hover {
			color: black;
			font-weight: 500;
		}
	}
`

interface IAlertsPopoverProps {
    assignment: any;
}

const AlertsPopover = (props: IAlertsPopoverProps) => {
    return (
        <StyledMenu>
            <div style={{ color: 'red' }}>{props.isLate ? 'Assignment is late' : ''} </div>
            <div style={{ color: '#3361FF' }}>{props.tags ? 'New tag' : ''} </div>
            <div> {props?.assignment?.unseenComments ? `New Comments: ${props?.assignment?.unseenComments}` : ''}</div>
        </StyledMenu>
    )
};


export default AlertsPopover