import * as React from 'react';
import {PerformanceCardHeader, PerformanceCardWrapper } from '../styles';
import KPICard from '../../KPICards/KPICard';
import { DashboardContext } from '../..';
import { IKPI } from '../../../../api/v1/types';
interface IPerformanceContainerProps {
    kpis: IKPI[];
}

const PerformanceContainer = (props: IPerformanceContainerProps) => {
    return (
        <PerformanceCardWrapper data-testid="performance-wrapper">
           <PerformanceCardHeader>
            Performance 
            </PerformanceCardHeader> 
                {/* <KPICard label="Total Renewals" value={props?.kpis?.total_renewals_offers?.value}  /> */}
                {/* <KPICard label="Total Endorsements" value={props?.kpis?.total_endorsements?.value} />   */}
        </PerformanceCardWrapper>
    );
}

const ConnectedPerformanceContainer = () => {
    const {
        kpis
    } = React.useContext(DashboardContext)

    return <PerformanceContainer
        kpis={kpis} />
}

export default ConnectedPerformanceContainer;