import * as React from 'react';
import {
  Table,
  TableContainer,
  TableHeading,
} from "../styles";
import { Skeleton } from '@mui/material';




const RESkeleton = () => {
    return (
        <TableContainer data-testid="table-container">
      <TableHeading>
         <h3>Renewals and Endorsements</h3>
       </TableHeading>
      <section className="table-container">
        <Table>
          <tr>
         <Skeleton className="skeleton-div" height={30} variant="text"  animation="wave"/>
          </tr>
          <tbody>
            {Array.from({length:10}).map((_, currentRow) => (
              <tr key={currentRow}>  
                <td colSpan={10}>
                <Skeleton className="skeleton-div" variant= "rectangular" height={40} animation="wave" />
               
              </td>        
                </tr>
        ))}
        </tbody>
        </Table>
            </section>
        </TableContainer>


           


    )
    }
export default RESkeleton;