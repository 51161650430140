import * as React from 'react';
import { Skeleton } from '@mui/material';
import styled from 'styled-components';

const SkeletonContainer = styled.div`
    display: flex;
    height: 100%;
    padding: 3rem;
`;
const LoadingDocuments = () => {
    return (
        <SkeletonContainer>
            {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} style={{ marginRight: '16px' }}>
                    <Skeleton variant="rectangular" width={200} height={200} animation="wave" />
                </div>
            ))}
        </SkeletonContainer>
    );
};

export default LoadingDocuments;
