import * as React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArchiveIcon from "@mui/icons-material/Archive";
import ReplyIcon from "@mui/icons-material/Reply";
import { StyledMenu } from "../styles";
import { IEmail } from "../../../../api/v1/types";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

interface IEmailOptionsProps {
 email: IEmail;
};

const EmailOptions = (props:IEmailOptionsProps) => {
  const handleGoToGoogleEmails = () => {
    window.open(`https://mail.google.com/mail/u/0/#inbox/${props?.email?.id}`, "_blank");
  };

  const downloadEmailAsPDF = () => {
    const emailBodySourceCode = props?.email?.body;
  
    if (emailBodySourceCode) {
      const tempDiv = document.createElement('div');
      tempDiv.style.position = 'absolute';
      tempDiv.style.maxWidth = '800px';
      tempDiv.style.width = '100%';
      tempDiv.style.padding = '20px';
      tempDiv.innerHTML = emailBodySourceCode;
      document.body.appendChild(tempDiv);
  
      setTimeout(() => {
        html2canvas(tempDiv, {
          windowWidth: tempDiv.scrollWidth,
          windowHeight: tempDiv.scrollHeight
        })
        .then((canvas) => {
          const imgWidth = 210; 
          const imgHeight = canvas.height * imgWidth / canvas.width;
          let heightLeft = imgHeight;
          const pdf = new jsPDF('p', 'mm', 'a4');
          let position = 0;
  
          pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pdf.internal.pageSize.height;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pdf.internal.pageSize.height;
          }
          pdf.save('email.pdf');
          document.body.removeChild(tempDiv);
        })
        .catch(err => {
          console.error('Error exporting PDF:', err);
          document.body.removeChild(tempDiv);
        });
      }, 150);
    }
  };
  
  

  return (
    <StyledMenu>
      <div>
        <p className="menu-icons" onClick={downloadEmailAsPDF}> 
          <DownloadIcon />
          Download
        </p>
        {/* <p className="menu-icons">
          <DeleteOutlineOutlinedIcon />
          Delete
        </p>
        <p className="menu-icons">
          <ArchiveIcon />
          Archive
        </p> */}
        <p className="menu-icons" onClick={handleGoToGoogleEmails}>
          <ReplyIcon />
          Go to Google Emails
        </p>
      </div>
    </StyledMenu>
  );
};
export default EmailOptions;
