import * as React from "react"
import { IAssignment, ILegacyAssignment, ELegacyAssignmentProps } from "../../../api/v1/types";
import { DashboardContext } from "..";
import NoPrioritiesPage from "./components/NoPrioritiesPage";
import TodaysPrioritiesSkeleton from "./components/TodaysPrioritiesSkeleton";
import TodaysPrioritiesView from "./components/TodaysPriorities";
import { Task } from "../../../api/v1/types"

export interface ITodaysPrioritiesProps {
  priorities: number[],
  setPriorities: (priorities: number[]) => void;
  clickOnMessage: () => void;
  expandTaskMenu?: boolean;
  expandAccordion?: number;
  setExpandAccordion?: React.Dispatch<React.SetStateAction<number>>;
  handleExpandAccordion?: (event: React.MouseEvent<HTMLElement>) => void;
  handleExpandAddTaskMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  assignments: { [key: string]: IAssignment & ILegacyAssignment };
  displayAssignment: any;
  selectedQuickView: string;
  loadingPriorities: boolean;
}

export const TodaysPriorities = (props: ITodaysPrioritiesProps) => {
  const [expandAll, setExpandAll] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [tasks, setTasks] = React.useState<(Task)[]>([]);
  const { loadingPriorities } = props;

  const currentUserView = !(props?.priorities?.length) ? <NoPrioritiesPage /> : <TodaysPrioritiesView priorities={props?.priorities} setPriorities={props?.setPriorities} displayAssignment={props?.displayAssignment} tasks={tasks} setTasks={setTasks} clickOnMessage={props.clickOnMessage} />
  const viewToUse = loadingPriorities ? <TodaysPrioritiesSkeleton priorities={props?.priorities} /> : currentUserView;
  return (
    <>
      {viewToUse}
    </>
  )
};

const ConnectedTodaysPriorities = () => {

  const {
    priorities,
    setPriorities,
    assignments,
    clickOnMessage,
    displayAssignment,
    selectedQuickView,
    selectAssignment,
    setSelectedAssignment,
    loadingAssignments,
    loadingPriorities
  } = React.useContext(DashboardContext);

  return <TodaysPriorities
    priorities={priorities}
    setPriorities={setPriorities}
    assignments={assignments}
    clickOnMessage={clickOnMessage}
    displayAssignment={displayAssignment}
    selectedQuickView={selectedQuickView}
    setSelectedAssignment={setSelectedAssignment}
    loadingAssignments={loadingAssignments}
    loadingPriorities={loadingPriorities}
  />
}
export default ConnectedTodaysPriorities;

