import  React from "react";
import {  ButtonHTMLAttributes } from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  cursor: pointer;
`;
interface IButtonProps {
  children: React.ReactNode;
}
const Button = (
  props: IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  const { children, ...buttonProps } = props;

  return <StyledButton {...buttonProps}>{props.children}</StyledButton>;
};

export default Button;
