import * as React from "react";
import NoAlertsView from "./components/NoAlertsView";
import RenderedAlerts from "./components/RenderedAlerts";
import AlertsSkeleton from "./components/AlertsSkeleton";
import useAlerts from "../../../hooks/useAlerts";

export enum EStatus {
  read = "read",
  unread = "unread",
}
export enum EPriority {
  high = "high",
  medium = "medium",
  low = "low",
}

export interface IAlerts {
  id: number;
  name: string;
  type: string;
  description: string;
  viewed: boolean;
  assignmentId: number;
  // initialStatus: //user.id;
  // users: any;
  contractNo: string;
  broker: string;
  // priority: EPriority;
  created_at: string;
  expires_at: string;
}

export interface IAlertsProps {
  error: string;
  isOpen: boolean;
  assignments: object;
  handleAlertsClose: () => void;
}
const Alerts = (props: IAlertsProps) => {
  const { isOpen, handleAlertsClose, assignments } = props;
  const intervalRef = React.useRef<any>();
  const firstHour = new Date();
  firstHour.setHours(0, 0, 0, 0);
  const lastMinuteofDay = new Date().setHours(23, 59, 59, 999); 
  const {
    alerts,
    setAlerts,
    markAlertAsViewed,
    loadingAlerts,
    alertsCount,
    todaysAlertsCount,
    fetchAlerts,
    alreadyLoaded,
  } = useAlerts({
    limit: 200,
    offset: 0,
    viewed: false,
    date_range: {
      start_date: firstHour,
      end_date: lastMinuteofDay,
    }
  })

  const periodicallyCheckForAlerts = () => {
    intervalRef.current = setInterval(fetchAlerts, 10000)
    return () => clearInterval(intervalRef.current)
  };

  React.useEffect(() => {
    fetchAlerts();
    periodicallyCheckForAlerts();
    return () => clearInterval(intervalRef.current)
  }, []);

  const _markAlertAsViewed = (alert_id: number) => {
      markAlertAsViewed(alert_id).then(()=>{
        fetchAlerts();
      }).catch((err)=>{
        console.error(err)
      })
  }
  
  const currentAlertsView = alerts  && !alerts?.length ? (
    <NoAlertsView />
  ) : (
    <RenderedAlerts
      alerts={alerts}
      setAlerts={setAlerts}
      markAlertAsViewed={_markAlertAsViewed}
      handleAlertsClose={handleAlertsClose}
      alertsCount={alertsCount}
      todaysAlertsCount={todaysAlertsCount}
    />
  );
  const viewToUse = !alreadyLoaded || (loadingAlerts && !alreadyLoaded) ? <AlertsSkeleton /> : currentAlertsView;
  return <>{viewToUse}</>;
};

export default Alerts;