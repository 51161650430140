import * as React from "react";
import asModal from "../../../../../hoc/asModal";
import styled, { keyframes } from "styled-components";
import AutoCompleteInput from "../../../CreateAssignment/components/AutocompleteInput";
import AttachModal from "../../../Inbox/components/AttachModal";
import { RenameFile } from "../components/FilePopover";

export const IconWrapper = styled.div`
  transform: rotate(45deg);
  margin-left: 1rem;
`;

export const DocumentUploadWrapper = styled.label`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
  input[type="file"] {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
`;

export const StyledFilesDiv = styled.div`
  padding: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  > * {
    position: relative;
      flex-basis: 100%;
      max-width: 25%;
  }
  @media (max-width: 1200px) {
  > * {
    position: relative;
      flex-basis: 100%;
      max-width: 50%;
  }
  @media (max-width: 768px) {
  > * {
    position: relative;
      flex-basis: 100%;
      max-width: 100%;
  }
  p {
    margin: 2rem;
  }
`;

export const StyledFile = styled.div<{
  isEditing: boolean;
}>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  gap: 1rem;
  transition: all 0.5s ease;
  h4,
  h5 {
    margin: 0.25rem;
    white-space: nowrap;
    text-wrap-mode: nowrap;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: auto;
  }
  .image {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    iframe {
      width: 200px;
      padding: 1.5rem;
      box-sizing: border-box;
      height: 250px;
      border: 1px solid transparent;
      pointer-events: none;
    }
  }
  .action {
    position: absolute;
    right: 0px;
    transform: translate(50%, 0);
    display: none;
    top: 0;
    background-color: white;
    gap: 0.5rem;
    z-index: 100;
    button {
      font-size: 0.8rem;
      background: none;
      border: none;
      cursor: pointer;
    }
  }
  .edit-section {
    display: none;
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    height: 100%;
    overflow: hidden;
    min-width: 150px;
    left: 100%;
    top: 0;
    background-color: white;
    padding: 1rem;
    color: black;
    text-align: left;
    .edit-dropdown {
      flex-basis: 100%;
      overflow: hidden;
      scroll-padding-bottom: 1rem;
    }
    > button {
      border: 1px solid #186ade;
      background: none;
      background-color: none;
      border-radius: 0.5rem;
      &:hover {
        background-color: #186ade;
        color: white;
      }
    }
  }
  &:hover {
    z-index: 100;
    .action {
      display: block;
    }
    iframe {
      border: 1px solid #cfc6c6;
    }
  }

  ${(props) =>
    props.isEditing &&
    `
      .action {
        display: none;
        visibility: hidden;
      }
      .edit-section {
        display: flex;
        visibility: visible;
      }
    `}
`;
export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  justify-content: center;
  text-align: center;
  h4 {
    .hide {
      display: none;
    }

    .show {
      display: block;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    // gap: 0.5rem;
    justify-content: center;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  button {
    background: none;
    border: none;
  }
`;

export const DocumentModal = asModal(AttachModal, "#modal-root");
export const StyledDocumentModal = styled(DocumentModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 3rem;
`;

export const StyledDocumentsContainer = styled.section`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  height: 100%;
`;

export const StyledDocuments = styled.div<{
  loadingDocuments: boolean;
  totalDocuments: number;
}>`
  border: 5px dashed rgba(120, 134, 162, 0.46);
  overflow-y: scroll;
  border-radius: 0.9375rem;
  color: #DBDBDB;
  position: relative;
  margin-bottom: 10rem
  scrollbar-width: thin; 
  scrollbar-color: transparent transparent;
  flex-basis: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  p {
    color: black;
  }
`;


export const TableContainer = styled.section`
  background-color: #ffffff;
  overflow: hidden;
  border: 1px solid #babfc3;
  border-radius: 0.5rem;
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  .table-container {
    overflow: auto;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: 100%;
  font-size: 0.8rem;
  position: relative;

  tr {
    border: 1px solid #e1e3e5;
    border-width: 1px 0px;
  }

  th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    text-align: left;
    font-size: 0.75rem;
    color: #6d7175;
    background-color: #cce5ff;
    padding: 0.4rem 15px;
  }

  td {
    color: #202223;
    padding: 1rem;
  }

  tbody tr:hover {
    cursor: pointer;
  }

  .styled-headers {
    background-color: #cce5ff;
  }
`;

export const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const LoadingFilePreview = styled.div<{ loading: boolean }>`
  min-width: 10rem;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 10rem;
  ${(props) => {
    const { loading } = props;
    return loading ? `opacity:1;` : `opacity:0;`;
  }}
  transition: opacity 2s;
  background: linear-gradient(270deg, #d3d3d3, #a9a9a9);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 5s ease infinite;  display: flex;
`
export const PreviewCover = styled.div<{ loading: boolean }>`
  min-width: 10rem;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 10rem;
  opacity: 0;
  z-index: 100;
`


export const StyledAttachButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #646570;
  border-radius: 0.3125rem;
  background-color: #FFF;
  color: #646570;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  cursor: pointer;
  label {
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0.2rem;
  }
`;
