import styled from "styled-components";

export const GeneralInformationForm = styled.form`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(, 1fr);
  grid-template-areas:
    "form class"
    "subclass .";
  .form {
    grid-area: form;
  }
  .expiry {
    grid-area: class;
  }
  .subclass {
    grid-area: subclass;
  }
`;

export const FormGroupContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const DurationInformationForm = styled.form`
`;

export const CurrencyInformationForm = styled.form`
`;
