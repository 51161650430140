import React from "react";
import styled from "styled-components";
import {Layers} from '../../pages/styles/GlobalStyles';

export const StyledRightPanel = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  z-index: ${Layers.Modal};
  padding: 1rem;
`;
