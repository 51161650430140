import * as React from "react";
import { CreateAssignmentContainer } from "./ContractType";
import styled from "styled-components";
import AutoCompleteInput from "./AutocompleteInput";
import { CreateAssignmentInput } from "../styles";
import { UserContext } from "../../../../../context/UserContext";
import type AssignmentStructure from "../../../../../api/v1/assignments/classes/assignment";
export const CreateAssignmentInputContainer = styled.div`
  height: 7%;
  border: 1px solid black;
  display: flex;
  align-items: center;
  gap: 25;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-block: 2%;
  align-items: center;
`;

export const INDSmallInputContainer = styled.div`
  width: 15%;
`;
export const INDMediumInputContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;
export const INDLargeInputContainer = styled.div`
  width: 50%;
`;

export const InputStyles = {
  paddingBlock: "10px",
};

export const InitialInput = styled.div`
outline: none;
  padding-block: 1rem;
  box-sizing: border-box;
  border: none;
 border-bottom-width: medium;
border-bottom-style: none;
border-bottom-color: currentcolor;
  width: 100%;
  border-radius: 0.8rem;
  font-size: 0.8rem;
  padding: 1rem;
  border-bottom: 1px solid #2d2b2b45;
  transition: all 0.3s ease;
cursor: pointer;
}
`;

export const GeneralInformationWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;

interface IGeneralInformation {
  assignment: AssignmentStructure;
  values: any;
  handleChange: any;
  search: any;
  options: any;
  setFieldValue: any;
  handleSelection: any;
  _handleChange: any;
  createAssignment: boolean;
  searchFunctions: Record<string, (searchTerm: string) => void>;
}

interface IField {
  label: string;
  name: string;
  component: string;
  options: string;
  value: string;
  conditionalRender?: string;
  setFieldValue?: boolean;
  errors?: string;
  testId: string;
  disabled?: boolean;
}

const fields: IField[] = [
  {
    "label": "Underwriter",
    "name": "underwriterId",
    "component": "AutoCompleteInput",
    "conditionalRender": "renderUnderwriterInput",
    "setFieldValue": true,
    "onSearch": "searchUnderwriters",
    "value": "underwriterId",
    "options": "underwriters",
    "errors": "underwriterId",
    "testId": "underwriterInput"
  },
  {
    "label": "Broker",
    "name": "brokerId",
    "component": "AutoCompleteInput",
    "setFieldValue": true,
    "value": "brokerId",
    "options": "brokers",
    "onSearch": "searchBroker",
    "errors": "brokerId",
    "testId": "brokerInput",
  },
  {
    "label": "Insurer",
    "name": "insurerId",
    "component": "AutoCompleteInput",
    "setFieldValue": true,
    "value": "insurerId",
    "options": "insurers",
    "onSearch": "searchInsurer",
    "errors": "insurerId",
    "testId": "insurerInput"
  },
  {
    "label": "Cedent",
    "name": "cedentId",
    "component": "AutoCompleteInput",
    "setFieldValue": true,
    "value": "cedentId",
    "options": "cedents",
    "onSearch": "searchCedent",
    "errors": "cedentId",
    "testId": "cedentInput"
  },
  {
    "label": "Create Date",
    "name": "createDate",
    "component": "CreateAssignmentInput",
    "conditionalRender": "renderCreateDate",
    "type": "date",
    "disabled": true,
    "value": "createDate",
    "onChange": "handleChange",
    "errors": "createDate",
    "testId": "createDateInput"
  },
  {
    "label": "Start Date",
    "name": "startDate",
    "component": "CreateAssignmentInput",
    "type": "date",
    "onKeyPress": "handleEnterKeyDown",
    "value": "startDate",
    "onChange": "handleChange",
    "errors": "startDate",
    "testId": "startDateInput"
  },
  {
    "label": "Expiration Date",
    "name": "endDate",
    "component": "CreateAssignmentInput",
    "type": "date",
    "onKeyPress": "handleEnterKeyDown",
    "value": "endDate",
    "onChange": "handleChange",
    "errors": "endDate",
    "testId": "endDateInput"
  },
  {
    "label": "Accept Date",
    "name": "acceptDate",
    "component": "CreateAssignmentInput",
    "conditionalRender": "renderAcceptDate",
    "type": "date",
    "disabled": true,
    "onKeyPress": "handleEnterKeyDown",
    "value": "acceptDate",
    "onChange": "handleChange",
    "errors": "acceptDate",
    "testId": "acceptDateInput"
  },
  {
    "label": "Comments",
    "name": "comment",
    "component": "CreateAssignmentInput",
    "value": "comment",
    "onChange": "handleChange",
    "errors": "comment",
    "testId": "comment"
  },
  {
    "label": "Last Status Update Date",
    "name": "statusDate",
    "component": "CreateAssignmentInput",
    "conditionalRender": "renderLastStatusUpdateDate",
    "type": "date",
    "disabled": true,
    "onKeyPress": "handleEnterKeyDown",
    "value": "statusDate",
    "onChange": "handleChange",
    "errors": "acceptDate",
    "testId": "statusDateInput"
  },
  {
    "label": "Status",
    "name": "statusId",
    "component": "AutoCompleteInput",
    "conditionalRender": "renderStatus",
    "setFieldValue": true,
    "value": "statusId",
    "options": "assignmentStatuses",
    "onSearch": "searchAssignmentStatus",
    "errors": "assignmentStatus",
    "testId": "statusInput"
  }
]

const FieldComponents: Record<string, React.FC<typeof CreateAssignmentInput | typeof AutoCompleteInput>> = {
  CreateAssignmentInput,
  AutoCompleteInput
}



interface IComponentProps {
  fieldProperties: IField;
  assignment: AssignmentStructure;
  options: any;
  searchFunctions: any;
  errors: any;
}
const Field = (props: IComponentProps): JSX.Element => {
  const { fieldProperties, errors, assignment, options, exclusions, handleChange, ...rest } = props
  const label = fieldProperties.label
  const component = fieldProperties?.component
  const name = fieldProperties?.name
  const value = assignment?.[fieldProperties?.value]
  const fieldOptions = options?.[fieldProperties?.options]
  const searchFunction = rest?.searchFunctions?.[fieldProperties?.onSearch]
  const error = errors?.[fieldProperties?.name]
  const testId = fieldProperties?.testId
  const Component: React.FC = FieldComponents[component]
  if (exclusions.includes(name)) return null
  return <Component
    id={name}
    label={label}
    name={name}
    value={value}
    options={fieldOptions}
    onSearch={searchFunction}
    errors={error}
    testId={testId}
    onChange={handleChange}
    type={fieldProperties?.type}
    {...rest}
  />
};


const FieldsFactory = (props: IGeneralInformation): JSX.Element => {
  return (
    <>
      {fields.map((field, index) => <Field key={index} fieldProperties={field} {...props} />)}
    </>
  )

}

const GeneralInformation = (props: IGeneralInformation) => {
  const { createAssignment, options, assignment } = props;
  const generalRef = React.useRef<HTMLElement>(null);

  const goToNextInput = (event: React.KeyboardEvent): void => {
    if (event.key !== "Enter") return; // Ensure the function only runs on Enter key press
  
    const children = generalRef?.current?.children;
    const childArray = Array.from(children || []);
  
    const currentChildIndex = childArray.findIndex(child => child.contains(event.target as Node));
    if (currentChildIndex === -1) return; // If the current element is not found, exit
  
    let sibling = childArray[currentChildIndex].nextElementSibling;
  
    // Find the next input that is not disabled and not of type submit
    while (sibling && sibling.querySelector('input[disabled], input[type="submit"]')) {
      sibling = sibling.nextElementSibling;
    }
  
    sibling?.querySelector('input:not([disabled]):not([type="submit"])')?.focus();
  };
  
  const handleEnterKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      goToNextInput(event);
    }
  };

  const conditionalFields = [
    { name: "underwriterId", render: createAssignment },
    { name: "createDate", render: createAssignment },
    { name: "statusDate", render: createAssignment },
    { name: "statusId", render: createAssignment },
    { name: "acceptDate", render: createAssignment },
  ];

  const exclusions = conditionalFields
    .filter((field) => !field.render)
    .map((field) => field.name);

  return (
    <>
      <GeneralInformationWrapper>
        <CreateAssignmentContainer
          ref={generalRef}
          onKeyPress={handleEnterKeyDown}
        >
          <FieldsFactory {...props} exclusions={exclusions} />
        </CreateAssignmentContainer>
      </GeneralInformationWrapper>
    </>
  );
};

export default GeneralInformation;