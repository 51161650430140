import * as React from "react";
import styled from "styled-components";
import MUIPopover from '@mui/material/Popover';
import { Layers } from "../../pages/styles/GlobalStyles";


export const PopoverContent = styled.div`
  background-color: white;
  position: absolute;
  border: 1px solid #e1e3e5;
  visibility: visible;
  border-radius: 0.25rem;
  padding-top: 0.5rem;
  z-index: ${Layers.Modal};
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
`;

export const PopoverWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  display: inline-block;
  ${PopoverContent} {
    visibility: hidden;
  }
  &:hover {
    ${PopoverContent} {
      min-width: 150px;
      visibility: visible;
      .caret {
        content: "  ";
        height: 5px;
        width: 5px;
        display: block;
        position: absolute;
        top: 0;
        transform: translate(50%, -50%) rotate(45deg);
        border: 1px solid #e1e3e5;
        border-right: none;
        border-bottom: none;
        background-color: white;
      }
      menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      button {
        text-align: left;
        background: none;
        border: none;
        width: 100%;
      }
    }
  }
`;

export const PopoverTrigger = styled.div``;

interface IPopoverProps {
  parent: React.RefObject<HTMLDivElement>;
  content: React.ReactNode;
  isOpen?: boolean;
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
}
const Popover = (props: IPopoverProps): JSX.Element => {
  const {anchorEl, isOpen} = props;
  return (
    <MUIPopover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {props.children}
    </MUIPopover>
  );
};

export default React.forwardRef(Popover);
