import {auth} from '../index';
import { IDepartment } from '../types';

export const getDepartments = async (): Promise<IDepartment[]> => {
  try {
    const response = await auth.get("/organization_hierarchy/");
    return response.data.organization_hierarchy;
  } catch (error) {
    console.error("Error fetching departments:", error);
    return [];
  }
};
