import * as React from "react";
import styled from "styled-components";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { AddUserButton } from "../../../styles";

export const NoPermissionsDiv = styled.div`
  .permission {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.4rem;
    h1 {
      font-size: 2rem;
    }
  }
    .button-container {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
    
`;

interface INoPermissionsSetProps {
  onClose: () => void;
  message: string;
}
const NoPermissionsSet = (props: INoPermissionsSetProps) => {
  const handleClose = () => {
    props.onClose();
  };
  return (
    <NoPermissionsDiv>

      <div className="permission">
        <ErrorOutlineIcon style={{ fontSize: "1.8rem", color: "red" }} />
        {props.message}
      </div>

      <div className="button-container">

        <AddUserButton onClick={handleClose}>
          Ok
        </AddUserButton>
      </div>
    </NoPermissionsDiv>
  );
};
export default NoPermissionsSet;
