import * as React from 'react';
import { NoAlertsDiv } from '../styles';



const NoAlertsView = () => {
    return (
     <NoAlertsDiv data-testid="no-alerts-view">No Alerts Today</NoAlertsDiv>
    )
}
export default NoAlertsView;