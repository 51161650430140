import * as React from 'react'
import { CreateAssignmentInput } from '../../../styles';
import PerformantAccordion from '../../../../../../../components/PerformantAccordion';
import { InsuredSubject } from '../../../../../../../api/v1/assignments/classes/insuredSubjects';
import AutoCompleteInput from '../../AutocompleteInput';

const filterUniqueLabels = (objects) => {
    const uniqueLabels = new Set();
    return objects.filter(obj => {
        if (!uniqueLabels.has(obj.label)) {
            uniqueLabels.add(obj.label);
            return true;
        }
        return false;
    });
};

interface ISubjectDescription {
    insuredSubject: InsuredSubject
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
    searchFunctions: ReturnType<searchFunctions>
    index: number
    options: any
    errors: any
}

const SubjectDescription = (props: ISubjectDescription): JSX.Element => {
    const {
        searchFunctions,
        insuredSubject,
        handleChange,
        setFieldValue,
        index,
        options,
        errors,
    } = props;

    const filteredInsuredObjects = React.useMemo(() =>
        filterUniqueLabels(options?.insuredObjects || []),
        [options?.insuredObjects]
    );

    console.log("checking filtered options", filteredInsuredObjects);

    return (
        <>
            <CreateAssignmentInput
                label="Insured"
                name={`insuredSubjects[${index}].insured`}
                className={'go-next-on-enter'}
                value={insuredSubject?.insured}
                onChange={handleChange}
                errors={errors?.insured}
                testId={`insuredSubjects[${index}].insured`}
            />
            <AutoCompleteInput
                label='Object'
                isForeign={false}
                className={'go-next-on-enter'}
                name={`insuredSubjects[${index}].insuredObject`}
                value={insuredSubject?.insuredObject}
                options={filteredInsuredObjects}
                onSearch={searchFunctions.searchInsuredObject}
                setFieldValue={setFieldValue}
                errors={errors?.insuredObject}
                testId={`insuredSubjects[${index}].insuredObject`}
            />

            <CreateAssignmentInput
                label="Policy No"
                name={`insuredSubjects[${index}].policyNumber`}
                value={!insuredSubject?.policyNumber ? "" : insuredSubject?.policyNumber}
                onChange={handleChange}
                className={'go-next-on-enter'}
                errors={errors?.policyNumber}
                testId={`insuredSubjects[${index}].policyNumber`}
            />
        </>
    )
}

export default SubjectDescription