import styled, {keyframes} from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const StyledLeadershipBanner = styled.section`
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: flex-start;
  background-color: #186ade;
  justify-content: space-between;

  .notification-handle {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: white;
  }
`;

export const LeadershipBannerText = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
`;

export const AnimatedText = styled.span`
  animation: ${slideIn} 0.5s ease-in-out;
`;
