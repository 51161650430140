import * as React from 'react';
import { TableContainer, TableFilteringOptions, TableHeading, Table } from "../../AssignmentTable/styles";
import { Skeleton } from '@mui/material';



const EmailsSkeleton = () => {
  return(


        <Table>
          <tbody>
            {Array.from({length:10}).map((_, currentRow) => (
              <tr key={currentRow}>  
                <td colSpan={10}>
                <Skeleton className="skeletondiv" variant= "rectangular" height={40} animation="wave" />
                
              </td>        
                </tr>
        ))}
        </tbody>
        </Table>

)
};
export default EmailsSkeleton;