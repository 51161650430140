import React from "react";
import styled from "styled-components";

const StyledToggle = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  height: 24px;

  .text {
    position: absolute;
    left: 0;
    transform: translateY(26%);
  }

  .toggle {
    min-width: 75px;
    color: white;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    transition: all 0.4s;

    & input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    & .slider {
      padding: 0 0.6rem;
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #DDDFE7;
      border-radius: 34px;
      transition: all 0.4s ease;

      &:before {
        position: absolute;
        content: "";
        height: 24px;
        width: 24px;
        left: 0;
        transform: translateX(0);
        background-color: white;
        border-radius: 50%;
        transition: all 0.4s ease;
        box-sizing: border-box;
        box-shadow: 
            inset 0px 1px 3px #00000045, 
            0px 0px 1px 1px #00000045;   
      }

      .toggle-label {
        min-width: 0px;
        pointer-events: none;
        position: absolute;
        top: 0;
        color: black;
        bottom: 0;
        font-size: 0.8rem;
        margin: auto;
        width: 100%;
        z-index: 1000;
        display: flex;
        align-items: center;
        padding: 0 0.3rem;
        box-sizing: border-box;
        z-index: 1;
        transition: all 0.4s ease;
        &.negative {
          
          justify-content: flex-end;
          text-align: right;
          right: 0;
        }
        &.positive {
          justify-content: flex-start;

          overflow: hidden;
          width:0px;
          color: white;
          left: 0;
        }

      }
    }

    & input:checked + .slider {
      background-color: #91d5ff;
      background-color: #91d5ff;
      box-shadow: inset 4px 7px 5px #6f889869,
      inset 0px 1px 3px #2f85bc;
      box-sizing: border-box;
      color: blue;
      box-sizing: border-box;
      &:before {
        left: 100%;
        transform: translateX(-100%);
      }
      .toggle-label.negative {
        width: 0;
        overflow: hidden;
        color: inherit;
        opacity: 0;
        }
      .toggle-label.positive {
          overflow: visible;
          width: 100%;
        }
      & input:focus + .slider {
        box-shadow: 
            inset 0px 1px 2px #91d5ff, 
            inset 0px 1px 3px #2d91d1;   
        }
      }
    }
`;


const Toggle = (props): JSX.Element => {
  const { isActive } = props;
  const onText = props.onText || "Active";
  const offText = props.offText || "Off";
  const visibleText = props.checked ? onText : offText;
  return (
    <StyledToggle>
      <div className="toggle">
        <input type="checkbox" {...props} checked={isActive} />
        <div className="slider">
          <div className="negative toggle-label">{offText}</div>
          <div className="positive toggle-label">{onText}</div>
        </div>
      </div>
    </StyledToggle>
  );
};

export default Toggle;
